export class Account {

    public username: string;
    // Access (10 == Read only, 20 == Read-Write, 30 == Full control)
    public access: number;

    constructor(username: string, access: number) {
        this.username = username;
        this.access = access;
    }
}