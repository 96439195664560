import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService } from '../_services/authentication.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {

    constructor(private router: Router, private authenticationService: AuthenticationService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.authenticationService.isConnected == true)
            return true;
        var params: any = { returnUrl: state.url.split('?')[0] };
        if (route != undefined && route.queryParams != undefined) {
            params.logo = (route.queryParams.logo != undefined) ? route.queryParams.logo : undefined;
            params.username = (route.queryParams.username != undefined) ? route.queryParams.username : undefined;
            params.password = (route.queryParams.password != undefined) ? route.queryParams.password : undefined;
            params.disableMenu = (route.queryParams.disableMenu != undefined) ? route.queryParams.disableMenu : undefined;
        }
        // not logged in so redirect to login page with the return url
        this.router.navigate(['/login'], { queryParams: params});
        return false;
    }
}
