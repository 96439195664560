import { Component, OnChanges, OnDestroy, OnInit, AfterViewInit, ElementRef, ViewChild, Input, EventEmitter, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DateAdapter } from '@angular/material/core';

import { SiteService } from '../../../_services/site.service';
import { ALERT_TYPE, AlertService } from '../../../_services/alert.service';
import { LeafletService } from '../../../_services/leaflet.service';
import { UtilsService } from '../../../_services/utils.service';

import { Site } from '../../../_models/site';
import { MapData } from '../../../_models/mapData';

@Component({
    selector: 'element-edit-site',
    templateUrl: 'editSite.component.html',
    styleUrls: ['editSite.component.scss']
})
export class EditSiteComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {

    // The current site
    @Input() currentSite: Site;
    // Event when we are done editing a site
    @Output() doneEditing: EventEmitter<any> = new EventEmitter<any>();

    // Boolean to know if user wants to edit the address (to avoid mistakes)
    public canEditAddress: Boolean = false;
    // Boolean when we are requesting the server
    public isSavingSite: Boolean = false;

    // The map container
    @ViewChild('map', { static: true }) mapContainer: ElementRef;
    private mapId = 'map';

    // List of subs
    private subs: Subscription[] = [];

    // Site form
    siteForm = this.fb.group({
        siteId: [{value: '', disabled: true}],
        name: [''],
        desc: [''],
        address: [{value: '', disabled: true}]
    });
    // Admin site form
    adminSiteForm = this.fb.group({
        siteId: [{value: '', disabled: true}],
        contact: [''],
        expiration: [''],
        type: ['']
    });
    // Dragonfly site form
    dragonflySiteForm = this.fb.group({
        siteId: [{value: '', disabled: true}],
        enabled: [undefined],
        expiration: [''],
        type: ['']
    });

    constructor(public siteService: SiteService, private fb: FormBuilder, private alertService: AlertService, private _adapter: DateAdapter<any>,
                private router: Router, private leafletService: LeafletService, private utilsService: UtilsService) {}

    ngOnInit() {
    }

    ngOnChanges() {
        // Set the form values
        this.siteForm.patchValue(this.currentSite || {});
        this.adminSiteForm.get('siteId').setValue(this.currentSite.siteId);
        this.dragonflySiteForm.get('siteId').setValue(this.currentSite.siteId);
        if (this.currentSite && this.currentSite.admin)
            this.adminSiteForm.patchValue(this.currentSite.admin);
        if (this.currentSite && this.currentSite.admin && this.currentSite.admin.dragonfly)
            this.dragonflySiteForm.patchValue(this.currentSite.admin.dragonfly);
        // Set the date values if needed
        if (this.adminSiteForm.get('expiration').value && this.adminSiteForm.get('expiration').value.length > 0)
            this.adminSiteForm.get('expiration').setValue(new Date(this.adminSiteForm.get('expiration').value));
        if (this.dragonflySiteForm.get('expiration').value && this.dragonflySiteForm.get('expiration').value.length > 0)
            this.dragonflySiteForm.get('expiration').setValue(new Date(this.dragonflySiteForm.get('expiration').value));
        // Check disabled fields
        this.productChanged(this.dragonflySiteForm, { checked: this.dragonflySiteForm.get('enabled').value });
        // Try to draw the map
        this.drawMap();
    }

    // When user changes the address manually with keyboard
    addressFocusout(event) {
        if (event && event.target && event.target.value)
            this.leafletService.moveMapToCoordinates(this.utilsService.getCoordinateFromAddress(event.target.value));
    }

    ngAfterViewInit() {
        // Try to draw the map
        this.drawMap();
        // Set date locale
        this._adapter.setLocale('en-GB');
    }

    private drawMap() {
        if (this.currentSite != undefined && this.mapContainer != undefined) {
            var self = this;
            this.leafletService.drawMap(this.mapId, new MapData({
                crosshair: true,
                isDisabled: true,
                showFloorplan: false,
                centerOnFloorplan: false,
                fullscreenControl: false,
                onMove: function(e) {
                    // Update the address input
                    if (e && e.target && e.target.getCenter) {
                        self.siteForm.controls['address'].patchValue(e.target.getCenter().lat.toFixed(7) + ',' + e.target.getCenter().lng.toFixed(7));
                        self.siteForm.markAsDirty();
                    }
                }
            }));
        }
    }

    ngOnDestroy() {
        // Clear the subs
        this.subs.forEach(function(sub) { sub.unsubscribe(); });
        // Remove the map and clear the html element passed as parameter
        this.leafletService.removeMap();
    }

    // When user saves the site
    onSubmit() {
        var newSite = this.siteForm.getRawValue();
        var newAdminSite = this.adminSiteForm.getRawValue();
        var newDragonflySite = this.dragonflySiteForm.getRawValue();
        // Check for null date(s)
        if (newAdminSite.expiration == null || newAdminSite.expiration == 'Invalid Date')
            delete newAdminSite.expiration;
        else
            newAdminSite.expiration = new Date(newAdminSite.expiration).toUTCString();
        if (newDragonflySite.expiration == null || newDragonflySite.expiration == 'Invalid Date')
            delete newDragonflySite.expiration;
        else
            newDragonflySite.expiration = new Date(newDragonflySite.expiration).toUTCString();
        // Check the configuration is correct
        if (this.siteForm.invalid || this.adminSiteForm.invalid || this.dragonflySiteForm.invalid ||
            !newSite.siteId || !newAdminSite.siteId || !newDragonflySite.siteId) {
            // Error
            this.alertService.showMessage(ALERT_TYPE.Error, "The site configuration is incorrect");
            return;
        }
        // If nothing has been changed, go back without doing anything
        if (this.siteForm.dirty == false && this.adminSiteForm.dirty == false && this.dragonflySiteForm.dirty == false) {
            // Navigate back
            this.doneEditing.emit();
            return;
        }
        this.isSavingSite = true;
        // Request the server to update the site
        const saveSitePromise = this.siteService.saveSite(newSite);
        const saveAdminSitePromise = this.siteService.saveAdminSite(newAdminSite);
        const saveDragonflySitePromise = this.siteService.saveAdminSite(newDragonflySite, 'dragonfly');
        Promise.all([saveSitePromise, saveAdminSitePromise, saveDragonflySitePromise]).then((res) => {
            // Success
            this.isSavingSite = false;
            // Show success message
            this.alertService.showMessage(ALERT_TYPE.Success, "The site has been correctly updated");
            // Navigate back
            this.doneEditing.emit(res[1]);
        }, (err) => {
            // Error
            console.log(err);
            this.isSavingSite = false;
            this.alertService.showMessage(ALERT_TYPE.Error, "An error occurred, cannot update the site");
        });
    }

    // When user clicks the 'Edit' button to edit the address
    setEditAddress(val) {
        if (val == true) {
            this.canEditAddress = true;
            // Update the form control
            this.siteForm.controls['address'].enable();
            // Enable the map
            this.leafletService.enableMap();
        } else {
            this.canEditAddress = false;
            // Update the form control
            this.siteForm.controls['address'].disable();
            // Enable the map
            this.leafletService.disableMap();
        }
    }

    // When user toggles a product checkbox
    public productChanged(form, event) {
        if (!(event && event.checked != undefined))
            return;
        // Set the current value
        form.get('enabled').setValue(event.checked);
        form.markAsDirty();
        // Update and enable/disable the expiration field
        if (event.checked == false) {
            form.get('expiration').setValue(undefined);
            form.get('expiration').disable();
            form.get('type').disable();
            form.get('type').setValue(undefined);
        } else {
            form.get('expiration').enable();
            form.get('type').enable();
            form.get('type').setValue(this.siteService.siteType[0].value);
        }
    }

}