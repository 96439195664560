import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormBuilder, Validators } from '@angular/forms';

import { MapService } from '../../_services/map.service';
import { UtilsService } from '../../_services/utils.service';

const REFRESH_TIME = 1000;

@Component({
	templateUrl: 'maps.component.html',
	styleUrls: ['maps.component.scss']
})
export class DragonflyMapsComponent implements OnDestroy {

	// List of maps
	public maps;
	private isGettingMaps: Boolean = false;

	// Error message
    public errorMessage  = '';

    // Stations table options
    public mapsGetIdFct = (n) => n.map_name;
    public mapsTableOptions = {
    	editable: true,
    	deletable: true
    }
    public mapsDisplayedColumns;
    public mapsAttributesToDisplay;
    public mapsFormGroup;
    public mapsAttributesToEdit;

	// List of subs
	private subs: Subscription[] = [];
	// Get sub
    private subGetData;

    constructor(private mapService: MapService, private utilsService: UtilsService, private fb: FormBuilder) {
    	var self = this;
    	// Instantiate the table variables
        // Displayed columns
        this.mapsDisplayedColumns = ['map_name', 'description', 'status', 'last_change'];
        // Attributes to display
        this.mapsAttributesToDisplay = [
            {display: 'Name', columnDef: 'map_name', getFct: (n) => {
                if (n == undefined)
                    return;
                return n.map_name;
            }},
            {display: 'Description', columnDef: 'description', getFct: (n) => {
                if (n == undefined)
                    return;
                return n.description;
            }},
            {display: 'Status', columnDef: 'status', getFct: (n) => {
                if (n == undefined)
                    return;
                return (n.status == 0) ? 'Not synchronized' : 'Synchronized';
            }},
            {display: 'Last change', columnDef: 'last_change', getFct: (n) => {
                if (n == undefined || n.timestamp == undefined || !isFinite(n.timestamp))
                    return;
                return self.utilsService.formatTimeFull(new Date().getTime() - (n.timestamp * 1000));
            }}
        ];
        this.mapsFormGroup = this.fb.group({
			map_name: [{value: '', disabled: true}],
			description: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(50), Validators.pattern('^[0-9a-zA-Z\\ _\\-\\.@]+$')]]
        });
        this.mapsAttributesToEdit = [
            {display: 'Name', attribute: 'map_name', width: '90%'},
            {display: 'Description', attribute: 'description', width: '90%'}
        ];
    }

    ngOnDestroy() {
        // Clear the subs
        this.subs.forEach(function(sub) { sub.unsubscribe(); });
        // Clear the current subscription if there is one
        clearInterval(this.subGetData);
        this.isGettingMaps = false;
	}

	ngOnInit() {
		// Get the list of maps
		this._getDataRecurrently();
	}






	/* ------------------------------------------------------------------------- */
    /* -                           Private functions                           - */
    /* ------------------------------------------------------------------------- */

	// Get the list of maps recurrently (depending on the REFRESH_TIME variable)
    _getDataRecurrently() {
        var self = this;
        // Clear the current subscription if there is one
        clearInterval(this.subGetData);
        // Start requesting recurrently
        this.subGetData = setInterval(function() {
            self._getData();
        }, REFRESH_TIME);
        // Start immediately
        self._getData();
    }

    // Get the list of maps
    private _getData() {
		if (this.isGettingMaps == true)
			return;
		this.isGettingMaps = true;
    	// Get all the maps
    	this.mapService.getMaps().then((maps) => {
    		// Got the map(s)
            // Remove error message if needed
            this.errorMessage = undefined;
            // Update our maps
    		this.maps = maps;
    	}, (err) => {
            console.log(err);
    		// Display an error
    		this.errorMessage = (err === 'Unknown Error') ? "Cannot connect to the Dragonfly server" : "Cannot get the list of maps from the Dragonfly server";
    		this.maps = [];
    	}).finally(() => {
            this.isGettingMaps = false;
        });
    }

}