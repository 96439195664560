import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { SiteService } from './site.service';
import { UtilsService } from './utils.service';
import { ConfigService } from './config.service';

import { Site } from '../_models/site';
import { VMarker } from '../_models/vmarker';

@Injectable({ providedIn: 'root' })
export class VMarkerService {

    // The current site
    private currentSite: Site;

    constructor(private http: HttpClient, private siteService: SiteService, private utilsService: UtilsService,
                private configService: ConfigService) {
        // Get the selected site
        this.siteService.currentSite.subscribe(site => {
            this.currentSite = site;
        });
    }

    /* -------------------------------------------------------- */
    /*                    Public functions                      */
    /* -------------------------------------------------------- */

    // Get the list of vmarkers for this site
    public getVMarkers(siteId: string) {
        return new Promise((resolve, reject) => {
            // Check site ID
            if (siteId == undefined)
                reject("Missing site ID");
            else {
                // Make a GET request to get the list of vmarkers
                this.http.get(`${this.configService.config.network.cvnavApiUrl}/sites/${siteId}/dragonfly/vmarkers/`).subscribe((vmarkers: any) => {
                    var result = (vmarkers == undefined || vmarkers.markers == undefined || vmarkers.markers.map == undefined) ? [] :
                                  vmarkers.markers.map(function(e) { return VMarker.fromData(e); });
                    // Success
                    resolve(result);
                }, (err) => {
                    // Error
                    reject(err);
                });
            }
        });
    }

    // Delete the vmarker by requesting the server
    public deleteVMarker(vmarkerId: number, site?: Site, http?: HttpClient) {
        return new Promise((resolve, reject) => {
            site = (site != undefined) ? site : this.currentSite;
            http = (http != undefined) ? http : this.http;
            // Check the vmarker object
            if (vmarkerId == undefined || site == undefined || site.siteId == undefined) {
                reject();
            } else {
                // Make a DELETE request to delete the vmarker
                http.delete(`${this.configService.config.network.cvnavApiUrl}/sites/${site.siteId}/dragonfly/vmarkers/${vmarkerId}/`).subscribe((res: any) => {
                    // Success
                    // Make sure it was a success
                    if (res != undefined && res.status == "success")
                        resolve();
                    else
                        reject();
                }, (err) => {
                    // Error
                    reject(err);
                });
            }
        });
    }

    // Save the vmarker by requesting the server
    public saveVMarker(newVMarker: any, site?: Site, http?: HttpClient) {
        return new Promise((resolve, reject) => {
            site = (site != undefined) ? site : this.currentSite;
            http = (http != undefined) ? http : this.http;
            // Check the vmarker object
            if (newVMarker == undefined || site == undefined || site.siteId == undefined) {
                reject();
            } else {
                var url = `${this.configService.config.network.cvnavApiUrl}/sites/${site.siteId}/dragonfly/vmarkers/`;
                // Create the form data object
                var formData: any = {
                    levelID: newVMarker.levelId, lat: newVMarker.lat, lng: newVMarker.lng, label: newVMarker.label,
                    alt: newVMarker.alt, orientation: newVMarker.orientation, onwall: newVMarker.onWall
                };
                // Trim the label if needed
                if (formData.label != undefined && formData.label.trim != undefined)
                    formData.label = formData.label.trim();
                // In case we have an ID, we are updating the existing visual marker
                if (newVMarker.id != undefined) {
                    url = `${this.configService.config.network.cvnavApiUrl}/sites/${site.siteId}/dragonfly/vmarkers/${newVMarker.id}/`;
                    formData.id = newVMarker.id;
                }
                // Make a POST request to update the vmarker
                http.post(url, formData).subscribe((res: any) => {
                    // Success
                    if (res != undefined && res.status == "success")
                        resolve(res);
                    else
                        reject();
                }, (err) => {
                    // Error
                    reject(err);
                });
            }
        });
    }

    // Get the visual marker image
    public getVMarkerImage(vmarkerId: number, site?: Site, http?: HttpClient) {
        var self = this;
        return new Promise((resolve, reject) => {
            site = (site != undefined) ? site : this.currentSite;
            http = (http != undefined) ? http : this.http;
            // Check the vmarker object
            if (vmarkerId == undefined || site == undefined || site.siteId == undefined) {
                reject();
            } else {
                // Make a GET request to get the vmarker image
                http.get(`${this.configService.config.network.cvnavApiUrl}/sites/${site.siteId}/dragonfly/vmarkers/${vmarkerId}/image`, {responseType: 'arraybuffer'}).subscribe((res: any) => {
                    // Success
                    // Transform the arraybuffer into an image
                    var base64 = self.utilsService.arrayBufferToBase64(res);
                    // Return the base64 string
                    resolve("data:image/png;base64," + base64);
                }, (err) => {
                    // Error
                    reject(err);
                });
            }
        });
    }
}