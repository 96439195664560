import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'alert',
  template: `
    <span class="noselect">{{data.message}}</span>
  `,
  styles: [`
    .noselect {
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
  `]
})
export class AlertComponent {

    constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) {
        
    }

}