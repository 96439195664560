<h1 class="text-center my-4">Login</h1>

<form (ngSubmit)="login()" [formGroup]="loginForm">

    <!-- Username -->
    <div class="d-flex justify-content-center mb-2">
        <mat-form-field style="min-width: 250px !important" color="accent">
            <input matInput placeholder="Email" name="username" formControlName="email">
            <mat-error *ngIf="loginForm.controls['email'].errors?.email && !loginForm.controls['email'].errors?.required">
                Please enter a valid email address
            </mat-error>
            <mat-error *ngIf="loginForm.controls['email'].errors?.required">
                Email is required
            </mat-error>
        </mat-form-field>
    </div>

    <!-- Password -->
    <div class="d-flex justify-content-center mb-5">
        <mat-form-field style="min-width: 250px !important" color="accent">
            <input matInput placeholder="Password" type="password" name="password" formControlName="password">
            <mat-error *ngIf="loginForm.controls['password'].errors?.required">
                Password is required
            </mat-error>
        </mat-form-field>
    </div>

    <!-- Login button -->
    <div class="d-flex justify-content-center pb-4">
        <button mat-raised-button *ngIf="loading == false" color="accent">Login</button>
        <mat-spinner *ngIf="loading == true" color="accent" [diameter]="36"></mat-spinner>
    </div>

</form>

<!-- Forgot password -->
<div class="d-flex justify-content-center pb-4" *ngIf="loading == false">
    <button mat-raised-button color="primary" routerLink="/reset-password">Forgot password</button>
</div>