<h1 class="text-center mb-4">Create new Dragonfly license</h1>

<!-- Spinner -->
<mat-spinner *ngIf="currentSite == undefined || allSites == undefined" class="mx-auto" color="accent"></mat-spinner>

<!-- Form -->
<form id="createNewLicenseFormId" (ngSubmit)="onSubmit()" [formGroup]="createNewLicenseForm" *ngIf="currentSite != undefined && allSites != undefined">

	<mat-tab-group mat-align-tabs="center" color="accent">

		<!-- Authentication tab -->
		<mat-tab label="Authentication">

			<!-- Account section -->
			<div class="p-3 mx-auto w-100 w-sm-80 w-md-85 w-xl-65 d-flex flex-column flex-sm-row">
				<!-- Title column -->
				<div class="text-left text-md-center font-weight-bold pb-4 w-100 w-sm-30 w-md-50">Account</div>
				<!-- Settings column -->
				<div class="d-flex flex-column w-100 w-sm-70 w-md-50">

					<!-- Username -->
					<mat-form-field class="pb-3" style="width: 300px" color="accent">
						<mat-label>Username</mat-label>
						<input matInput placeholder="Username" formControlName="username">
						<mat-error *ngIf="createNewLicenseForm.controls['username'].errors?.required">
				          Required
				        </mat-error>
					</mat-form-field>

					<!-- Password -->
					<mat-form-field class="pb-3" style="width: 300px" color="accent">
						<mat-label>Password</mat-label>
						<input matInput placeholder="Password" formControlName="password" [type]="hide ? 'password' : 'text'">
						<button type="button" mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
							<mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
						</button>
						<mat-error *ngIf="createNewLicenseForm.controls['password'].errors?.required">
				          Required
				        </mat-error>
				        <mat-error *ngIf="createNewLicenseForm.controls['password'].errors?.minlength">
				          Must be at least 6 characters long
				        </mat-error>
					</mat-form-field>

				</div>
			</div>

		</mat-tab>

		<!-- Site tab -->
		<mat-tab label="Site">

			<!-- Details section -->
			<div class="p-3 mx-auto w-100 w-sm-80 w-md-85 w-xl-65 d-flex flex-column flex-sm-row">
				<!-- Title column -->
				<div class="text-left text-md-center font-weight-bold pb-4 w-100 w-sm-30 w-md-50">Details</div>
				<!-- Settings column -->
				<div class="d-flex flex-column w-100 w-sm-70 w-md-50">

					<!-- Site ID -->
					<mat-form-field class="pb-3" style="width: 120px; height: 77px;" color="accent">
						<mat-select placeholder="Site ID" formControlName="siteId">
							<mat-option *ngFor="let site of allSites" [value]="site.siteId">
								{{site.siteId}}
								<span *ngIf="site.name != undefined && site.name.length > 0"> - {{site.name}}</span>
							</mat-option>
						</mat-select>
					</mat-form-field>

					<!-- Company name -->
					<mat-form-field class="pb-3" style="width: 255px" color="accent">
						<mat-label>Company name</mat-label>
						<input matInput placeholder="Company name" formControlName="company">
						<mat-error *ngIf="createNewLicenseForm.controls['company'].errors?.required">
				          Required
				        </mat-error>
					</mat-form-field>

				</div>
			</div>

		</mat-tab>

		<!-- License tab -->
		<mat-tab label="License">

			<!-- Details section -->
			<div class="p-3 mx-auto w-100 w-sm-80 w-md-85 w-xl-65 d-flex flex-column flex-sm-row">
				<!-- Title column -->
				<div class="text-left text-md-center font-weight-bold pb-4 w-100 w-sm-30 w-md-50">Details</div>
				<!-- Settings column -->
				<div class="d-flex flex-column w-100 w-sm-70 w-md-50">

					<!-- Maximum number of devices -->
					<mat-form-field class="pb-3" style="width: 200px; height: 77px;" color="accent">
						<input matInput type="number" placeholder="Max number of devices" formControlName="max_instances" min="0">
						<mat-error *ngIf="createNewLicenseForm.controls['max_instances'].errors?.required">
				          Required
				        </mat-error>
				        <mat-error *ngIf="createNewLicenseForm.controls['max_instances'].errors?.min">
				          Must be a positive number
				        </mat-error>
					</mat-form-field>

					<!-- Expiration date -->
					<mat-form-field class="pb-3" style="width: 120px; height: 77px;" color="accent">
						<input matInput [min]="currentDate" [matDatepicker]="picker" placeholder="Expiration date" formControlName="license_end_timestamp">
						<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
						<mat-datepicker #picker></mat-datepicker>
					</mat-form-field>

				</div>
			</div>

		</mat-tab>

	</mat-tab-group>

	<!-- Buttons -->
	<div class="d-flex justify-content-center pt-4" *ngIf="currentSite != undefined && allSites != undefined">
		<div *ngIf="isCreatingLicense != true">
			<button mat-raised-button class="mr-2 lightColor" (click)="createNewLicenseForm.reset()">Reset</button>
			<button mat-raised-button [disabled]="createNewLicenseForm.invalid || createNewLicenseForm.pristine" form="createNewLicenseFormId" color="accent" class="ml-2">Create</button>
		</div>
		<!-- Spinner -->
		<mat-spinner *ngIf="isCreatingLicense == true" color="accent" [diameter]="36"></mat-spinner>
	</div>

</form>
