<h1 class="text-center mb-4">Dragonfly Maps</h1>

<!-- Spinner -->
<div *ngIf="maps == undefined">
	<mat-spinner class="mx-auto" color="accent" [diameter]="36"></mat-spinner>
</div>

<!-- Error message -->
<h3 style="text-align: center" *ngIf="errorMessage != undefined">{{errorMessage}}</h3>

<!-- Maps -->
<div *ngIf="maps != undefined && maps.length > 0">
	<element-table
		[elements]="maps"
		[elementName]="'Dragonfly map'"
		[getElementIdFct]="mapsGetIdFct"
		[options]="mapsTableOptions"
		[displayedColumns]="mapsDisplayedColumns"
	    [attributesToDisplay]="mapsAttributesToDisplay"
		[attributesToEdit]="mapsAttributesToEdit"
		[formGroup]="mapsFormGroup"
		[editElementFct]="mapService.updateMap"
		[deleteElementFct]="mapService.deleteMap"
	></element-table>
</div>