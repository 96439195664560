import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { SiteService } from './site.service';
import { ConfigService } from './config.service';

import { Site } from '../_models/site';

// Technology
export enum TECHNOLOGY {
    Navimote = "navimote",
    Dragonfly = "dragonfly"
};

@Injectable({ providedIn: 'root' })
export class HistoryService {

    // The current site
    private currentSite: Site;

    constructor(private http: HttpClient, private siteService: SiteService, private configService: ConfigService) {
        // Get the selected site
        this.siteService.currentSite.subscribe(site => {
            this.currentSite = site;
        });
    }

    /* -------------------------------------------------------- */
    /*                    Public functions                      */
    /* -------------------------------------------------------- */

    // Get the history status (activated or not) for this site
    public getHistoryStatus(siteId: string, techno: TECHNOLOGY = TECHNOLOGY.Navimote) {
        return new Promise((resolve, reject) => {
            // Check site ID
            if (siteId == undefined)
                reject("Missing site ID");
            else {
                // Make a GET request to get the history status
                this.http.get(`${this.configService.config.network.loggerApiUrl}/${techno}/sites/${siteId}/historyStatus/`, { headers: {
                    'Cache-Control':  'no-cache, no-store, must-revalidate, post-check=0, pre-check=0'
                }}).subscribe((res: any) => {
                    // Success
                    resolve(res);
                }, (err) => {
                    // Error
                    reject(err);
                });
            }
        });
    }

    // Return the dates that have data for this sites
    // Result format: ['mm/dd/yyyy', ...]
    public getEnabledDates(siteId: string, techno: TECHNOLOGY = TECHNOLOGY.Navimote) {
        return new Promise((resolve, reject) => {
            // Check parameters
            if (siteId == undefined)
                reject("Missing site ID");
            else {
                // Make a GET request to get the history status
                this.http.get(`${this.configService.config.network.loggerApiUrl}/${techno}/sites/${siteId}/allEnabledDates/`, { headers: {
                    'Cache-Control':  'no-cache, no-store, must-revalidate, post-check=0, pre-check=0'
                }}).subscribe((res: string[]) => {
                    // Success
                    resolve(res);
                }, (err) => {
                    // Error
                    reject(err);
                });
            }
        });
    }

    // Get the pre-signed S3 url for this date
    public getPresignedS3Url(siteId: string, day, month, year, techno: TECHNOLOGY = TECHNOLOGY.Navimote) {
        return new Promise((resolve, reject) => {
            // Check site ID
            if (siteId == undefined)
                reject("Missing site ID");
            else if (day == undefined || month == undefined || year == undefined)
                reject("Incorrect date parameters");
            else {
                // Check day and month digits
                month = (month <= 9) ? '0'+month : month;
                day = (day <= 9) ? '0'+day : day;
                // Make a GET request to get the history status
                this.http.get(`${this.configService.config.network.loggerApiUrl}/${techno}/sites/${siteId}/years/${year}/months/${month}/days/${day}/devices/`, { headers: {
                    'Cache-Control':  'no-cache, no-store, must-revalidate, post-check=0, pre-check=0'
                }}).subscribe((res: any) => {
                    // Success
                    resolve(res);
                }, (err) => {
                    // Error
                    reject(err);
                });
            }
        });
    }

    // Delete the data for this day on this site
    public deleteDayData(siteId: string, day, month, year, techno: TECHNOLOGY = TECHNOLOGY.Navimote) {
        return new Promise((resolve, reject) => {
            // Check site ID
            if (siteId == undefined)
                reject("Missing site ID");
            else if (day == undefined || month == undefined || year == undefined)
                reject("Incorrect date parameters");
            else {
                // Check day and month digits
                month = (month <= 9) ? '0'+month : month;
                day = (day <= 9) ? '0'+day : day;
                // Make a DELETE request to delete data for this day
                this.http.delete(`${this.configService.config.network.loggerApiUrl}/${techno}/sites/${siteId}/years/${year}/months/${month}/days/${day}/deleteData/`).subscribe((res: any) => {
                    // Success
                    resolve(res);
                }, (err) => {
                    // Error
                    reject(err);
                });
            }
        });
    }

    // Delete ALL the data for this site
    public deleteData(siteId: string, techno: TECHNOLOGY = TECHNOLOGY.Navimote) {
        return new Promise((resolve, reject) => {
            // Check site ID
            if (siteId == undefined)
                reject("Missing site ID");
            else {
                // Make a DELETE request to delete all the data for this site
                this.http.delete(`${this.configService.config.network.loggerApiUrl}/${techno}/sites/${siteId}/deleteData/`).subscribe((res: any) => {
                    // Success
                    resolve(res);
                }, (err) => {
                    // Error
                    reject(err);
                });
            }
        });
    }

}