import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { ConfigService } from './config.service';

// List of all the categories
export enum CATEGORY {
    General = "general",
    Admin = "admin",
    Dragonfly = "dragonfly"
};

@Injectable({ providedIn: 'root' })
export class CategoryService {

    // The current categories
	private categoriesSubject: BehaviorSubject<any[]>;
    public categories: Observable<any[]>;
    // The list of all the categories
    private _listOfCategories: any;

    constructor(private configService: ConfigService) {
        // Save all the categories
        this._listOfCategories = this.configService.config.categories;
        // Instantiate the subject/observable
        this.categoriesSubject = new BehaviorSubject<any[]>([]);
        this.categories = this.categoriesSubject.asObservable();
    }

    // Select the categories that the user can see
    public loadCategory(category: CATEGORY, currentUser = undefined) {
        var categoriesToLoad = [], self = this;
        if (this._listOfCategories != undefined && this._listOfCategories[category] != undefined) {
            // For each category
            categoriesToLoad = this._listOfCategories[category].filter((cat) => {
                // If only visible for certain users
                if (currentUser != undefined && currentUser.username != undefined &&
                    cat.onlyVisibleForUsers != undefined &&
                    cat.onlyVisibleForUsers.map(user => user.toLowerCase()).indexOf(currentUser.username.toLowerCase()) == -1) {
                    return false;
                }
                return true;
            });
        }
    	this.categoriesSubject.next(categoriesToLoad);
        return categoriesToLoad;
    }
    // Select the categories that the user can see
    public getSelectedPath(routerLink: string):string|null {
        let result:string = null; 
        if (this._listOfCategories != undefined) {
            // For each category
            Object.entries(this._listOfCategories).find((cat) => {
                // If only visible for certain users
                return (cat[1] as any).find(item=>{
                    if ((item as any).routerLink == routerLink) {
                        result = cat[0];
                    }
                    return result;
                });
            });
        }
        return result;
    }

}