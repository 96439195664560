import { Component, ViewChild, HostListener, OnDestroy } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { Subscription } from 'rxjs';

import { ResponsiveService } from '../_services/responsive.service';
import { CategoryService, CATEGORY } from '../_services/category.service';
import { AuthenticationService } from '../_services/authentication.service';
import { IntegrationService } from '../_services/integration.service';

@Component({
    templateUrl: 'general.component.html',
    styles: [`
        .fixedToTop {
            position: fixed;
            top: 64px;
        }
        @media (max-width: 599px) {
            .fixedToTop {
                top: 56px !important;
            }
        }
    `]
})
export class GeneralComponent implements OnDestroy {

    public disableMenu;

	public drawerCollapsed: boolean = false;
	public categories = [];

    // Boolean if user has scroll more than the top bar height
    public hasScrollMoreThanTopBar: Boolean = false;

    // List of subs
    private subs: Subscription[] = [];

	@ViewChild(MatDrawer) componentDrawer: MatDrawer;

    constructor(private responsiveService: ResponsiveService, private categoryService: CategoryService,
                private authenticationService: AuthenticationService, private integrationService: IntegrationService) {
        // Get the boolean to know if we are in mobile mode
    	this.subs.push(this.responsiveService.drawerCollapse.subscribe((drawerCollapsed) => {
    		this.drawerCollapsed = drawerCollapsed;
    	}));
        // Wait for the current user before loading categories
        this.subs.push(this.authenticationService.currentUser.subscribe(user => {
            if (user != undefined)
                // Load the categories in the service
                this.categories = this.categoryService.loadCategory(CATEGORY.General, user);
        }));
        // Watch the disable menu
        this.integrationService.disableMenu_obs.subscribe((disableMenu) => {
            this.disableMenu = disableMenu;
        });
    }

    ngOnDestroy() {
        // Clear the subs
        this.subs.forEach(function(sub) { sub.unsubscribe(); });
    }

    closeDrawer() {
    	if (this.drawerCollapsed == true && this.componentDrawer != undefined)
    		this.componentDrawer.close();
    }

    // When the user scrolls
    @HostListener("window:scroll", [])
    onWindowScroll() {
        // If we scroll down more than the top bar height
        this.hasScrollMoreThanTopBar = (window != undefined && window.scrollY != undefined && !isNaN(window.scrollY) && window.scrollY >= 64) ? true : false;
    }

}