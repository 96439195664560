
import * as moment from 'moment';
import * as ecef from 'ecef-projector';
/* -------------------------------------------------------- */
/*                     Regular position                     */
/* -------------------------------------------------------- */
export class Position {

    public siteId: string;
    public levelId: number;
    public source: string;
    public device: string;
    public fixed_at: number;
    public lat: number;
    public lng: number;
    public alt: number;
    public pitch: number;
    public yaw: number;
    public roll: number;
    public status: number;

    constructor(siteId: string, levelId: number, source: string, device: string, fixed_at: number, lat: number,
                lng: number, alt: number, pitch: number, yaw: number, roll: number, status: number) {
        this.siteId = siteId;
        this.levelId = levelId;
        this.source = source;
        this.device = device;
        this.fixed_at = fixed_at;
        this.lat = lat;
        this.lng = lng;
        this.alt = alt;
        this.pitch = pitch;
        this.yaw = yaw;
        this.roll = roll;
        this.status = status;
    }

    // Second constructor
    static fromData(data: any) {
        if (data == undefined)
            return undefined;
        let { siteId, levelId, source, device, fixed_at, lat, lng, alt = 0, pitch, yaw, roll, status } = data;
        if (lat == undefined || lng == undefined || levelId == undefined || siteId == undefined)
        	return undefined;
        return new this(siteId, levelId, source, device, fixed_at, lat, lng, alt, pitch, yaw, roll, status);
    }
}



/* -------------------------------------------------------- */
/*                    Historical position                   */
/* -------------------------------------------------------- */
export class HistoryPosition {

    public status: number;
    public x : number;
    public y : number;
    public z : number; 

    public constructor(public content: Position,public dateTime: Date){
        if(!content.alt)
            this.content = content ={...content, alt: 0};
        [this.x,this.y,this.z] = ecef.project(content.lat, content.lng ,content.alt);
        this.status = content.status;
    }

    public getDistance(vector:HistoryPosition): number{ 
        return Math.sqrt( Math.pow((this.x-vector.x), 2) + Math.pow((this.y-vector.y), 2)); 
    }
    public getDurationMs(vector:HistoryPosition): number{
        if(vector.dateTime > this.dateTime)
            return 0;
        return Math.abs((vector.dateTime as any) - (this.dateTime as any));
    }

    public toArray() {
        return [this.content.lat, this.content.lng, this.content.alt, this.content.fixed_at];
    }

    // Second constructor
    static fromData(data: any,timezone:string) {
        if (data == undefined)
            return undefined;
        let { lat, lng, alt = 0, fixed_at } = data;
        if (lat == undefined || lng == undefined || fixed_at == undefined)
            return undefined;
        return new this(data, moment.tz(fixed_at,timezone).toDate());
    }
}