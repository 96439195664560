<mat-accordion *ngIf="mapSettings != undefined">
	<mat-expansion-panel id="mapSettingsPanel">
		<mat-expansion-panel-header>
			<mat-panel-title *ngIf="mapSettings.title != undefined">
				{{mapSettings.title}}
			</mat-panel-title>
		</mat-expansion-panel-header>
		<div *ngIf="mapSettings.settings != undefined" class="d-flex flex-column">
			<!-- For each settings -->
			<div *ngFor="let setting of mapSettings.settings">
				<!-- Depending on the type, display the right html -->

				<!-- If it's a checkbox -->
				<div *ngIf="setting.type == 'checkbox'" class="d-flex mb-2">
					<label *ngIf="setting.label != undefined" class="mr-3">{{setting.label}}</label>
					<mat-checkbox class="ml-auto" [(ngModel)]="setting.value" (change)="mapSettings.onChange(mapSettings, setting.id)"></mat-checkbox>
				</div>

				<!-- If it's a slideToggle -->
				<div *ngIf="setting.type == 'slideToggle'" class="d-flex mb-2">
					<label *ngIf="setting.label != undefined" class="mr-auto">{{setting.label}}</label>
					<mat-slide-toggle class="mx-3" [(ngModel)]="setting.value" (change)="mapSettings.onChange(mapSettings, setting.id)"></mat-slide-toggle>
					<label *ngIf="setting.label2 != undefined" class="ml-auto">{{setting.label2}}</label>
				</div>

				<!-- If it's a divider -->
				<mat-divider *ngIf="setting.type == 'divider'" class="mb-4"></mat-divider>

			</div>
		</div>
	</mat-expansion-panel>
</mat-accordion>