import { Injectable } from "@angular/core";
import { FormControl } from "@angular/forms";
import * as moment from 'moment';
import 'moment-timezone';

export enum LocalStorageKeys {
    DistanceUnit = 'DistanceUnit',
    DurationUnit = 'DurationUnit',
    SpeedUnit  = 'SpeedUnit',
    SelectedChart  = 'SelectedChart',
    StopThreshold  = 'StopThreshold',
    OfflineThreshold  = 'OfflineThreshold',
    Timezone  = 'Timezone',
   // DistanceThreshold  = 'DistanceThreshold'
   StartDate = 'StartDate',
    TimeRangeMin = 'TimeRangeMin',
    EndDate = 'EndDate',
    TimeRangeMax= 'TimeRangeMax',
    FilterBasedOnLevel= 'FilterBasedOnLevel',
}


export enum DistanceUnits{
    Meter = 'Meter',
    kilometer = 'kilometer',
    Foot = 'Foot',
    Mile = 'Mile'
}

export enum DurationUnits{
    HourMinuteSecond = 'HourMinuteSecond',
    DayHourMinuteSecond = 'DayHourMinuteSecond',
    Minutes = 'Minutes',
    Percent = 'Percent'
}

export enum SpeedUnits{
    Kmh = 'Kmh',
    ms = 'ms',
    MPH = 'MPH',
}

export enum ChartType {
    DurationRange = 'DurationRange',
    EachDeviceDurationPercent = 'EachDeviceDurationPercent',
    DurationDistanceInRange = 'DurationDistanceInRange',
    DurationPieChart = 'DurationPieChart',
    DistancePieChart = 'DistancePieChart',
    MaximumSpeedBarChart = 'MaximumSpeedBarChart',
    AverageSpeedBarChart = 'AverageSpeedBarChart',
    DistanceBarChart = 'DistanceBarChart',
    DurationBarChart = 'DurationBarChart'
}



@Injectable({ providedIn: 'root' })
export class SettingsService {

    public selectedChart: string= ChartType.DurationRange;
    private stopThresholdDefault: string = '00:05';
    private offlineThresholdDefault: string = '00:10';
    // The current timezone
    public distanceThreshold: number = .06;
    public distanceThresholdInput= new FormControl();

    constructor(){
        
        const selectedChart = localStorage.getItem(LocalStorageKeys.SelectedChart);
        if(selectedChart)
            this.selectedChart = selectedChart;      
    }

    getStartDate(defaultValue:Date):Date|undefined{
        return this.getDate(LocalStorageKeys.StartDate,defaultValue);
    }
    getTimeRangeMin(defaultValue:Date|undefined):Date|undefined{
       return this.getDate(LocalStorageKeys.TimeRangeMin,defaultValue);
    }
    getEndDate(defaultValue:Date|undefined):Date|undefined{
        return this.getDate(LocalStorageKeys.EndDate,defaultValue);
    }
    private getDate(key:LocalStorageKeys,defaultValue:Date|undefined):Date|undefined{
        const date = localStorage.getItem(key);
        if(date)
            return new Date(date);
        return defaultValue;
    }
    getTimeRangeMax(defaultValue:Date|undefined):Date|undefined{
        return this.getDate(LocalStorageKeys.TimeRangeMax,defaultValue);
    }

    setStartDate(value:Date){
        localStorage.setItem(LocalStorageKeys.StartDate,value.toISOString());
    }
    setTimeRangeMin(value:Date){
        localStorage.setItem(LocalStorageKeys.TimeRangeMin,value.toISOString());
    }
    setEndDate(value:Date){
        localStorage.setItem(LocalStorageKeys.EndDate,value.toISOString());
    }
    setTimeRangeMax(value:Date){
        localStorage.setItem(LocalStorageKeys.TimeRangeMax,value.toISOString());
    }


    getSelectedDurationUnit(): DurationUnits {
        return localStorage.getItem(LocalStorageKeys.DurationUnit) as DurationUnits ?? DurationUnits.HourMinuteSecond; 
        
    }
    getSelectedDistanceUnit(): DistanceUnits {
        return localStorage.getItem(LocalStorageKeys.DistanceUnit) as DistanceUnits ?? DistanceUnits.Meter; 
        
    }
    getSelectedSpeedUnit(): SpeedUnits {
        return localStorage.getItem(LocalStorageKeys.SpeedUnit) as SpeedUnits ?? SpeedUnits.Kmh; 
        
    }
    getSelectedTimezone(): string {
        return localStorage.getItem(LocalStorageKeys.Timezone) ?? 'UTC';
    }
    formatDuration(ms:number,allMs:number): string {
        const unit = this.getSelectedDurationUnit();
        if(unit == DurationUnits.Minutes)
            return `${this.round(ms / (1000 * 60))}m`;
        if(unit == DurationUnits.Percent)
            return `${this.round(allMs == 0?0: (ms / allMs) * 100)}%`;
        const dur = moment.duration(ms,'milliseconds');
        const minuteSecond =`${dur.minutes().toString().padStart(2,'0')}:${dur.seconds().toString().padStart(2,'0')}`;
        
        if(unit == DurationUnits.HourMinuteSecond)
            return `${(dur.hours() + (dur.days() * 24)).toString().padStart(3,'0')}:${minuteSecond}`;
        return `${dur.days().toString().padStart(3,'0')}:${dur.hours().toString().padStart(2,'0')}:${minuteSecond}`;
    }
    round(value:number): number {
        return Math.round(value * 100) / 100;
    }
    formatDistance(meter:number): string {            
        return `${this.round(this.convertDistanceToMeter(meter))}${this.getDistanceUnit()}`;
    }

    getDistanceUnit(): string {
        switch(this.getSelectedDistanceUnit()){
            case DistanceUnits.Mile:            
                return `Mile`;
            case DistanceUnits.kilometer:            
                return `Km`;
            case DistanceUnits.Foot:            
                return `ft`;
            default:
                return `m`;
        }
    }

    convertDistanceFromMeter(meter:number): number {
        switch(this.getSelectedDistanceUnit()){
            case DistanceUnits.Mile:            
                return 1609.34 * meter;
            case DistanceUnits.kilometer:            
                return 1000 * meter;
            case DistanceUnits.Foot:            
                return 0.3048 * meter;
            default:
                return meter;
        }
    }

    convertDistanceToMeter(meter:number): number {
        switch(this.getSelectedDistanceUnit()){
            case DistanceUnits.Mile:            
                return 0.000621371 * meter;
            case DistanceUnits.kilometer:            
                return 0.001 * meter;
            case DistanceUnits.Foot:            
                return 3.2808399 * meter;
            default:
                return meter;
        }
    }
    formatSpeed(speedMpS:number): string {
        return `${this.round(this.convertSpeed(speedMpS))}${this.getSpeedUnit()}`;        
    }
    
    convertSpeed(speedMpS:number): number {
        switch(this.getSelectedSpeedUnit()){
            case SpeedUnits.MPH:            
                return (2.23694 * speedMpS);
            case SpeedUnits.Kmh:            
                return (3.6 * speedMpS);
            default:
                return (speedMpS);
        }
    }

    getSpeedUnit(): string {
        switch(this.getSelectedSpeedUnit()){
            case SpeedUnits.MPH:            
                return `MPH`;
            case SpeedUnits.Kmh:            
                return `Km/h`;
            default:
                return `m/s`;
        }
    }
    formatDate(value?:Date):string {
        if(value == undefined)
            return '';
        return moment.tz(value,this.getSelectedTimezone()).format('L, HH:mm:ss z');
    }
    formatDisplayDate(isMultiDateSelect:Boolean,value?:Date):string {
        if(value == undefined)
            return '';
        return moment.tz(value,this.getSelectedTimezone()).format(isMultiDateSelect ? 'L, HH:mm:ss': 'HH:mm:ss');
    }
    parseThreshold(value:string){ 
        const parts = value.split(':').map(i=> parseInt(i));
        return (parts[1] + (parts[0] * 60)) * 1000 ; 
    }

    setDistanceUnit(value:DistanceUnits){        
        localStorage.setItem(LocalStorageKeys.DistanceUnit, value); 
    }

    setDurationUnit(value:DurationUnits){        
        localStorage.setItem(LocalStorageKeys.DurationUnit, value); 
    }
    setSpeedUnit(value:SpeedUnits){        
        localStorage.setItem(LocalStorageKeys.SpeedUnit, value); 
    }
    
    setTimezone(value:string) {
        localStorage.setItem(LocalStorageKeys.Timezone,value);
    }
    
    selectedChartChanged(event){    
        localStorage.setItem(LocalStorageKeys.SelectedChart, this.selectedChart);    
    }

    setOfflineThreshold(value:string){        
        localStorage.setItem(LocalStorageKeys.OfflineThreshold,value);  
    }

    setStopThreshold(value:string){        
        localStorage.setItem(LocalStorageKeys.StopThreshold,value);  
    }

    getOfflineThresholdInput():string{   
        return (localStorage.getItem(LocalStorageKeys.OfflineThreshold) ?? this.offlineThresholdDefault);  
    }

    getStopThresholdInput(){        
        return (localStorage.getItem(LocalStorageKeys.StopThreshold) ?? this.stopThresholdDefault);
    }

    getOfflineThreshold(){   
        return this.parseThreshold(this.getOfflineThresholdInput());  
    }

    getStopThreshold(){        
        return this.parseThreshold(this.getStopThresholdInput());
    }
    getFilterBasedOnLevel() : Boolean{   
        const value = localStorage.getItem(LocalStorageKeys.FilterBasedOnLevel);
        return value == undefined || value == "1";
    }
    setFilterBasedOnLevel(value:Boolean){        
        return localStorage.setItem(LocalStorageKeys.FilterBasedOnLevel, value? "1" : "0");
    }
}