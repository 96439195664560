import { Component, Input, OnInit, ViewChild, OnChanges, EventEmitter, Output, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FormGroup } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import * as _ from 'lodash';

import { YesNoDialogComponent } from '../../common/dialogs/yes-no-dialog.component';
import { EditDialogComponent } from '../../common/dialogs/edit-dialog.component';

import { ALERT_TYPE, AlertService } from '../../_services/alert.service';
import { ResponsiveService } from '../../_services/responsive.service';
import { ConfigService } from '../../_services/config.service';
import { SiteService } from '../../_services/site.service';

import { Site } from '../../_models/site';
import { DataSource } from '@angular/cdk/collections';

export interface TableComponentOptions
{
    deletable?:boolean;
    editable?:boolean;
    navigatable?:boolean;
    showable?:boolean;
}

export interface TableComponentCommand
{
    key?:string;
    icon?:string;
    title:string;  
}
export interface TableComponentCommandEvent
{
    command?:TableComponentCommand;
    element?:any;
}

@Component({
    selector: 'element-table',
    templateUrl: 'table.component.html',
    styleUrls: ['table.component.scss']
})
export class TableComponent implements OnInit, OnChanges {

    public isLoading: Boolean = true;
    public pageIndex: number = 0;
    public pageSize: number = 10;
    @Input()
    public set selectedIndex(value: number) {
        this.pageIndex = value / this.pageSize;
    }
    @Input() public disabledSortColumnName : string = "";
    @Input()
    public set disableSort(value: boolean) {     
        if(this.sort)
        {
            if(value && this.disabledSortColumnName)
            {
                this.sortField = this.disabledSortColumnName;
                this.sort.sort({
                    id: this.disabledSortColumnName,
                    start: 'asc',
                    disableClear: true
                });
            }
            this.sort.disabled = value;
        }   
    }
    @Input() public disableShow: Boolean = false;

    @Input() commands:TableComponentCommand[] = [];
	// The elements
    @Input() elements: any[];
    // The site we are working on
    @Input() currentSite: Site;
    // The name to describe the element ('Node', 'Device', ...etc)
    @Input() elementName: string = "element";
    // The function to get the unique ID per element
    @Input() getElementIdFct;
    // The table options
    @Input() options: TableComponentOptions;
    showCustomColumns: Boolean = false;
    showAction: Boolean = false;
    showShow: Boolean = false;
    showAllChecked: Boolean = true;
    showAllIndeterminate: Boolean = false;
    @Input() showColor: Boolean = false;
    // The table variables
    columnsSource: string[];
    @Input() displayedColumns: string[] = [];
    // Boolean to disable the filter input
    @Input() showFilterInput: Boolean = true;

    // Contains the functions to get the data for each properties of the element
    // MUST MATCH the 'columnsSource' variable
    // Example:
    //          {display: 'Latitude', columnDef: 'lat', getFct: <function>, clickable: <function>}
    @Input() attributesToDisplay: any[];
    // Contains the attributes to display when editing
    // MUST MATCH the 'columnsSource' variable
    // Example:
    //          {display: 'Latitude', attribute: 'lat', formGroupName:'loc', width: '150px', type: 'number'}
    @Input() attributesToEdit: any[];

    @Input() formGroup: FormGroup;

    // Action functions
    @Input() editElementFct;
    @Input() deleteElementFct;
    @Input() startNavigationFct;
    @Input() stopNavigationFct;
    @Input() hideShow:Boolean = false;
    @Input() colorReadonly:Boolean = false;
    

    // Function events
    @Output() showElementEvent = new EventEmitter<[any, any]>();
    @Output() showAllEvent = new EventEmitter<Boolean>();
    @Output() colorElementEvent = new EventEmitter<[any, any]>();
    @Output() deleteElementEvent = new EventEmitter<any>();
    @Output() commandEvent = new EventEmitter<TableComponentCommandEvent>();

    // The min-width for each column
    columnMinWidth = 95;

    // Private variables
    dataSource: MatTableDataSource<any>;
    @ViewChild('paginator', { static: true }) paginator: MatPaginator;
    @ViewChild('sort', { static: true }) sort: MatSort;
    @Input() sortField = 'name';
    @Input() sortDirection = 'asc';
    // The booleans to show the spinners
    showSpinners: any = {};

    constructor(private alertService: AlertService, private dialog: MatDialog, private responsiveService: ResponsiveService,
                private http: HttpClient, private configService: ConfigService, private siteService: SiteService) {
    	// Assign the data to the data source for the table to render
        this.dataSource = new MatTableDataSource(this.elements);
    }

	ngOnInit() {
        this.columnsSource = Array.from(this.displayedColumns);
        // Determinate if we need to show the Action column
        if (this.options != undefined && this.options.navigatable == true && this.columnsSource.indexOf('navigation') == -1)
            this.columnsSource.push('navigation');
        // Determinate if we need to show the Action column
        this.showAction =  (this.options != undefined && (this.options.editable == true || this.options.deletable == true ||  (this.commands && this.commands.length > 0)));
       
        if (this.showAction == true && this.columnsSource.indexOf('action') == -1)
            this.columnsSource.push('action');
        // Determinate if we need to show the Show column
        this.showShow = (this.options != undefined && this.options.showable == true);
        if (this.showShow == true)
            this.columnsSource.unshift('show');

        // Instantiate sorts and paginators
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        // Sorting function
        this.dataSource.sortingDataAccessor = (data: any, sortHeaderId: string) => {
            const value: any = _.get(data, sortHeaderId);
            switch(sortHeaderId) {
                case 'lrrt':
                    // For the last seen value, the 'n/a' should not be before
                    return (value == undefined) ? Number.MAX_SAFE_INTEGER : value;
                break;
                default:
                    return (value != undefined && value.toLowerCase != undefined) ? value.toLowerCase() : value;
            }
        };
    }

    ngOnChanges() {
        if (this.elements == undefined)
            return;
    	this.dataSource.data = this.elements;
        this.isLoading = false;
        // Check the spinners
        this.checkSpinners();
    }

    applyFilter(filterValue: string, dataSource) {
        dataSource.filter = filterValue.trim().toLowerCase();
        if (dataSource.paginator)
            dataSource.paginator.firstPage();
    }

    attributeClicked(attr, element) {
        if (attr == undefined || attr.clickable == undefined)
            return;
        attr.clickable(element);
    }

    getElementClasses(attr, element) {
        if (attr.getClasses)
            return attr.getClasses(element);
        return '';
    }

    getId(element) {
        if (this.getElementIdFct)
            return this.getElementIdFct(element);
        return element.mac;
    }

    /* ------------------------------------------------------------------------- */
    /* -                           Action functions                            - */
    /* ------------------------------------------------------------------------- */

    executeCommand(command:TableComponentCommand,element: any) {
        this.commandEvent.emit({ command ,  element });
    }
    // When user wants to edit an element
    editElement(element: any) {
        // Check object
        if (element == undefined || this.getId(element) == undefined) {
            this.alertService.showMessage(ALERT_TYPE.Error, "An error occurred, cannot edit the " + this.elementName);
            return;
        }
        var self = this;
        var dialog = this.dialog.open(EditDialogComponent, { panelClass: ['mw-none','w-90','w-sm-70','w-md-50','w-lg-40', 'w-xl-30'], disableClose: true, data: {
            title: "Edit " + this.elementName,
            noBtnLabel: "Cancel",
            yesBtnLabel: "Save",
            YesIsAccent: true,
            objectToEdit: element,
            formGroup: self.formGroup,
            attributesToEdit: self.attributesToEdit
        }});
        dialog.updatePosition({ top: this.responsiveService.getDialogTopPosition() + 'px' });
        // When user answers
        dialog.afterClosed().subscribe(response => {
            if (response == undefined || response.error != undefined || self.editElementFct == undefined) {
                // Error
                self.alertService.showMessage(ALERT_TYPE.Error, "An error occurred, cannot edit the " + self.elementName);
            } else if (response.value == true && response.result != undefined) {
                // Show the spinner for this element
                self.setSpinner(self.getId(element), true);
                self.editElementFct(response.result, self.currentSite, self.http).then((res) => {
                    // Success
                    // Set the spinner so it will be removed on next refresh
                    self.setSpinner(self.getId(element), false);
                    // Show success message
                    self.alertService.showMessage(ALERT_TYPE.Success, "The " + self.elementName + " has been correctly edited");
                }, () => {
                    // Error
                    // Set the spinner so it will be removed on next refresh
                    self.setSpinner(self.getId(element), false);
                    // Show error message
                    self.alertService.showMessage(ALERT_TYPE.Error, "An error occurred, cannot edit the " + self.elementName);
                });
            }
        });
    }

    // When user click the Delete button
    deleteElement(element: any) {
        var self = this;
        // Check we have the MAC address
        if (element == undefined || self.getId(element) == undefined) {
            self.alertService.showMessage(ALERT_TYPE.Error, "Missing MAC address, cannot delete the " + self.elementName);
            return;
        }
        // Define the message for the dialog
        var message = "Are you sure you want to delete the " + self.elementName;
        if (element.name != undefined && element.name.length > 0)
            message += " named \"" + element.name + "\"";
        message += "?";
        var dialog = this.dialog.open(YesNoDialogComponent, { panelClass: ['mw-none','w-90','w-sm-80','w-md-50','w-lg-40'], data: {
            title: "Delete " + self.elementName,
            message:  message,
            noBtnLabel: "Cancel",
            yesBtnLabel: "Delete",
            YesIsWarn: true
        }});
        dialog.updatePosition({ top: this.responsiveService.getDialogTopPosition() + 'px' });
        // When user answers
        dialog.afterClosed().subscribe(result => {
            // If true, means user wants to delete the element
            if (result == true) {
                // Show the spinner for this element
                self.setSpinner(self.getId(element), true);
                // Need to delete the noode
                self.deleteElementFct(element, self.currentSite, self.http).then(() => {
                    // Success
                    // Set the spinner so it will be removed on next refresh
                    self.setSpinner(self.getId(element), false);
                    // Show success message
                    self.alertService.showMessage(ALERT_TYPE.Success, "The " + self.elementName + " has been correctly deleted");
                    // Trigger event
                    self.deleteElementEvent.emit(element);
                }, (err) => {
                    // Error
                    // Set the spinner so it will be removed on next refresh
                    self.setSpinner(self.getId(element), false);
                    // Show error message
                    // In case of Dragonfly, use another message
                    if (self.elementName == 'Dragonfly registered device')
                        self.alertService.showMessage(ALERT_TYPE.Error, "Only one licensed device can be deleted every 24 hours. Please try again in another day.");
                    else
                        self.alertService.showMessage(ALERT_TYPE.Error, "An error occurred, cannot delete the " + self.elementName);
                });
            }
        });
    }

    // When user change the Show checkbox
    showChanged(event: any, element: any) {   
        this.showAllIndeterminate = !this.elements.every(i=>i.isSelected == element.isSelected);
        if(!this.showAllIndeterminate )
            this.showAllChecked = this.elements.every(i=> i.isSelected);
        this.showElementEvent.emit([event, element]);
    }
    // When user change the Show checkbox
    showAllChanged(event: any, element: any) {
        const self= this;
        this.elements.forEach(i=> i.isSelected = self.showAllChecked);
        this.showAllEvent.emit(this.showAllChecked);
    }
    // When user change the Show checkbox
    colorChanged(event, element) {
        element.color = event.target.value;
        this.colorElementEvent.emit([event, element]);
    } 

    /* ------------------------------------------------------------------------- */
    /* -                         Navigation functions                          - */
    /* ------------------------------------------------------------------------- */

    // When user wants to start the navigation
    startNavigation(element, dontMap) {
        if (!this.startNavigationFct)
            return;
        // Show the spinner
        this.setSpinner(this.getId(element), true);
        // Start the function
        this.startNavigationFct(dontMap).then(() => {}, (err) => {
            console.log(err);
            // Can't start the device
            this.alertService.showMessage(ALERT_TYPE.Error, "An error occurred, cannot start the " + this.elementName);
        }).finally(() => {
            // Set the spinner so it will be removed on next refresh
            this.setSpinner(this.getId(element), false);
        });
    }

    // When user wants to stop the navigation
    stopNavigation(element) {
        if (!this.stopNavigationFct)
            return;
        // Show the spinner
        this.setSpinner(this.getId(element), true);
        // Start the function
        this.stopNavigationFct().then(() => {}, (err) => {
            console.log(err);
            // Can't start the device
            this.alertService.showMessage(ALERT_TYPE.Error, "An error occurred, cannot stop the " + this.elementName);
        }).finally(() => {
            // Set the spinner so it will be removed on next refresh
            this.setSpinner(this.getId(element), false);
        });
    }


    /* ------------------------------------------------------------------------- */
    /* -                           Private functions                           - */
    /* ------------------------------------------------------------------------- */

    // Set the spinner for an element defined with its mac address
    setSpinner(mac, value) {
        if (mac && this.showSpinners)
            this.showSpinners[mac] = value;
    }

    // Check if some spinners need to be removed
    checkSpinners() {
        for (var mac in this.showSpinners) {
            if (this.showSpinners[mac] == false)
                this.showSpinners[mac] = undefined;
        }
    }

}
