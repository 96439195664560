<h1 class="text-center mb-4">Dragonfly Marking</h1>

<!-- Spinner -->
<div *ngIf="vmarkers == undefined">
	<mat-spinner class="mx-auto" color="accent" [diameter]="36"></mat-spinner>
</div>

<!-- Information -->
<div class="d-flex justify-content-center mb-2" *ngIf="vmarkers != undefined">
	<div id="infoBubble" class="d-inline-block text-justify m-3 px-3 py-2 rounded">
		<div class="d-flex align-items-center">
			You can create or move a visual marker by respectively clicking on the map or dragging it
		</div>
	</div>
</div>

<!-- Map -->
<div id="mapContainer" class="px-3 px-md-4 px-lg-5" [ngClass]="{'invisible': currentSite == undefined || vmarkers == undefined}">
	<div id="map" #map class="noselect w-100 h-100">
		<!-- Top right div inside the map -->
        <div class="leaflet-top leaflet-right mt-2 mr-2" style="pointer-events: auto" (click)="$event.stopPropagation()">
			<map-settings [mapSettings]="mapSettings"></map-settings>
		</div>
	</div>
</div>
