/* -------------------------------------------------------- */
/*                       Visual marker                      */
/* -------------------------------------------------------- */
export class VMarker {

    public id: number;
    public siteId: string;
    public levelId: number;
    public lat: number;
    public lng: number;
    public alt: number;
    public orientation: number;
    public onWall: Boolean;
    public label: string;

    constructor(id: number, siteId: string, levelId: number, lat: number, lng: number, alt: number, orientation: number, onWall: Boolean, label: string) {
        this.id = id;
        this.siteId = siteId;
        this.levelId = levelId;
        this.lat = lat;
        this.lng = lng;
        this.alt = alt;
        this.orientation = orientation;
        this.onWall = onWall;
        this.label = label;
    }

    // Second constructor
    static fromData(data: any) {
        if (data == undefined)
            return undefined;
        let { id, siteID, levelID, lat, lng, alt = 0, orientation, onwall = false, label } = data;
        if (id == undefined || siteID == undefined || levelID == undefined || lat == undefined || lng == undefined || orientation == undefined)
            return undefined;
        return new this(id, siteID, levelID, lat, lng, alt, orientation, onwall, label);
    }
}