import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';
import * as tinycolor from 'tinycolor2';

import L from 'leaflet';
import 'leaflet-path-transform';
import 'leaflet-imageoverlay-rotated';
import 'leaflet-fullscreen';
import 'leaflet-draw';
import 'leaflet.control.opacity';
import 'leaflet.polylinemeasure';
import 'leaflet-geometryutil';
import { OpenStreetMapProvider } from 'leaflet-geosearch';

import { MapData } from '../_models/mapData';
import { Floorplan } from '../_models/floorplan';
import { Site } from '../_models/site';
import { Level } from '../_models/level';

import { MarkerService } from '../_services/marker.service';
import { UtilsService } from '../_services/utils.service';
import { FloorplanService } from '../_services/floorplan.service';
import { SiteService } from '../_services/site.service';
import { LevelService } from '../_services/level.service';

export interface TimeLayerAPI{
    stop:()=>void;
}


@Injectable({ providedIn: 'root' })
export class LeafletService {

    // The current site
    private currentSite: Site;
    // The current floorplan
    private currentFloorplan: Floorplan;
    // The levels
    private levels: Level[];

	// The map
	public map;
    // The map data
    private mapData: MapData;
	// The marker for the crosshair
	private crosshairMarker;

    // Floorplans variables
    private _layers = [];

    private layersControl;

    // Geo-fences variables
    private _geoFencesGroupLayer;
    private _geoFencesPolygons = [];
    private _geoFencesControl;
    private _polygonTimeLayer = undefined;
    private _markerTimeLayer = undefined;
    private _timeControl = undefined;

    // The geo search to found the address
    private provider = new OpenStreetMapProvider();

    constructor(private markerService: MarkerService, private utilsService: UtilsService, private floorplanService: FloorplanService,
                private siteService: SiteService, private levelService: LevelService) { }

    /* -------------------------------------------------------- */
    /*                    Public functions                      */
    /* -------------------------------------------------------- */

    // Return the map
    public getMap() {
        return this.map;
    }

    // Draw a map in a container
    public drawMap(mapId, mapData: MapData) {
        var self = this;
        // Save the map data
        this.mapData = mapData;
        // Remove the map if it's already initialized
        this.removeMap();
        // Get the current site
        this.siteService.currentSite.pipe(take(1)).subscribe((currentSite: Site) => {
            this.currentSite = currentSite;
            // Get the current floorplan
            this.floorplanService.currentFloorplan.pipe(take(1)).subscribe((currentFloorplan: Floorplan) => {
                this.currentFloorplan = currentFloorplan;
                // Find the map center from the map data
                // (check the site, else the center attribute, else set a default one)
                this._findMapCenter(this.mapData).then(() => {
                    // Done calculating the center of the map
                    // Draw the map
                    try {
                        this.map = L.map(mapId, this.mapData);
                    } catch(err) {}
                    // Return if no map
                    if (this.map == undefined)
                        return;
                    // Add the layers
                    // OSM: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                    // Satellite: 'http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
                    var mapLayer = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                        id: 'map',
                        attribution: '© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
                        maxZoom: this.mapData.maxZoom,
                        maxNativeZoom: this.mapData.maxNativeZoom
                    }).addTo(this.map);
                    var satLayer = L.tileLayer('http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
                        id: 'sat',
                        attribution: '© <a href="http://www.esri.com/">Esri</a> contributors',
                        maxZoom: this.mapData.maxZoom,
                        maxNativeZoom: this.mapData.maxNativeZoom
                    });
                    // Add the controle to change the layer
                    // But first, remove it if it's already there
                    if (this.layersControl != undefined)
                        this.layersControl.remove(this.map);
                    this.layersControl = L.control.layers({
                        "Map": mapLayer,
                        "Satellite": satLayer
                    }, null, { collapsed: false }).setPosition('bottomleft').addTo(this.map);



                    // Set center
                    this.map.setView(this.mapData.center, this.mapData.zoom);
                    // Show the floorplan if needed
                    this._showFloorplan();
                    // Show the editable image if needed
                    this._showEditableImage();
                    // Display the uniform scaling control
                    this._addUniformScalingControl();
                    // Add the events for the map
                    this._addEventsMap();
                    // Add the markers/floorplans/controls on the map
                    this._addComponentsOnMap();
                    // Add the geo-fences related components
                    this._addGeoFencesOnMap();
                    // Add the scale if needed
                    if (mapData.showScale == true)
                        L.control.scale().addTo(this.map);
                    setTimeout(function() {
                        // Display the ruler control
                        self._addRulerControl();
                    }, 1000);
                });
            });
        });
    }
    public removeTimeLayer(){
        this.removeFromMap(this._polygonTimeLayer);
        this.removeFromMap(this._markerTimeLayer);
        if(this._timeControl)
        this.map.removeControl(this._timeControl);

        this._polygonTimeLayer = undefined;
        this._markerTimeLayer = undefined;
        this._timeControl = undefined;
        
    }
    public addTimeLayer(getMarkerTooltip:(label: string, alt: number, dateTime: Date)=> string,points:{ time?:Date ,isStopped :Boolean, coordinates:any[] ,label:string ,index:number, color:string,changeProcessing:(value:Boolean)=> void}[],
            follow:()=> Boolean,
            timezone:any,
            onStop:()=> void,
            onPlay:()=> void) : TimeLayerAPI{
        const self = this;
        // var timeSeriesGeoJSON =
        // {"features": [
        //     { "type": "Feature", "id": -1549271008, "properties": { "id": 827793, "time": "2006-03-11T08:00:00", "insol": 61.73542 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 39.151222675648221, 34.199670805202523 ], [ 39.151222675712766, 34.199675276071595 ], [ 39.151228272367668, 34.199675276015682 ], [ 39.151228272302838, 34.199670805146624 ], [ 39.151222675648221, 34.199670805202523 ] ] ] } },
        //     { "type": "Feature", "id": -1549271008, "properties": { "id": 827794, "time": "2006-03-11T09:00:00", "insol": 161.73542 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 39.151222675648221, 34.199670805202523 ], [ 39.151222675712766, 34.199675276071595 ], [ 39.151228272367668, 34.199675276015682 ], [ 39.151228272302838, 34.199670805146624 ], [ 39.151222675648221, 34.199670805202523 ] ] ] } }
        //   ],
        //  "type":"FeatureCollection"
        // };
       
        self.removeTimeLayer();
        if(points.length == 0)
                return;
        // start of TimeDimension manual instantiation
        const timeDimension = new L.TimeDimension({
            period: "PT1S"
        });
        let isInitialized = false;
        const lstBound={ lastProcessingId:null,updateView : true,lastProcessing:(v)=>{} };
        timeDimension.on('timeload', function(e){ //triggered when a new time is displayed
          
            if(isInitialized && lstBound.updateView && follow && follow())
                self.fitBoundsForTimeLine( self._markerTimeLayer);
          });
        // helper to share the timeDimension object between all layers
        this.map.timeDimension = timeDimension; 
        // otherwise you have to set the 'timeDimension' option on all layers.

        const player = new L.TimeDimension.Player({
            transitionTime: 100, 
            loop: false,
            startOver:true
        }, timeDimension);
        player.on('play', function(e){ //triggered when a new time is displayed
            if(isInitialized)
                onPlay();
        });
        player.on('stop', function(e){ //triggered when a new time is displayed
            if(isInitialized)
                onStop();
        });

        const timeDimensionControlOptions = {
        player:        player,
        timeDimension: timeDimension,
        position:      'bottomleft',
        autoPlay:      true,
        minSpeed:      1,
        speedStep:     0.5,
        maxSpeed:      15,
        timeSliderDragUpdate: true,
        timeZones: [timezone,'Local']
        };

        this._timeControl =  new L.Control.TimeDimension(timeDimensionControlOptions);
        this._timeControl["_getDisplaySpeed"] = function(fps) {
            return `${fps} X`;
        };
        this.map.addControl(this._timeControl);


        function style(feature) {
            return {
              weight: 3,
              opacity: .80,
              color: feature['properties']['color'],
              dashArray: '',
              fillOpacity: 1,
              fillColor: feature['properties']['color']
            };
          }


          const polygonGeoJSON = {"features": points.map((item,index)=>{
            const isoTime = item.time.toISOString();
            return { "type": "Feature", "id": index, "properties": { "label" : item.label,"rawTime": item.time, "color": item.color,"id": index,"index": item.index,  "time": isoTime, "insol": 0 }, 
            "geometry": { 
                "type": "Polygon",
                "coordinates": [item.coordinates]},
             };
        }),
         "type":"FeatureCollection"
        };
        const polygonLayer = L.geoJSON(polygonGeoJSON, {    
            style:style,
            onEachFeature: function(feature, layer) {
                const text = getMarkerTooltip(feature.properties?.label,feature.geometry.coordinates[0][1][2],feature.properties.rawTime);
                layer.bindPopup(text);
                layer.bindTooltip(text);
              }
        });
        const polygonTimeLayer = L.timeDimension.layer.geoJson(polygonLayer, {
            updateTimeDimension: true,
            waitForReady: true
        });

        polygonTimeLayer.addTo(this.map);
        this._polygonTimeLayer = polygonTimeLayer;



        const markerGeoJSON = {"features": points.map((item,index)=>{
            const isoTime = item.time.toISOString();
            return { "type": "Feature", "id": index, "properties": { "isStopped" : item.isStopped,"rawTime": item.time, "label" : item.label, "changeProcessing" : item.changeProcessing, "color": item.color,"id": index,"index": item.index, "time": isoTime, "insol": 0 }, 
            "geometry": { 
                "type": "LineString",
                "coordinates": [item.coordinates[1]]},
             };
        }),
         "type":"FeatureCollection"
        };
        const markerLayer = L.geoJSON(markerGeoJSON, {
            pointToLayer: function (feature, latLng) {
                
                lstBound.updateView = !self.map.getBounds().contains(latLng);
                const changeProcessing = feature.properties?.changeProcessing;
                if(changeProcessing)
                    changeProcessing(true);
                if(lstBound.lastProcessing && lstBound.lastProcessingId != feature.properties?.index)
                    lstBound.lastProcessing(false);

                lstBound.lastProcessingId = feature.properties?.index;
                lstBound.lastProcessing = changeProcessing;
                return self.createCustomMarker(latLng,getMarkerTooltip(feature.properties?.label,feature.geometry.coordinates[2],feature.properties.rawTime),feature.properties?.color,feature.properties?.isStopped);
               
            }
        });
        const markerTimeLayer = L.timeDimension.layer.geoJson(markerLayer, {
            updateTimeDimension: true,
            duration: 'PT1S',
            updateTimeDimensionMode: 'replace',
            addlastPoint: true
        });
        markerTimeLayer.addTo(this.map);
        self._markerTimeLayer = markerTimeLayer;
        isInitialized = true;
        self.fitBoundsForTimeLine(markerTimeLayer);

        return {
            stop:player.stop
        }
    }

    private fitBoundsForTimeLine(markerTimeLayer: any) {
        try{
            this.map.panTo(markerTimeLayer.getBounds().getCenter())
            // this.map.fitBounds(markerTimeLayer.getBounds(), {
            //     paddingBottomRight: [100,100]
            // });
        }
        catch(e){ console.error(e); }
    }
    // Draw a marker on the current map
    public createMarker(latLng:any,label:string,color:string,isStopped:Boolean) {
           
        let marker = null;
        if(isStopped)
        {
            // let radiusMts = .75;
            // let bounds = latLng.toBounds(radiusMts); 
            // return L.rectangle(bounds, {
            //     fillColor:color,
            //     color:"#000" ,
            //     weight: 2,
            //     opacity: 1,
            //     fillOpacity: 0.8
            // });

            
            const markerHtmlStyles = `
            background-color: ${tinycolor(color).lighten()};
            width: 1rem;
            height: 1rem;
            display: block;
            position: relative;
            border: 2px solid #000`
    
            const icon = L.divIcon({
                className: "my-custom-pin",
                // iconAnchor: [0, 24],
                // labelAnchor: [-6, 0],
                // popupAnchor: [0, -36],
                html: `<span style="${markerHtmlStyles}" />`
            })
    
            marker = L.marker(latLng, {
                icon: icon
            });
        }
        else
        {
            marker =  L.circleMarker(latLng,{
                radius: 8,
                fillColor:color,
                color:"#000" ,
                weight: 2,
                opacity: 1,
                fillOpacity: 0.8
            });
        }
        marker.bindTooltip(label);
        return marker;
    }
    // Draw a marker on the current map
    public createCustomMarker(latLng:any,label:string,color:string,isStopped:Boolean) {      
        let marker = null;
        if(isStopped)
        {
            const markerHtmlStyles = `
            background-color: ${tinycolor(color).lighten()};
            width: 1.5rem;
            height: 1.5rem;
            display: block;
            position: relative;
            border: 5px solid ${color}`
    
            const icon = L.divIcon({
                className: "my-custom-pin",
                iconAnchor: [0, 24],
                labelAnchor: [-6, 0],
                popupAnchor: [0, -36],
                html: `<span style="${markerHtmlStyles}" />`
            })
    
            marker = L.marker(latLng, {
                icon: icon
            });
        }
        else
        {
            const markerHtmlStyles = `
            background-color: ${tinycolor(color).lighten()};
            width: 2rem;
            height: 2rem;
            display: block;
            left: -1rem;
            top: -1rem;
            position: relative;
            border-radius: 1.5rem 2rem 0;
            transform: rotate(45deg);
            border: 8px solid ${color}`
    
            const icon = L.divIcon({
                className: "my-custom-pin",
                iconAnchor: [0, 24],
                labelAnchor: [-6, 0],
                popupAnchor: [0, -36],
                html: `<span style="${markerHtmlStyles}" />`
            })
    
            marker = L.marker(latLng, {
                icon: icon
            });

        }
        
        marker.bindTooltip(label);
        return marker;
    }


    // Draw a marker on the current map
    public drawMarker(loc) {
        // Make sure there is a map
        if (this.map != undefined) {
            // Create marker
            var marker = new L.marker([loc.lat, loc.lng], { icon: this.markerService.createMarker(), draggable: true });
            marker.addTo(this.map);
            return marker;
        }
        return undefined;
    }

    // Draw a popup on the current map
    public drawPopup(options, loc) {
        // Make sure there is a map
        if (this.map != undefined) {
            // Create popup
            var popup = new L.popup(options).setLatLng([loc.lat, loc.lng]);
            popup.addTo(this.map).openPopup();
            return popup;
        }
        return undefined;
    }

    // Remove the map from the container
    public removeMap() {
        var self = this;
        // Remove the crosshair marker if needed
        if (this.crosshairMarker != undefined)
            this.removeFromMap(this.crosshairMarker);
        this.crosshairMarker = undefined;
        // Remove the map
    	if (this.map != undefined && this.map.remove != undefined)
    		this.map.remove();
    	this.map = undefined;
    }

    // Move the map to those coordinates
    public moveMapToCoordinates(latLng: [number, number]) {
    	if (this.map != undefined && this.map.panTo != undefined && latLng != undefined && latLng.length > 1)
    		this.map.panTo(new L.LatLng(latLng[0], latLng[1]));
    }
    // Move the map to those bounds
    public moveMapToBounds(bounds: L.LatLngBounds) {
        if (this.map != undefined && this.map.fitBounds != undefined && bounds != undefined)
            this.map.fitBounds(bounds);
    }

    // Disable the map interactions
    public disableMap() {
    	if (this.map != undefined) {
			this.map.dragging.disable();
			this.map.touchZoom.disable();
			this.map.doubleClickZoom.disable();
			this.map.scrollWheelZoom.disable();
			this.map.boxZoom.disable();
			this.map.keyboard.disable();
			if (this.map.tap) this.map.tap.disable();
		}
    }
    // Enable the map interactions
    public enableMap() {
    	if (this.map != undefined) {
			this.map.dragging.enable();
			this.map.touchZoom.enable();
			this.map.doubleClickZoom.enable();
			this.map.scrollWheelZoom.enable();
			this.map.boxZoom.enable();
			this.map.keyboard.enable();
			if (this.map.tap) this.map.tap.enable();
		}
    }

    // Return a list of layers (with their floorplan)
    public getEditedFloorplans() {
        var self = this;
        return new Promise((resolve, reject) => {
            if (self.mapData.editableFloorplan != true || self._layers.length <= 0)
                reject();
            // Just in case we will need to create a new floorplan, let's get an empty KML
            self.utilsService.getNewFloorplanKml().then((emptyKml) => {
                // Success, got an empty KML
                var editedFloorplans = [];
                // For each layers
                self._layers.forEach(function(rectangle) {
                    var newKml = rectangle.kml;
                    // If kml is missing (meaning we are creating a new floorplan), just get the default one
                    if (newKml == undefined)
                        newKml = JSON.parse(JSON.stringify(emptyKml));
                    // Get the two circles bounds
                    var lngCircleBounds = rectangle.lngCircle.getBounds();
                    var latCircleBounds = rectangle.latCircle.getBounds();
                    // Update the new KML values
                    newKml.kml.GroundOverlay.LatLonBox.north = latCircleBounds._northEast.lat;
                    newKml.kml.GroundOverlay.LatLonBox.south = latCircleBounds._southWest.lat;
                    newKml.kml.GroundOverlay.LatLonBox.east = lngCircleBounds._northEast.lng;
                    newKml.kml.GroundOverlay.LatLonBox.west = lngCircleBounds._southWest.lng;
                    newKml.kml.GroundOverlay.LatLonBox.rotation = rectangle.imageRotation;
                    // XML to string
                    var newKmlStr = self.utilsService.kmlToString(newKml);
                    // Check we have a floorplan object
                    var floorplan = (rectangle.floorplan != undefined) ? rectangle.floorplan : {};
                    // Add the new floorplan with its new KML in the result array
                    editedFloorplans.push({floorplan: floorplan, image: rectangle.image, newKml: newKmlStr, hasBeenTransformed: rectangle.hasBeenTransformed});
                });
                resolve(editedFloorplans);
            }, (err) => {
                // Error
                reject();
            });
        });
    }

    // Add something on the map
    public addOnMap(something, centerMapToObj = false) {
        if (this.map != undefined && something != undefined && something.addTo != undefined) {
            if (centerMapToObj == true && something.getBounds != undefined)
                this.map.fitBounds(something.getBounds());
            return something.addTo(this.map);
        }
        return undefined;
    }

    // Remove something from the map
    public removeFromMap(something) {
        if (this.map != undefined && something != undefined)
            this.map.removeLayer(something);
    }

    // Update the geo-fences on the map
    public updateGeoFences(newGeoFences) {
        if (this.mapData) {
            // Update variable
            this.mapData.geoFences = newGeoFences;
            // Update the map
            this._addGeoFencesOnMap();
        }
    }

    // Return the closest point from this latLng point on this polyline
    public locateOnLine(polyline, latLng) {
        if (!this.map)
            return;
        return L.GeometryUtil.closestLayer(this.map, polyline, latLng);
    }

    /* -------------------------------------------------------- */
    /*                    Private functions                     */
    /* -------------------------------------------------------- */

    // Get the floorplan's KML and show it on the map
    private _showFloorplan() {
    	if (this.currentSite == undefined || this.currentSite.siteId == undefined)
    		return;
    	var self = this, floorplan = (this.mapData.useFloorplan != undefined) ? this.mapData.useFloorplan : this.currentFloorplan;
        // Empty our array of overlays
        this._layers = [];
		// If we have the KML urls
		if (floorplan == undefined || floorplan.kmlAligned == undefined || floorplan.kml == undefined)
            return;
        // Load the regular KML
        self.utilsService.getKmlContentFromUrl(floorplan.kml).then((kmlContent: any) => {
            self.utilsService.getKmlContentFromUrl(floorplan.kmlAligned).then((kmlAlignedContent: any) => {
                // Here, we have both KMLs content
                // Check we have all the data we need
                if (kmlAlignedContent.kml != undefined && kmlAlignedContent.kml.GroundOverlay != undefined &&
                    kmlAlignedContent.kml.GroundOverlay.LatLonBox != undefined && kmlAlignedContent.kml.GroundOverlay.Icon != undefined &&
                    kmlAlignedContent.kml.GroundOverlay.Icon.href != undefined && kmlAlignedContent.kml.GroundOverlay.Icon.viewBoundScale != undefined &&
                    kmlAlignedContent.kml.GroundOverlay.LatLonBox.east != undefined && kmlAlignedContent.kml.GroundOverlay.LatLonBox.north != undefined &&
                    kmlAlignedContent.kml.GroundOverlay.LatLonBox.south != undefined && kmlAlignedContent.kml.GroundOverlay.LatLonBox.west != undefined &&
                    kmlContent.kml != undefined && kmlContent.kml.GroundOverlay != undefined &&
                    kmlContent.kml.GroundOverlay.LatLonBox != undefined && kmlContent.kml.GroundOverlay.LatLonBox.rotation != undefined) {

                    var iconHref = kmlAlignedContent.kml.GroundOverlay.Icon.href, box = kmlAlignedContent.kml.GroundOverlay.LatLonBox;
                    // Use the KML data to show the floorplan on the map

                    // If user doesn't want to show the floorplan
                    if (self.mapData.showFloorplan == false) {
                        // just center the map to the right position if wanted to
                        if (self.mapData.centerOnFloorplan == true)
                            self.map.fitBounds([[box.north, box.west], [box.south, box.east]]);
                    }
                    // If the floorplan is not editable
                    else if (self.mapData.editableFloorplan != true) {
                        // Just display the image
                        if(self.map)
                            L.imageOverlay(iconHref, [[box.north, box.west], [box.south, box.east]]).on('load', function(e) {
                                if (e && e.target && e.target.getBounds != undefined && self.map != undefined)
                                    self.map.fitBounds(e.target.getBounds());
                            }).addTo(self.map);
                    } else {
                        // Display the editable floorplan
                        var layer = self._addEditableFloorplan(floorplan, iconHref, box, kmlContent, kmlAlignedContent);
                        // Display the floorplan opacity slider control
                        self._addFloorplanOpacityControl(layer);
                    }
                }
            });
        });
    }

    // Display an editable image
    private _showEditableImage() {
        if (this.mapData.image == undefined || this.mapData.editableFloorplan == undefined ||
            this.mapData.editableFloorplan != true || this.mapData.image.base64Data == undefined)
            return;
        // Calculate the box coordinates (north, south, east and west)
        // Get the dimensions of the image (will be needed to display the image on the map)
        this.utilsService.getImageDimensionsFromBase64(this.mapData.image.base64Data).then((dimensions: [number, number]) => {
            dimensions[0] = dimensions[0] / 100;
            dimensions[1] = dimensions[1] / 100;
            // Success
            // Calculate the coordinates difference (in latlng) from the center
            var box = {
                north: L.GeometryUtil.destination({ lat: this.mapData.center[0], lng: this.mapData.center[1] }, 0, dimensions[1]).lat,
                south: L.GeometryUtil.destination({ lat: this.mapData.center[0], lng: this.mapData.center[1] }, 180, dimensions[1]).lat,
                west: L.GeometryUtil.destination({ lat: this.mapData.center[0], lng: this.mapData.center[1] }, 270, dimensions[0]).lng,
                east: L.GeometryUtil.destination({ lat: this.mapData.center[0], lng: this.mapData.center[1] }, 90, dimensions[0]).lng
            };
            // Display the editable image on the map
            var layer = this._addEditableFloorplan(this.mapData.image, this.mapData.image.base64Data, box);
            // Display the floorplan opacity slider control
            this._addFloorplanOpacityControl(layer);
        });
    }

    // Display the floorplan opacity slider control on the map
    private _addFloorplanOpacityControl(layer) {
        if (layer == undefined)
            return;
        L.control.opacity({"Floorplan opacity": layer}).addTo(this.map);
    }

    // Display the uniform scaling control on the map
    private _addUniformScalingControl() {
        if (this.mapData.editableFloorplan == undefined || this.mapData.editableFloorplan != true)
            return;
        var command = L.control({position: 'topright'}), self = this;
        command.onAdd = function (map) {
            var div = L.DomUtil.create('div');
            var checked = (self.mapData.uniformScaling) ? 'checked="checked"' : '';
            div.innerHTML = '<label style="border: 2px solid rgba(0,0,0,0.3)" class="px-2 py-1 bg-white rounded"><input class="position-relative" style="top: 2px" type="checkbox" ' + checked + '> Uniform scaling</label>'; 
            L.DomEvent.on(div, 'mouseup', function(ev) {
                // Toggle the uniform scaling
                self.mapData.uniformScaling = !self.mapData.uniformScaling;
                if (self.mapData.editableFloorplan == true) {
                    // For each layer, set the uniform scaling value
                    self._layers.forEach(function(rectangle) {
                        rectangle.transform.setOptions({uniformScaling: self.mapData.uniformScaling});
                    });
                }
            });
            // Disable the map events on this control
            L.DomEvent.disableClickPropagation(div);
            return div;
        };
        command.addTo(self.map);
    }

    // Check the map data and add the components needed
    private _addComponentsOnMap() {
        var self = this;
        // Disable the map if needed
        if (this.mapData.isDisabled == true)
            this.disableMap();
    	// Add the crosshair if needed
		if (this.mapData.crosshair == true) {
			this.crosshairMarker = new L.marker(this.map.getCenter(), { icon: this.markerService.createMarker(), clickable:false });
			this.crosshairMarker.addTo(this.map);
		}
        // Add the polylines if needed
        if (this.mapData.polylines.length > 0) {
            this.mapData.polylines.forEach(function(p) {
                if (p != undefined && p.addTo != undefined)
                    p.addTo(self.map);
            });
        }
    }

    // Add all the elements related to geo-fences
    private _addGeoFencesOnMap() {
        var self = this;
        // Remove the geo-fences if needed
        if (this._geoFencesPolygons.length > 0) {
            this._geoFencesPolygons.forEach(function(gf) {
                self.removeFromMap(gf);
            });
        }
        this._geoFencesPolygons = [];
        // Create the group layer if needed
        if (this._geoFencesGroupLayer == undefined) {
            this._geoFencesGroupLayer = new L.FeatureGroup();
            this.map.addLayer(this._geoFencesGroupLayer);
        }
        this._geoFencesGroupLayer.clearLayers();
        // Add the geo-fences if needed
        if (this.mapData.showGeoFences == true) {
            // Show geo-fences on map
            this.mapData.geoFences.forEach(function(gf) {
                if (gf != undefined && gf.vertexes != undefined && gf.vertexes[0] != undefined && gf.vertexes[0].length > 0) {
                    var tooltipText = gf.id.toString();
                    if (gf.name != undefined && gf.name.length > 0)
                        tooltipText += " (" + gf.name + ")";
                    var newGeoFence = new L.polygon(gf.vertexes[0], {weight: 1, color: (gf.behavior == 'include') ? 'green' : 'red'})
                                      .bindTooltip(tooltipText).addTo(self.map);
                    newGeoFence.geoFenceId = gf.id;
                    newGeoFence.addTo(self._geoFencesGroupLayer);
                    newGeoFence.on('click', function(e) {
                        // Run the event fct if we have one
                        if (self.mapData.onGeoFenceClicked != undefined)
                            self.mapData.onGeoFenceClicked(e);
                    });
                    self._geoFencesPolygons.push(newGeoFence);
                }
            });
        }
        // Remove the geo-fences control if needed
        if (self._geoFencesControl && self.map)
            this.map.removeControl(this._geoFencesControl);
        this._geoFencesControl = undefined;
        // Add the draw toolbar if needed
        if (this.mapData.showDrawToolbar == true) {
            // Draw toolbar options
            var options = {
                draw: {
                    polygon: {
                        allowIntersection: false, // Restricts shapes to simple polygons
                        drawError: {
                            color: 'red', // Color the shape will turn when intersects
                            message: '<strong>Oh snap!<strong> you can\'t draw that!' // Message that will show when intersect
                        }
                    },
                    rectangle: { showArea: false }, 
                    // Turns off those drawing tools
                    marker: false,
                    circle: false,
                    circlemarker: false,
                    polyline: false
                }
            };
            if(!this.mapData.hideEditInToolbar)
                options["edit"] =  {
                    featureGroup: this._geoFencesGroupLayer,
                    remove: false
                };

            // Instantiate and display the toolbar
            this._geoFencesControl = new L.Control.Draw(options);
            this.map.addControl(this._geoFencesControl);
            // Callback when a shape is drawn (clear before)
            this.map.off(L.Draw.Event.CREATED);
            this.map.on(L.Draw.Event.CREATED, function (e) {
                // Run the event fct if we have one
                if (self.mapData.onGeoFenceCreated != undefined)
                    self.mapData.onGeoFenceCreated(e);
            });
            // Callback when a/some shape(s) are edited (clear before)
            this.map.off(L.Draw.Event.EDITED);
            this.map.on(L.Draw.Event.EDITED, function (e) {
                // Run the event fct if we have one
                if (self.mapData.onGeoFenceEdited != undefined && e.layers != undefined && e.layers._layers != undefined)
                    self.mapData.onGeoFenceEdited(e.layers._layers);
            });
            // Callback when the user starts drawing
            this.map.off(L.Draw.Event.DRAWSTART);
            this.map.on(L.Draw.Event.DRAWSTART, function(e) {
                if (self.mapData.onGeoFenceStartDrawing != undefined)
                    self.mapData.onGeoFenceStartDrawing();
            });
            // Callback when the user stops drawing
            this.map.off(L.Draw.Event.DRAWSTOP);
            this.map.on(L.Draw.Event.DRAWSTOP, function(e) {
                if (self.mapData.onGeoFenceStopDrawing != undefined)
                    self.mapData.onGeoFenceStopDrawing();
            });
            // Callback when the user starts editing
            this.map.off(L.Draw.Event.EDITSTART);
            this.map.on(L.Draw.Event.EDITSTART, function(e) {
                if (self.mapData.onGeoFenceStartEditing != undefined)
                    self.mapData.onGeoFenceStartEditing();
            });
            // Callback when the user stops editing
            this.map.off(L.Draw.Event.EDITSTOP);
            this.map.on(L.Draw.Event.EDITSTOP, function(e) {
                if (self.mapData.onGeoFenceStopEditing != undefined)
                    self.mapData.onGeoFenceStopEditing();
            });
        }
    }

    public isPointInsidePolygon(marker:{ lat:number,lng:number }, poly:number[][]) {
        var inside = false;
        var x = marker.lat, y = marker.lng;
        for (var ii=0;ii<poly.length;ii++){
            var polyPoints = poly[ii];
            for (var i = 0, j = polyPoints.length - 1; i < polyPoints.length; j = i++) {
                var xi = polyPoints[i][0], yi = polyPoints[i][1];
                var xj = polyPoints[j][0], yj = polyPoints[j][1];
    
                var intersect = ((yi > y) != (yj > y))
                    && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
                if (intersect) inside = !inside;
            }
        }
    
        return inside;
    };
    // Check the map data and add the events needed
    private _addEventsMap() {
    	var self = this;
        // First, clear all previous event listeners
        this.map.off('move');
        this.map.off('moveend');
        this.map.off('click');
        // When user moves the map
    	this.map.on('move', function(e) {
			// Run the event fct if we have one
			if (self.mapData.onMove != undefined)
				self.mapData.onMove(e);
			// Update the crosshair if we have one
			if (self.crosshairMarker != undefined)
				self.crosshairMarker.setLatLng(self.map.getCenter());
		});
        // When stops moving the map
		this.map.on('moveend', function(e) {
			// Run the event fct if we have one
			if (self.mapData.onMoveEnd != undefined)
				self.mapData.onMoveEnd(e);
		});
        // When user clicks on the map
        this.map.on('click', function(e) {
            // Run the event fct if we have one
            if (self.mapData.onClick != undefined)
                self.mapData.onClick(e.latlng);
        });
    }

    private _findMapCenter(mapData: MapData) {
        var self = this;
        return new Promise((resolve, reject) => {
        	// Get the center if needed
            if (mapData.center == undefined) {
                var floorplan = (mapData.useFloorplan != undefined) ? mapData.useFloorplan : self.currentFloorplan;
            	// Set default to 0,0
            	mapData.center = [0, 0];
                // If we have a floorplan, and we are showing it, use its bounds as center
                if (mapData.showFloorplan == true && floorplan != undefined && floorplan.kmlAligned != undefined) {
                    // Get the KML content for the first floorplan, and get the center coordinates
                    self.utilsService.getKmlContentFromUrl(floorplan.kmlAligned).then((kml: any) => {
                        if (kml != undefined && kml.kml != undefined && kml.kml.GroundOverlay != undefined &&
                            kml.kml.GroundOverlay.LatLonBox != undefined && kml.kml.GroundOverlay.LatLonBox.east != undefined &&
                            kml.kml.GroundOverlay.LatLonBox.north != undefined && kml.kml.GroundOverlay.LatLonBox.west != undefined &&
                            kml.kml.GroundOverlay.LatLonBox.south != undefined) {
                            let fpCoord = kml.kml.GroundOverlay.LatLonBox;
                            mapData.center = [(parseFloat(fpCoord.north) + parseFloat(fpCoord.south)) / 2, (parseFloat(fpCoord.east) + parseFloat(fpCoord.west)) / 2];
                        }
                        resolve(self);
                    }, () => { resolve(self); });
                }
            	else if (self.currentSite != undefined && self.currentSite.address != undefined) {
                    // Try to parse the address to get the coordinates
            		var parsedCenter = this.utilsService.getCoordinateFromAddress(self.currentSite.address);
                    // If we got coordinates
            		if (parsedCenter != undefined && !isNaN(parsedCenter[0]) && !isNaN(parsedCenter[1])) {
            			mapData.center = parsedCenter;
                        resolve(self);
                    }
                    else {
                        // Use the geo search
                        this.provider.search({ query: self.currentSite.address }).then((addresses) => {
                            if (addresses != undefined && addresses.length > 0 && addresses[0].x != undefined && addresses[0].y != undefined &&
                                !isNaN(parseFloat(addresses[0].x)) && !isNaN(parseFloat(addresses[0].y)))
                                mapData.center = [parseFloat(addresses[0].y), parseFloat(addresses[0].x)];
                            resolve(self);
                        }, (err) => {
                            resolve(self);
                        });
                    }
    	        } else
                    resolve(self);
            } else
                resolve(self);
        });
    }

    // Add the ruler to the map
    private _addRulerControl() {
        if (this.mapData.showRuler == true) {
            L.control.polylineMeasure({
                position: 'topright',
                unit: 'metres',
                showBearings: false,
                clearMeasurementsOnStop: false,
                showMeasurementsClearControl: true,
                showUnitControl: true
            }).addTo(this.map);
        }
    }

    // First parameter can be a floorplan or a FpImage (if we are creating a new floorplan)
    private _addEditableFloorplan(floorplan: any, iconHref: string, box, kmlContent?, kmlAlignedContent?) {
        var self = this;

        // Use a rectangle to make the floorplan editable
        var polyCorners = [[box.north, box.west],[box.south, box.east]];
        var rectangle = new L.Rectangle(polyCorners, {
            draggable: true,
            transform: true,
            opacity: 0,
            fillOpacity: 0
        }).addTo(this.map);
        // Make the rectangle editable
        rectangle.transform.enable({uniformScaling: self.mapData.uniformScaling});

        // Add the image into the rectangle
        var imageBounds = L.latLngBounds([polyCorners[0],polyCorners[1]]);
        var topleft = L.latLng(box.north, box.west),
            topright = L.latLng(box.north, box.east),
            bottomleft = L.latLng(box.south, box.west);
        var overlay = L.imageOverlay.rotated(iconHref, topleft, topright, bottomleft, {
            opacity: self.mapData.floorplanOpacity,
            interactive: true
        }).addTo(this.map);

        // Use two circles to determine the not aligned coordinates (north, south, east and west)
        var lngCircleRadius = (kmlContent != undefined) ? L.latLng(kmlContent.kml.GroundOverlay.LatLonBox.north, kmlContent.kml.GroundOverlay.LatLonBox.west).distanceTo(L.latLng(kmlContent.kml.GroundOverlay.LatLonBox.north, kmlContent.kml.GroundOverlay.LatLonBox.east)) : topleft.distanceTo(topright);
        var lngCircle = L.circle(rectangle.getCenter(), {radius: lngCircleRadius / 2, interactive: false, opacity: 0, fillOpacity: 0}).addTo(this.map);
        var latCircleRadius = (kmlContent != undefined) ? L.latLng(kmlContent.kml.GroundOverlay.LatLonBox.north, kmlContent.kml.GroundOverlay.LatLonBox.west).distanceTo(L.latLng(kmlContent.kml.GroundOverlay.LatLonBox.south, kmlContent.kml.GroundOverlay.LatLonBox.west)) : topleft.distanceTo(bottomleft);
        var latCircle = L.circle(rectangle.getCenter(), {radius: latCircleRadius / 2, interactive: false, opacity: 0, fillOpacity: 0}).addTo(this.map);
        rectangle.lngCircle = lngCircle;
        rectangle.latCircle = latCircle;

        // Set the rotation, if not available, use 0
        let rotation = (kmlContent != undefined) ? parseFloat(kmlContent.kml.GroundOverlay.LatLonBox.rotation) : 0;
        rectangle.imageRotation = rotation;
        // Add some important data so we can access it when saving the floorplan
        rectangle.hasBeenTransformed = false;
        rectangle.imageOverlay = overlay;
        rectangle.image = iconHref;             // In case we are creating a new floorplan, we will need the image data
        rectangle.kml = kmlContent;
        // Depending on the floorplan type (Floorplan or FpImage), we store what we will need
        if (floorplan != undefined && floorplan.fpId != undefined)
            rectangle.floorplan = floorplan;
        else if (floorplan != undefined && floorplan.base64Data != undefined)
            rectangle.image = floorplan;

        // Add our rectangle in the array
        this._layers.push(rectangle);
        // Move the map view to the floorplan
        this.map.fitBounds(rectangle.getBounds());

        // When rectangle is edited, update the floorplan image position
        rectangle.on('transformed scaleend rotateend', function(e) {
            // Set that the floorplan has been transformed
            rectangle.hasBeenTransformed = true;
            // Update the image overlay
            var updatedTopLeft = e.target._latlngs[0][1],
                updatedTopRight = e.target._latlngs[0][2],
                updatedBottomLeft = e.target._latlngs[0][0];
                overlay.reposition(updatedTopLeft, updatedTopRight, updatedBottomLeft);
            // Update the circles
            rectangle.lngCircle.setLatLng(rectangle.getCenter());
            rectangle.latCircle.setLatLng(rectangle.getCenter());
        });
        rectangle.on('transformed', function(e) {
            if (e && e.scale != undefined && e.scale.x != undefined && e.scale.y != undefined) {
                // Update the circles
                rectangle.lngCircle.setRadius(lngCircle.getRadius() * e.scale.x);
                rectangle.latCircle.setRadius(latCircle.getRadius() * e.scale.y);
            }
        });
        rectangle.on('rotateend', function(e) {
            // Set that the floorplan has been transformed
            rectangle.hasBeenTransformed = true;
            if (e.rotation != undefined && !isNaN(e.rotation))
                rectangle.imageRotation -= self.utilsService.degreesFromRadians(e.rotation);
        });
        
        return overlay;
    }
}