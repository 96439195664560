import { Routes, RouterModule } from '@angular/router';

/* Global components */
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { NewPasswordComponent } from './reset-password/new-password.component';

/* General category components */
import { AccountComponent } from './general/account/account.component';
import { GeneralComponent } from './general/general.component';
import { FloorplanComponent } from './general/floorplan/floorplan.component';
import { SiteComponent } from './general/site/site.component';
import { LevelComponent } from './general/level/level.component';
import { GeoFenceComponent } from './general/geo-fence/geo-fence.component';

/* Admin category components */
import { SitesComponent } from './admin/sites/sites.component';
import { AdminComponent } from './admin/admin.component';

/* Dragonfly category components */
import { DragonflyComponent } from './dragonfly/dragonfly.component';
import { DragonflyMapComponent } from './dragonfly/map/map.component';
import { DragonflyMapsComponent } from './dragonfly/maps/maps.component';
import { DragonflyHistoryComponent } from './dragonfly/history/history.component';
import { DragonflyMarkingComponent } from './dragonfly/marking/marking.component';
import { DragonflyLicenseComponent } from './dragonfly/license/license.component';
import { DragonflyCreateNewLicenseComponent } from './dragonfly/create-new-license/create-new-license.component';

/* Common components */
import { CategoriesComponent } from './common/categories/categories.component';

/* Guards */
import { AuthGuard } from './_guards/auth.guard';
import { AdminGuard } from './_guards/admin.guard';
import { SettingsComponent } from './general/settings/settings.component';

// Routes for the normal dashboard
const appRoutes: Routes = [
    { path: '', component: HomeComponent, canActivate: [AuthGuard] },
    { path: 'admin', component: AdminComponent, canActivate: [AuthGuard, AdminGuard], children: [
        { path: '', component: CategoriesComponent, canActivate: [AuthGuard, AdminGuard] },
        { path: 'sites', component: SitesComponent, canActivate: [AuthGuard, AdminGuard] }
    ] },
    { path: 'general', component: GeneralComponent, canActivate: [AuthGuard], children: [
    	{ path: '', component: CategoriesComponent, canActivate: [AuthGuard] },
        { path: 'accounts', component: AccountComponent, canActivate: [AuthGuard] },
    	{ path: 'site', component: SiteComponent, canActivate: [AuthGuard] },
    	{ path: 'levels', component: LevelComponent, canActivate: [AuthGuard] },
    	{ path: 'floorplans', component: FloorplanComponent, canActivate: [AuthGuard] },
        { path: 'geo-fences', component: GeoFenceComponent, canActivate: [AuthGuard] },
        { path: 'settings', component: SettingsComponent, canActivate: [AuthGuard] }
    ] },
    { path: 'dragonfly', component: DragonflyComponent, canActivate: [AuthGuard], children: [
        { path: '', component: CategoriesComponent, canActivate: [AuthGuard] },
        { path: 'map', component: DragonflyMapComponent, canActivate: [AuthGuard] },
        { path: 'history', component: DragonflyHistoryComponent, canActivate: [AuthGuard] },
        { path: 'marking', component: DragonflyMarkingComponent, canActivate: [AuthGuard] },
        { path: 'license', component: DragonflyLicenseComponent, canActivate: [AuthGuard] },
        { path: 'create-new-license', component: DragonflyCreateNewLicenseComponent, canActivate: [AuthGuard] }
    ] },
    { path: 'login', component: LoginComponent },
    { path: 'reset-password', component: ResetPasswordComponent },
    { path: 'new-password', component: NewPasswordComponent },
    // Otherwise redirect to home
    { path: '**', redirectTo: '' }
];

export const Routing = RouterModule.forRoot(appRoutes);