import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_BASE_HREF } from '@angular/common';

import { MaterialModule } from './material.module';
import { Routing } from './app.routing';
import { PipesModule } from './_pipes/pipes.module';

/* Others */
import { Ng5SliderModule } from 'ng5-slider';
import { AngularResizedEventModule } from 'angular-resize-event';
import { NgxGraphModule } from '@swimlane/ngx-graph';

/* Helpers */
import { BasicAuthInterceptor } from './_helpers/basic-auth.interceptor';
import { ErrorInterceptor } from './_helpers/error.interceptor';
import { AlertComponent } from './_helpers/alert.component';

/* Global components */
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { NewPasswordComponent } from './reset-password/new-password.component';

/* General category components */
import { AccountComponent } from './general/account/account.component';
import { GeneralComponent } from './general/general.component';
import { LevelComponent } from './general/level/level.component';
import { FloorplanComponent } from './general/floorplan/floorplan.component';
import { SiteComponent } from './general/site/site.component';
import { GeoFenceComponent } from './general/geo-fence/geo-fence.component';

/* Admin category components */
import { AdminComponent } from './admin/admin.component';
import { SitesComponent } from './admin/sites/sites.component';
import { SitesTableComponent } from './admin/sites/sitesTable/sitesTable.component';
import { EditSiteComponent } from './admin/sites/editSite/editSite.component';

/* Dragonfly category components */
import { DragonflyComponent } from './dragonfly/dragonfly.component';
import { DragonflyMapComponent } from './dragonfly/map/map.component';
import { DragonflyMapsComponent } from './dragonfly/maps/maps.component';
import { DragonflyHistoryComponent } from './dragonfly/history/history.component';
import { DragonflyMarkingComponent } from './dragonfly/marking/marking.component';
import { DragonflyLicenseComponent } from './dragonfly/license/license.component';
import { DragonflyCreateNewLicenseComponent } from './dragonfly/create-new-license/create-new-license.component';

/* Common components */
import { CategoriesComponent } from './common/categories/categories.component';
import { MapSettingsComponent } from './common/mapSettings/mapSettings.component';
import { TableComponent } from './common/table/table.component';

/* Dialogs */
import { EditAccountDialogComponent } from './common/dialogs/accounts/edit-dialog.component';
import { SiteSelectorDialogComponent } from './common/dialogs/sites/siteSelector/site-selector-dialog.component';
import { LevelSelectorDialogComponent } from './common/dialogs/levels/levelSelector/level-selector-dialog.component';
import { YesNoDialogComponent } from './common/dialogs/yes-no-dialog.component';
import { OkDialogComponent } from './common/dialogs/ok-dialog.component';
import { EditDialogComponent } from './common/dialogs/edit-dialog.component';
import { CreateLevelDialogComponent } from './common/dialogs/levels/createLevel/create-level-dialog.component';
import { EditFloorplanDialogComponent } from './common/dialogs/floorplans/edit-floorplan-dialog.component';
import { UploadImageFloorplanDialogComponent } from './common/dialogs/floorplans/uploadImageFloorplan/upload-image-floorplan-dialog.component';
import { VMarkerPopupDialogComponent, VMarkerImagePopupDialogComponent } from './dragonfly/marking/vmarker-dialog.component';
import { CreateGeoFenceDialogComponent } from './common/dialogs/geo-fences/create-geo-fence-dialog.component';

import * as PlotlyJS from 'plotly.js-dist-min';
import { PlotlyModule } from 'angular-plotly.js';
import { MyDurationPickerDirective } from './_helpers/my-duration-picker-directive';
import { SettingsComponent } from './general/settings/settings.component';

PlotlyModule.plotlyjs = PlotlyJS;

@NgModule({
  declarations: [
    AppComponent,
    AlertComponent,
    HomeComponent,
    LoginComponent,
    ResetPasswordComponent,
    NewPasswordComponent,
    GeneralComponent,
    AdminComponent,
    CategoriesComponent,
    FloorplanComponent,
    LevelComponent,
    SiteComponent,
    AccountComponent,
    SitesComponent,
    GeoFenceComponent,
    SiteSelectorDialogComponent,
    EditAccountDialogComponent,
    LevelSelectorDialogComponent,
    YesNoDialogComponent,
    OkDialogComponent,
    EditDialogComponent,
    CreateLevelDialogComponent,
    EditFloorplanDialogComponent,
    UploadImageFloorplanDialogComponent,
    MapSettingsComponent,
    SitesTableComponent,
    EditSiteComponent,
    TableComponent,
    DragonflyComponent,
    DragonflyMapComponent,
    DragonflyMapsComponent,
    DragonflyHistoryComponent,
    DragonflyMarkingComponent,
    DragonflyLicenseComponent,
    DragonflyCreateNewLicenseComponent,
    VMarkerPopupDialogComponent,
    VMarkerImagePopupDialogComponent,
    CreateGeoFenceDialogComponent
    , MyDurationPickerDirective, SettingsComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    Routing,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    Ng5SliderModule,
    PipesModule,
    AngularResizedEventModule,
    NgxGraphModule,PlotlyModule
  ],
  providers: [
    // { provide: APP_BASE_HREF, useValue: '/dashboard-test' },
    { provide: HTTP_INTERCEPTORS, useClass: BasicAuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
  ],
  entryComponents: [
    AlertComponent,
    SiteSelectorDialogComponent,
    EditAccountDialogComponent,
    LevelSelectorDialogComponent,
    YesNoDialogComponent,
    OkDialogComponent,
    EditDialogComponent,
    CreateLevelDialogComponent,
    EditFloorplanDialogComponent,
    UploadImageFloorplanDialogComponent,
    VMarkerPopupDialogComponent,
    VMarkerImagePopupDialogComponent,
    CreateGeoFenceDialogComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }