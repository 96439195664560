import { Component, OnDestroy, OnInit, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';

import L from 'leaflet';
import 'leaflet-rotatedmarker';

import { GeoFenceService } from '../../_services/geoFence.service';
import { SiteService } from '../../_services/site.service';
import { ALERT_TYPE, AlertService } from '../../_services/alert.service';
import { LeafletService } from '../../_services/leaflet.service';
import { MarkerService } from '../../_services/marker.service';
import { FloorplanService } from '../../_services/floorplan.service';
import { VMarkerService } from '../../_services/vmarker.service';
import { LevelService } from '../../_services/level.service';
import { ResponsiveService } from '../../_services/responsive.service';
import { UtilsService } from '../../_services/utils.service';

import { GeoFence } from '../../_models/geoFence';
import { Site } from '../../_models/site';
import { Level } from '../../_models/level';
import { MapData } from '../../_models/mapData';
import { Floorplan } from '../../_models/floorplan';
import { VMarker } from '../../_models/vmarker';
import { MapSettings, SETTING_TYPE } from '../../_models/mapSettings';

import { YesNoDialogComponent } from '../../common/dialogs/yes-no-dialog.component';
import { VMarkerPopupDialogComponent } from './vmarker-dialog.component';

const REFRESH_TIME = 3000;
const DRAGONFLY_LOCALSTORAGE_KEY = "Dragonfly_marking";

@Component({
	templateUrl: 'marking.component.html',
	styleUrls: ['marking.component.scss']
})
export class DragonflyMarkingComponent implements OnInit, AfterViewInit, OnDestroy {

	// The current site
	public currentSite: Site;
	// The current floorplan
	private currentFloorplan: Floorplan;
    // The current level
    private currentLevel: Level;

    // Sub variables
    private subGetData;

    // List of geo-fences
    private geoFences: GeoFence[];

    // VMarkers variables
    public vmarkers: VMarker[];
    private isGettingVMarkers: Boolean = false;
    private vmarkersMarker = [];
    // To save the marker if we start dragging it
    private draggingMarker;

    // Variable used to display an alert
    private showAlert = undefined;

	// List of subs
	private subs: Subscription[] = [];

	// The map container
	@ViewChild('map', { static: true }) mapContainer: ElementRef;
	private mapId = 'map';
    // The popup that shows when we click on a station
    private elementPopup;
    // The map settings
    public mapSettings: MapSettings = MapSettings.fromData({
        title: "Settings",
        settings: [
            {
                id: "showFloorplan",
                type: SETTING_TYPE.Checkbox,
                label: "Floorplan",
                value: true
            },{
                id: "showGeofences",
                type: SETTING_TYPE.Checkbox,
                label: "Geo-Fences",
                value: false
            }
        ],
        // This function is triggered when the user changes any of the map settings
        // newMapSettings: Contains the update MapSettings object
        // lastSettingChanged: Contains the ID of the map setting that has been changed during this trigger
        onChange: (newMapSettings, lastSettingChanged) => this.onMapSettingsChange(newMapSettings, lastSettingChanged)
    });

    constructor(private siteService: SiteService, private alertService: AlertService, private leafletService: LeafletService,
    			private markerService: MarkerService, private levelService: LevelService, private floorplanService: FloorplanService,
                public vmarkerService: VMarkerService, private dialog: MatDialog, private responsiveService: ResponsiveService,
                private utilsService: UtilsService, private geoFenceService: GeoFenceService) {
        var self = this;
        // Instantiate the Leaflet popup
        this.elementPopup = new L.popup();
    }

    ngOnInit() {
    	var self = this;
        // Try getting map settings from localstorage
        try {
            var savedMapSettings = JSON.parse(localStorage.getItem(DRAGONFLY_LOCALSTORAGE_KEY));
            if (savedMapSettings != undefined)
                self.mapSettings.updateMapSettings(savedMapSettings);
        } catch(err) {
            // Update localstorage
            localStorage.setItem(DRAGONFLY_LOCALSTORAGE_KEY, JSON.stringify(this.mapSettings.settings));
        }
    	// Get the selected site
		this.subs.push(this.siteService.currentSite.subscribe((site: Site) => {
			this.currentSite = site;
			// Get the list of stations
			this.getDataRecurrently();
			// Try to draw the map
			this.drawMap();
		}));
		// Get the current floorplan
    	this.subs.push(this.floorplanService.currentFloorplan.subscribe((currentFloorplan: Floorplan) => {
    		this.currentFloorplan = currentFloorplan;
			// Try to draw the map
			this.drawMap();
    	}));
        // Get the current level
        this.subs.push(this.levelService.currentLevel.subscribe((currentLevel: Level) => {
            this.currentLevel = currentLevel;
        }));
        // Get the geo-fences
        this.subs.push(this.geoFenceService.geoFences.subscribe((geoFences: GeoFence[]) => {
            this.geoFences = geoFences;
            // Try to draw the map
            this.drawMap();
        }));
    }

    ngAfterViewInit() {
    	// Try to draw the map
    	this.drawMap();
    }

    ngOnDestroy() {
    	var self = this;
    	// Clear the subs
    	this.subs.forEach(function(sub) { sub.unsubscribe(); });
    	// Clear the existing markers
        this.vmarkersMarker.forEach(function(marker) { self.leafletService.removeFromMap(marker); });
    	// Clear the current subscription if there is one
    	clearInterval(this.subGetData);
        this.isGettingVMarkers = false;
		// Remove the map
		this.leafletService.removeMap();
	}

    // Get the list of vmarkers recurrently (depending on the REFRESH_TIME variable)
    public getDataRecurrently() {
        var self = this;
        // Clear the current subscription if there is one
        clearInterval(this.subGetData);
        this.isGettingVMarkers = false;
        // Start requesting recurrently
        this.subGetData = setInterval(function() {
            self._getData();
        }, REFRESH_TIME);
        // Start immediately
        self._getData();
    }



    /* ------------------------------------------------------------------------- */
    /* -                           Private functions                           - */
    /* ------------------------------------------------------------------------- */

    // Get the list of stations
    private _getData() {
        // Check we have what we need
        if (this.currentSite == undefined || this.currentLevel == undefined)
            return;
        // Get the stations
        this.getVMarkers();
    }



    /* ------------------------------------------------------------------------- */
    /* -                          VMarkers functions                           - */
    /* ------------------------------------------------------------------------- */

    // Get the list of vmarkers
    private getVMarkers() {
        var self = this;
        if (this.isGettingVMarkers == true || this.currentLevel == undefined || this.currentSite == undefined)
            return;
        // Get the visual markers
        this.vmarkerService.getVMarkers(this.currentSite.siteId).then((vmarkers: VMarker[]) => {
            this.vmarkers = vmarkers;
            // Get the vmarkers located on the current level
            let currentVMarkers = vmarkers.filter(function(vm){
                // Keep the vmarker if on this level, and if we are not dragging its marker
                // Remove all the others
                return (self.utilsService.isLocatedOnLevel(vm, self.currentLevel) && (self.draggingMarker == undefined || self.draggingMarker.vm.id != vm.id));
            });
            // Clear the existing markers
            this.vmarkersMarker.forEach(function(marker) {
                if (self.draggingMarker != marker)
                    self.leafletService.removeFromMap(marker);
            });
            // Draw vmarkers on the map
            this.drawElements(currentVMarkers, this.vmarkersMarker, (m) => self.getVMMarker(m));
            // Show alert if needed
            if (self.showAlert == 'created')
                self.alertService.showMessage(ALERT_TYPE.Success, "The visual marker has been correctly created");
            else if (self.showAlert == 'moved')
                self.alertService.showMessage(ALERT_TYPE.Success, "The visual marker has been correctly moved");
            else if (self.showAlert == 'updated')
                self.alertService.showMessage(ALERT_TYPE.Success, "The visual marker has been correctly updated");
            else if (self.showAlert == 'deleted')
                self.alertService.showMessage(ALERT_TYPE.Success, "The visual marker has been correctly deleted");
            self.showAlert = undefined;
            this.isGettingVMarkers = false;
        }).catch((err) => {
            console.log(err);
            this.isGettingVMarkers = false;
            this.vmarkers = [];
        });
    }

    // When a user stops moving a marker, meaning he wants to move the vmarker
    private moveVMarker(e, self, vm) {
        if (e && e.target && e.target.getLatLng && vm != undefined && vm.lat != undefined && vm.lng != undefined) {
            var latLng = e.target.getLatLng();
            // Ask user if he is sure
            var dialog = this.dialog.open(YesNoDialogComponent, { panelClass: ['mw-none','w-90','w-sm-80','w-md-50','w-lg-40'], data: {
                title: "Move visual marker",
                message:  "Are you sure you want to move the visual marker?",
                noBtnLabel: "No",
                yesBtnLabel: "Yes"
            }});
            dialog.updatePosition({ top: this.responsiveService.getDialogTopPosition() + 'px' });
            // When user answers
            dialog.afterClosed().subscribe(result => {
                // If true, means user wants to move the vmarker
                if (result == true) {
                    // Update the vmarker location
                    vm.lat = latLng.lat;
                    vm.lng = latLng.lng;
                    // Save it
                    self.vmarkerService.saveVMarker(vm).then((res) => {
                        // Success
                        // Show success message
                        self.showAlert = 'moved';
                        // Set it to undefined, it will be removed on the next refresh
                        self.draggingMarker = undefined;
                    }, () => {
                        // Error
                        // Show error message
                        self.alertService.showMessage(ALERT_TYPE.Error, "An error occurred, cannot move the visual marker");
                        // Hide the dragged marker
                        self.draggingMarker.closeTooltip().setOpacity(0);
                        // Then set it to undefined, it will be removed on the next refresh
                        self.draggingMarker = undefined;
                    });
                } else {
                    // Hide the dragged marker
                    self.draggingMarker.closeTooltip().setOpacity(0);
                    // Then set it to undefined, it will be removed on the next refresh
                    self.draggingMarker = undefined;
                    // Draw the old marker
                    self.drawElements([vm], self.vmarkersMarker, (m) => self.getVMMarker(m));
                }
            });
        } else {
            this.alertService.showMessage(ALERT_TYPE.Error, "An error occurred, cannot get the map coordinate");
            // Hide the dragged marker
            this.draggingMarker.closeTooltip().setOpacity(0);
            // Then set it to undefined, it will be removed on the next refresh
            this.draggingMarker = undefined;
            // Draw the old marker
            self.drawElements([vm], self.vmarkersMarker, (m) => self.getVMMarker(m));
        }
    }

    private getVMMarker(vmarker) {
        var self = this;
        // Display only the visual markers on the current level
        if (vmarker == undefined || vmarker.levelId != self.currentLevel.levelId)
            return;
        // Create the marker
        var newMarker = new L.marker([vmarker.lat, vmarker.lng], {
            icon: self.markerService.createDragonflyVMarker(),
            rotationAngle: -vmarker.orientation,
            draggable: true
        }).on('click', () => self.openElementPopup(vmarker))
          .on('dragstart', () => { self.draggingMarker = newMarker; self.draggingMarker.vm = vmarker; })
          .on('dragend', (e) => self.moveVMarker(e, self, vmarker));
        // Add id if there is one
        if (vmarker.id != undefined) {
            let title = (vmarker.label != undefined && vmarker.label.length > 0) ? vmarker.label : vmarker.id.toString();
            newMarker = newMarker.bindTooltip(title, { permanent: true, direction: 'top', offset: [0, -15], opacity: 0.95 });
        }
        return newMarker;
    }


    /* ------------------------------------------------------------------------- */
    /* -                             Map functions                             - */
    /* ------------------------------------------------------------------------- */

    // Try to draw the map
    drawMap() {
        var self = this;
    	if (this.currentSite != undefined && this.mapContainer != undefined && this.geoFences != undefined) {
            // Remove the map
            this.leafletService.removeMap();
            // (Re)draw it
    		this.leafletService.drawMap(this.mapId, new MapData({
                showFloorplan: self.mapSettings.getMapSetting('showFloorplan'),
                onClick: (latLng) => self.createVMarker(latLng),
                showGeoFences: self.mapSettings.getMapSetting('showGeofences'),
                geoFences: self.geoFences
            }));
            document.getElementById('map').style.cursor = 'crosshair';
    	}
    }

    // When user changes the map settings (top right corner)
    private onMapSettingsChange(newMapSettings, lastSettingChanged) {
        // Update our map settings
        this.mapSettings.updateMapSettings(newMapSettings.settings);
        // Update localstorage
        localStorage.setItem(DRAGONFLY_LOCALSTORAGE_KEY, JSON.stringify(this.mapSettings.settings));
        // Redraw the markers, or redraw the map
        if (lastSettingChanged != "showFloorplan" && lastSettingChanged != "showGeofences")
            this.getDataRecurrently();
        else
            this.drawMap();
    }

    // Draw an element on the map
    // @params:
    //    elements: Array of the elements we want to display (must contain lat and lng)
    //    markers: Array of the existing markers (they will be removed, then re-created)
    //    getMarkerFct: Function that will take an element (from the 1st param), and return the correct marker
    private drawElements(elements: any[], markers: any[], getMarkerFct) {
        var self = this;
        // Check parameter
        if (elements == undefined || elements.length <= 0)
            return;
        // For each station, add its marker
        elements.forEach(function(element) {
            if (element.lat == undefined || element.lng == undefined)
                return;
            // Create the marker from the function passed in parameter
            var newMarker = getMarkerFct(element);
            // If we could not get a marker, continue to the next element
            if (newMarker == undefined)
                return;
            // Save it
            markers.push(newMarker);
            // Draw it on the map
            self.leafletService.addOnMap(newMarker);
        });
    }



    /* ------------------------------------------------------------------------- */
    /* -                            Popup functions                            - */
    /* ------------------------------------------------------------------------- */

    // Show a popup to display the station information
    private openElementPopup(element) {
        var self = this;
        // Open the edit visual marker dialog
        var dialog = this.dialog.open(VMarkerPopupDialogComponent, { panelClass: ['mw-none','w-90','w-sm-50','w-md-35','w-lg-25'], data: {
            vmarker: element
        }});
        dialog.updatePosition({ top: this.responsiveService.getDialogTopPosition() + 'px' });
        // When user answers
        dialog.afterClosed().subscribe(response => {
            if (response != undefined && response.value == 'create' && response.result != undefined) {
                self.vmarkerService.saveVMarker(response.result).then((res) => {
                    // Show success message
                    self.showAlert = 'created';
                }, (err) => {
                    // Show error message
                    self.alertService.showMessage(ALERT_TYPE.Error, "An error occurred, cannot create the visual marker");
                });
            } else if (response != undefined && response.value == 'edit' && response.result != undefined) {
                self.vmarkerService.saveVMarker(response.result).then((res) => {
                    // Show success message
                    self.showAlert = 'updated';
                }, (err) => {
                    // Show error message
                    self.alertService.showMessage(ALERT_TYPE.Error, "An error occurred, cannot update the visual marker");
                });
            } else if (response != undefined && response.value == 'delete' && response.result != undefined && response.result.id != undefined) {
                self.vmarkerService.deleteVMarker(response.result.id).then((res) => {
                    // Show success message
                    self.showAlert = 'deleted';
                }, (err) => {
                    // Show error message
                    self.alertService.showMessage(ALERT_TYPE.Error, "An error occurred, cannot delete the visual marker");
                });
            }
        });
    }

    // Open the dialog to create a new visual marker
    private createVMarker(latLng) {
        // Check latLng
        if (latLng == undefined || latLng.lat == undefined || latLng.lng == undefined) {
            this.alertService.showMessage(ALERT_TYPE.Error, "An error occurred, cannot get the map coordinate");
            return;
        }
        if (this.currentLevel == undefined || this.currentLevel.levelId == undefined) {
            this.alertService.showMessage(ALERT_TYPE.Error, "An error occurred, cannot get the current level");
            return;
        }
        var self = this;
        var newVisualMarker = { lat: latLng.lat, lng: latLng.lng, levelId: this.currentLevel.levelId, onWall: true, orientation: 0, alt: 0 };
        // Open the dialog
        this.openElementPopup(newVisualMarker);
    }

}