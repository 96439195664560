import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Validators, FormBuilder } from '@angular/forms';

import { SiteService } from '../_services/site.service';
import { ALERT_TYPE, AlertService } from '../_services/alert.service';

import { Match } from '../_helpers/match.validator';

@Component({templateUrl: 'new-password.component.html'})
export class NewPasswordComponent {

    // Reset form
    resetForm = this.fb.group({
        email: [{value: '', disabled: true}, [Validators.required, Validators.email]],
        token: [{value: '', disabled: true}, [Validators.required]],
        newPassword: ['', [Validators.required, Validators.minLength(6)]],
        confirmPassword: ['', Validators.required]
    }, {
        validator: Match('newPassword', 'confirmPassword')
    });

    loading = false;
    done = false;

    constructor(private route: ActivatedRoute, private router: Router, private fb: FormBuilder,
                private siteService: SiteService, private alertService: AlertService) {
        // Get the token if there is one
        this.route.queryParams.subscribe(params => {
            // Set the token if there is one
            if (params && params.token && params.token.length > 0 && params.email && params.email.length > 0) {
                this.resetForm.get('token').setValue(params.token);
                this.resetForm.get('email').setValue(params.email);
            } else
                this.router.navigate(['/login']);
        });
    }

    resetPassword() {
        // Show spinner
        this.loading = true;
        // Request server
        this.siteService.resetPassword(this.resetForm.get('email').value, this.resetForm.get('token').value, this.resetForm.get('newPassword').value).then(() => {
            this.done = true;
            this.alertService.showMessage(ALERT_TYPE.Success, 'Your password has been successfully changed');
        }, (err) => {
            console.log(err);
            if (err && err.status == 'error' && err.errors && err.errors.length > 0)
                this.alertService.showMessage(ALERT_TYPE.Error, err.errors[0]);
            else
                this.alertService.showMessage(ALERT_TYPE.Error, 'Cannot save the new password');
        }).finally(() => {
            this.loading = false;
        });
    }
}