import { Component, OnDestroy, OnInit, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import L from 'leaflet';
import { Subscription } from 'rxjs';
import { FormBuilder } from '@angular/forms';

import { GeoFenceService } from '../../_services/geoFence.service';
import { SiteService } from '../../_services/site.service';
import { ALERT_TYPE, AlertService } from '../../_services/alert.service';
import { LeafletService } from '../../_services/leaflet.service';
import { UtilsService } from '../../_services/utils.service';
import { MarkerService } from '../../_services/marker.service';
import { FloorplanService } from '../../_services/floorplan.service';
import { StationService } from '../../_services/station.service';
import { LevelService } from '../../_services/level.service';

import { GeoFence } from '../../_models/geoFence';
import { Site } from '../../_models/site';
import { Level } from '../../_models/level';
import { MapData } from '../../_models/mapData';
import { Floorplan } from '../../_models/floorplan';
import { DragonflyDevice } from '../../_models/station';
import { MapSettings, SETTING_TYPE } from '../../_models/mapSettings';

const REFRESH_TIME = 3000;
const DRAGONFLY_LOCALSTORAGE_KEY = "Dragonfly_map";

@Component({
	templateUrl: 'map.component.html',
	styleUrls: ['map.component.scss']
})
export class DragonflyMapComponent implements OnInit, AfterViewInit, OnDestroy {

	// The current site
	public currentSite: Site;
	// The current floorplan1
	private currentFloorplan: Floorplan;
    // The current level
    private currentLevel: Level;

    // Sub variables
    private subGetData;

    // List of geo-fences
    private geoFences: GeoFence[];

    // Stations variables
    public stations: DragonflyDevice[];
    private isGettingStations: Boolean = false;
    private stationsMarker = [];

    // Stations table options
    public stationsTableOptions = {
        editable: true,
        deletable: true
    }
    public stationsDisplayedColumns;
    public stationsAttributesToDisplay;
    public stationsFormGroup;
    public stationsAttributesToEdit;

	// List of subs
	private subs: Subscription[] = [];

	// The map container
	@ViewChild('map', { static: true }) mapContainer: ElementRef;
	private mapId = 'map';
    // The popup that shows when we click on a station
    private elementPopup;
    // The map settings
    public mapSettings: MapSettings = MapSettings.fromData({
        title: "Settings",
        settings: [
            {
                id: "showStationLabels",
                type: SETTING_TYPE.Checkbox,
                label: "Device labels",
                value: true
            },{
                id: 'divider',
                type: SETTING_TYPE.Divider
            },{
                id: "showFloorplan",
                type: SETTING_TYPE.Checkbox,
                label: "Floorplan",
                value: true
            },{
                id: "showGeofences",
                type: SETTING_TYPE.Checkbox,
                label: "Geo-Fences",
                value: false
            }
        ],
        // This function is triggered when the user changes any of the map settings
        // newMapSettings: Contains the update MapSettings object
        // lastSettingChanged: Contains the ID of the map setting that has been changed during this trigger
        onChange: (newMapSettings, lastSettingChanged) => this.onMapSettingsChange(newMapSettings, lastSettingChanged)
    });

    constructor(private siteService: SiteService, private alertService: AlertService, private leafletService: LeafletService,
    			private utilsService: UtilsService, private markerService: MarkerService, private levelService: LevelService,
    			private floorplanService: FloorplanService, public stationService: StationService, private fb: FormBuilder,
                private geoFenceService: GeoFenceService) {
        var self = this;
        // Instantiate the Leaflet popup
        this.elementPopup = new L.popup();
        // Instantiate the table variables
        this.stationsDisplayedColumns = ['udo.name', 'udo.desc', 'mac', 'position.levelId', 'position.status', 'pos', 'position.alt', 'position.fixed_at'];
        this.stationsAttributesToDisplay = [
            {display: 'Name', columnDef: 'udo.name', getFct: (n) => {
                if (n.udo == undefined)
                    return;
                return n.udo.name;
            }, tooltip: 'Go to location', clickable: (n) => {
                // When user clicks on the name, move the map to the element
                if (self.leafletService == undefined || n == undefined || n.position == undefined ||
                    n.position.lat == undefined || n.position.lng == undefined)
                    return;
                // Change level
                self.levelService.selectLevel(n.position.levelId);
                // Move the map after waiting 1 second
                setTimeout(function() {
                    self.leafletService.moveMapToCoordinates([n.position.lat, n.position.lng]);
                }, 1000);
            }},
            {display: 'Description', columnDef: 'udo.desc', getFct: (n) => {
                if (n.udo == undefined)
                    return;
                return n.udo.desc;
            }},
            {display: 'Mac', columnDef: 'mac', getFct: (n) => {
                return n.mac;
            }},
            {display: 'Level ID', columnDef: 'position.levelId', getFct: (n) => {
                if (n.position == undefined)
                    return;
                return n.position.levelId;
            }},
            {display: 'Status', columnDef: 'position.status', getFct: (n) => {
                if (n == undefined || n.position == undefined)
                    return;
                switch(n.position.status) {
                    case 0: return 'Not ready'; break;
                    case 1: return 'Idle'; break;
                    case 2: return 'Map init'; break;
                    case 3: return 'Navigation'; break;
                    case 4: return 'Lost'; break;
                    case 5: return 'Mapping'; break;
                    default: return;
                }
            }},
            {display: 'Position', columnDef: 'pos', getFct: (n) => {
                if (n.position == undefined || n.position.lat == undefined || n.position.lng == undefined)
                    return;
                return n.position.lat.toFixed(6) + ", " + n.position.lng.toFixed(6);
            }, tooltip: 'Go to location', clickable: (n) => {
                // When user clicks on the position, move the map to the element
                if (self.leafletService == undefined || n == undefined || n.position == undefined ||
                    n.position.lat == undefined || n.position.lng == undefined)
                    return;
                // Change level
                self.levelService.selectLevel(n.position.levelId);
                // Move the map after waiting 1 second
                setTimeout(function() {
                    self.leafletService.moveMapToCoordinates([n.position.lat, n.position.lng]);
                }, 1000);
            }},
            {display: 'Altitude', columnDef: 'position.alt', getFct: (n) => {
                if (n.position == undefined || n.position.alt == undefined)
                    return;
                return n.position.alt.toFixed(2);
            }},
            /*{display: 'Battery', columnDef: 'device_status.battery', getFct: (n) => {
                if (n.device_status == undefined || n.device_status.battery == undefined)
                    return;
                if (isFinite(n.device_status.battery)) {
                    if (n.device_status.battery == -101)
                        return 'fully charged';
                    var str = Math.abs(n.device_status.battery) + '%';
                    if (n.device_status.battery < 0)
                        str += ' (charging)';
                    return str;
                }
                return;
            }},*/
            {display: 'Last seen', columnDef: 'position.fixed_at', getFct: (n) => {
                if (n.position == undefined || n.position.fixed_at == undefined || !isFinite(n.position.fixed_at))
                    return;
                return self.utilsService.formatTimeFull(new Date().getTime() - n.position.fixed_at);
            }}
        ];
        this.stationsFormGroup = this.fb.group({
            mac: [{value: '', disabled: true}],
            udo: this.fb.group({
                name: [''],
                desc: ['']
            })
        });
        this.stationsAttributesToEdit = [
            {display: 'MAC', attribute: 'mac', width: '150px'},
            {display: 'Name', attribute: 'name', formGroupName:'udo', width: '90%'},
            {display: 'Description', attribute: 'desc', formGroupName:'udo', width: '90%'}
        ];
    }

    ngOnInit() {
    	var self = this;
        // Try getting map settings from localstorage
        try {
            var savedMapSettings = JSON.parse(localStorage.getItem(DRAGONFLY_LOCALSTORAGE_KEY));
            if (savedMapSettings != undefined)
                self.mapSettings.updateMapSettings(savedMapSettings);
        } catch(err) {
            // Update localstorage
            localStorage.setItem(DRAGONFLY_LOCALSTORAGE_KEY, JSON.stringify(this.mapSettings.settings));
        }
    	// Get the selected site
		this.subs.push(this.siteService.currentSite.subscribe((site: Site) => {
			this.currentSite = site;
			// Get the list of stations
			this.getDataRecurrently();
			// Try to draw the map
			this.drawMap();
		}));
		// Get the current floorplan
    	this.subs.push(this.floorplanService.currentFloorplan.subscribe((currentFloorplan: Floorplan) => {
    		this.currentFloorplan = currentFloorplan;
			// Try to draw the map
			this.drawMap();
    	}));
        // Get the current level
        this.subs.push(this.levelService.currentLevel.subscribe((currentLevel: Level) => {
            this.currentLevel = currentLevel;
        }));
        // Get the geo-fences
        this.subs.push(this.geoFenceService.geoFences.subscribe((geoFences: GeoFence[]) => {
            this.geoFences = geoFences;
            // Try to draw the map
            this.drawMap();
        }));
    }

    ngAfterViewInit() {
    	// Try to draw the map
    	this.drawMap();
    }

    ngOnDestroy() {
    	var self = this;
    	// Clear the subs
    	this.subs.forEach(function(sub) { sub.unsubscribe(); });
    	// Clear the existing markers
        this.stationsMarker.forEach(function(marker) { self.leafletService.removeFromMap(marker); });
    	// Clear the current subscription if there is one
    	clearInterval(this.subGetData);
        this.isGettingStations = false;
		// Remove the map and clear the html element passed as parameter
		this.leafletService.removeMap();
	}

    // Get the list of stations recurrently (depending on the REFRESH_TIME variable)
    public getDataRecurrently() {
        var self = this;
        // Clear the current subscription if there is one
        clearInterval(this.subGetData);
        this.isGettingStations = false;
        // Start requesting recurrently
        this.subGetData = setInterval(function() {
            self._getData();
        }, REFRESH_TIME);
        // Start immediately
        self._getData();
    }



    /* ------------------------------------------------------------------------- */
    /* -                           Private functions                           - */
    /* ------------------------------------------------------------------------- */

    // Get the list of stations
    private _getData() {
        // Check we have what we need
        if (this.currentSite == undefined || this.currentLevel == undefined)
            return;
        // Get the stations
        this.getStations();
    }



    /* ------------------------------------------------------------------------- */
    /* -                          Stations functions                           - */
    /* ------------------------------------------------------------------------- */

    // Get the list of stations
    private getStations() {
        var self = this;
        if (this.isGettingStations == true)
            return;
        this.isGettingStations = true;
        // Get the dragonfly devices
        this.stationService.getDragonflyDevices(this.currentSite.siteId, {}).then((stations: Array<any>) => {
            this.stations = stations;
            // Clear the existing markers
            this.stationsMarker.forEach(function(marker) {
                self.leafletService.removeFromMap(marker);
            });
            // Draw stations on the map
            this.drawElements(stations, this.stationsMarker, function(station) {
                let stationOnLevel = self.utilsService.isLocatedOnLevel(station, self.currentLevel);
                // Create the marker, active if lrrt < 15 minutes
                var newMarker = new L.marker([station.position.lat, station.position.lng], {
                    icon: self.markerService.createDragonflyStation(stationOnLevel, station.position)
                }).on('click', () => self.openElementPopup(self, station));
                // Add name/mac if there is one
                if ((station.name != undefined || station.mac != undefined) && self.mapSettings.getMapSetting('showStationLabels') == true)
                    newMarker = newMarker.bindTooltip((station.name != undefined && station.name != "") ? station.name : station.mac, { permanent: true, direction: 'top', offset: [0, -10], opacity: 0.95 });
                return newMarker;
            });
            this.isGettingStations = false;
        }).catch((err) => {
            console.log(err);
            this.isGettingStations = false;
        });
    }



    /* ------------------------------------------------------------------------- */
    /* -                             Map functions                             - */
    /* ------------------------------------------------------------------------- */

    // Try to draw the map
    drawMap() {
        var self = this;
    	if (this.currentSite != undefined && this.mapContainer != undefined && this.geoFences != undefined) {
            // Remove the map
            this.leafletService.removeMap();
            // (Re)draw it
    		this.leafletService.drawMap(this.mapId, new MapData({
                showFloorplan: self.mapSettings.getMapSetting('showFloorplan'),
                showGeoFences: self.mapSettings.getMapSetting('showGeofences'),
                geoFences: self.geoFences
            }));
    	}
    }

    // When user changes the map settings (top right corner)
    private onMapSettingsChange(newMapSettings, lastSettingChanged) {
        // Update our map settings
        this.mapSettings.updateMapSettings(newMapSettings.settings);
        // Update localstorage
        localStorage.setItem(DRAGONFLY_LOCALSTORAGE_KEY, JSON.stringify(this.mapSettings.settings));
        // Redraw the markers, or redraw the map
        if (lastSettingChanged != "showFloorplan" && lastSettingChanged != "showGeofences")
            this.getDataRecurrently();
        else
            this.drawMap();
    }

    // Draw an element on the map
    // @params:
    //    elements: Array of the elements we want to display (must contain a position object)
    //    markers: Array of the existing markers (they will be removed, then re-created)
    //    getMarkerFct: Function that will take an element (from the 1st param), and return the correct marker
    private drawElements(elements: any[], markers: any[], getMarkerFct) {
        var self = this;
        // Check parameter
        if (elements == undefined || elements.length <= 0)
            return;
        // For each station, add its marker
        elements.forEach(function(element) {
            if (element.position == undefined || element.position.lat == undefined || element.position.lng == undefined)
                return;
            // Create the marker from the function passed in parameter
            var newMarker = getMarkerFct(element);
            // If we could not get a marker, continue to the next element
            if (newMarker == undefined)
                return;
            // Save it
            markers.push(newMarker);
            // Draw it on the map
            self.leafletService.addOnMap(newMarker);
        });
    }

    // Show a popup to display the station information
    private openElementPopup(self, element) {
        // Close the popup
        self.elementPopup.closePopup();
        // Remove from map
        self.leafletService.removeFromMap(self.elementPopup);
        // Update the popup position
        self.elementPopup.setLatLng([element.position.lat, element.position.lng]);
        // Update content
        let mac = self.utilsService.formatMac(element.mac) || element.mac;
        var content = `<div class="text-center"><h3 class="mb-0 font-weight-bold">Mac</h3>${mac}`;
        if (element.name)
            content += `<hr class="mt-1 mb-1"/><h3 class="mb-0 font-weight-bold">Name</h3>${element.name}`;
        if (element.desc)
            content += `<hr class="mt-1 mb-1"/><h3 class="mb-0 font-weight-bold">Description</h3>${element.desc}`;
        if (element.position && element.position.lat && element.position.lng)
            content += `<hr class="mt-1 mb-1"/><h3 class="mb-0 font-weight-bold">Lat, Lng</h3>${element.position.lat.toFixed(6)}, ${element.position.lng.toFixed(6)}`;
        if (element.lrrt)
            content += `<hr class="mt-1 mb-1"/><h3 class="mb-0 font-weight-bold">Last seen</h3>${self.utilsService.formatTimeFull(element.lrrt * 1000)}`;
        content += `</div>`;
        self.elementPopup.setContent(content);
        // Trigger update
        self.elementPopup.update();
        // Add it to the map
        self.leafletService.addOnMap(self.elementPopup);
    }

}