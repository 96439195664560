import { Component, OnDestroy, OnInit, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, of, Subscription } from 'rxjs';
import { FormBuilder } from '@angular/forms';

import { GeoFenceService } from '../../_services/geoFence.service';
import { SiteService } from '../../_services/site.service';
import { LeafletService } from '../../_services/leaflet.service';
import { ALERT_TYPE, AlertService } from '../../_services/alert.service';
import { ResponsiveService } from '../../_services/responsive.service';
import { FloorplanService } from '../../_services/floorplan.service';
import { LevelService } from '../../_services/level.service';

import { GeoFence } from '../../_models/geoFence';
import { MapSettings, SETTING_TYPE } from '../../_models/mapSettings';
import { Site } from '../../_models/site';
import { Floorplan } from '../../_models/floorplan';
import { Level } from '../../_models/level';
import { MapData } from '../../_models/mapData';

import { YesNoDialogComponent } from '../../common/dialogs/yes-no-dialog.component';
import { EditDialogComponent } from '../../common/dialogs/edit-dialog.component';
import { CreateGeoFenceDialogComponent } from '../../common/dialogs/geo-fences/create-geo-fence-dialog.component';

const REFRESH_TIME = 3000;
const GEOFENCES_LOCALSTORAGE_KEY = "GeoFences_map";

@Component({
	templateUrl: 'geo-fence.component.html',
	styleUrls: ['geo-fence.component.scss']
})
export class GeoFenceComponent implements OnInit, AfterViewInit, OnDestroy {

	// List of all the geo-fences for the selected site
	public geoFences: MatTableDataSource<GeoFence>;
	// The current site
	public currentSite: Site;
	// The current floorplan
	private currentFloorplan: Floorplan;
    // The current level
    private currentLevel: Level;

    // The geo-fence being edited
    private currentlyEditedGeoFence;

    // Boolean if user is busy drawing or editing
    private userBusyDrawingEditing: Boolean = false;

	// List of subs
    private subs: Subscription[] = [];
    // Interval for the refresh
    private fetchInterval;

    // The map container
	@ViewChild('map', { static: true }) mapContainer: ElementRef;
	private mapId = 'map';
    // The popup that shows when we click on a node/station
    private elementPopup;
    // The map settings
    public mapSettings: MapSettings = MapSettings.fromData({
        title: "Settings",
        settings: [
            {
                id: "showFloorplan",
                type: SETTING_TYPE.Checkbox,
                label: "Floorplan",
                value: true
            }
        ],
        // This function is triggered when the user changes any of the map settings
        // newMapSettings: Contains the update MapSettings object
        // lastSettingChanged: Contains the ID of the map setting that has been changed during this trigger
        onChange: (newMapSettings, lastSettingChanged) => this.onMapSettingsChange(newMapSettings, lastSettingChanged)
    });

    /* Datatable variables */
	// The columns to display, and the attributes to use to get the values
	public columnsToDisplay = [
		{display:'Geo-Fence ID', attribute:'id', getFct: (n) => n.id},
		{display:'Name', attribute:'name', getFct: (n) => n.name},
		{display:'Position', attribute:'vertexes', getFct: (n) => {
			if (n.vertexes == undefined || n.vertexes.length <= 0 || n.vertexes[0] == undefined || n.vertexes[0][0] == undefined)
				return '';
			return n.vertexes[0][0];
        }, clickable: true, tooltip: 'Go to location'}
	];
	public columnsAttributes = ['id', 'name', 'vertexes', 'action'];
	// Use for the datatable sorting
	@ViewChild(MatSort, { static: true }) sort: MatSort;

    constructor(private geoFenceService: GeoFenceService, private alertService: AlertService, public dialog: MatDialog,
    			private responsiveService: ResponsiveService, private fb: FormBuilder, private siteService: SiteService,
    			private floorplanService: FloorplanService, private levelService: LevelService, private leafletService: LeafletService) {
    	// Get the geo-fences
        this.subs.push(this.geoFenceService.geoFences.subscribe((geoFences: GeoFence[]) => {
            // Fill our datatable with the data
            if (geoFences != undefined) {
                var firstTime = (this.geoFences == undefined) ? true : false;
				// Fill our datatable with the data
				this.geoFences = new MatTableDataSource(geoFences);
                // Simply update the geo-fences if we are not in editing mode
                if (this.userBusyDrawingEditing != true)
                    this.leafletService.updateGeoFences(this.geoFences.data);
                // Initialize sort if needed
                if (firstTime)
                    this._initializeSort();
            }
            // Draw the map if needed
            if (this.currentSite && this.mapContainer && this.mapContainer.nativeElement.classList.contains('leaflet-container') == false)
                this.drawMap();
        }));
    }

    ngOnInit() {
    	var self = this;
        // Try getting map settings from localstorage
        try {
            var savedMapSettings = JSON.parse(localStorage.getItem(GEOFENCES_LOCALSTORAGE_KEY));
            if (savedMapSettings != undefined)
                self.mapSettings.updateMapSettings(savedMapSettings);
        } catch(err) {
            // Update localstorage
            localStorage.setItem(GEOFENCES_LOCALSTORAGE_KEY, JSON.stringify(this.mapSettings.settings));
        }
    	// Get the selected site
		this.subs.push(this.siteService.currentSite.subscribe((site: Site) => {
            if(self.currentSite?.siteId != site.siteId)
                setTimeout(()=>{
                    self.drawMap();
                },200);
			self.currentSite = site;
			// Try to draw the map
			// this.drawMap();
		}));
		// Get the current floorplan
    	this.subs.push(this.floorplanService.currentFloorplan.subscribe((currentFloorplan: Floorplan) => {
    		self.currentFloorplan = currentFloorplan;
			// Try to draw the map
			// this.drawMap();
    	}));
        // Get the current level
        this.subs.push(this.levelService.currentLevel.subscribe((currentLevel: Level) => {
            if(self.currentLevel?.levelId != currentLevel?.levelId)
                setTimeout(()=>{
                    self.drawMap();
                },200);
            self.currentLevel = currentLevel;
        }));
        // Refresh every X seconds
        this.fetchInterval = setInterval(() => {
            self.geoFenceService.refreshGeoFences();
        }, REFRESH_TIME);
    }

    ngAfterViewInit() {
    	// Try to initialize the sort
    	this._initializeSort();
    }

	ngOnDestroy() {
		// Clear the subs
        this.subs.forEach(function(sub) { sub.unsubscribe(); });
        clearInterval(this.fetchInterval);
	}

	private _initializeSort() {
    	if (this.geoFences && this.sort)
	    	this.geoFences.sort = this.sort;
    }

    public attributeClicked(attr, element) {
    	// When user clicks on the position, move the map to the geo-fence
        if (attr != "vertexes" || this.leafletService == undefined || element == undefined || element.vertexes == undefined ||
        	element.vertexes[0] == undefined || element.vertexes[0][0] == undefined)
            return;
        this.leafletService.moveMapToCoordinates(element.vertexes[0][0]);
    }

    /* ------------------------------------------------------------------------- */
    /* -                         Geo-Fences functions                          - */
    /* ------------------------------------------------------------------------- */

    // When user wants to delete a geo-fence
    public deleteGeoFence(geoFence) {
    	// Check object
        if (geoFence == undefined || geoFence.id == undefined) {
            this.alertService.showMessage(ALERT_TYPE.Error, "An error occurred, cannot delete the geo-fence");
            return;
        }
    	var self = this;
    	var dialog = this.dialog.open(YesNoDialogComponent, { panelClass: ['mw-none','w-90','w-sm-80','w-md-50','w-lg-40'], data: {
    		title: "Delete geo-fence",
    		message: "Are you sure you want to delete the geo-fence with ID: " + geoFence.id,
    		noBtnLabel: "Cancel",
    		yesBtnLabel: "Delete",
    		YesIsWarn: true
    	}});
		dialog.updatePosition({ top: this.responsiveService.getDialogTopPosition() + 'px' });
		// When user answers
		dialog.afterClosed().subscribe(result => {
			// If true, means user wants to delete the geo-fence
			if (result == true) {
				// Show the spinner for this geo-fence
				geoFence.showSpinner = true;
				self.geoFenceService.deleteGeoFence(geoFence).then(() => {
					// Success
					// Remove the spinner
					geoFence.showSpinner = undefined;
					// Show success message
					self.alertService.showMessage(ALERT_TYPE.Success, "The geo-fence has been correctly deleted");
				}, () => {
					// Error
					// Remove the spinner
					geoFence.showSpinner = undefined;
					// Show error message
					self.alertService.showMessage(ALERT_TYPE.Error, "An error occurred, cannot delete the geo-fence");
				})
			}
		});
    }

    // When user wants to edit a geo-fence
    public editGeoFence(geoFence) {
    	// Check object
        if (geoFence == undefined || geoFence.id == undefined) {
            this.alertService.showMessage(ALERT_TYPE.Error, "An error occurred, cannot edit the geo-fence");
            return;
        }
    	var self = this;
    	var dialog = this.dialog.open(EditDialogComponent, { panelClass: ['mw-none','w-90','w-sm-70','w-md-50','w-lg-40', 'w-xl-30'], disableClose: true, data: {
    		title: "Edit geo-fence",
    		noBtnLabel: "Cancel",
    		yesBtnLabel: "Save",
    		YesIsAccent: true,
    		objectToEdit: geoFence,
    		formGroup: this.fb.group({
				id: [{value: '', disabled: true}],
				name: ['']
		    }),
    		attributesToEdit: [
    			{display: 'Geo-fence ID', attribute: 'id', width: '100px'},
    			{display: 'Name', attribute: 'name', width: '90%'}
    		]
    	}});
		dialog.updatePosition({ top: this.responsiveService.getDialogTopPosition() + 'px' });
		// When user answers
		dialog.afterClosed().subscribe(response => {
			if (response == undefined || response.error != undefined) {
				// Error
				self.alertService.showMessage(ALERT_TYPE.Error, "An error occurred, cannot edit the geo-fence");
			} else if (response.value == true && response.result != undefined) {
				// Need to update the geo-fence
				geoFence.showSpinner = true;
				self.geoFenceService.saveGeoFence(response.result).then((res) => {
					// Success
					// Remove the spinner
					geoFence.showSpinner = undefined;
					// Show success message
					self.alertService.showMessage(ALERT_TYPE.Success, "The geo-fence has been correctly edited");
				}, () => {
					// Error
					// Remove the spinner
					geoFence.showSpinner = undefined;
					// Show error message
					self.alertService.showMessage(ALERT_TYPE.Error, "An error occurred, cannot edit the geo-fence");
				});
			}
		});
    }

    // When user wants to create a geo-fence
    public createGeoFence(element) {
    	// Check object
        if (element == undefined || element.layer == undefined || element.layer._latlngs == undefined ||
        	element.layer._latlngs.length <= 0 || element.layer._latlngs[0].length <= 0) {
            this.alertService.showMessage(ALERT_TYPE.Error, "An error occurred, cannot create the geo-fence");
            return;
        }
        var coordinates = [[]];
        element.layer._latlngs[0].forEach(function(latLng) {
        	coordinates[0].push([latLng.lat.toFixed(6), latLng.lng.toFixed(6)]);
        });
    	var self = this;
    	var dialog = this.dialog.open(CreateGeoFenceDialogComponent, { panelClass: ['mw-none','w-90','w-sm-70','w-md-50','w-lg-40', 'w-xl-30'], disableClose: true, data: {
    		vertexes: coordinates
    	}});
		dialog.updatePosition({ top: this.responsiveService.getDialogTopPosition() + 'px' });
		// When user answers
		dialog.afterClosed().subscribe(response => {
			if (response == undefined || response.error != undefined) {
				// Error
				self.alertService.showMessage(ALERT_TYPE.Error, "An error occurred, cannot create the geo-fence");
			} else if (response.value == true && response.result != undefined) {
				// Need to create the geo-fence
				self.geoFenceService.createGeoFence(response.result).then((res) => {
					// Success
					// Show success message
					self.alertService.showMessage(ALERT_TYPE.Success, "The geo-fence has been correctly created");
				}, () => {
					// Error
					// Show error message
					self.alertService.showMessage(ALERT_TYPE.Error, "An error occurred, cannot create the geo-fence");
				});
			}
		});
    }

    // Save the list of geo-fences passed as parameters
    private saveEditedGeoFences(geoFences) {
        var self = this;
        // For each geo-fences that got edited
        for (var index in geoFences) {
            var editedLayer = geoFences[index];
            // Gather the new coordinates
            var coordinates = [];
            editedLayer._latlngs[0].forEach(function(latLng) {
                coordinates.push([latLng.lat.toFixed(6), latLng.lng.toFixed(6)]);
            });
            // Find the geo-fence in our data
            var originalGeoFence = undefined;
            this.geoFences.data.forEach(function(orgGeoFence) {
                if (orgGeoFence.id == editedLayer.geoFenceId)
                    originalGeoFence = orgGeoFence;
            });
            // If the original geo-fence has been found
            if (originalGeoFence != undefined) {
                // Set the new coordinates
                originalGeoFence.vertexes[0] = coordinates;
                // Request the server to update the geo-fence
                originalGeoFence.showSpinner = true;
                self.geoFenceService.saveGeoFence(originalGeoFence).then((res) => {
                    // Success
                    // Remove the spinner
                    originalGeoFence.showSpinner = undefined;
                    // Show success message
                    self.alertService.showMessage(ALERT_TYPE.Success, "The geo-fence has been correctly edited");
                }, () => {
                    // Error
                    // Remove the spinner
                    originalGeoFence.showSpinner = undefined;
                    // Show error message
                    self.alertService.showMessage(ALERT_TYPE.Error, "An error occurred, cannot edit the geo-fence");
                });
            } else {
                // Show error message
                self.alertService.showMessage(ALERT_TYPE.Error, "An error occurred, cannot found the geo-fence");
            }
        }
    }

    /* ------------------------------------------------------------------------- */
    /* -                             Map functions                             - */
    /* ------------------------------------------------------------------------- */

    // Try to draw the map
    drawMap() {
        var self = this;
    	if (this.currentSite != undefined && this.mapContainer != undefined) {
            // Remove the map
            this.leafletService.removeMap();
            // (Re)draw it
    		this.leafletService.drawMap(this.mapId, new MapData({
                showFloorplan: self.mapSettings.getMapSetting('showFloorplan'),
                showGeoFences: true,
                geoFences: (this.geoFences != undefined) ? this.geoFences.data : [],
                showDrawToolbar: true,
                onGeoFenceCreated: function(element) {
                	self.createGeoFence(element);
                },
                onGeoFenceEdited: function(layers) {
                    self.saveEditedGeoFences(layers);
                },
                onGeoFenceStartDrawing: function() {
                    self.userBusyDrawingEditing = true;
                },
                onGeoFenceStopDrawing: function() {
                    self.userBusyDrawingEditing = false;
                },
                onGeoFenceStartEditing: function() {
                    self.userBusyDrawingEditing = true;
                },
                onGeoFenceStopEditing: function() {
                    self.userBusyDrawingEditing = false;
                }
            }));
    	}
    }

    // When user changes the map settings (top right corner)
    private onMapSettingsChange(newMapSettings, lastSettingChanged) {
        // Update our map settings
        this.mapSettings.updateMapSettings(newMapSettings.settings);
        // Update localstorage
        localStorage.setItem(GEOFENCES_LOCALSTORAGE_KEY, JSON.stringify(this.mapSettings.settings));
        // Redraw the map if needed
        if (lastSettingChanged == "showFloorplan")
            this.drawMap();
    }

}