import { Component, AfterViewInit, ViewChild, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';

import { LevelService } from '../../_services/level.service';
import { ALERT_TYPE, AlertService } from '../../_services/alert.service';
import { ResponsiveService } from '../../_services/responsive.service';

import { YesNoDialogComponent } from '../../common/dialogs/yes-no-dialog.component';
import { EditDialogComponent } from '../../common/dialogs/edit-dialog.component';
import { CreateLevelDialogComponent } from '../../common/dialogs/levels/createLevel/create-level-dialog.component';

import { Level } from '../../_models/level';

@Component({
	templateUrl: 'level.component.html'
})
export class LevelComponent implements AfterViewInit, OnDestroy {

	// List of all the levels for the selected site
	public levels: MatTableDataSource<Level>;

	// Boolean when requesting the server to create a new level
	public isCreatingNewLevel: Boolean = false;

	/* Datatable variables */
	// The columns to display, and the attributes to use to get the values
	public columnsToDisplay = [
		{display:'Level ID', attribute:'levelId'},
		{display:'Name', attribute:'name'},
		{display:'Description', attribute:'desc'}
	];
	public columnsAttributes = ['levelId', 'name', 'desc', 'action'];
	// Use for the datatable sorting
	@ViewChild(MatSort) sort: MatSort;

	// List of subs
    private subs: Subscription[] = [];

    constructor(private levelService: LevelService, public dialog: MatDialog, private alertService: AlertService,
    			private fb: FormBuilder, private responsiveService: ResponsiveService) {
    	// Get the list of levels
    	this.subs.push(this.levelService.levels.subscribe((levels: Level[]) => {
    		// Fill our datatable with the data
            if (levels != undefined) {
				// Fill our datatable with the data
				this.levels = new MatTableDataSource(levels);
				// Try to initialize the sort
				this._initializeSort();
            }
    	}));
    }

    ngAfterViewInit() {
    	// Try to initialize the sort
    	this._initializeSort();
    }

    ngOnDestroy() {
		// Clear the subs
        this.subs.forEach(function(sub) { sub.unsubscribe(); });
	}

    // When user wants to create a new level
    createLevel() {
    	var self = this;
    	var dialog = this.dialog.open(CreateLevelDialogComponent, { panelClass: ['mw-none','w-90','w-sm-80','w-md-60','w-lg-50'], disableClose: true, data: {
    		levels: this.levels.data
    	}});
		dialog.updatePosition({ top: this.responsiveService.getDialogTopPosition() + 'px' });
		// When user answers
		dialog.afterClosed().subscribe(response => {
			if (response == undefined || response.error != undefined) {
				// Error
				self.alertService.showMessage(ALERT_TYPE.Error, "An error occurred, cannot create the level");
			} else if (response.value == true && response.result != undefined) {
				// Show spinner
				self.isCreatingNewLevel = true;
				// Need to create the level
				self.levelService.saveLevel(response.result).then((res) => {
					// Success
					// Remove the spinner
					self.isCreatingNewLevel = false;
					// Show success message
					self.alertService.showMessage(ALERT_TYPE.Success, "The level has been correctly created");
				}, () => {
					// Error
					// Remove the spinner
					self.isCreatingNewLevel = false;
					// Show error message
					self.alertService.showMessage(ALERT_TYPE.Error, "An error occurred, cannot create the level");
				});
			}
		});
    }

    // When user wants to delete a level
    deleteLevel(level: any) {
    	// Check object
        if (level == undefined || level.levelId == undefined) {
            this.alertService.showMessage(ALERT_TYPE.Error, "An error occurred, cannot delete the level");
            return;
        }
    	var self = this;
    	var dialog = this.dialog.open(YesNoDialogComponent, { panelClass: ['mw-none','w-90','w-sm-80','w-md-50','w-lg-40'], data: {
    		title: "Delete level",
    		message: "Are you sure you want to delete the level with ID: " + level.levelId,
    		noBtnLabel: "Cancel",
    		yesBtnLabel: "Delete",
    		YesIsWarn: true
    	}});
		dialog.updatePosition({ top: this.responsiveService.getDialogTopPosition() + 'px' });
		// When user answers
		dialog.afterClosed().subscribe(result => {
			// If true, means user wants to delete the level
			if (result == true) {
				// Show the spinner for this level
				level.showSpinner = true;
				self.levelService.deleteLevel(level).then(() => {
					// Success
					// Remove the spinner
					level.showSpinner = undefined;
					// Show success message
					self.alertService.showMessage(ALERT_TYPE.Success, "The level has been correctly deleted");
				}, () => {
					// Error
					// Remove the spinner
					level.showSpinner = undefined;
					// Show error message
					self.alertService.showMessage(ALERT_TYPE.Error, "An error occurred, cannot delete the level");
				})
			}
		});
    }

    // When user wants to edit a level
    editLevel(level: any) {
    	// Check object
        if (level == undefined || level.levelId == undefined) {
            this.alertService.showMessage(ALERT_TYPE.Error, "An error occurred, cannot edit the level");
            return;
        }
    	var self = this;
    	var dialog = this.dialog.open(EditDialogComponent, { panelClass: ['mw-none','w-90','w-sm-70','w-md-50','w-lg-40', 'w-xl-30'], disableClose: true, data: {
    		title: "Edit level",
    		noBtnLabel: "Cancel",
    		yesBtnLabel: "Save",
    		YesIsAccent: true,
    		objectToEdit: level,
    		formGroup: this.fb.group({
				levelId: [{value: '', disabled: true}],
				name: [''],
		        desc: ['']
		    }),
    		attributesToEdit: [
    			{display: 'Level ID', attribute: 'levelId', width: '100px'},
    			{display: 'Name', attribute: 'name', width: '90%'},
    			{display: 'Description', attribute: 'desc', width: '90%'}
    		]
    	}});
		dialog.updatePosition({ top: this.responsiveService.getDialogTopPosition() + 'px' });
		// When user answers
		dialog.afterClosed().subscribe(response => {
			if (response == undefined || response.error != undefined) {
				// Error
				self.alertService.showMessage(ALERT_TYPE.Error, "An error occurred, cannot edit the level");
			} else if (response.value == true && response.result != undefined) {
				// Need to update the level
				level.showSpinner = true;
				self.levelService.saveLevel(response.result).then((res) => {
					// Success
					// Remove the spinner
					level.showSpinner = undefined;
					// Show success message
					self.alertService.showMessage(ALERT_TYPE.Success, "The level has been correctly edited");
				}, () => {
					// Error
					// Remove the spinner
					level.showSpinner = undefined;
					// Show error message
					self.alertService.showMessage(ALERT_TYPE.Error, "An error occurred, cannot edit the level");
				});
			}
		});
    }

    private _initializeSort() {
    	if (this.levels && this.sort)
	    	this.levels.sort = this.sort;
    }
}