import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';

import { SiteService } from './site.service';
import { ALERT_TYPE, AlertService } from './alert.service';
import { ConfigService } from './config.service';

import { Site } from '../_models/site';
import { Level } from '../_models/level';

@Injectable({ providedIn: 'root' })
export class LevelService {

	private levelsSubject: BehaviorSubject<Level[]>;
    public levels: Observable<Level[]>;
    private _levels: Level[];

	private currentLevelSubject: BehaviorSubject<Level>;
    public currentLevel: Observable<Level>;
    private _currentLevel: Level;

    // The current site
    private currentSite: Site;

    constructor(private http: HttpClient, private siteService: SiteService, private alertService: AlertService,
                private configService: ConfigService) {
    	this.currentLevelSubject = new BehaviorSubject<Level>(undefined);
        this.currentLevel = this.currentLevelSubject.asObservable();
        this.levelsSubject = new BehaviorSubject<Level[]>(undefined);
        this.levels = this.levelsSubject.asObservable();
        // Get the selected site
        this.siteService.currentSite.subscribe(site => {
            this.currentSite = site;
            // Check we have the current site
            if (this.currentSite != undefined && this.currentSite.siteId != undefined) {
                // Get the list of levels for the selected site
                this._getLevels(this.currentSite).then((levels: Level[]) => {
                    // Success
                    // Save the list of levels
                    this._levels = levels.map(function(e) { return Level.fromData(e); });
                    // Update observable
                    this.levelsSubject.next(levels);
                    // Get saved levelId from local storage, else try to use default 0
                    var levelId = localStorage.getItem('currentLevelId') || '0';
                    // Select the level
                    this.selectLevel(parseInt(levelId));
                }, () => {
                    // Error
                    this.alertService.showMessage(ALERT_TYPE.Error, "An error occurred, cannot get the list of levels");
                });
            }
        });
    }


    /* -------------------------------------------------------- */
    /*                    Public functions                      */
    /* -------------------------------------------------------- */

    // Select the level with the correct id, then update observable
    public selectLevel(levelId: number) {
        // Check level ID
        if (levelId == undefined)
            return;
        var foundLevel = this._levels.find(x => x.levelId == levelId);
    	// Check if we found it, else use the first one as default
    	if (foundLevel == undefined && this._levels.length > 0)
    		foundLevel = this._levels[0];
    	// Update the local storage
    	if (foundLevel != undefined && foundLevel.levelId != undefined && foundLevel.levelId.toString != undefined)
    		localStorage.setItem('currentLevelId', foundLevel.levelId.toString());
    	// Notify observable
        this._currentLevel = foundLevel;
    	this.currentLevelSubject.next(this._currentLevel);
    }

    // Save the level by requesting the server
    public saveLevel(newLevel: Level) {
        return new Promise((resolve, reject) => {
            // Check the level object
            if (newLevel == undefined || newLevel.levelId == undefined ||
                this.currentSite == undefined || this.currentSite.siteId == undefined) {
                reject();
            } else {
                // Create the form data object
                var formData = new HttpParams();
                for (var key in newLevel)
                    formData = formData.set(key, newLevel[key]);
                // Make a PUT request to update the level
                this.http.put(`${this.configService.config.network.navizonApiUrl}/sites/${this.currentSite.siteId}/levels/${newLevel.levelId}/`, formData.toString()).subscribe((res: Level) => {
                    // Success
                    // Update the level in our data
                    this._updateLevel(res);
                    resolve(res);
                }, (err) => {
                    // Error
                    reject();
                });
            }
        });
    }

    // Delete the level by requesting the server
    public deleteLevel(level: Level) {
        return new Promise((resolve, reject) => {
            // Check the level object
            if (level == undefined || level.levelId == undefined ||
                this.currentSite == undefined || this.currentSite.siteId == undefined) {
                reject();
            } else {
                // Make a DELETE request to delete the level
                this.http.delete(`${this.configService.config.network.navizonApiUrl}/sites/${this.currentSite.siteId}/levels/${level.levelId}/`).subscribe((res: any) => {
                    // Success
                    // Make sure it was a success
                    if (res != undefined && res.status == "success") {
                        // Level deleted, update our data
                        this._deleteLevel(level);
                        resolve();
                    } else
                        reject();
                }, (err) => {
                    // Error
                    reject();
                });
            }
        });
    }


    /* -------------------------------------------------------- */
    /*                    Private functions                     */
    /* -------------------------------------------------------- */

    // Update our data to remove this level, then notify the observables
    private _deleteLevel(deletedLevel: Level) {
        if (deletedLevel == undefined || deletedLevel.levelId == undefined)
            return;
        // Keep all the levels with a different id to remove the deleted level
        this._levels = this._levels.filter(x => x.levelId != deletedLevel.levelId);
        // Update observable
        this.levelsSubject.next(this._levels);
        // Check if the deleted level is our current selected level
        if (this._currentLevel && this._currentLevel.levelId == deletedLevel.levelId)
            // Run the select level function to force the selection of a new level
            this.selectLevel(deletedLevel.levelId);
    }

    // Update our data with the new level, then notify the observables
    private _updateLevel(newLevel: Level) {
        if (newLevel == undefined || newLevel.levelId == undefined)
            return;
        var self = this, found = false;
        // Go over all the levels, then update the right one
        this._levels.forEach(function(level, index) {
            if (newLevel.levelId == level.levelId) {
                found = true;
                self._levels[index] = newLevel;
            }
        });
        // If we didn't find the level, it means it has to be created
        if (found == false)
            this._levels.push(newLevel);
        // Update observables
        this.levelsSubject.next(this._levels);
        // If the updated level is our current level, notify the observable about the change
        if (this._currentLevel && newLevel && this._currentLevel.levelId == newLevel.levelId)
            this.currentLevelSubject.next(newLevel);
    }

    // Make a GET request to gather the list of all the levels for this site
    private _getLevels(site: Site) {
        return new Promise((resolve, reject) => {
            // Check the site object
            if (site == undefined || site.siteId == undefined) {
                reject();
            } else {
                // Make a GET request to get the list of levels
                this.http.get(`${this.configService.config.network.navizonApiUrl}/sites/${site.siteId}/levels/?with=fpCount`).subscribe((levels: Level[]) => {
                    // Success, got all the levels
                    resolve(levels);
                }, (err) => {
                    // Error
                    reject();
                });
            }
        });
    }
}