<!-- Close button -->
<i id="closeButton" class="material-icons position-relative cursor-pointer" (click)="closeDialog()">close</i>

<!-- Title -->
<h3 class="noselect pb-2 text-center">Choose site</h3>

<!-- Autocomplete input -->
<form (click)="$event.stopPropagation()">
    <mat-form-field class="w-100" color="accent">
        <input type="text" placeholder="Site ID / Name" aria-label="Site ID" matInput [formControl]="siteSelectorControl" [matAutocomplete]="auto">
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="onSiteSelected($event)">
            <mat-option *ngFor="let option of filteredOptions | async" [value]="option.siteId">
                {{option.siteId}}
                <span *ngIf="option.name != undefined && option.name.length > 0"> - {{option.name}}</span>
                <span *ngIf="option.company != undefined && option.company.length > 0"> - {{option.company}}</span>
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
</form>