import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class BasicAuthInterceptor implements HttpInterceptor {
    
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Add authorization header with basic auth credentials if available
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        if (currentUser && currentUser.authdata) {
            var newHeaders = {'Authorization': `Basic ${currentUser.authdata}`};
            // Check if we already have a content type header, else use a default one
            var contentType = (request.headers != undefined && request.headers.get != undefined && request.headers.get('Content-Type') != undefined) ? request.headers.get('Content-Type') : 'application/x-www-form-urlencoded';
            contentType = (contentType == 'none') ? undefined : contentType;
            if (contentType != undefined)
                newHeaders['Content-Type'] = contentType;
            request = request.clone({
                setHeaders: newHeaders
            });
        }
        return next.handle(request);
    }
}