<h1 class="text-center mb-4">Levels</h1>

<!-- Spinner -->
<mat-spinner *ngIf="levels == undefined" class="mx-auto" color="accent"></mat-spinner>

<!-- Create new level button -->
<div class="d-flex w-100 pb-4" *ngIf="levels != undefined">
	<!-- Button (desktop only) -->
	<button mat-raised-button *ngIf="isCreatingNewLevel == false" color="accent" class="mx-auto d-none d-md-block" (click)="createLevel()">Create new level</button>
	<!-- Button (mobile only) -->
	<button mat-mini-fab color="accent" *ngIf="isCreatingNewLevel == false" class="fixedBottomRight d-block d-md-none" (click)="createLevel()">
		<mat-icon aria-label="Create new level">add</mat-icon>
	</button>
	<!-- Spinner -->
	<mat-spinner *ngIf="isCreatingNewLevel == true" class="mx-auto" color="accent" [diameter]="36"></mat-spinner>
</div>

<!-- Data table -->
<div class="container d-flex flex-column" *ngIf="levels != undefined">

	<mat-table [dataSource]="levels" class="border w-100 responsive-datatable" matSort matSortActive="levelId" matSortDirection="desc">

		<!-- Columns -->
		<ng-container matColumnDef="{{column.attribute}}" *ngFor="let column of columnsToDisplay">
			<mat-header-cell *matHeaderCellDef mat-sort-header class="justify-content-center">
				{{column.display}}
			</mat-header-cell>
			<mat-cell *matCellDef="let element">
				<span class="mobile-label pr-2">{{column.display}}:</span>
				<div class="mx-auto">{{element[column.attribute]}}</div>
			</mat-cell>
		</ng-container>

		<!-- Edit/Delete -->
		<ng-container matColumnDef="action">
			<mat-header-cell *matHeaderCellDef></mat-header-cell>
			<mat-cell *matCellDef="let element" class="mx-auto">
				<div class="mx-auto">

					<!-- Edit button -->
					<button *ngIf="element.showSpinner != true" class="mx-1" mat-mini-fab color="accent" matTooltip="Edit" (click)="editLevel(element)">
						<mat-icon aria-label="Edit">edit</mat-icon>
					</button>
					<!-- Delete button -->
					<button *ngIf="element.showSpinner != true" class="mx-1" mat-mini-fab color="accent" matTooltip="Delete" (click)="deleteLevel(element)">
						<mat-icon aria-label="Delete">delete</mat-icon>
					</button>

					<!-- Spinner -->
					<mat-spinner *ngIf="element.showSpinner == true" color="accent" [diameter]="36"></mat-spinner>

				</div>
			</mat-cell>
		</ng-container>

		<mat-header-row *matHeaderRowDef="columnsAttributes"></mat-header-row>
		<mat-row *matRowDef="let row; columns: columnsAttributes;"></mat-row>

	</mat-table>

</div>