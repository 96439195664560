import { Injectable } from '@angular/core';

import Config from '../config.json';

@Injectable({ providedIn: 'root' })
export class ConfigService {

    // The main configuration
    public config;

    constructor() {
    	this.config = Config;
    }

    /* -------------------------------------------------------- */
    /*                   Public functions                       */
    /* -------------------------------------------------------- */


    /* -------------------------------------------------------- */
    /*                   Private functions                      */
    /* -------------------------------------------------------- */

}