import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as _ from 'lodash';

@Component({    
        selector: 'edit',
        template:  `<h1 mat-dialog-title>{{data.title}}</h1>

                    <div mat-dialog-content>
                      
                      <form id="myForm" (ngSubmit)="onSubmit()" [formGroup]="data.formGroup" *ngIf="data && data.formGroup && data.attributesToEdit">
                        <div class="w-100 d-flex" *ngFor="let attr of data.attributesToEdit">

                          <div class="w-100 d-flex" *ngIf="attr.formGroupName == undefined">
                            <mat-form-field appearance="outline" class="mx-auto" [ngClass]="attr.classes" [ngStyle]="{'width': attr.width || '260px'}" color="accent">
                                <mat-label>{{attr.display}}</mat-label>
                                <input matInput placeholder="{{attr.display}}" [type]="attr.type != undefined ? attr.type : 'text'" [formControlName]="attr.attribute">
                                <mat-error *ngIf="data.formGroup.controls[attr.attribute] &&
                                                  data.formGroup.controls[attr.attribute].errors &&
                                                  data.formGroup.controls[attr.attribute].errors.required">
                                  Required
                                </mat-error>
                                <mat-error *ngIf="data.formGroup.controls[attr.attribute] &&
                                                  data.formGroup.controls[attr.attribute].errors &&
                                                  data.formGroup.controls[attr.attribute].errors.minlength">
                                  Too short
                                </mat-error>
                                <mat-error *ngIf="data.formGroup.controls[attr.attribute] &&
                                                  data.formGroup.controls[attr.attribute].errors &&
                                                  data.formGroup.controls[attr.attribute].errors.maxlength">
                                  Too long
                                </mat-error>
                            </mat-form-field>
                          </div>

                          <div class="w-100 d-flex" *ngIf="attr.formGroupName != undefined" [formGroupName]="attr.formGroupName">
                            <mat-form-field appearance="outline" class="mx-auto" [ngClass]="attr.classes" [ngStyle]="{'width': attr.width || '260px'}" color="accent">
                                <mat-label>{{attr.display}}</mat-label>
                                <input matInput placeholder="{{attr.display}}" [type]="attr.type != undefined ? attr.type : 'text'" [formControlName]="attr.attribute">
                                <mat-error *ngIf="data.formGroup.controls[attr.attribute] &&
                                                  data.formGroup.controls[attr.attribute].errors &&
                                                  data.formGroup.controls[attr.attribute].errors.required">
                                  Required
                                </mat-error>
                                <mat-error *ngIf="data.formGroup.controls[attr.attribute] &&
                                                  data.formGroup.controls[attr.attribute].errors &&
                                                  data.formGroup.controls[attr.attribute].errors.minlength">
                                  Too short
                                </mat-error>
                                <mat-error *ngIf="data.formGroup.controls[attr.attribute] &&
                                                  data.formGroup.controls[attr.attribute].errors &&
                                                  data.formGroup.controls[attr.attribute].errors.maxlength">
                                  Too long
                                </mat-error>
                            </mat-form-field>
                          </div>

                        </div>
                      </form>

                    </div>

                    <div mat-dialog-actions>

                      <button mat-raised-button
                        [ngClass]="{'mat-primary': data.NoIsPrimary, 'mat-accent': data.NoIsAccent, 'mat-warn': data.NoIsWarn, 'lightColor': !(data.NoIsPrimary || data.NoIsAccent || data.NoIsWarn)}"
                        class="ml-auto mr-2" (click)="no()">
                          {{data.noBtnLabel || 'No'}}
                      </button>

                      <button mat-raised-button
                        [ngClass]="{'mat-primary': data.YesIsPrimary, 'mat-accent': data.YesIsAccent, 'mat-warn': data.YesIsWarn, 'lightColor': !(data.YesIsPrimary || data.YesIsAccent || data.YesIsWarn)}"
                        class="ml-2 mr-auto" form="myForm">
                          {{data.yesBtnLabel || 'Yes'}}
                      </button>

                    </div>`
})
export class EditDialogComponent {

  constructor(public dialogRef: MatDialogRef<EditDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    // Check we have all the data we need
    if (this.data == undefined || this.data.formGroup == undefined ||
      this.data.attributesToEdit == undefined)
      this.dialogRef.close({error: true});
    // Set the form values if we can
    this.data.formGroup.patchValue(this.data.objectToEdit || {});
  }
  
  // If user chooses the 'no' option
  no() {
    this.dialogRef.close({value: false});
  }

  onSubmit() {
    // If form is not valid
    if (this.data.formGroup.valid == false)
      return;
    // If the form has not been touched, return like a cancel
    else if (this.data.formGroup.dirty == false)
      this.dialogRef.close({value: false});
    else {
      // Return a true value, and the result object
      this.dialogRef.close({value: true, result: _.merge(this.data.objectToEdit, this.data.formGroup.value)});
    }
  }
}