export class GeoFence {

	public id: number;
	// Geo-Fence type (include, exclude)
    public behavior: string;
    // "geometry" for geo-fences
    public class: string;
    // Timestamp
    public lastEdited: number;
    public levelId: number;
    public name: string;
    public type: string;
    public vertexes: any;

    constructor(id: number, levelId: number, behavior: string, classe: string, lastEdited: number, name: string, type: string, vertexes: any) {
		this.id = id;
		this.levelId = levelId;
		this.behavior = behavior;
		this.class = classe;
		this.lastEdited = lastEdited;
		this.name = name;
		this.type = type;
		this.vertexes = vertexes;
    }

    // Second constructor
    static fromData(data: any) {
        if (data == undefined)
            return undefined;
        let { id, levelId, behavior, lastEdited = undefined, name = "", type = "", vertexes = undefined } = data;
        if (id == undefined || levelId == undefined || behavior == undefined || data['class'] != "geometry" || vertexes == undefined)
        	return undefined;
        return new this(id, levelId, behavior, data['class'], lastEdited, name, type, vertexes);
    }
}