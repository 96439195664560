import { Floorplan } from './floorplan';
import { FpImage } from './fpImage';

export class MapData {

    // --- Map parameters ---
	// Center the map to this coordinate
    public center: [number, number];
    // Default zoom for the map
    public zoom: number = 18;
    // Max zoom allowed for the map
    public maxZoom: number = 22;
    // To keep images (tiles layers) from the last available zoom level and scale them up
    public maxNativeZoom: number = 19;
    // Internal boolean for the map
    public editable: Boolean = false;
    // Boolean if we want a crosshair in the center of the map
    public crosshair: Boolean = false;
    // Boolean if we want the map to be disabled
    public isDisabled: Boolean = false;
    // Boolean if we want the scale to be displayed
    public showScale: Boolean = false;
    // Boolean to display the fullscreen button
    public fullscreenControl: Boolean = true;
    // Boolean if we want the ruler to be displayed
    public showRuler: Boolean = false;
    // Boolean if we want to display the draw toolbar
    public showDrawToolbar: Boolean = false;
    public hideEditInToolbar: Boolean = false;

    // --- Polylines parameters ---
    public polylines: any[] = [];

    // --- Geo-fences parameters ---
    // Boolean if we want to display the geo-fences
    public showGeoFences: Boolean = false;
    // The geo-fences polygons
    public geoFences: any[] = [];

    // --- Create new floorplan parameters ---
    // The image (in case we are creating a new floorplan)
    public image: FpImage;
    // Boolean if the editing keep the scale
    public uniformScaling: Boolean = false;
    // Number for the floorplan opacity
    public floorplanOpacity: number = 1;

    // --- Floorplan parameters ---
    // Boolean if we want to show the floorplan on the map
    public showFloorplan: Boolean = true;
    // We can specify a floorplan (to avoid using the default current one)
    public useFloorplan: Floorplan;
    // Boolean if the floorplan is editable
    public editableFloorplan: Boolean = false;
    // Boolean if we want the map to be centered on the floorplan
    // (used when not displaying the floorplan, but still want to center on it)
    public centerOnFloorplan: Boolean = true;

    // --- Events parameters ---
    public onMove;
    public onMoveEnd;
    public onClick;
    public onGeoFenceCreated;
    public onGeoFenceClicked;
    public onGeoFenceEdited;
    public onGeoFenceStartDrawing;
    public onGeoFenceStopDrawing;
    public onGeoFenceStartEditing;
    public onGeoFenceStopEditing;

    // Specific constructors for some keys
    private specificConstructors = {
        'image': (obj) => FpImage.fromData(obj),
        'useFloorplan': (obj) => Floorplan.fromData(obj)
    };

    constructor(data: any) {
        if (data == undefined)
            return undefined;
        for (let key in data) {
            // For some keys, we need to run a specific constructor
            let specificConstructor = this.specificConstructors[key];
            this[key] = (specificConstructor != undefined) ? specificConstructor(data[key]) : data[key];
        }
        // Set the internal boolean for the edition
        if (this.editableFloorplan == true)
            this.editable = true;
        // Remove useless properties
        delete this.specificConstructors;
    }

}