import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { DistanceUnits, DurationUnits, SettingsService, SpeedUnits } from 'src/app/_services/settings.service';
import * as moment from 'moment';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  public selectedDistanceUnit:DistanceUnits ;
  public selectedDurationUnit:DurationUnits ;
  public selectedSpeedUnit: SpeedUnits;
  public currentTimezone = new FormControl();
  // The list of timezones
  public allTimezonesName: string[] = [];
  // The list of timezones currently shown in the selector
  public filteredTimezonesName: Observable<string[]>;

  constructor(public settingsService: SettingsService) {
  }

  ngOnInit(): void {
    this.selectedDurationUnit = this.settingsService.getSelectedDurationUnit();
    this.selectedDistanceUnit = this.settingsService.getSelectedDistanceUnit();
    this.selectedSpeedUnit = this.settingsService.getSelectedSpeedUnit();
    this.currentTimezone.setValue(this.settingsService.getSelectedTimezone());
    
    // When user enters something in the timezone selector
    this.filteredTimezonesName = this.currentTimezone.valueChanges.pipe(startWith(''),
        map(value => this._filter(value))
    );
    // Get the list of timeszones
    this.allTimezonesName = (moment as any).tz.names();
  }

  durationUnitChanged(event){        
    this.settingsService.setDurationUnit(this.selectedDurationUnit); 
  }
  speedUnitChanged(event){        
    this.settingsService.setSpeedUnit( this.selectedSpeedUnit); 
  }
  distanceUnitChanged(event){        
    this.settingsService.setDistanceUnit(this.selectedDistanceUnit);  
  }
    // When user chooses a timezone
  onTimezoneSelected(event) {      
    this.settingsService.setTimezone(this.currentTimezone.value);  
  }
  /* ------------------------------------------------------------------------- */
  /* -                      Timezone selector functions                      - */
  /* ------------------------------------------------------------------------- */

  // The filter used with user types in the timezone selector
  private _filter(value: string): string[] {
      const filterValue = value.toLowerCase();
      return this.allTimezonesName.filter(option => option.toLowerCase().includes(filterValue));
  }

}
