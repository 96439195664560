export class Udo {

    public name: string;
    public desc: string;

    constructor(name: string, desc: string) {
        this.name = name;
        this.desc = desc;
    }

    // Second constructor
    static fromData(data: any) {
        if (data == undefined)
            return undefined;
        let { name, desc } = data;
        return new this(name, desc);
    }
}