import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, Validators } from '@angular/forms';

import { ALERT_TYPE, AlertService } from '../../../../_services/alert.service';

import { Level } from '../../../../_models/level';

@Component({    
        selector: 'new-level',
        template:  `<h1 mat-dialog-title>Create new level</h1>

                    <div mat-dialog-content>
                      
                      <form id="myForm" (ngSubmit)="onSubmit()" [formGroup]="myFormGroup">

                        <div class="w-100 d-flex pb-2">
                          <mat-form-field appearance="outline" class="mx-auto" style="width: 120px" color="accent">
                            <mat-select placeholder="Level ID" formControlName="levelId">
                              <mat-option *ngFor="let levelId of levelIds" [value]="levelId" [disabled]="disabledLevelIds.indexOf(levelId) != -1">
                                {{levelId}}
                              </mat-option>
                            </mat-select>
                            <mat-error *ngIf="myFormGroup.controls['levelId'].errors?.required">
                              Level ID is required
                            </mat-error>
                          </mat-form-field>
                        </div>

                        <div class="w-100 d-flex">
                          <mat-form-field appearance="outline" class="mx-auto" style="width: 260px" color="accent">
                              <mat-label>Name</mat-label>
                              <input matInput placeholder="Name" formControlName="name">
                          </mat-form-field>
                        </div>

                        <div class="w-100 d-flex">
                          <mat-form-field appearance="outline" class="mx-auto" style="width: 260px" color="accent">
                              <mat-label>Description</mat-label>
                              <input matInput placeholder="Description" formControlName="desc">
                          </mat-form-field>
                        </div>

                      </form>

                    </div>

                    <div mat-dialog-actions>

                      <button mat-raised-button class="ml-auto mr-2 lightColor" (click)="cancel()">Cancel</button>
                      <button mat-raised-button [disabled]="myFormGroup.invalid" color="accent" class="ml-2 mr-auto" form="myForm">Create</button>

                    </div>`
})
export class CreateLevelDialogComponent {

  public myFormGroup = this.fb.group({
    levelId: ['', Validators.required],
    name: [''],
    desc: ['']
  });
  // List of all the level ID user can choose from
  public levelIds = [];
  public disabledLevelIds = [];
  // Min and max limits for the level IDs
  private _minLevelId = -10;
  private _maxLevelId = 20;

  constructor(public dialogRef: MatDialogRef<CreateLevelDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
              private fb: FormBuilder, private alertService: AlertService) {
    var self = this;
    // Check we have the list of all the levels
    // (needed to disabled the already used ID)
    if (this.data == undefined || this.data.levels == undefined)
      this.dialogRef.close({error: true});
    // Set the array of level IDs
    this.levelIds = Array.from(new Array(Math.abs(this._minLevelId) + Math.abs(this._maxLevelId) + 1), (x,i) => i - Math.abs(this._minLevelId));
    // Set the array of disabled level IDS (already used IDs)
    this.data.levels.forEach(function(level) {
      if (level && level.levelId != undefined)
        self.disabledLevelIds.push(level.levelId);
    });
  }
  
  // If user chooses the 'cancel' option
  cancel() {
    this.dialogRef.close({value: false});
  }

  onSubmit() {
    // If the form is invalid, it means the user didn't choose a level id
    if (this.myFormGroup.invalid == true)
      return;
    // If the form has not been touched, return like a cancel
    if (this.myFormGroup.dirty == false)
      this.dialogRef.close({value: false});
    else {
      // Try to instantiate a level object
      var levelToCreate = Level.fromData(this.myFormGroup.value);
      // Return a true value, and the result object (level to create)
      this.dialogRef.close({value: true, result: levelToCreate});
    }
  }
}