<h1 class="text-center mb-4">Site</h1>

<!-- Spinner -->
<mat-spinner *ngIf="currentSite == undefined" class="mx-auto" color="accent"></mat-spinner>

<!-- Form -->
<form id="siteFormId" (ngSubmit)="onSubmit()" [formGroup]="siteForm" *ngIf="currentSite != undefined" class="text-center d-flex flex-wrap align-items-center justify-content-center">

	<!-- Site ID -->
	<mat-form-field appearance="outline" class="mx-2" style="width: 100px" color="accent">
		<mat-label>Site ID</mat-label>
		<input matInput placeholder="Site ID" formControlName="siteId">
	</mat-form-field>

	<!-- Name -->
	<mat-form-field appearance="outline" class="mx-2" style="width: 260px" color="accent">
		<mat-label>Name</mat-label>
		<input matInput placeholder="Name" formControlName="name">
	</mat-form-field>

	<!-- Company -->
	<mat-form-field appearance="outline" class="mx-2" style="width: 260px" color="accent">
		<mat-label>Company</mat-label>
		<input matInput placeholder="Company" formControlName="company">
	</mat-form-field>

	<!-- Description -->
	<mat-form-field appearance="outline" class="mx-2" style="width: 260px" color="accent">
		<mat-label>Description</mat-label>
		<input matInput placeholder="Description" formControlName="desc">
	</mat-form-field>

	<!-- Access -->
	<mat-form-field class="mx-2" style="width: 120px" color="accent">
		<mat-select placeholder="Access" formControlName="access">
			<mat-option [value]="currentSite.access">{{currentSite.access}}</mat-option>
		</mat-select>
	</mat-form-field>

	<hr class="w-100"/>

	<!-- Address -->
	<mat-form-field appearance="outline" class="mx-2" color="accent">
		<mat-label>Address</mat-label>
		<input matInput placeholder="Address" formControlName="address" (focusout)="addressFocusout($event)">
	</mat-form-field>

	<!-- Edit button -->
	<div class="mx-2 mb-3" *ngIf="canEditAddress == false">
		<button mat-stroked-button color="accent" matTooltip="Edit the address by changing the coordinates or by moving the map" (click)="setEditAddress(true)">Edit</button>
	</div>

</form>

<!-- Map -->
<div class="container" [ngClass]="{'visible': currentSite != undefined, 'invisible': currentSite == undefined}">
	<div id="map" #map class="mx-auto noselect" style="height: 220px; max-width: 350px;"></div>
</div>

<hr class="w-100" *ngIf="currentSite != undefined" />

<!-- Buttons -->
<div class="container d-flex" *ngIf="currentSite != undefined">

	<div class="mx-auto" *ngIf="isSavingSite == false">
		<button mat-raised-button class="mr-2 lightColor" [routerLink]="['/general']">Cancel</button>
		<button mat-raised-button [disabled]="siteForm.invalid || siteForm.pristine" form="siteFormId" color="accent" class="ml-2">Save</button>
	</div>

	<div class="mx-auto" *ngIf="isSavingSite == true">
		<mat-spinner color="accent" [diameter]="36"></mat-spinner>
	</div>

</div>