import { Component, Input } from '@angular/core';

import { MapSettings } from '../../_models/mapSettings';

@Component({
	selector: 'map-settings',
	templateUrl: 'mapSettings.component.html',
	styles: [`
		
	`]
})
export class MapSettingsComponent {

	@Input() mapSettings: MapSettings;

    constructor() {}

}