import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, Validators } from '@angular/forms';

@Component({    
        selector: 'new-geo-fence',
        template:  `<h1 mat-dialog-title>Create new geo-fence</h1>

                    <div mat-dialog-content>
                      
                      <form id="myForm" (ngSubmit)="onSubmit()" [formGroup]="myFormGroup">

                        <div class="w-100 d-flex">
                          <mat-form-field appearance="outline" class="mx-auto" style="width: 260px" color="accent">
                              <mat-label>Name</mat-label>
                              <input matInput placeholder="Name" formControlName="name">
                          </mat-form-field>
                        </div>

                        <div class="w-100 d-flex">
                          <mat-form-field appearance="outline" class="mx-auto" style="width: 150px" color="accent">
                            <mat-select placeholder="Behavior" formControlName="behavior">
                              <mat-option value="include">Include</mat-option>
                              <mat-option value="exclude">Exclude</mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>

                      </form>

                    </div>

                    <div mat-dialog-actions>

                      <button mat-raised-button class="ml-auto mr-2 lightColor" (click)="cancel()">Cancel</button>
                      <button mat-raised-button color="accent" class="ml-2 mr-auto" form="myForm">Create</button>

                    </div>`
})
export class CreateGeoFenceDialogComponent {

  public myFormGroup = this.fb.group({
    name: [''],
    behavior: ['include']
  });

  constructor(public dialogRef: MatDialogRef<CreateGeoFenceDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
              private fb: FormBuilder) {
    // Check we have the geo-fence coordinates
    if (this.data == undefined || this.data.vertexes == undefined || this.data.vertexes[0] == undefined)
      this.dialogRef.close({error: true});
  }
  
  // If user chooses the 'cancel' option
  cancel() {
    this.dialogRef.close({value: false});
  }

  onSubmit() {
    var newGeoFence = {
      behavior: this.myFormGroup.controls['behavior'].value,
      vertexes: this.data.vertexes,
      name: this.myFormGroup.controls['name'].value,
      class: "geometry",
      type: "area"
    }
    this.dialogRef.close({value: true, result: newGeoFence});
  }
}