

<div id="progressFullScreen" *ngIf="loadingStatus > 0">
	<mat-progress-bar class="mt-n2 mt-md-0" mode="query" ></mat-progress-bar>
</div>

<!-- Spinner -->
<div *ngIf="historyStatus == undefined">
	<mat-spinner class="mx-auto" color="accent" [diameter]="36"></mat-spinner>
</div>

<!-- If history is not activated or has no data -->
<div class="text-center" *ngIf="historyStatus == false">
	To access this page, you need to:<br/><br/>
	<b>Enable the Historical tracking feature from the Dragonfly application (<a href="https://dragonflycv.com/support/knowledge-base/information-about-the-dragonfly-dashboard/#Dragonfly_History" target="_blank">instructions</a>)</b><br/>
	<b>Generate at least one position by navigating with the device</b><br/><br/><br/>
	As long as the Historical feature is activated, the position of the device will be saved to the Onit server.<br/>
	However, you can delete the data from the server at anytime.
</div>

<!-- If history is activated and has data -->
<div class="d-flex flex-column flex-md-row" [ngClass]="{'invisible': historyStatus == undefined || historyStatus != true}">
	<div class="d-flex flex-column w-100 w-md-50 px-3 px-md-4 px-lg-4 mb-3 mb-md-0">
		<h1 class="text-center"> History</h1> 
		<div id="mapContainer" class="" style="overflow: hidden;">
			<div class="d-flex flex-row align-items-center" >
				
	
				<div  class="ml-2" *ngIf="chartActivated && historyDevices != undefined && historyDevices.length > 0">
					<mat-form-field  id="chartSelector" class="mx-3 mt-1" color="accent">
						<mat-select [(value)]="this.settingsService.selectedChart" placeholder="Chart Type" (selectionChange)="selectedChartChanged($event)" >
							<mat-option *ngIf="selectedDevice == null" value="DurationRange">Duration in time range</mat-option>
							<mat-option *ngIf="selectedDevice == null" value="EachDeviceDurationPercent">Duration percent</mat-option>
							<mat-option *ngIf="selectedDevice != null" value="DurationDistanceInRange">Duration distance in time range</mat-option>
							<mat-option value="DurationPieChart">Duration pie-chart</mat-option>
							<mat-option value="DistancePieChart">Distance pie-chart</mat-option>
							<mat-option value="DurationBarChart">Duration bar-chart</mat-option>
							<mat-option value="DistanceBarChart">Distance bar-chart</mat-option>
							<mat-option value="MaximumSpeedBarChart">Maximum speed bar-chart</mat-option>
							<mat-option value="AverageSpeedBarChart">Average speed bar-chart</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
	
	
					<!-- <button class="ml-auto mt-n4" matTooltip="Map" [disabled]="isPlaying" *ngIf="!chartActivated" mat-fab value="map" color="accent">
						<mat-icon >map</mat-icon>
					</button>-->
					<button class="ml-auto mr-1" matTooltip="Map" [disabled]="isPlaying" *ngIf="chartActivated" (click)="ChangeChartActivated(false)"  mat-mini-fab value="map" color="accent">
						<mat-icon >map</mat-icon>
					</button>
	<!-- 
					<button class="ml-1 mt-n4" matTooltip="Chart" [disabled]="isPlaying" mat-fab *ngIf="chartActivated" value="chart" color="accent">
						<mat-icon >bar_chart</mat-icon>
					</button>  -->
	
				
			</div>
	
			<div id="map"  [ngStyle]="{ 'display' : !chartActivated ? 'block' : 'none'}" #map class="noselect w-100 h-100">
				<div class="leaflet-top leaflet-right mt-2 mr-2 d-flex flex-row align-items-start" style="pointer-events: auto" (click)="$event.stopPropagation()">
					
					<form class="example-form ">
						<mat-form-field class="example-full-width">
						  <input type="text"
								 placeholder="Geo-Fence"
								 aria-label="Number"
								 matInput
								 [formControl]="selectedGeoFence"
								 [matAutocomplete]="auto"
								 (input)="onGeoFenceInput($event)">
						  <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"  (optionSelected)="onGeoFenceSelected($event)">
							<mat-option *ngFor="let option of geoFences | async" [value]="option.name" [id]="option.id">
							  {{option.name}}
							</mat-option>
						  </mat-autocomplete>
						</mat-form-field>
					  </form>

					<button matTooltip="Chart" class="ml-1" [disabled]="isPlaying" mat-mini-fab *ngIf="!chartActivated && historyDevices != undefined && historyDevices.length > 0" (click)="ChangeChartActivated(true)" value="chart" color="accent">
						<mat-icon >bar_chart</mat-icon>
					</button>
					
					<button matTooltip="Settings" class="ml-1"  mat-mini-fab [matMenuTriggerFor]="menu" aria-label="Settings" *ngIf="loadingStatus == 0 && (historyDevices != undefined && historyDevices.length > 0)">
						<mat-icon >settings</mat-icon>
					  </button>
					  <mat-menu #menu="matMenu">
						<button mat-menu-item  (click)="lineSelectorChanged($event)">
							<mat-icon>{{heatmapActivated ? 'radio_button_checked' : 'radio_button_unchecked'}}</mat-icon>
							<span>Heatmap</span>
						</button>
		
						<mat-divider  ></mat-divider>

						<button mat-menu-item   (click)="heightSelectorChanged($event)">
							<mat-icon>{{heightActivated ? 'radio_button_checked' : 'radio_button_unchecked'}}</mat-icon>
							<span>Line height</span>
						</button>

		
						<mat-divider ></mat-divider>


						<button mat-menu-item   (click)="timelineActivatedChanged($event)">
							<mat-icon>{{timelineActivated ? 'radio_button_checked' : 'radio_button_unchecked'}}</mat-icon>
							<span>Timeline</span>
						</button>

						<button mat-menu-item *ngIf="!heatmapActivated && !heightActivated && timelineActivated"  (click)="followTimelineActivated =!followTimelineActivated">
							<mat-icon>{{followTimelineActivated ? 'check_box' : 'check_box_outline_blank'}}</mat-icon>
							<span>Follow</span>
						</button>
		
					
		
						<mat-divider ></mat-divider>
						<button mat-menu-item   (click)="noneActivatedChanged($event)">
							<mat-icon>{{!(timelineActivated || heightActivated || heatmapActivated) ? 'radio_button_checked' : 'radio_button_unchecked'}}</mat-icon>
							<span>Line</span>
						</button>
					  </mat-menu>


					<!-- <mat-accordion *ngIf="loadingStatus == 0 && (historyDevices != undefined && historyDevices.length > 0)">
						<mat-expansion-panel id="mapSettingsPanel">
							<mat-expansion-panel-header>
								<mat-panel-title>
									Settings
								</mat-panel-title>
							</mat-expansion-panel-header>
							<div  class="d-flex flex-column">
	
								
					<div class="d-flex mb-2">
							<label  class="mr-3">Heatmap</label>
							<mat-slide-toggle class="ml-auto"  [(ngModel)]="heatmapActivated" [disabled]="isPlaying" (change)="lineSelectorChanged($event)"></mat-slide-toggle>
						</div>
		
						<mat-divider *ngIf="!heatmapActivated" ></mat-divider>
						<div *ngIf="!heatmapActivated" class="d-flex mb-2">
							<label  class="mr-3">Line height</label>
							<mat-checkbox class="ml-auto"  [disabled]="isPlaying"  [(ngModel)]="heightActivated" (change)="lineSelectorChanged($event)" ></mat-checkbox>
						</div>
		
						<mat-divider *ngIf="!heatmapActivated && !heightActivated" class="mb-4"></mat-divider>
						<div *ngIf="!heatmapActivated && !heightActivated" class="d-flex mb-2">
							<label  class="mr-3">Timeline</label>
							<mat-slide-toggle class="ml-auto"[disabled]="isPlaying"  [(ngModel)]="timelineActivated" (change)="timelineActivatedChanged($event)" ></mat-slide-toggle>
						</div>
		
						<div class="d-flex mb-2"  *ngIf="!heatmapActivated && !heightActivated && timelineActivated">
							<label  class="mr-3">Follow</label>
							<mat-checkbox class="ml-auto" [(ngModel)]="followTimelineActivated" ></mat-checkbox>
						</div>
					
							</div>
						</mat-expansion-panel>
					</mat-accordion> -->
					
				</div>
	
			</div>
			<div *ngIf="!chartFullSize" [ngClass]="{ 'fullScreen' :chartFullSize }" [ngStyle]="{ 'display' : chartActivated ? 'block' : 'none'}">
				
				<div *ngFor='let graph of graphs;' >
					<plotly-plot  
						[data]="graph.data"
						[layout]="graph.layout"
						[config]="graph.config"
						[useResizeHandler]="true"></plotly-plot>
				</div>
			</div>
		</div>
	</div>
	<!-- Map -->
	

	<mat-divider [vertical]="true" ></mat-divider>
	<!-- History input -->
	<div class="d-flex flex-column w-md-50 " [ngClass]="{'visible': historyStatus == true, 'invisible': historyStatus != true}">

		<div class="d-flex flex-column flex-sm-row justify-content-center mb-2 mt-sm-0">

			<!-- Datepicker-->
			<mat-form-field id="historyDatepicker" class="mb-2 mr-auto mr-sm-2 ml-auto ml-sm-0" color="accent">
				  <mat-date-range-input [rangePicker]="picker" [dateFilter]="dateFilter"   >
					<input matStartDate [formControl]="startSelectedDate" placeholder="Start date">
					<input matEndDate [formControl]="endSelectedDate" (dateChange)="dateChanged($event)" placeholder="End date">
				  </mat-date-range-input>
				  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
				  <mat-date-range-picker touchUi #picker></mat-date-range-picker>
			</mat-form-field>
			<div class="d-flex flex-row justify-content-center">
				<mat-form-field id="fromTimeField" class="mb-2 mr-2 ml-2 ml-sm-0" color="accent">
		
			  
					<!-- The timepicker input -->
					<input matTimepicker #tFrom="matTimepicker" 
					  [strict]="false" mode="24h" #time="ngModel" [ngModel]="defaultFromValue"
					  (timeChange)="timeFromChanged($event)" 
					  placeholder="Start time">
				  
					<!-- An icon for the timepicker input that will open the timepicker dialog when clicked -->
					<mat-icon matSuffix style="cursor: pointer;"  (click)="tFrom.showDialog()">access_time</mat-icon>
				  
				  </mat-form-field>
	
				  <mat-form-field id="toTimeField" class="mb-2 mr-4 mr-ml-2 ml-2 ml-sm-0" color="accent">
			
				  
					<!-- The timepicker input -->
					<input matTimepicker #tTo="matTimepicker" 
					  [strict]="false" mode="24h" #time="ngModel" [ngModel]="defaultToValue"
					  (timeChange)="timeToChanged($event)" 
					  placeholder="End time">
				  
					<!-- An icon for the timepicker input that will open the timepicker dialog when clicked -->
					<mat-icon matSuffix style="cursor: pointer;" (click)="tTo.showDialog()">access_time</mat-icon>
				  
				  </mat-form-field>
	
				<button *ngIf="isDeletingData != true" [disabled]="!endSelectedDate.value" class="mx-1" mat-mini-fab [matTooltip]="Search"  color="accent" (click)="search()"  >
					<mat-icon aria-label="Search">search </mat-icon>
				</button>
				<!-- Menu button -->
				<div class="text-center">
					<button *ngIf="isDeletingData != true"  class="mx-1" mat-mini-fab matTooltip="Menu"  color="accent"  [matMenuTriggerFor]="deleteMenu">
						<mat-icon aria-label="Delete">menu</mat-icon>
					</button>
	
					<mat-menu #deleteMenu="matMenu">
						<button mat-menu-item  (click)="changeFilterBasedOnLevel()">
							<mat-icon>{{filterBasedOnLevel ? 'check_box' : 'check_box_outline_blank'}}</mat-icon>
							<span>Filter based on level</span>
						</button>

						<mat-divider ></mat-divider>
						<button mat-menu-item (click)="deleteSelectedDate()" [disabled]="isPlaying || canDeleteSelectedDate != true"><mat-icon>delete_sweep</mat-icon> <span>Delete Selected dates</span></button>
						<button mat-menu-item (click)="deleteAllData()" [disabled]="isPlaying"><mat-icon>delete</mat-icon> <span>Delete All the data</span> </button>
						
						<mat-divider ></mat-divider>
						<button mat-menu-item (click)="exportToCsv()"  [disabled]="historyDevices == undefined || historyDevices.length == 0" > <mat-icon>cloud_download</mat-icon> <span>Csv Export</span></button>
						
						<mat-divider ></mat-divider>
						<a mat-menu-item (click)="goToInfo()" > <mat-icon>info</mat-icon> <span>Info</span></a>
						
					</mat-menu>
					<!-- Spinner -->
					<mat-spinner *ngIf="isDeletingData == true" class="mx-auto" color="accent" [diameter]="36"></mat-spinner>
				</div>
			</div>
			
		</div>


		<div id="lineHeatSelector" class="w-100 d-flex flex-row align-items-center text-left mb-3 ml-4" *ngIf="selectedArea == null && selectedDevice != null">
			<button mat-mini-fab matTooltip="Back to devices"  [disabled]="isPlaying" (click)="returnToDevices($event)" ><mat-icon aria-label="Back to devices">arrow_back</mat-icon></button>
			<button mat-mini-fab matTooltip="Csv Export" class="ml-1" [disabled]="isPlaying" (click)="exportDevice(selectedDevice)" ><mat-icon aria-label="Csv Export">cloud_download</mat-icon></button>
			
			<h2 class="mx-3 my-0">{{selectedDevice?.label}}</h2>
		</div>
		
		<div id="lineHeatSelector" class="w-100 d-flex flex-row align-items-center text-left mb-3 ml-4" *ngIf="selectedArea != null">
			<button mat-mini-fab matTooltip="Back to devices"  [disabled]="isPlaying" (click)="returnFromSelection($event)" ><mat-icon aria-label="Back to devices">arrow_back</mat-icon></button>
			<button mat-mini-fab matTooltip="Csv Export" class="ml-1" [disabled]="isPlaying" (click)="exportSelectionArea($event)" ><mat-icon aria-label="Csv Export">cloud_download</mat-icon></button>
			
			<h2 class="mx-3 my-0">Selection</h2>
			<mat-slide-toggle [(ngModel)]="selectionIncluded" (change)="selectionIncludeChanged($event)" >Include</mat-slide-toggle>
		</div>
		<!-- Spinner -->
		<div *ngIf="historyDevices == undefined" class="mx-auto">
			<mat-spinner class="mx-auto" color="accent" [diameter]="36"></mat-spinner>
		</div>

			<mat-divider *ngIf="isSearched && loadingStatus == 0 && (historyDevices != undefined && historyDevices.length <= 0)" class="my-2" ></mat-divider>

		<!-- No devices found -->
		<div *ngIf="isSearched && loadingStatus == 0 && (historyDevices != undefined && historyDevices.length <= 0)" class="mx-auto mt-2">
			
			No devices found
		</div>

		<!-- Datatable -->
	
		<element-table *ngIf="loadingStatus == 0" [ngClass]="{'collapsed': historyDevices == undefined || historyDevices.length == 0 || selectedDevice != null || selectedArea != null}"
			[(elements)]="historyDevices"
			[elementName]="'Dragonfly device'"
			disabledSortColumnName="index"
			[showColor]="!heightActivated"
			[options]="stationsTableOptions"
			[disableSort]="timelineActivated"
			[disableShow]="isPlaying"
			[displayedColumns]="stationsDisplayedColumns"
			[commands]="stationsCommands"
			[attributesToDisplay]="stationsAttributesToDisplay"
			[showFilterInput]="false"
			[selectedIndex]="deviceSelectedIndex"
			(colorElementEvent)="changeDeviceColor($event)"			
			(showAllEvent)="showAllDevice($event)"
			(showElementEvent)="showDevice($event)"
			(commandEvent)="stationsCommand($event)"
		></element-table>

		<!-- Datatable -->
		<element-table *ngIf="loadingStatus == 0" [ngClass]="{'collapsed': selectedDevice == null || selectedArea != null}"
			[(elements)]="this.selectedActivities"
			[elementName]="'Dragonfly device activity'"
			disabledSortColumnName="index"
			[showColor]="!heightActivated"
			[disableSort]="timelineActivated"
			[disableShow]="isPlaying"
			[selectedIndex]="activitySelectedIndex"
			[options]="activitiesTableOptions"
			[displayedColumns]="activitiesDisplayedColumns"
			[commands]="activitiesCommands"
			[attributesToDisplay]="activitiesAttributesToDisplay"
			[showFilterInput]="false"
			(colorElementEvent)="changeDeviceColor($event)"
			(showAllEvent)="showAllDevice($event)"
			(showElementEvent)="showDevice($event)"
		></element-table>

		<element-table *ngIf="loadingStatus == 0" [ngClass]="{'collapsed': selectedArea == null}"
			[(elements)]="selectionAreaData"
			[elementName]="'Dragonfly area activity'"
			disabledSortColumnName="index"
			[showColor]="true"
			[colorReadonly]="true"
			hideShow="true"		
			[options]="selectionAreaTableOptions"
			[displayedColumns]="selectionAreaDisplayedColumns"
			[commands]="[]"
			[attributesToDisplay]="selectionAreaAttributesToDisplay"
			[showFilterInput]="true"
		></element-table>

	</div>

</div>



<div *ngIf="chartFullSize">			
	<div *ngFor='let graph of graphs;' id="chartsFullScreen" >
		<plotly-plot  
			[data]="graph.data"
			[layout]="graph.layout"
			[config]="graph.config"
			[useResizeHandler]="true"></plotly-plot>
	</div>
</div>