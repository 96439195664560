export class Floorplan {

	public fpId: string;
	public levelId: number;
	public kml: string;
	public kmlAligned: string;
	public desc: string;
	public name: string;
	public access: string;

    constructor(fpId: string, levelId: number, kml: string = "", kmlAligned: string = "",
    			desc: string = "", name: string = "", access: string = "private") {
		this.fpId = fpId;
		this.levelId = levelId;
		this.kml = kml;
		this.kmlAligned = kmlAligned;
		this.desc = desc;
		this.name = name;
		this.access = access;
    }

    // Second constructor
    static fromData(data: any) {
    	if (data == undefined)
    		return undefined;
        let { fpId, levelId, kml, kmlAligned, desc = "", name = "", access = "private" } = data;
        if (fpId == undefined || levelId == undefined || kml == undefined || kmlAligned == undefined)
        	return undefined;
        return new this(fpId, levelId, kml, kmlAligned, desc, name, access);
    }
}