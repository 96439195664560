import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Validators, FormBuilder } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AuthenticationService } from '../_services/authentication.service';
import { ALERT_TYPE, AlertService } from '../_services/alert.service';

@Component({templateUrl: 'login.component.html'})
export class LoginComponent implements OnInit {

    // Login form
    loginForm = this.fb.group({
        email: ['', [Validators.required, Validators.email]],
        password: ['']
    });

    loading = false;
    returnUrl: string;

    constructor(private route: ActivatedRoute, private router: Router, private fb: FormBuilder,
                private authenticationService: AuthenticationService, private alertService: AlertService) {}

    ngOnInit() {
        // Get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
        // Reset form
        this.loginForm.reset();
        // Check if user already logged in
        this.authenticationService.currentUser.subscribe(user => {
            if (user != undefined)
                this.router.navigate(['/']);
        });
    }

    login() {
        // Stop here if form is invalid
        if (this.loginForm.invalid)
            return;
        // Show spinner
        this.loading = true;
        // If no password provided, set it to empty string
        let password = (this.loginForm.value.password != undefined) ? this.loginForm.value.password : '';
        // Try to authenticate
        this.authenticationService.login(this.loginForm.value.email, password).then(
                data => {
                    // Success
                    this.router.navigate([this.returnUrl]);
                },
                error => {
                    // Error
                    this.alertService.showMessage(ALERT_TYPE.Error, error);
                    this.loading = false;
                }
            );
    }
}