import { Component, OnInit, OnDestroy } from '@angular/core';
import { first } from 'rxjs/operators';
import { Subscription } from 'rxjs';

import { AuthenticationService } from '../_services/authentication.service';

@Component({templateUrl: 'home.component.html'})
export class HomeComponent implements OnInit, OnDestroy {

	// The current user
	public currentUser = undefined;

	// List of subs
    private subs: Subscription[] = [];

    constructor(private authenticationService: AuthenticationService) {
    	// Get the connected user
		this.subs.push(this.authenticationService.currentUser.subscribe(user => {
			this.currentUser = user;
		}));
    }

    ngOnInit() { }

    ngOnDestroy() {
        // Clear the subs
        this.subs.forEach(function(sub) { sub.unsubscribe(); });
    }
}