export class Status {

    public battery: number;
    public fw_version: string;
    public received_at: number;
    public type_of_device: string;

    constructor(battery: number, fw_version: string, received_at: number, type_of_device: string) {
        this.battery = battery;
        this.fw_version = fw_version;
        this.received_at = received_at;
        this.type_of_device = type_of_device;
    }

    // Second constructor
    static fromData(data: any) {
        if (data == undefined)
            return undefined;
        let { battery, fw_version, received_at, type_of_device } = data;
        return new this(battery, fw_version, received_at, type_of_device);
    }
}