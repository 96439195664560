import { Location } from './location';
import { Position, HistoryPosition } from './position';
import { Udo } from './udo';
import { Status } from './status';
import { DBSCAN } from 'density-clustering';
import * as moment from 'moment';
import { partitionBy } from '../_helpers/utils';

export const DeviceColors: string[] = ['#0000FF','#008000','#FFFF00','#FF0000','#800080','#000080','#808000','#800000','#FF00FF','#008080','#00FF00'];
    
export class DragonflyDevice {

    public mac: string;
    public device_status: Status;
    public position: Position;
    public device_type: string;
    public udo: Udo;
    public current_server_time: number;
    public store_position: Boolean;

    constructor(mac: string, device_status: Status, position: Position, device_type: string, udo: Udo,
                current_server_time: number, store_position: Boolean) {
        this.mac = mac;
        this.device_status = device_status;
        this.position = position;
        this.device_type = device_type;
        this.udo = udo;
        this.current_server_time = current_server_time;
        this.store_position = store_position;
    }

    // Second constructor
    static fromData(data: any) {
        if (data == undefined)
            return undefined;
        let { mac, device_status, position, device_type, udo, current_server_time, store_position } = data;
        if (mac == undefined || device_status == undefined || position == undefined)
            return undefined;
        return new this(mac, Status.fromData(device_status), Position.fromData(position), device_type, Udo.fromData(udo), current_server_time, store_position);
    }

}

export const computeSpeed =(dist:number ,duration:number):number=>{
    if(!dist || !duration || dist == 0 || duration == 0)
        return 0;
    return (dist / duration) * 1000;
}

export enum ActivityHistoryType {
    Driving,
    Stopped,
    Offline,
}

export interface IPolyline
{
    maxAlt: number;
    minAlt: number;
    label:string;
    positions: HistoryPosition[];
    color?: string;
    isRect: Boolean;
    getPolylines():  [number[][][],HistoryPosition[]];
    getHeatmaps(): number[][][];
    levelIds: number[];
}

function isNaN(x) {
    return x !== x;
}

export abstract class HistoryBase{
    public positions: HistoryPosition[] = [];
    public isSelected: Boolean = true;
    public isDisabled: Boolean = false;
    public isProcessing: Boolean = false;
    public isRect: Boolean = false;
    public index: number = 0;

    // Polylines variables
    protected _polylines: HistoryPolyline[] = [];
    protected _currentPolyline: HistoryPolyline;
    protected _lastStatus: number = undefined;
    protected _positionIdx: number = 0;
    // Minimum and maximum altitude values we saw (used to determine the line color)
    public maxAlt: number = 0;
    public minAlt: number = 0;


    public getHeatmaps() : number[][][] {
        //return this.activities.filter(i=> i.type == ActivityHistoryType.Driving).flatMap(i=> i.getHeatmaps());
        var heatmaps = [], self = this;
        this._polylines.forEach(function(polyline) {
            for (var i = polyline.startIdx; i < (polyline.startIdx + polyline.length); i++) {
                heatmaps.push([self.positions[i].content.lat, self.positions[i].content.lng, 0.2]);
            }
        });
        return heatmaps;
    }
    public addHistoryPosition(newPos: HistoryPosition) {
        this.positions.push(newPos);
        // Update max and min alt
        this.maxAlt = (newPos.content.alt != undefined && newPos.content.alt > this.maxAlt) ? newPos.content.alt : this.maxAlt;
        this.minAlt = (newPos.content.alt != undefined && newPos.content.alt < this.minAlt) ? newPos.content.alt : this.minAlt;
        // Start a new polyline
        if (!this._currentPolyline || (this._lastStatus != 3 && newPos.status == 3)) {
            this._currentPolyline = new HistoryPolyline(this._positionIdx);
            this._polylines.push(this._currentPolyline);                
        }
        // Continue the polyline
        else if (this._lastStatus == 3 && newPos.status == 3)
        {
             this._currentPolyline.length += 1;
        }
           
        // Update status and position counter
        this._lastStatus = newPos.status;
        this._positionIdx += 1;    
    
    }
}

export abstract class ActivityHistory extends HistoryBase implements IPolyline {
    public distance: number = 0;
    public start?: Date;
    public stop?: Date;
    public percentValue?: number = 0;
    public percent?: string = '';
    public color?: string= '';
    public activityColor?: string;
    public speeds: number[] = [];
    public label: string= '';
    public maxSpeed: number = 0;
    public averageSpeed: number = 0;
    public levelIds: number[];
    public get levelIdsText(): string{
        return this.levelIds.join(`, `);
    }


    constructor(public type:ActivityHistoryType, public duration: number = 0){
       super();
    }
    public getPolylines(): [number[][][],HistoryPosition[]] {
        return [[this.positions.map(i=> [i.content.lat, i.content.lng,i.content.alt])],this.positions];
    }

    public abstract forHistoryDevice(device:HistoryDevice);

}

export class DrivingActivityHistory extends ActivityHistory {
    constructor( public duration: number = 0){
       super(ActivityHistoryType.Driving,duration);
       this.activityColor = 'limegreen';
    }
    public  forHistoryDevice(device:HistoryDevice) {
        device.drivingDuration += this.duration;
    }
}

export class StoppedActivityHistory extends ActivityHistory {
    constructor( public duration: number = 0){
       super(ActivityHistoryType.Stopped,duration);
       this.activityColor = 'orangered';
    }
    public forHistoryDevice(device:HistoryDevice) {
        device.stoppedDuration += this.duration;
        this.isRect = true;
    }
}

export class OfflineActivityHistory extends ActivityHistory {
    constructor( public duration: number = 0){
       super(ActivityHistoryType.Offline,duration);
       this.activityColor = 'aliceblue';
    }
    public forHistoryDevice(device:HistoryDevice) {
        device.offlineDuration += this.duration;
    }
}

export interface IHistoryDevice  {
    drivingDuration: number;
    stoppedDuration: number;
    offlineDuration: number;
    allDuration:number;
}



export class HistoryDevice extends HistoryBase implements IPolyline,IHistoryDevice  {

    public mac: string;
    public udo: Udo;
    public get levelIds():number[]{
        return this.activities.flatMap(j=>j.levelIds).filter((value, index, categoryArray) => categoryArray.indexOf(value) === index);
    }
    public get levelIdsText(): string{
        return this.levelIds.join(`, `);
    }
    
    public timezone_label: string;
    public utc_offset_min: number;
    public allDuration: number = 0;
    public drivingDuration: number = 0;
    public stoppedDuration: number = 0;

    public get allActiveDuration(): number{
        return this.drivingDuration;
    }
    public get maxActiveDuration(): number{
        return Math.max(...this.activities.filter(i=> i.type == ActivityHistoryType.Driving).map(j=> j.duration));
    }
    public get maxDistance(): number{
        return Math.max(...this.activities.filter(i=> i.type == ActivityHistoryType.Driving).map(j=> j.distance));
    }

    public offlineDuration: number = 0;
    public maxSpeed: number = 0;
    public averageSpeed: number = 0;
    public distance: number = 0;

    public activities: ActivityHistory[] = [];

    

    constructor(mac: string, udo: Udo,  timezone_label: string, utc_offset_min: number, positions: any[],public color?: string) {
        super();
        this.mac = mac;
        this.positions = positions;
        this.udo = udo;
        this.timezone_label = timezone_label;
        this.utc_offset_min = utc_offset_min;
    }

    public get label(){
        return this.udo.name &&  this.udo.name != 'unknown' ? this.udo.name : this.mac;
    }

    // Second constructor
    static fromData(data: any,timezone:string, color?: string) {
        if (data == undefined)
            return undefined;
        let { mac, udo, timezone_label = 'UTC', utc_offset_min = 0, position } = data;
        if (mac == undefined)
            return undefined;
        // Instantiate our new object
        var newHistoryDevice = new this(mac, Udo.fromData(udo), timezone_label, utc_offset_min, [],color);
        // Add it the position
        newHistoryDevice.addPosition(position,timezone);
        return newHistoryDevice;
    }

    // Add a new position object in our positions array
    public addPosition(position: any,timezone:string) {
        const newPos = HistoryPosition.fromData(position,timezone);
        if (newPos != undefined) {
            this.addHistoryPosition(newPos); 
        }
        
    }
    
    public cleanActivities()
    {
        this.maxSpeed = 0;
        this.allDuration = 0;
        this.distance = 0;
        this.activities = [];
    }
    
    public calculateDistance(stopThresholdMs:number ,offlineThresholdMs:number,start:Date,end:Date,distanceThreshold:number):Promise<boolean>
    {
        return new Promise((resolveAll, rejectAll) => {
            const self = this;
            this.cleanActivities();
            let positions = this.positions.filter(i=> i.dateTime >=start &&  i.dateTime<=end );
            if(positions.length > 1){
                if(start < positions[0].dateTime)
                    positions.unshift(new HistoryPosition(positions[0].content,start));
                    
                const position = positions[positions.length - 1];
                if(end > position.dateTime)
                    positions.push(new HistoryPosition(position.content,end));
            }
            
            self.allDuration = ((end as any) - (start as any));
            

            if(positions.length <= 1)
            {
                this.activities.push(new OfflineActivityHistory(self.allDuration)); 
            }
            else if(positions.length > 1)
            {
                //let stopPositions : HistoryPosition[] = [];

                const positionClusters:Record<number,number> = {};

                let clusterIndex = 0;
                let clusterValue = 0;
                if (typeof Worker !== 'undefined') {
                    const res = partitionBy(positions,4000,item=>[item.x,item.y]);
                    Promise.all(res.map((items,index) => {
                            const data = { items , distanceThreshold ,index };
                            return new Promise((resolve, reject) => {
                                const worker = new Worker('./positiondbscan.worker', { type: 'module' });
                                worker.addEventListener('message', event => { 
                                    console.log(event.data.index);
                                    resolve(event.data)
                                });
                                worker.addEventListener('error', reject);
                                worker.postMessage(data);
                            });
                        }))
                        .then(results => {

                            results.sort((i:any,j:any) => i.index -  j.index).forEach((d:any)=>{
                                const clusters:number[][] = d.clusters;
                                let currentIndex = clusterIndex;
                                let clusterMax = 0;   

                                clusters.forEach((cluster,clusterIdx)=>{
                                    cluster.forEach(i=>{
                                        positionClusters[i + clusterIndex] = clusterIdx + clusterValue;
                                        currentIndex = i + clusterIndex;
                                    });
                                    clusterMax = clusterIdx + clusterValue;
                                });
            
                                clusterIndex = currentIndex + 1;
                                clusterValue = clusterMax + 1;
                            });
                            
                            self.calculateBasedOnCluster(positions,positionClusters,stopThresholdMs,offlineThresholdMs,start,end,distanceThreshold);

                            resolveAll(true);
                        });

                } else {

                    const dbscan = new DBSCAN();

                    partitionBy(positions,4000,item=>[item.x,item.y]).forEach(items=>{
                        
                        let currentIndex = clusterIndex;
                        let clusterMax = 0;

                        const clusters = dbscan.run(items,distanceThreshold, 1);        
                        clusters.forEach((cluster,clusterIdx)=>{
                            cluster.forEach(i=>{
                                positionClusters[i + clusterIndex] = clusterIdx + clusterValue;
                                currentIndex = i + clusterIndex;
                            });
                            clusterMax = clusterIdx + clusterValue;
                        });

                        clusterIndex = currentIndex + 1;
                        clusterValue = clusterMax + 1;
                    });
                    

                    self.calculateBasedOnCluster(positions,positionClusters,stopThresholdMs,offlineThresholdMs,start,end,distanceThreshold);
                    resolveAll(true);
                }
            }
        });
    }

    calculateBasedOnCluster(positions:HistoryPosition[],positionClusters:Record<number,number>,stopThresholdMs:number ,offlineThresholdMs:number,start:Date,end:Date,distanceThreshold:number)
    {
        const self = this;
        let activity : ActivityHistory | null = null;
        let stopDist : number = 0;
        let last:HistoryPosition = positions[0];
        let lastCluster: number = positionClusters[0];
        let maxDur = last.dateTime;
        let stop: Date = new Date();
        let startAct: Date;

        for(let i = 1; i<positions.length; i++){
            const current = positions[i];
            const currentCluster: number = positionClusters[i];
            if(maxDur < current.dateTime){
                maxDur =  current.dateTime;
                const dist = current.getDistance(last);
                let duration;
                let durationForSpeed;
                if(activity == null){
                    durationForSpeed = current.getDurationMs(last);
                    duration = current.getDurationMs(last);
                    stop.setTime(start.getTime() + duration);
                } else {
                    durationForSpeed = current.getDurationMs(last);
                    duration = Math.abs((activity.stop as any) - (current.dateTime as any));
                    startAct = activity.stop;
                    stop = new Date();
                    stop.setTime(activity.stop.getTime() + duration);
                }
           
                if(duration > offlineThresholdMs)
                {
                    if(activity == null){
                        activity = new OfflineActivityHistory();
                        this.setNewActivity(activity, start, duration);
                    } else if(activity.type != ActivityHistoryType.Offline){
                        activity = new OfflineActivityHistory();
                        activity.start = startAct;  
                        self.activities.push(activity);
                    }      
                    activity.stop = stop;
                    activity.positions.push(current);     
                    activity.type = ActivityHistoryType.Offline; 
                    stopDist = 0;
                }
                else if(currentCluster == lastCluster)
                {     
                    if(duration > stopThresholdMs)
                    {
                        if(activity == null){
                            activity = new StoppedActivityHistory();
                            this.setNewActivity(activity, start, duration);
                        } else if(activity.type != ActivityHistoryType.Stopped){
                            activity = new StoppedActivityHistory();
                            activity.start = startAct;  
                            self.activities.push(activity);
                        }
                        activity.stop = stop;
                        activity.addHistoryPosition(current);
                        stopDist = 0;
                    }
                    else
                    {
                        if(activity != null){
                            activity.stop = stop;
                        }
                        stopDist += dist;
                    }
                }
                else if(currentCluster != lastCluster)
                {
                    const d = dist + stopDist;

                    if(activity == null){
                        activity = new DrivingActivityHistory();
                        this.setNewActivity(activity, start, duration);
                    } else if(activity.type != ActivityHistoryType.Driving){
                        activity = new DrivingActivityHistory();
                        activity.start = startAct;  
                        self.activities.push(activity);
                    }
                    self.distance += d;
                    activity.distance += d;
                    const speed = computeSpeed(dist, durationForSpeed);
                    if(self.maxSpeed < speed)
                        self.maxSpeed = speed;
                    activity.stop = stop;
                    activity.speeds.push(speed);
                    activity.addHistoryPosition(current);

                    stopDist = 0;
                }
            }   
            last = current;
            lastCluster = currentCluster;
        }
        
        activity.stop = end;
        self.drivingDuration = 0;
        self.stoppedDuration = 0;
        self.offlineDuration = 0;
        const labelIndex:Record<number,number> = {};
        self.activities.forEach((i,index)=>{
            i.index = index; 
            i.percentValue =  self.allDuration == 0 ? 0:  (i.duration / self.allDuration) * 100;
            i.percent = `${i.percentValue}%`;         
            i.color = DeviceColors[(index) % DeviceColors.length];
            i.label = `${ActivityHistoryType[i.type]}#${labelIndex[i.type] ? ++labelIndex[i.type] : labelIndex[i.type] = 1}`;           
            i.duration = Math.abs((i.start as any) - (i.stop as any));
            i.averageSpeed =computeSpeed(i.distance ,i.duration);      
            i.maxSpeed = i.speeds.length == 0 ? 0 : Math.max(...i.speeds);    
            i.levelIds =self.getLevelIds(i.positions);
            i.forHistoryDevice(self);
        });
    
        self.averageSpeed = self.drivingDuration == 0 ? 0 : (self.distance/ self.drivingDuration) * 1000; 
    }




    getLevelIds(positions:HistoryPosition[]): number[]{
        return positions.filter(j=> j).map(j=>j.content.levelId).filter((value, index, categoryArray) => categoryArray.indexOf(value) === index);
    }
    // Return an array of array of positions (lat, lng, alt)
    // to draw the polylines
    public getPolylines() :  [number[][][],HistoryPosition[]] {
        const drivings = this.activities.filter(i=> i.type == ActivityHistoryType.Driving).map(i=> i.getPolylines());
        return [drivings.flatMap(i=> i[0]),drivings.flatMap(i=> i[1])];
        // var polylines = [], self = this;
        // this._polylines.forEach(function(polyline) {
        //     // If we don't have at least 2 points, nothing to add
        //     if (polyline.length <= 1)
        //         return;
        //     // Create the array of points for this polyline
        //     var currPolyline = [];
        //     for (var i = polyline.startIdx; i < (polyline.startIdx + polyline.length); i++) {
        //         if ((startTimestamp == undefined || endTimestamp == undefined) ||
        //             (startTimestamp != undefined && endTimestamp != undefined && startTimestamp <= self.positions[i].content.fixed_at && endTimestamp >= self.positions[i].content.fixed_at))
        //             currPolyline.push([self.positions[i].content.lat, self.positions[i].content.lng, self.positions[i].content.alt]);
        //     }
        //     // Add the polyline to the array of polylines
        //     if (currPolyline.length > 0)
        //         polylines.push(currPolyline);
        // });
        // return polylines;
    }

    // Return an array of array of positions (lat, lng, intensity)
    // to draw the heatmaps
   
    setNewActivity(activity: ActivityHistory, start : Date, duration: number){
        const self = this;
        activity.start = start; 
        activity.stop = new Date();
        activity.stop.setTime(activity.start.getTime() + duration);
        self.activities.push(activity);
    }
    public getLastPosition(activity:ActivityHistory):HistoryPosition{
        return activity.positions[activity.positions.length -1];
    }
}

// Used to determine the polylines for a HistoryDevice object
export class HistoryPolyline {

    public startIdx: number;
    public length: number = 1;

    constructor(startIdx: number) {
        this.startIdx = startIdx;
    }
}