import { Component, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { LevelService } from '../../../../_services/level.service';

import { Level } from '../../../../_models/level';

@Component({
	selector: 'level-selector-dialog',
	templateUrl: './level-selector-dialog.html',
	styles: ['i#closeButton { float: right;top: 2px; }']
})
export class LevelSelectorDialogComponent implements OnDestroy {

	// The list of levels
	public levels: Level[];
	// The current level
	public currentLevel: Level;

	// List of subs
    private subs: Subscription[] = [];

	constructor(public dialogRef: MatDialogRef<LevelSelectorDialogComponent>, private levelService: LevelService) {
		// Get the list of levels
		this.subs.push(this.levelService.levels.subscribe((levels: Level[]) => {
			this.levels = levels;
		}));
		// Get the current level
		this.subs.push(this.levelService.currentLevel.subscribe((currentLevel: Level) => {
			this.currentLevel = currentLevel;
		}));
	}

	ngOnDestroy() {
        // Clear the subs
        this.subs.forEach(function(sub) { sub.unsubscribe(); });
    }

	onLevelSelected(event) {
		// Try to get the levelId
		if (event != undefined && event.value != undefined)
			this.levelService.selectLevel(event.value);
		// Close the dialog
		this.closeDialog();
	}

	closeDialog(): void {
		this.dialogRef.close();
	}

}