import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpParams, HttpBackend } from '@angular/common/http';

import { ALERT_TYPE, AlertService } from './alert.service';
import { AuthenticationService } from './authentication.service';
import { LevelService } from './level.service';
import { ConfigService } from './config.service';

import { SiteService } from './site.service';

import { User } from '../_models/user';
import { Site } from '../_models/site';
import { Level } from '../_models/level';
import { Floorplan } from '../_models/floorplan';

@Injectable({ providedIn: 'root' })
export class FloorplanService {

	private floorplansSubject: BehaviorSubject<Floorplan[]>;
    public floorplans: Observable<Floorplan[]>;
    private _floorplans: Floorplan[];

    private currentFloorplanSubject: BehaviorSubject<Floorplan>;
    public currentFloorplan: Observable<Floorplan>;
    private _currentFloorplan: Floorplan;

    // The current site
    private currentSite: Site;
    // The current user
    private currentUser: User;

    // A new HttpClient to avoid the interceptor (for the multipart/form-data POST request)
    private http2: HttpClient;

    constructor(private http: HttpClient, private siteService: SiteService, private alertService: AlertService,
                private authenticationService: AuthenticationService, private handler: HttpBackend,
                private levelService: LevelService, private configService: ConfigService) {
        var self = this;
        this.http2 = new HttpClient(handler);
        this.floorplansSubject = new BehaviorSubject<Floorplan[]>(undefined);
        this.floorplans = this.floorplansSubject.asObservable();
        this.currentFloorplanSubject = new BehaviorSubject<Floorplan>(undefined);
        this.currentFloorplan = this.currentFloorplanSubject.asObservable();
        // Get the selected site
        this.siteService.currentSite.subscribe(site => {
            this.currentSite = site;
            // Check we have the current site
            if (this.currentSite != undefined && this.currentSite.siteId != undefined) {
                // Get the list of floorplans for the selected site
                this._getFloorplans(this.currentSite).then((floorplans: Floorplan[]) => {
                    // Success
                    // Save the list of floorplans
                    self._floorplans = floorplans.map(function(e) { return Floorplan.fromData(e); });
                    // Update observable
                    self.floorplansSubject.next(self._floorplans);
                    // Get the current level
                    self.levelService.currentLevel.subscribe((currentLevel: Level) => {
                        // Found the current floorplan
                        var foundFloorplan = undefined;
                        self._floorplans.forEach(function(fp) {
                            if (fp && currentLevel && fp.levelId == currentLevel.levelId)
                                foundFloorplan = fp;
                        });
                        // Notify observable
                        self._currentFloorplan = foundFloorplan;
                        self.currentFloorplanSubject.next(self._currentFloorplan);
                    });
                }, () => {
                    // Error
                    this.alertService.showMessage(ALERT_TYPE.Error, "An error occurred, cannot get the list of floorplans");
                });
            }
        });
        // Get the connected user
        this.authenticationService.currentUser.subscribe(user => {
            this.currentUser = user;
        });
    }


    /* -------------------------------------------------------- */
    /*                    Public functions                      */
    /* -------------------------------------------------------- */

    // Save the floorplan by requesting the server
    public saveFloorplan(floorplan: any, newKml: string) {
        return new Promise((resolve, reject) => {
            // Check the floorplan object
            if (floorplan == undefined || floorplan.levelId == undefined || this.currentSite == undefined ||
                this.currentSite.siteId == undefined || this.currentUser == undefined || newKml == undefined ||
                this.currentUser.username == undefined || this.currentUser.password == undefined)
                reject();
            else {
                // Create the form data object
                var formData = new FormData();
                // Add the data needed by the server
                formData.append('fp_id', floorplan.fpId || '');
                formData.append('name', floorplan.name || '');
                formData.append('desc', floorplan.desc || '');
                formData.append('levelId', floorplan.levelId.toString());
                formData.append('access', floorplan.access || 'private');
                // If we have an image
                if (floorplan.image != undefined && floorplan.image.blob != undefined && floorplan.image.name != undefined)
                    formData.append('image', floorplan.image.blob, floorplan.image.name);
                formData.append('newLevelId', floorplan.levelId.toString());
                formData.append('newLevelName', floorplan.name || '');
                formData.append('username', this.currentUser.username);
                formData.append('password', this.currentUser.password);
                formData.append('kml_content', newKml);
                // Make a PUT request to update the floorplan
                this.http2.post(`${this.configService.config.network.navizonApiUrl}/sites/${this.currentSite.siteId}/floorplans/`, formData).subscribe((newFloorplan: Floorplan) => {
                    // Success
                    // Update the floorplan in our data
                    this._updateFloorplan(floorplan, newFloorplan);
                    resolve(newFloorplan);
                }, (err) => {
                    // Error
                    reject(err);
                });
            }
        });
    }

    // Delete the floorplan by requesting the server
    public deleteFloorplan(floorplan: Floorplan) {
        return new Promise((resolve, reject) => {
            // Check the floorplan object
            if (floorplan == undefined || floorplan.fpId == undefined ||
                this.currentSite == undefined || this.currentSite.siteId == undefined) {
                reject();
            } else {
                // Make a DELETE request to delete the floorplan
                this.http.delete(`${this.configService.config.network.navizonApiUrl}/sites/${this.currentSite.siteId}/floorplans/${floorplan.fpId}/`).subscribe((res: any) => {
                    // Success
                    // Make sure it was a success
                    if (res != undefined && res.status == "success") {
                        // floorplan deleted, update our data
                        this._deleteFloorplan(floorplan);
                        resolve(res);
                    } else
                        reject();
                }, (err) => {
                    // Error
                    reject();
                });
            }
        });
    }


    /* -------------------------------------------------------- */
    /*                    Private functions                     */
    /* -------------------------------------------------------- */

    // Update our data to remove this floorplan, then notify the observables
    private _deleteFloorplan(deletedFloorplan: Floorplan) {
        if (deletedFloorplan == undefined || deletedFloorplan.fpId == undefined)
            return;
        // Keep all the floorplans with a different id to remove the deleted floorplan
        this._floorplans = this._floorplans.filter(x => x.fpId != deletedFloorplan.fpId);
        // Update observable
        this.floorplansSubject.next(this._floorplans);
    }

    // Update our data with the new floorplan, then notify the observables
    private _updateFloorplan(oldFloorplan: Floorplan, newFloorplan: Floorplan) {
        // Check the new floorplan data
        if (newFloorplan == undefined || newFloorplan.fpId == undefined)
            return;
        // If there is no old floorplan, it means it's a new one, just add it
        if (oldFloorplan == undefined || oldFloorplan.fpId == undefined)
            this._floorplans.push(newFloorplan);
        else {
            var self = this;
            // Go over all the floorplans, then update the right one
            this._floorplans.forEach(function(floorplan, index) {
                if (floorplan.fpId == oldFloorplan.fpId)
                    self._floorplans[index] = newFloorplan;
            });
        }
        // Update observables
        this.floorplansSubject.next(this._floorplans);
    }

    // Make a GET request to gather the list of all the floorplans for this site
    private _getFloorplans(site: Site) {
        return new Promise((resolve, reject) => {
            // Check the site object
            if (site == undefined || site.siteId == undefined) {
                reject();
            } else {
                // Make a GET request to get the list of floorplans
                this.http.get(`${this.configService.config.network.navizonApiUrl}/sites/${site.siteId}/floorplans/`).subscribe((floorplans: Floorplan[]) => {
                    // Success, got all the floorplans
                    resolve(floorplans);
                }, (err) => {
                    // Error
                    reject();
                });
            }
        });
    }
}