import { Component, Input, OnInit, OnChanges, ViewChild, EventEmitter, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { HttpClient } from '@angular/common/http';

import { ConfigService } from '../../../_services/config.service';
import { SiteService } from '../../../_services/site.service';

import { Site } from '../../../_models/site';

@Component({
    selector: 'element-sites-table',
    templateUrl: 'sitesTable.component.html',
    styleUrls: ['sitesTable.component.scss']
})
export class SitesTableComponent implements OnInit, OnChanges {

    isLoading: Boolean = true;

	// The sites
    @Input() sites: Site[];
    // List of columns we don't want to display
    @Input() removeCols;

    // Event when a site is selected to be edited
    @Output() siteSelected: EventEmitter<any> = new EventEmitter<any>();
    // Event when a site status has changed
    @Output() siteStatusChanged: EventEmitter<any> = new EventEmitter<any>();

    // Table variables
    dataSource: MatTableDataSource<any>;
    // Displayed columns
    displayedColumns = ['siteId', 'name', 'type', 'status', 'days_left', 'dStatus', 'dDays_left', 'action'];
    // The min-width for each column
    columnMinWidth = 150;

    // The booleans to show the spinners
    public showSpinners: any = {};

    // Just to access the Math object from the HTML code
    math = Math;

    @ViewChild('paginator', { static: true }) paginator: MatPaginator;
    @ViewChild('sort', { static: true }) sort: MatSort;

    constructor(public siteService: SiteService, private configService: ConfigService, private http: HttpClient) {
    	// Assign the data to the data source for the table to render
        this.dataSource = new MatTableDataSource(this.sites);
    }

	ngOnInit() {
        // Instantiate sorts and paginators
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        // Remove the cols if needed
        if (this.removeCols && this.removeCols.length > 0)
            this.displayedColumns = this.displayedColumns.filter((c) => this.removeCols.indexOf(c) == -1);
    }

    ngOnChanges() {
        if (this.sites == undefined)
            return;
    	this.dataSource.data = this.sites;
        this.isLoading = false;
    }

    applyFilter(filterValue: string, dataSource) {
        dataSource.filter = filterValue.trim().toLowerCase();
        if (dataSource.paginator)
            dataSource.paginator.firstPage();
    }

    newDate(val) {
        if (val)
            return new Date(val);
        return new Date();
    }

    // When we want to change the status of a site
    public setSiteStatus(element, freeze) {
        // Check site
        if (!(element && element.siteId))
            return;
        // Show spinner
        this.showSpinners[element.siteId] = true;
        // Find the right function to call
        var fct = (freeze == true) ? this.siteService.freezeSite.bind(this) : this.siteService.unfreezeSite.bind(this);
        // Request the server
        fct(element).then(() => {
            // Success
            element.frozen = freeze;
            // Emit event
            this.siteStatusChanged.emit(element);
        }, (err) => {
            // Error
            console.log(err);
        }).finally(() => {
            this.showSpinners[element.siteId] = undefined;
        });
    }

    // When user wants to edit a site
    public editSite(element) {
        if (!(element && element.siteId))
            return;
        // Trigger event
        this.siteSelected.emit(element);
    }

    // Return the type label for this site
    public getTypeLabel(site) {
        var res = '';
        this.siteService.siteType.forEach((t) => {
            if (site && site.admin && site.admin.type == t.value)
                res = t.label;
        });
        return res;
    }

    // Return the status label for this site
    public getStatusLabel(site) {
        return (site.frozen == true) ? 'Frozen' : 'Active';
    }

    // Return the dragonfly status label for this site
    public getDragonflyStatusLabel(site) {
        return (site.admin && site.admin.dragonfly && site.admin.dragonfly.enabled == true) ? 'Enabled' : 'Disabled';
    }

    // Return the number of days left for this site
    public getDaysLeft(s) {
        if (s != undefined && s.admin != undefined && s.admin.expiration != undefined && s.admin.expiration.length > 0) {
            var expiration = new Date(s.admin.expiration);
            if (expiration && expiration.getTime && expiration instanceof Date && isFinite(expiration.getTime()))
                return Math.round((expiration.getTime() - new Date().getTime()) / 86400000);
        }
        return '';
    }

    // Return the number of days left for this site
    public getDragonflyDaysLeft(s) {
        if (s && s.admin && s.admin.dragonfly && s.admin.dragonfly.expiration != undefined && s.admin.dragonfly.expiration.length > 0) {
            var expiration = new Date(s.admin.dragonfly.expiration);
            if (expiration && expiration.getTime && expiration instanceof Date && isFinite(expiration.getTime()))
                return Math.round((expiration.getTime() - new Date().getTime()) / 86400000);
        }
        return '';
    }

    // When we want to sort the table
    public sortData(sort) {
        if (!(sort && sort.active && sort.direction))
            return;
        this.sites = this.sites.sort((a, b) => {
            const isAsc = sort.direction === 'asc';
            switch (sort.active) {
                case 'type': return compare(this.getTypeLabel(a), this.getTypeLabel(b), isAsc);
                case 'status': return compare(this.getStatusLabel(a), this.getStatusLabel(b), isAsc);
                case 'days_left': return compare(this.getDaysLeft(a), this.getDaysLeft(b), isAsc);
                case 'dStatus': return compare(this.getDragonflyStatusLabel(a), this.getDragonflyStatusLabel(b), isAsc);
                case 'dDays_left': return compare(this.getDragonflyDaysLeft(a), this.getDragonflyDaysLeft(b), isAsc);
                default: return 0;
            }
        });
    }

    /* ------------------------------------------------------------------------- */
    /* -                           Private functions                           - */
    /* ------------------------------------------------------------------------- */

}

function compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}