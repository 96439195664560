import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { first } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';

import { ConfigService } from './config.service';
import { AuthenticationService } from './authentication.service';

import { Site } from '../_models/site';

@Injectable({ providedIn: 'root' })
export class AdminService {

    private activeSitesSubject: BehaviorSubject<Site[]>;
    public activeSites: Observable<Site[]>;
    private _activeSites: Site[];

    private frozenSitesSubject: BehaviorSubject<Site[]>;
    public frozenSites: Observable<Site[]>;
    private _frozenSites: Site[];

    constructor(private http: HttpClient, private configService: ConfigService, private authenticationService: AuthenticationService) {
        this.activeSitesSubject = new BehaviorSubject<Site[]>(undefined);
        this.activeSites = this.activeSitesSubject.asObservable();
        this.frozenSitesSubject = new BehaviorSubject<Site[]>(undefined);
        this.frozenSites = this.frozenSitesSubject.asObservable();
        // Watch current user
        this.authenticationService.currentUser.subscribe((currentUser) => {
            // If user is superuser
            if (currentUser && currentUser.isSuperuser == true)
                this._initialize();
        });
    }

    // Load everything needed for the admin section
    private _initialize() {
        // Get the list of active sites
        this.http.get(this.configService.config.network.navizonAdminApiUrl + "/sites/admin/")
        .pipe(first()).subscribe((activeSites: Site[]) => {
            this._activeSites = activeSites;
            this.activeSitesSubject.next(activeSites);
        }, (err) => {
            console.log(err);
            this._activeSites = [];
            this.activeSitesSubject.next([]);
        });
        // Get the list of frozen sites
        this.http.get(this.configService.config.network.navizonAdminApiUrl + "/sites/admin/?type=frozen")
        .pipe(first()).subscribe((frozenSites: Site[]) => {
            this._frozenSites = frozenSites;
            this.frozenSitesSubject.next(frozenSites);
        }, (err) => {
            console.log(err);
            this._frozenSites = [];
            this.frozenSitesSubject.next([]);
        });
    }

}