<h1 class="text-center my-4">New password</h1>

<form (ngSubmit)="resetPassword()" [formGroup]="resetForm">

    <!-- Username -->
    <div class="d-flex justify-content-center mb-2" *ngIf="done != true">
        <mat-form-field style="min-width: 250px !important" color="accent">
            <input matInput placeholder="Email" name="username" formControlName="email">
            <mat-error *ngIf="resetForm.controls['email'].errors?.email && !resetForm.controls['email'].errors?.required">
                Please enter a valid email address
            </mat-error>
            <mat-error *ngIf="resetForm.controls['email'].errors?.required">
                Email is required
            </mat-error>
        </mat-form-field>
    </div>

    <!-- New password -->
    <div class="d-flex justify-content-center mb-2" *ngIf="done != true">
        <mat-form-field appearance="outline" style="width: 260px" color="accent">
            <mat-label>New password</mat-label>
            <input matInput placeholder="New password" formControlName="newPassword" type="password">
            <mat-error *ngIf="resetForm.controls['newPassword'].errors?.required">
              New password is required
            </mat-error>
            <mat-error *ngIf="resetForm.controls['newPassword'].errors?.minlength">
              New password must be at least 6 characters long
            </mat-error>
        </mat-form-field>
    </div>

    <!-- Confirm new password -->
    <div class="d-flex justify-content-center mb-2" *ngIf="done != true">
        <mat-form-field appearance="outline" style="width: 260px" color="accent">
            <mat-label>Confirm password</mat-label>
            <input matInput placeholder="Confirm password" formControlName="confirmPassword" type="password">
            <mat-error *ngIf="resetForm.controls['confirmPassword'].errors?.required">
              Confirm password is required
            </mat-error>
            <mat-error *ngIf="resetForm.controls['confirmPassword'].errors?.match">
              Password must match
            </mat-error>
        </mat-form-field>
    </div>

    <!-- Reset button -->
    <div class="d-flex justify-content-center py-4" *ngIf="done != true">
        <button mat-raised-button *ngIf="loading == false" color="accent" [disabled]="resetForm.invalid">Save new password</button>
        <mat-spinner *ngIf="loading == true" color="accent" [diameter]="36"></mat-spinner>
    </div>

    <!-- Success message -->
    <div class="d-flex justify-content-center py-4" *ngIf="done == true">
        Your account has been updated with your new password.
    </div>
    <div class="d-flex justify-content-center py-4" *ngIf="done == true">
        <button mat-raised-button color="accent" routerLink="/login">Login</button>
    </div>

</form>