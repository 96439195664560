import { Component, Inject, AfterViewInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import * as _ from 'lodash';

import { ALERT_TYPE, AlertService } from '../../_services/alert.service';
import { ResponsiveService } from '../../_services/responsive.service';
import { VMarkerService } from '../../_services/vmarker.service';

import { YesNoDialogComponent } from '../../common/dialogs/yes-no-dialog.component';

@Component({
        selector: 'popup-vmarker',
        template:  `
                    <h1 mat-dialog-title *ngIf="data.vmarker != undefined && data.vmarker.id != undefined">Visual marker {{data.vmarker.id}}</h1>
                    <h1 mat-dialog-title *ngIf="data.vmarker == undefined || data.vmarker.id == undefined">New visual marker</h1>

                    <div mat-dialog-content>
                      <form id="myForm" (ngSubmit)="onSubmit()" [formGroup]="myFormGroup">

                        <div class="w-100 d-flex">
                          <mat-form-field appearance="outline" class="mx-auto" style="width: 140px" color="accent">
                              <mat-label>Latitude</mat-label>
                              <input matInput placeholder="Latitude" formControlName="lat">
                              <mat-error *ngIf="myFormGroup.get('lat').errors?.isNotNumber">
                                Must be a valid number
                              </mat-error>
                              <mat-error *ngIf="myFormGroup.get('lat').errors?.required">
                                Required
                              </mat-error>
                          </mat-form-field>
                          <mat-form-field appearance="outline" class="mx-auto" style="width: 140px" color="accent">
                              <mat-label>Longitude</mat-label>
                              <input matInput placeholder="Longitude" formControlName="lng">
                              <mat-error *ngIf="myFormGroup.get('lng').errors?.isNotNumber">
                                Must be a valid number
                              </mat-error>
                              <mat-error *ngIf="myFormGroup.get('lng').errors?.required">
                                Required
                              </mat-error>
                          </mat-form-field>
                        </div>
                        
                        <div class="w-100 d-flex">
                          <mat-form-field appearance="outline" class="mx-auto" style="width: 200px" color="accent">
                              <mat-label>Label</mat-label>
                              <input matInput placeholder="Label" type="text" formControlName="label">
                          </mat-form-field>
                        </div>

                        <div class="w-100 d-flex">

                          <div class="mx-auto mb-2">
                            <mat-form-field appearance="outline" class="mx-auto" style="width: 100px" color="accent">
                                <mat-label>Orientation (°)</mat-label>
                                <input matInput placeholder="Orientation" type="number" min="-360" max="360" formControlName="orientation">
                                <mat-error *ngIf="myFormGroup.get('orientation').errors?.required">
                                  Required
                                </mat-error>
                                <mat-error *ngIf="myFormGroup.get('orientation').errors?.max || myFormGroup.get('orientation').errors?.min">
                                  Incorrect
                                </mat-error>
                            </mat-form-field>

                            <div class="mx-auto"
                                 style="width: 50px;height: 50px;border: 2px solid #666666;border-radius: 50%;">

                              <div style="width: 48px;height: 48px;border-radius: 50%;float: left;z-index: 103"
                                   (mousemove)="mousemove($event)"
                                   (mousedown)="mousedown($event)"
                                   (mouseup)="mouseup($event)"
                                   (mouseleave)="mouseleave($event)"
                                   (click)="mouseclick($event)"
                                   >
                              </div>

                              <div id="anglePicker" class="ui-anglepicker-pointer"
                                   style="position: relative;top: 50%;left: 50%;width: 55%;transform-origin: 0px 0px;z-index: 100;pointer-events: none;">

                                <div class="ui-anglepicker-dot"
                                     style="height: 5px;width: 5px;position: absolute;background: #666666;border-radius: 50%;margin: -2px 0px 0px -2px;z-index: 101;pointer-events: none;"></div>
                                
                                <div class="ui-anglepicker-line"
                                     style="height: 1px;background: #666666;z-index: 102;pointer-events: none;"></div>

                              </div>

                            </div>



                          </div>

                          <mat-form-field appearance="outline" class="mx-auto" style="width: 100px" color="accent">
                              <mat-label>Altitude (m)</mat-label>
                              <input matInput placeholder="Altitude" type="number" formControlName="alt">
                              <mat-error *ngIf="myFormGroup.get('alt').errors?.required">
                                Required
                              </mat-error>
                          </mat-form-field>
                        </div>

                        <div class="w-100 d-flex">
                          <mat-checkbox class="mx-auto" formControlName="onWall">On wall</mat-checkbox>
                        </div>

                      </form>
                    </div>

                    <div mat-dialog-actions>
                      <div class="w-100 d-flex mb-3" >

                        <button mat-raised-button [matMenuTriggerFor]="menu" class="mx-auto" *ngIf="data.vmarker != undefined && data.vmarker.id != undefined" color="primary">QR code</button>
                        <mat-menu #menu="matMenu">
                          <button mat-menu-item (click)="showQR()">
                            <mat-icon>open_in_new</mat-icon>
                            <span>Show</span>
                          </button>
                          <button mat-menu-item (click)="printQR()">
                            <mat-icon>print</mat-icon>
                            <span>Print</span>
                          </button>
                        </mat-menu>

                      </div>
                      <div class="w-100 d-flex">
                        <button mat-raised-button style="width: 75px" class="ml-auto mr-2 lightColor" (click)="no()">Cancel</button>
                        <button mat-raised-button style="width: 75px" color="warn" class="mx-2" (click)="onDelete()" *ngIf="data.vmarker != undefined && data.vmarker.id != undefined">
                          Delete
                        </button>
                        <button mat-raised-button style="width: 75px" [disabled]="myFormGroup.invalid" color="accent" class="mr-auto ml-2" form="myForm">Save</button>
                      </div>
                    </div>`
})
export class VMarkerPopupDialogComponent implements AfterViewInit {

  public myFormGroup = this.fb.group({
    lat: ['', [Validators.required, Validators.min(-90), Validators.max(90), isNumber]],
    lng: ['', [Validators.required, Validators.min(-180), Validators.max(180), isNumber]],
    label: [''],
    orientation: ['', [Validators.required, Validators.min(-360), Validators.max(360)]],
    alt: ['', [Validators.required]],
    onWall: ['']
  });

  public canChooseAngle = false;

  constructor(public dialogRef: MatDialogRef<VMarkerPopupDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private fb: FormBuilder,
              private dialog: MatDialog, private responsiveService: ResponsiveService, private vmarkerService: VMarkerService,
              private alertService: AlertService) {
    var self = this;
    // Check we have all the data we need
    if (this.data == undefined || this.data.vmarker == undefined)
      this.dialogRef.close({error: true});
    // Fixed the number of decimals for the lat & lng
    this.data.vmarker.lat = (this.data.vmarker.lat.toFixed != undefined) ? parseFloat(this.data.vmarker.lat.toFixed(7)) : this.data.vmarker.lat;
    this.data.vmarker.lng = (this.data.vmarker.lng.toFixed != undefined) ? parseFloat(this.data.vmarker.lng.toFixed(7)) : this.data.vmarker.lng;
    // Transform the angle (VPS uses a reversed angle)
    this.data.vmarker.orientation = this.checkAngle(-this.data.vmarker.orientation);
    // Set the form values if we can
    this.myFormGroup.patchValue(this.data.vmarker || {});
    // Set the value change event
    this.myFormGroup.controls['orientation'].valueChanges.subscribe(newAngle => {
      if (newAngle == undefined || newAngle == null || newAngle < -360 || newAngle > 360)
        newAngle = 0;
      self.setAnglePicker(newAngle);
    });
  }

  ngAfterViewInit() {
    // Set the angle picker
    if (this.data && this.data.vmarker && this.data.vmarker.orientation != undefined)
      this.setAnglePicker(this.data.vmarker.orientation);
  }
  
  // If user chooses the 'no' option
  no() {
    this.dialogRef.close({value: undefined});
  }

  onDelete() {
    var self = this;
    // Define the message for the dialog
    var message = "Are you sure you want to delete the visual marker";
    if (this.data.vmarker.id != undefined)
        message += " with ID: " + this.data.vmarker.id;
    message += "?";
    var dialog = this.dialog.open(YesNoDialogComponent, { panelClass: ['mw-none','w-90','w-sm-80','w-md-50','w-lg-40'], data: {
        title: "Delete visual marker",
        message:  message,
        noBtnLabel: "Cancel",
        yesBtnLabel: "Delete",
        YesIsWarn: true
    }});
    dialog.updatePosition({ top: this.responsiveService.getDialogTopPosition() + 'px' });
    // When user answers
    dialog.afterClosed().subscribe(result => {
        // If true, means user wants to delete the floorplan
        if (result == true) {
            // Return a 'delete' value, and return the visual marker ID
            self.dialogRef.close({value: 'delete', result: _.merge(self.data.vmarker, self.myFormGroup.value)});
        }
    });
  }

  onSubmit() {
    // If form is not valid
    if (this.myFormGroup.valid == false)
      return;
    // If the form has not been touched, return like a cancel
    else if (this.myFormGroup.dirty == false && this.data.vmarker.id != undefined)
      this.dialogRef.close({value: undefined});
    else {
      var newVisualMarker = _.merge(this.data.vmarker, this.myFormGroup.value);
      // Cast the lat and lng as float (currently string)
      newVisualMarker.lat = parseFloat(newVisualMarker.lat);
      newVisualMarker.lng = parseFloat(newVisualMarker.lng);
      // Transform the angle (VPS uses a reversed angle)
      newVisualMarker.orientation = this.checkAngle(-newVisualMarker.orientation);
      if (newVisualMarker.id != undefined)
        // Return a 'edit' value, and the result object
        this.dialogRef.close({value: 'edit', result: newVisualMarker});
      else
        // Return a 'create' value, and the result object
        this.dialogRef.close({value: 'create', result: newVisualMarker});
    }
  }

  mousemove(event) {
    this.anglePickerClicked(event);
  }
  mousedown(event) {
    this.canChooseAngle = true;
    this.anglePickerClicked(event);
  }
  mouseup(event) {
    this.canChooseAngle = false;
  }
  mouseclick(event) {
    this.canChooseAngle = false;
  }
  mouseleave(event) {
    this.canChooseAngle = false;
  }

  anglePickerClicked(event) {
    if (event == undefined || event.offsetX == undefined || event.offsetY == undefined || this.canChooseAngle != true)
      return;
    var clickPoint = {x: event.offsetX, y: event.offsetY}, origin = {x: 24, y: 24}, zeroPoint = {x: 24, y: 0};
    clickPoint.x = (clickPoint.x < 0) ? 0 : clickPoint.x;
    clickPoint.y = (clickPoint.y < 0) ? 0 : clickPoint.y;
    // Calculate angle from coordinates
    var deltaX = clickPoint.x - origin.x, deltaY = clickPoint.y - origin.y;
    var rad = Math.atan2(deltaY, deltaX);
    var angle = Math.round((rad * (180 / Math.PI)) + 90);
    var finalAngle = this.checkAngle(angle);
    // Move the angle picker line to the correct position
    this.setAnglePicker(finalAngle);
    // Update form control
    this.myFormGroup.controls['orientation'].setValue(finalAngle);
    this.myFormGroup.controls['orientation'].markAsDirty();
  }

  checkAngle(angle) {
    if (angle <  0)
      return angle + 360;
    return angle;
  }

  // Move the angle picker line to the correct position
  setAnglePicker(angle) {
    document.getElementById('anglePicker').style.transform = "rotate(" + (angle - 90) + "deg)";
  }

  // When user wants to show the QR code
  showQR() {
    var self = this;
    if (this.data.vmarker == undefined || this.data.vmarker.id == undefined)
      return;
    this.vmarkerService.getVMarkerImage(this.data.vmarker.id).then((base64) => {
      // Show the QR code
      this.dialog.open(VMarkerImagePopupDialogComponent, { panelClass: ['mw-none','w-100','h-100'], data: {
        base64: base64
      }});
    }, (err) => {
      console.log(err);
      // Show error message
      self.alertService.showMessage(ALERT_TYPE.Error, "An error occurred, cannot get the visual marker image");
    });
  }

  // When user wants to print the QR code
  printQR() {
    var self = this;
    if (this.data.vmarker == undefined || this.data.vmarker.id == undefined)
      return;
    this.vmarkerService.getVMarkerImage(this.data.vmarker.id).then((base64) => {

      // Show the QR code
      var printDialog = this.dialog.open(VMarkerImagePopupDialogComponent, { panelClass: ['mw-none','w-100','h-100'], data: {
        base64: base64
      }});
      // Open the print window
      setTimeout(function() {
        window.print();
        printDialog.close();
      }, 500);


      // Show the image in a new tab
      /*
      var win = window.open("", '_blank');
      var image = new Image();
      (image as any).src = base64;
      win.document.write(image.outerHTML);
      win.focus();
      */

    }, (err) => {
      console.log(err);
      // Show error message
      self.alertService.showMessage(ALERT_TYPE.Error, "An error occurred, cannot get the visual marker image");
    });
  }

}

function isNumber(c: FormControl) {
  var number = parseFloat(c.value);
  if (number == undefined || number == null || isNaN(number))
    return { isNotNumber: true };
  return null;
}

@Component({
        selector: 'popup-image-vmarker',
        template:  `
                    <div class="text-center mb-4">
                      <button mat-button (click)="dialogRef.close()" class="lightColor">Close</button>
                    </div>
                    <div class="text-center">
                      <img src="{{data.base64}}" style="max-width: 100%; max-height: 100%;"/>
                    </div>
                  `
})
export class VMarkerImagePopupDialogComponent {

  constructor(public dialogRef: MatDialogRef<VMarkerImagePopupDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

}