<h1 mat-dialog-title>Create floorplan</h1>

<div mat-dialog-content>

    <!-- Stepper -->
    <mat-horizontal-stepper [linear]="true" #stepper color="accent">

      <!-- Choose address stepper -->
      <mat-step [stepControl]="chooseAddressFormGroup">
        <form class="d-flex flex-column pt-5" [formGroup]="chooseAddressFormGroup">

          <!-- Title -->
          <ng-template matStepLabel>Choose address</ng-template>

          <mat-radio-group aria-label="Select site" formControlName="addressMode" class="d-flex flex-column">
            <!-- Default site address -->
            <mat-radio-button value="defaultSite" class="mx-auto mb-4">
              Default site address: <span class="font-weight-bold">{{data.site.address}}</span>
            </mat-radio-button>
            <!-- Search site address -->
            <mat-radio-button value="searchSite" class="mx-auto">
              Search address
              <span *ngIf="chooseAddressFormGroup.get('address').value != undefined && chooseAddressFormGroup.get('address').value.x != undefined && chooseAddressFormGroup.get('address').value.y != undefined">
                : <span class="font-weight-bold">{{chooseAddressFormGroup.get('address').value.y}}, {{chooseAddressFormGroup.get('address').value.x}}</span>
              </span>
            </mat-radio-button>
          </mat-radio-group>

          <!-- Autocomplete input (address chooser) -->
          <mat-form-field class="mx-auto w-100 w-sm-80 w-md-50 w-lg-30" *ngIf="chooseAddressFormGroup.get('addressMode').value == 'searchSite'" color="accent">
            <input type="text" placeholder="Search" matInput [formControl]="autocompleteFormControl" [matAutocomplete]="auto" (keyup)="autocompleteInputChanged()">
            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="autocompleteInputSelected($event)">
              <mat-option *ngFor="let addr of addresses" [value]="addr.label">
                {{addr.label}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>

          <!-- Stepper buttons -->
          <div class="text-center pt-5">
            <button mat-raised-button (click)="cancel()" class="mr-2 lightColor">Cancel</button>
            <button mat-raised-button color="accent" matStepperNext class="ml-2" [disabled]="chooseAddressFormGroup.invalid">Next</button>
          </div>

        </form>
      </mat-step>

      <!-- Upload image stepper -->
      <mat-step [stepControl]="imgUploadFormGroup">
        <form class="d-flex flex-column pt-5" [formGroup]="imgUploadFormGroup">

          <!-- Title -->
          <ng-template matStepLabel>Upload an image</ng-template>

          <!-- Upload image button -->
          <label class="mat-raised-button mat-accent mx-auto" *ngIf="isDownloadingImage != true" style="width: 130px">
            <input type="file" accept="image/*" (change)="onFileChange($event)" class="w-0 invisible" >Choose image
          </label>
          <mat-spinner *ngIf="isDownloadingImage == true" class="mx-auto mt-3" color="accent" [diameter]="36"></mat-spinner>

          <!-- Floorplan preview -->
          <div class="d-flex flex-column w-100 mt-4" *ngIf="image != undefined && image.sanitizedData != undefined">
            <img [src]="image.sanitizedData" style="height: 150px; max-width: 100%; object-fit: contain;" class="mx-auto">
          </div>

          <!-- Floorplan size warning -->
          <div class="d-flex flex-column w-100 mt-4 text-center">
            Image must be less than 5 MB
          </div>

          <!-- Stepper buttons -->
          <div class="text-center pt-5">
            <button mat-raised-button (click)="cancel()" class="mr-2 lightColor">Cancel</button>
            <button mat-raised-button matStepperPrevious class="mx-2 lightColor">Back</button>
            <button mat-raised-button color="accent" matStepperNext class="ml-2" [disabled]="imgUploadFormGroup.invalid" (click)="displayImageOnMap()">Next</button>
          </div>

        </form>
      </mat-step>

      <!-- Position floorplan -->
      <mat-step>

        <!-- Title -->
        <ng-template matStepLabel>Position floorplan</ng-template>

        <!-- Form for the level ID, name and description -->
        <form id="floorplanForm" [formGroup]="floorplanFormGroup" class="pt-2 pb-3" (ngSubmit)="createFloorplan()">
          <div class="w-100 d-flex flex-wrap">
            <!-- Level ID -->
            <mat-form-field appearance="outline" class="mx-auto" style="width: 120px" color="accent">
              <mat-select placeholder="Level ID" formControlName="levelId">
                <mat-option *ngFor="let level of levels" [value]="level.levelId" [disabled]="level.canGetNewFloorplan != true">
                  {{level.levelId}} <span *ngIf="level.canGetNewFloorplan != true">(already used)</span>
                </mat-option>
              </mat-select>
              <mat-error *ngIf="floorplanFormGroup.controls['levelId'].errors?.required">
                Level ID is required
              </mat-error>
            </mat-form-field>
            <!-- Name -->
            <mat-form-field appearance="outline" class="mx-auto" style="width: 260px" color="accent">
              <mat-label>Name</mat-label>
              <input matInput placeholder="Name" formControlName="name">
            </mat-form-field>
            <!-- Description -->
            <mat-form-field appearance="outline" class="mx-auto" style="width: 260px" color="accent">
              <mat-label>Description</mat-label>
              <input matInput placeholder="Description" formControlName="desc">
            </mat-form-field>
          </div>
        </form>
        <!-- Map with editable image -->
        <div id="map" #map class="mx-auto w-100 noselect" style="height: 350px;"></div>

        <!-- Stepper buttons -->
        <div class="text-center pt-4">
          <button mat-raised-button (click)="cancel()" class="mr-2 lightColor">Cancel</button>
          <button mat-raised-button matStepperPrevious class="mx-2 lightColor">Back</button>
          <button mat-raised-button [disabled]="floorplanFormGroup.invalid" form="floorplanForm" color="accent" class="ml-2">Done</button>
        </div>

      </mat-step>

    </mat-horizontal-stepper>
  
</div>