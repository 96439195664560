<h1 class="text-center mb-4">Geo-Fences</h1>

<!-- Spinner -->
<mat-spinner class="mx-auto" color="accent" *ngIf="geoFences == undefined"></mat-spinner>

<!-- Map -->
<div id="mapContainer" class="px-3 px-md-4 px-lg-5 mb-4" [ngClass]="{'visible': geoFences != undefined, 'invisible': geoFences == undefined}">
	<div id="map" #map class="noselect w-100 h-100">
		<!-- Top right div inside the map -->
        <div class="leaflet-top leaflet-right mt-2 mr-2" style="pointer-events: auto" (click)="$event.stopPropagation()">
			<map-settings [mapSettings]="mapSettings"></map-settings>
		</div>
	</div>
</div>

<!-- Data table -->
<div class="container d-flex flex-column">

	<mat-table [dataSource]="geoFences" class="border w-100 responsive-datatable" matSort matSortActive="id" matSortDirection="asc" [ngClass]="{'d-none': geoFences == undefined}">

		<!-- Columns -->
		<ng-container matColumnDef="{{column.attribute}}" *ngFor="let column of columnsToDisplay">
			<mat-header-cell *matHeaderCellDef mat-sort-header class="justify-content-center">
				{{column.display}}
			</mat-header-cell>
			<mat-cell *matCellDef="let element">
				<span class="mobile-label pr-2">{{column.display}}:</span>
				<div class="mx-auto">
					<!-- Text version -->
					<span *ngIf="column.clickable == undefined" (click)="attributeClicked(column.attribute, element)" matTooltip="{{column.tooltip}}">{{column.getFct(element)}}</span>
					<!-- Button version -->
					<button mat-button *ngIf="column.clickable != undefined" class="link" (click)="attributeClicked(column.attribute, element)" matTooltip="{{column.tooltip}}">{{column.getFct(element)}}</button>
				</div>
			</mat-cell>
		</ng-container>

		<!-- Edit/Delete -->
		<ng-container matColumnDef="action">
			<mat-header-cell *matHeaderCellDef></mat-header-cell>
			<mat-cell *matCellDef="let element" class="mx-auto">
				<div class="mx-auto">

					<!-- Edit button -->
					<button *ngIf="element.showSpinner != true" class="mx-1" mat-mini-fab color="accent" matTooltip="Edit" (click)="editGeoFence(element)">
						<mat-icon aria-label="Edit">edit</mat-icon>
					</button>
					<!-- Delete button -->
					<button *ngIf="element.showSpinner != true" class="mx-1" mat-mini-fab color="accent" matTooltip="Delete" (click)="deleteGeoFence(element)">
						<mat-icon aria-label="Delete">delete</mat-icon>
					</button>

					<!-- Spinner -->
					<mat-spinner *ngIf="element.showSpinner == true" color="accent" [diameter]="36"></mat-spinner>

				</div>
			</mat-cell>
		</ng-container>

		<mat-header-row *matHeaderRowDef="columnsAttributes"></mat-header-row>
		<mat-row *matRowDef="let row; columns: columnsAttributes;"></mat-row>

	</mat-table>

</div>