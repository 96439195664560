<div *ngIf="isLoading == true">
	<mat-spinner class="mx-auto" color="accent" [diameter]="36"></mat-spinner>
</div>

<div [ngClass]="{'invisible': isLoading == true}" class="d-flex flex-column px-3 pt-0 pb-3">

	<mat-form-field class="w-100 w-sm-50 w-md-40 w-lg-30 mx-auto" *ngIf="showFilterInput == true" color="accent">
		<input matInput (keyup)="applyFilter($event.target.value, dataSource)" placeholder="Filter">
	</mat-form-field>

	<div class="border bg-white" style="overflow-x: auto">

		<table #sort="matSort" mat-table class="w-100 centered-table" [ngStyle]="{'min-width.px': columnsSource.length * columnMinWidth}" [dataSource]="dataSource" matSort [matSortActive]="sortField" [matSortDirection]="sortDirection">

			<!-- Columns -->
			<ng-container *ngFor="let attr of attributesToDisplay" matColumnDef="{{attr.columnDef}}">
				<th mat-header-cell *matHeaderCellDef mat-sort-header class="centered-header">{{attr.display}}</th>
				<td mat-cell class="text-center" *matCellDef="let element">
					<div [ngClass]="getElementClasses(attr, element)">
						<!-- Text version -->
						<span *ngIf="attr.clickable == undefined" (click)="attributeClicked(attr, element)" matTooltip="{{attr.tooltip}}">{{attr.getFct(element)}}</span>
						<!-- Button version -->
						<button mat-button *ngIf="attr.clickable != undefined" class="link m-0 p-0" (click)="attributeClicked(attr, element)" matTooltip="{{attr.tooltipFct ? attr.tooltipFct(element) : attr.tooltip}}">{{attr.getFct(element)}}</button>
					</div>
				</td>
			</ng-container>

			<!-- Navigation column -->
			<ng-container *ngIf="options && options.navigatable == true" matColumnDef="navigation">
				<th mat-header-cell *matHeaderCellDef mat-sort-header class="centered-header" width="300px">Navigation</th>
				<td mat-cell class="text-center" *matCellDef="let element">
					<button mat-flat-button color="warn" *ngIf="element && element.state != 0 && element.state != 1 && showSpinners[getId(element)] == undefined" (click)="stopNavigation(element)">Stop</button>
					<button mat-flat-button color="accent" class="mr-2" *ngIf="element && (element.state == 0 || element.state == 1) && showSpinners[getId(element)] == undefined" [disabled]="element.state == 0" (click)="startNavigation(element, false)">Start mapping</button>
					<button mat-flat-button color="primary" class="ml-2" *ngIf="element && (element.state == 0 || element.state == 1) && showSpinners[getId(element)] == undefined" [disabled]="element.state == 0" (click)="startNavigation(element, true)">Start</button>
					<!-- Spinner -->
					<mat-spinner *ngIf="showSpinners[getId(element)] != undefined" class="mx-auto" color="accent" [diameter]="36"></mat-spinner>
				</td>
			</ng-container>

			<!-- Action column -->
			<ng-container *ngIf="showAction == true" matColumnDef="action">
				<th mat-header-cell *matHeaderCellDef mat-sort-header class="centered-header"></th>
				<td mat-cell class="text-center" *matCellDef="let element">






					<!-- Edit button -->
					<button *ngIf="options.editable == true && getId(element) != undefined && showSpinners[getId(element)] == undefined" class="mx-1" mat-mini-fab color="accent" matTooltip="Edit" (click)="editElement(element)">
						<mat-icon aria-label="Edit">edit</mat-icon>
					</button>

				
					<!-- Delete button -->
					<button *ngIf="options.deletable == true && getId(element) != undefined && showSpinners[getId(element)] == undefined" class="mx-1" mat-mini-fab color="accent" matTooltip="Delete" (click)="deleteElement(element)">
						<mat-icon aria-label="Delete">delete</mat-icon>
					</button>

					
					<!-- <button *ngIf="options.showRowExport == true && getId(element) != undefined && showSpinners[getId(element)] == undefined" class="mx-1" mat-mini-fab color="accent" matTooltip="Export CSV" (click)="exportElement(element)">
						<mat-icon aria-label="Export"  >cloud_download</mat-icon>
					</button> -->
					


					<!-- <div *ngIf="commands && commands.length > 0" class="text-center">
						<button *ngIf="isDeletingData != true" class="mx-1" mat-mini-fab matTooltip="Menu"  color="accent"  [matMenuTriggerFor]="deleteMenu">
							<mat-icon aria-label="Delete">menu</mat-icon>
						</button>
		
						<mat-menu #deleteMenu="matMenu">
							<button  *ngFor="let command of commands;" mat-menu-item
							[matTooltip]="command.title" 
							(click)="executeCommand(command,element)">
								<mat-icon [aria-label]="command.title"   > {{command.icon}}</mat-icon>
							</button>
						</mat-menu>
					</div>-->



					<button  *ngFor="let command of commands;" class="mx-1" mat-mini-fab color="accent" [matTooltip]="command.title" (click)="executeCommand(command,element)">
						<mat-icon [aria-label]="command.title"   > {{command.icon}}</mat-icon>
					</button> 
					<!-- Spinner -->
					<mat-spinner *ngIf="getId(element) != undefined && showSpinners[getId(element)] != undefined" class="mx-auto" color="accent" [diameter]="36"></mat-spinner>
				</td>
			</ng-container>




			<!-- Show column -->
			<ng-container *ngIf="showShow == true" matColumnDef="show">
				<th mat-header-cell style="padding-left: 15px"  class="pl-2" *matHeaderCellDef class="centered-header">
					<mat-checkbox color="accent" *ngIf="!hideShow"  [(ngModel)]="showAllChecked" 
					[(indeterminate)]="showAllIndeterminate" [disabled]="disableShow"
					(change)="showAllChanged($event, element)" >
					Show
				</mat-checkbox>
				</th>
				<td mat-cell style="padding-right: 15px" class="text-center" *matCellDef="let element">
					<div class="d-flex align-items-center flex-row">
						
						<!-- <mat-icon *ngIf="element.isProcessing" color="accent" >map</mat-icon> -->
						<div *ngIf="showColor == true" class="ml-1" style="position: relative;height: 20px;width: 20px;">
							<div [ngStyle]="{'position':'absolute','width': '20px','height': '20px','top': '0px','left': '0px', 'border-radius':  element.isRect ?'0%':'100%', 'background-color': element.isSelected || colorReadonly ? element.color : 'gray' }" ></div>
							<input type="color"  style="opacity: 0;position:absolute;width: 20px;height: 20px;top: 0px;left: 0px;" 
						 		matInput [disabled]="!element.isSelected || element.isDisabled" [value]="element.color" (change)="colorChanged($event, element)" />						
						</div>
						<div *ngIf="showColor != true" class="ml-1" style="height: 20px;width: 20px;"></div>

						<!-- Show checkbox -->
						<mat-slide-toggle class="ml-1" *ngIf="!hideShow" color="accent" [(ngModel)]="element.isSelected" 
						[disabled]="element.isDisabled" (change)="showChanged($event, element)"></mat-slide-toggle>
						<!-- <mat-spinner *ngIf="element.isProcessing" class="mx-auto" color="accent" [diameter]="20"></mat-spinner> -->
					</div>


				</td>
			</ng-container>


			<tr mat-header-row *matHeaderRowDef="columnsSource"></tr>
			<tr mat-row *matRowDef="let element; columns: columnsSource;"  [ngClass]="{'highlightedRow':element.isProcessing}"></tr>

		</table>

		<mat-paginator #paginator [pageSizeOptions]="[10, 25, 100]" [ngStyle]="{'min-width.px': columnsSource.length * columnMinWidth}" [(pageIndex)]="pageIndex" [(pageSize)]="pageSize" color="accent"></mat-paginator>

	</div>
	
</div>