import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, Validators, FormControl, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

import L from 'leaflet';
import { OpenStreetMapProvider } from 'leaflet-geosearch';

import { ALERT_TYPE, AlertService } from '../../../../_services/alert.service';
import { LeafletService } from '../../../../_services/leaflet.service';
import { LevelService } from '../../../../_services/level.service';
import { FloorplanService } from '../../../../_services/floorplan.service';
import { UtilsService } from '../../../../_services/utils.service';

import { MapData } from '../../../../_models/mapData';
import { Level } from '../../../../_models/level';
import { Floorplan } from '../../../../_models/floorplan';
import { FpImage } from '../../../../_models/fpImage';

const MAX_IMAGE_SIZE = 5000000;   // 5MB

@Component({
        selector: 'upload-image-floorplan',
        templateUrl: 'upload-image-floorplan-dialog.component.html',
        styleUrls: ['upload-image-floorplan-dialog.component.scss']
})
export class UploadImageFloorplanDialogComponent implements OnDestroy, OnInit {

    // Upload image form
    public imgUploadFormGroup = this.fb.group({
      file: [null, Validators.required]
    });
    // Choose address form
    public chooseAddressFormGroup;
    // Floorplan form
    public floorplanFormGroup = this.fb.group({
      levelId: [undefined, Validators.required],
      name: [''],
      desc: ['']
    });

    // List of all the levels (with their availability to get a new floorplan)
    public levels: Level[] = [];

    // Boolean to know when we are downloading the image
    public isDownloadingImage: Boolean = false;

    // Our floorplan image
    public image: FpImage;

    // List of subs
    private subs: Subscription[] = [];

    // List of addresses for the autocomplete input
    public addresses = [];
    public autocompleteFormControl = new FormControl();

    // The geo search to found the address
    private provider = new OpenStreetMapProvider();

    constructor(public dialogRef: MatDialogRef<UploadImageFloorplanDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
                private fb: FormBuilder, private alertService: AlertService, private sanitizer: DomSanitizer,
                private leafletService: LeafletService, private levelService: LevelService, private floorplanService: FloorplanService,
                private utilsService: UtilsService) {
      // Check we have all the data we need
      if (this.data == undefined || this.data.site == undefined)
        this.dialogRef.close({error: true});
      else {
        // Get the levels, and find which ones can get a new floorplan (because only one per level)
        this.subs.push(this.levelService.levels.subscribe((levels: Level[]) => {
          this.levels = levels;
          // Get the floorplans
          this.subs.push(this.floorplanService.floorplans.subscribe((floorplans: Floorplan[]) => {
            // For each level, find if there is already a floorplan on it
            this.levels.forEach(function(level: any) {
              // Find if there is a floorplan on it
              var fp = floorplans.filter((x) => x.levelId == level.levelId);
              level.canGetNewFloorplan = (fp.length <= 0) ? true : false;
            });
          }));
        }));
      }
    }

    ngOnInit() {
      this.chooseAddressFormGroup = new FormGroup({
        addressMode: new FormControl(null, [Validators.required]),
        address: new FormControl(null, [])
      }, siteValidator);
    }

    ngOnDestroy() {
        // Clear the subs
        this.subs.forEach(function(sub) { sub.unsubscribe(); });
    }
    
    // If user chooses the 'cancel' option
    cancel() {
      this.dialogRef.close({value: false});
    }

    // When user choose an image
    onFileChange(event) {
      // Check there is a file to download
      if (event.target.files && event.target.files.length) {
        const reader = new FileReader();
        const [file] = event.target.files;
        // Check image size
        if (file && file.size > MAX_IMAGE_SIZE) {
          this.alertService.showMessage(ALERT_TYPE.Error, "Image must be less than " + (MAX_IMAGE_SIZE/1000000) + " MB");
          return;
        }
        // Start downloading the image
        this.isDownloadingImage = true;
        reader.readAsDataURL(file);
        // Callback when image is done downloading
        reader.onload = () => {
          if (reader == undefined || reader.result == undefined)
            this.alertService.showMessage(ALERT_TYPE.Error, "An error occurred, cannot find the data for this image");
          else {
            // Image has been downloaded, we can create our floorplan image
            let base64Data = reader.result.toString();
            let sanitizedData = this.sanitizer.bypassSecurityTrustResourceUrl(base64Data);
            let blob = this.utilsService.b64toBlob(base64Data);
            this.image = new FpImage(file.name, file.type, base64Data, sanitizedData, blob);
            // Patch the form value, so we can go to the next step
            this.imgUploadFormGroup.patchValue({
              file: reader.result
            });
          }
          this.isDownloadingImage = false;
        };
        // Callback when download had an error
        reader.onerror = () => {
          this.isDownloadingImage = false;
          this.alertService.showMessage(ALERT_TYPE.Error, "An error occurred, cannot download the image");
        }
      } else {
        this.alertService.showMessage(ALERT_TYPE.Error, "An error occurred, cannot find the image to download");
      }
    }

    // When user is ready to position the floorplan on the map
    displayImageOnMap() {
      // Get the coordinates the user wants to use
      var center, searchedAddr = this.chooseAddressFormGroup.get('address').value;
      // If user used the search input
      if (this.chooseAddressFormGroup.get('addressMode').value == 'searchSite' && searchedAddr != undefined &&
          searchedAddr.x != undefined && searchedAddr.y != undefined)
        center = [parseFloat(searchedAddr.y), parseFloat(searchedAddr.x)];
      // Instantiate the map
      // Draw the map for this floorplan
      this.leafletService.drawMap("map", new MapData({
          image: this.image,
          showFloorplan: false,
          editableFloorplan: true,
          showScale: true,
          uniformScaling: true,
          showRuler: true,
          center: (center != undefined) ? center : undefined
      }));
    }

    // When user has done positioning the image and wants to create the floorplan
    createFloorplan() {
      if (this.floorplanFormGroup.invalid == true)
        return;
      var self = this;
      // Get the layers (with their floorplans) from the map service
      this.leafletService.getEditedFloorplans().then((layers: any[]) => {
        layers.forEach(function(layer) {
          // Add the missing info to the floorplan object
          if (layer && layer.floorplan != undefined)
            layer.floorplan = {...layer.floorplan, ...self.floorplanFormGroup.value};
        });
        this.dialogRef.close({value: true, result: layers});
      }, (err) => {
        this.alertService.showMessage(ALERT_TYPE.Error, "An error occurred, cannot get the floorplan data");
      });
    }

    autocompleteInputChanged() {
      // We want the user to type at least 3 characters to start searching the address
      if (this.autocompleteFormControl.value == undefined || this.autocompleteFormControl.value.length < 3)
        return;
      // Use the geo search
      this.provider.search({ query: this.autocompleteFormControl.value }).then((addresses) => {
        this.addresses = addresses;
      }, (err) => {
        this.addresses = [];
      });
    }

    autocompleteInputSelected(event) {
      if (event && event.option && event.option.value != undefined && this.addresses.length > 0) {
        // Search all the addresses for the selected one
        var selectedAddr = undefined;
        this.addresses.forEach(function(addr) {
          if (addr.label == event.option.value)
            selectedAddr = addr;
        });
        // If we found the address
        if (selectedAddr != undefined && selectedAddr.x != undefined && selectedAddr.y != undefined)
          this.chooseAddressFormGroup.get('address').setValue(selectedAddr);
      }
    }
}

function siteValidator(control: FormGroup): { [key: string]: boolean } | null {
  let mode = control.controls['addressMode'].value;
  let searchedAddr = control.controls['address'].value;
  // Check if we can go to the next step
  if (mode == 'defaultSite' || (mode == 'searchSite' && searchedAddr != undefined && searchedAddr.x != undefined && searchedAddr.y != undefined))
    return null;
  // Else, invalidate the form
  return { 'siteValidator': true };
}