export class Level {

	public alt: string = "";
	public desc: string = "";
	public fpCount: number;
	public levelId: number;
	public name: string = "";

    constructor(levelId: number, fpCount: number, desc: string = "", name: string = "", alt: string = "") {
		this.levelId = levelId;
		this.fpCount = fpCount;
		this.alt = alt;
		this.desc = desc;
		this.name = name;
    }

    // Second constructor
    static fromData(data: any) {
    	if (data == undefined)
    		return undefined;
        let { levelId, fpCount, alt = "", desc = "", name = "" } = data;
        if (levelId == undefined)
        	return undefined;
        return new this(levelId, fpCount, desc, name, alt);
    }
}