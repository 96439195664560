import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { AlertComponent } from '../_helpers/alert.component';

// Type of message (for styling)
export enum ALERT_TYPE {
    Success = "success",
    Warning = "warning",
    Error = "error"
};

@Injectable({ providedIn: 'root' })
export class AlertService {

    constructor(private snackBar: MatSnackBar) { }

    showMessage(type: ALERT_TYPE, message, optionalDuration?) {
        let duration = (optionalDuration) ? optionalDuration : 5000;
        this.snackBar.openFromComponent(AlertComponent, { verticalPosition: 'bottom', horizontalPosition: 'right', duration: duration, panelClass: type, data: { type: type, message: message }});
    }
}