import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Validators, FormBuilder } from '@angular/forms';

import { SiteService } from '../_services/site.service';
import { ALERT_TYPE, AlertService } from '../_services/alert.service';

@Component({templateUrl: 'reset-password.component.html'})
export class ResetPasswordComponent {

    // Reset form
    resetForm = this.fb.group({
        email: ['', [Validators.required, Validators.email]]
    });

    loading = false;
    done = false;

    constructor(private route: ActivatedRoute, private router: Router, private fb: FormBuilder,
                private siteService: SiteService, private alertService: AlertService) {
    }

    sendResetPasswordEmail() {
        // Show spinner
        this.loading = true;
        // Request server
        this.siteService.sendResetPasswordEmail(this.resetForm.get('email').value).then((res) => {
            console.log(res);
            this.done = true;
        }, (err) => {
            console.log(err);
            if (err && err.status == 'error' && err.errors && err.errors.length > 0)
                this.alertService.showMessage(ALERT_TYPE.Error, err.errors[0]);
            else
                this.alertService.showMessage(ALERT_TYPE.Error, 'Invalid email or account does not exist');
        }).finally(() => {
            this.loading = false;
        });
    }
}