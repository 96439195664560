import { Component } from '@angular/core';

import { CategoryService } from '../../_services/category.service';

@Component({
	templateUrl: 'categories.component.html',
	styles: [`
		mat-card {
			outline: none;
		}
		.mat-card-title {
			margin-left: -16px;
		}
	`]
})
export class CategoriesComponent {

	public categories = [];

    constructor(private categoryService: CategoryService) {
    	// Get the categories
    	this.categoryService.categories.subscribe((categories) => {
    		this.categories = categories;
    	});
    }

}