import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';

import { SiteService } from './site.service';
import { LevelService } from './level.service';
import { ALERT_TYPE, AlertService } from './alert.service';
import { ConfigService } from './config.service';

import { Site } from '../_models/site';
import { Level } from '../_models/level';
import { GeoFence } from '../_models/geoFence';
import { switchMap, map, tap, debounceTime } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class GeoFenceService {

    // The current site
    public currentSite: Site;
    // The current level
    public currentLevel: Level;

    private geoFencesSubject: BehaviorSubject<GeoFence[]>;
    public geoFences: Observable<GeoFence[]>;
    private _geoFences: GeoFence[];

    // Boolean when we are requesting the server
    private _isFetching: Boolean = false;

    constructor(private http: HttpClient, private siteService: SiteService, private levelService: LevelService,
                private alertService: AlertService, private configService: ConfigService) {
        this.geoFencesSubject = new BehaviorSubject<GeoFence[]>(undefined);
        this.geoFences = this.geoFencesSubject.asObservable();
        // Get the selected site
        this.siteService.currentSite.subscribe(site => {
            this.currentSite = site;
            if (this.currentLevel != undefined && this.currentSite != undefined)
                this._getGeoFences(this.currentSite, this.currentLevel);
        });
        // Get the selected level
        this.levelService.currentLevel.subscribe(level => {
            this.currentLevel = level;
            if (this.currentLevel != undefined && this.currentSite != undefined)
                this._getGeoFences(this.currentSite, this.currentLevel);
        });
    }

    /* -------------------------------------------------------- */
    /*                   Public functions                       */
    /* -------------------------------------------------------- */
    // Trigger a refresh for the geo-fences
    // Result available through the observable
    public refreshGeoFences() {
        if (this._isFetching )
            return;
        this._getGeoFences(this.currentSite, this.currentLevel);
        this.initialized = true;
    }

    public initialized:Boolean = false;
    // Delete a geo-fence for this site
    public deleteGeoFence(geoFence: GeoFence) {
        return new Promise((resolve, reject) => {
            // Check the site object
            if (this.currentSite == undefined || this.currentSite.siteId == undefined || geoFence == undefined || geoFence.id == undefined) {
                reject();
            } else {
                // Make a DELETE request to delete the geo-fence
                this.http.delete(`${this.configService.config.network.navizonApiUrl}/sites/${this.currentSite.siteId}/metadata/${geoFence.id}/`).subscribe((res: any) => {
                    // Success
                    // Make sure it was a success
                    if (res != undefined && res.status == "success") {
                        // geo-fence deleted, update our data
                        this._deleteGeoFence(geoFence);
                        resolve(res);
                    } else
                        reject();
                }, (err) => {
                    // Error
                    reject();
                });
            }
        });
    }

    // Save the geo-fence by requesting the server
    public saveGeoFence(newGeoFence: GeoFence) {
        return new Promise((resolve, reject) => {
            // Check the site and geo-fence objects
            if (this.currentSite == undefined || this.currentSite.siteId == undefined || newGeoFence == undefined || newGeoFence.id == undefined) {
                reject();
            } else {
                var newGeoFenceObj = {
                    behavior: newGeoFence.behavior, class: newGeoFence.class, id: newGeoFence.id, lastEdited: newGeoFence.lastEdited,
                    levelId: newGeoFence.levelId, name: newGeoFence.name, type: newGeoFence.type, vertexes: newGeoFence.vertexes
                };
                // Make a PUT request to update the geo-fence
                this.http.put(`${this.configService.config.network.navizonApiUrl}/sites/${this.currentSite.siteId}/metadata/${newGeoFence.id}/`, newGeoFenceObj).subscribe((res: GeoFence) => {
                    // Success
                    // Update the geo-fence in our data
                    this._updateGeoFence(res);
                    resolve(res);
                }, (err) => {
                    // Error
                    reject();
                });
            }
        });
    }

    // Create the geo-fence by requesting the server
    public createGeoFence(newGeoFence: any) {
        return new Promise((resolve, reject) => {
            // Check the site, level and geo-fence objects
            if (this.currentLevel == undefined || this.currentLevel.levelId == undefined ||
                this.currentSite == undefined || this.currentSite.siteId == undefined || newGeoFence == undefined) {
                reject();
            } else {
                // Set the level ID for the new geo-fence
                newGeoFence.levelId = this.currentLevel.levelId;
                // Make a POST request to create the geo-fence
                this.http.post(`${this.configService.config.network.navizonApiUrl}/sites/${this.currentSite.siteId}/metadata/`, newGeoFence, {
                    headers: new HttpHeaders({'Content-Type': 'application/json'})
                }).subscribe((res: GeoFence) => {
                    // Success
                    // Update the geo-fence in our data
                    this._updateGeoFence(res);
                    resolve(res);
                }, (err) => {
                    // Error
                    reject();
                });
            }
        });
    }

    /* -------------------------------------------------------- */
    /*                   Private functions                      */
    /* -------------------------------------------------------- */

    // Get the list of geo-fences for this site
    private _getGeoFences(site: Site, level: Level) {
        var self = this;
        // Check site ID and level ID
        if (site?.siteId == undefined || level?.levelId == undefined) {
            // Error
            //this.alertService.showMessage(ALERT_TYPE.Error, "An error occurred, cannot get the list of geo-fences");
        } else {
            this._isFetching = true;
            // Make a GET request to get the list of geo-fences
            this.http.get(`${this.configService.config.network.navizonApiUrl}/sites/${site.siteId}/levels/${level.levelId}/metadata/`).subscribe((geoFences: GeoFence[]) => {
                // Success
                self._isFetching = false;
                // Save the list of geo-fences
                self._geoFences = geoFences.map(function(e) { return GeoFence.fromData(e); });
                // Update observable
                self.geoFencesSubject.next(self._geoFences);
            }, (err) => {
                // Error
                self._isFetching = false;
                self.alertService.showMessage(ALERT_TYPE.Error, "An error occurred, cannot get the list of geo-fences");
            });
        }
    }

    // Update our data to remove this geo-fence, then notify the observables
    private _deleteGeoFence(deletedGeoFence: GeoFence) {
        if (deletedGeoFence == undefined || deletedGeoFence.id == undefined)
            return;
        // Keep all the geo-fences with a different id to remove the deleted geo-fence
        this._geoFences = this._geoFences.filter(x => x.id != deletedGeoFence.id);
        // Update observable
        this.geoFencesSubject.next(this._geoFences);
    }

    // Update our data with the new geo-fence, then notify the observables
    private _updateGeoFence(newGeoFence: GeoFence) {
        if (newGeoFence == undefined || newGeoFence.id == undefined)
            return;
        var self = this, found = false;
        // Go over all the levels, then update the right one
        this._geoFences.forEach(function(geoFence, index) {
            if (newGeoFence.id == geoFence.id) {
                found = true;
                self._geoFences[index] = newGeoFence;
            }
        });
        // If we didn't find the geo-fence, it means it has to be created
        if (found == false)
            this._geoFences.push(newGeoFence);
        // Update observables
        this.geoFencesSubject.next(this._geoFences);
    }
    private actionSubject = new BehaviorSubject<string>('');
    readonly action$ = this.actionSubject.asObservable();

    public setAction(input: string): void {
      this.actionSubject.next(input);
    }
    public loadedGeoFences : GeoFence[]=[];
    get autocomplete(): Observable<GeoFence[]> {
        const self = this;
        return this.action$.pipe(
            // Taps the emitted value from action stream
            tap((data: string) => {
              //console.log('input:', data)
            }),
            // Wait for 250 ms to allow the user to finish typing
            debounceTime(250),
            // switchMap fires REST based on above input
            switchMap(input => ((!!input && input.trim().length > 0) ? this.geoFences : of([]))
            .pipe(
              // Additional sorting on switchMap output
              map((regions: GeoFence[]) => regions.filter(i=> i.name.toLowerCase().includes(input.toLowerCase())).sort((region1, region2) => region1.name.localeCompare(region2.name))),
              // Taps the final emitted value from inner observable
              tap((data: GeoFence[]) =>{ 
                self.loadedGeoFences = data;
                //console.log('output:', data);
              })
            )),
          );
    }

}