import { Component, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DataSource } from '@angular/cdk/collections';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Observable, of, Subscription } from 'rxjs';
import L from 'leaflet';

import { FloorplanService } from '../../_services/floorplan.service';
import { LeafletService } from '../../_services/leaflet.service';
import { SiteService } from '../../_services/site.service';
import { ALERT_TYPE, AlertService } from '../../_services/alert.service';
import { ResponsiveService } from '../../_services/responsive.service';

import { YesNoDialogComponent } from '../../common/dialogs/yes-no-dialog.component';
import { OkDialogComponent } from '../../common/dialogs/ok-dialog.component';
import { EditFloorplanDialogComponent } from '../../common/dialogs/floorplans/edit-floorplan-dialog.component';
import { UploadImageFloorplanDialogComponent } from '../../common/dialogs/floorplans/uploadImageFloorplan/upload-image-floorplan-dialog.component';

import { Site } from '../../_models/site';
import { Floorplan } from '../../_models/floorplan';
import { MapData } from '../../_models/mapData';

@Component({
    templateUrl: 'floorplan.component.html',
    styleUrls: ['floorplan.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
            state('expanded', style({ height: '*', visibility: 'visible' })),
            transition('expanded <=> collapsed', animate('300ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
        ])
    ]
})
export class FloorplanComponent implements OnDestroy {

    // The current site
    public currentSite: Site;

	// List of all the floorplans for the selected site
	public floorplans: MyCustomDataSource<Floorplan>;

    // Boolean when requesting the server to create a new floorplan
    public showSpinner: Boolean = false;

    /* Datatable variables */
    // The columns to display, and the attributes to use to get the values
    public columnsToDisplay = [
        {display:'Floorplan ID', attribute:'fpId'},
        {display:'Level ID', attribute:'levelId'},
        {display:'Name', attribute:'name'},
        {display:'Description', attribute:'desc'}
    ];
    public columnsAttributes = ['fpId', 'levelId', 'name', 'desc', 'action', 'expandedIcon'];
    // The expanded element (opened with click on row)
    public expandedElement: any;
    public isExpansionDetailRow = (i: number, row: Object) => row.hasOwnProperty('detailRow');

    // List of subs
    private subs: Subscription[] = [];

    constructor(private floorplanService: FloorplanService, public dialog: MatDialog, private alertService: AlertService,
                private leafletService: LeafletService, private siteService: SiteService, private responsiveService: ResponsiveService) {
        // Get the selected site
        this.subs.push(this.siteService.currentSite.subscribe((site: Site) => {
            this.currentSite = site;
        }));
    	// Get the list of floorplans
    	this.subs.push(this.floorplanService.floorplans.subscribe((floorplans) => {
            // Fill our datatable with the data
            if (floorplans != undefined)
                this.floorplans = new MyCustomDataSource(floorplans);
    	}));
    }

    ngOnDestroy() {
        // Clear the subs
        this.subs.forEach(function(sub) { sub.unsubscribe(); });
    }

    // When user wants to create a new floorplan by uploading an image
    createFpWithImage() {
        var self = this;
        // Display the dialog to help user uploading his image
        var dialog = this.dialog.open(UploadImageFloorplanDialogComponent, { panelClass: ['mw-none','w-90','w-sm-80','w-md-80','w-lg-75'], disableClose: true, data: {
            site: this.currentSite
        }});
        dialog.updatePosition({ top: this.responsiveService.getDialogTopPosition() + 'px' });
        // When user answers
        dialog.afterClosed().subscribe(response => {
            if (response != undefined && response.value == true && response.result != undefined)
                self._saveListFloorplans(response.result, self);
            else if (response != undefined && response.error == true)
                // Error
                self.alertService.showMessage(ALERT_TYPE.Error, "An error occurred, cannot create the floorplan");
        });
    }

    // When user wants to delete a floorplan
    deleteFloorplan(floorplan: any) {
        // Check object
        if (floorplan == undefined || floorplan.fpId == undefined || floorplan.levelId == undefined) {
            this.alertService.showMessage(ALERT_TYPE.Error, "An error occurred, cannot delete the floorplan");
            return;
        }
        var self = this;
        // Define the message for the dialog
        var message = "Are you sure you want to delete the floorplan";
        if (floorplan.name != undefined && floorplan.name.length > 0)
            message += " named \"" + floorplan.name + "\"";
        message += " on level " + floorplan.levelId;
        message += "?";
        var dialog = this.dialog.open(YesNoDialogComponent, { panelClass: ['mw-none','w-90','w-sm-80','w-md-50','w-lg-40'], data: {
            title: "Delete floorplan",
            message:  message,
            noBtnLabel: "Cancel",
            yesBtnLabel: "Delete",
            YesIsWarn: true
        }});
        dialog.updatePosition({ top: this.responsiveService.getDialogTopPosition() + 'px' });
        // When user answers
        dialog.afterClosed().subscribe(result => {
            // If true, means user wants to delete the floorplan
            if (result == true) {
                // Show the spinner for this floorplan
                floorplan.showSpinner = true;
                // Need to delete the floorplan
                self.floorplanService.deleteFloorplan(floorplan).then(() => {
                    // Success
                    // Remove the spinner
                    floorplan.showSpinner = undefined;
                    // Show success message
                    self.alertService.showMessage(ALERT_TYPE.Success, "The floorplan has been correctly deleted");
                }, () => {
                    // Error
                    // Remove the spinner
                    floorplan.showSpinner = undefined;
                    // Show error message
                    self.alertService.showMessage(ALERT_TYPE.Error, "An error occurred, cannot delete the floorplan");
                });
            }
        });
    }

    // When user wants to edit a floorplan
    editFloorplan(floorplan: any) {
        // Check object
        if (floorplan == undefined || floorplan.fpId == undefined || this.currentSite == undefined) {
            this.alertService.showMessage(ALERT_TYPE.Error, "An error occurred, cannot edit the floorplan");
            return;
        }
        // Close the row if needed
        this.expandedElement = undefined;
        var self = this;
    
        // Open the edit floorplan dialog
        var dialog = this.dialog.open(EditFloorplanDialogComponent, { panelClass: ['mw-none','w-90','w-sm-80','w-md-60','w-lg-50'], disableClose: true, data: {
            site: this.currentSite,
            floorplan: floorplan
        }});
        dialog.updatePosition({ top: this.responsiveService.getDialogTopPosition() + 'px' });
        // When user answers
        dialog.afterClosed().subscribe(response => {
            if (response != undefined && response.value == true && response.result != undefined)
                self._saveListFloorplans(response.result, floorplan);
            else if (response.error == true)
                // Error
                self.alertService.showMessage(ALERT_TYPE.Error, "An error occurred, cannot update the floorplan");
        });
    }

    // When user wants to toggle a row
    public toggleRow(floorplan) {
        var self = this;
        this.expandedElement = (this.expandedElement == floorplan) ? undefined : floorplan;
        // Draw the map for this floorplan
        this.leafletService.drawMap("map-" + floorplan.fpId, new MapData({
            useFloorplan: floorplan,
            onClick: function(loc) {
                // Draw a marker on the map
                var marker = self.leafletService.drawMarker(loc);
                // Show the popup with coordinates
                var popup = self.leafletService.drawPopup({
                    closeButton: true,
                    autoClose: false,
                    closeOnClick: false,
                    offset: [0, -30]
                }, loc);
                // Display the coordinates
                popup.setContent('<p><span>Lat: </span><b style="user-select: all">' + loc.lat.toFixed(6) + '</b><span style="margin-left: 10px">Lng: </span><b style="user-select: all">' + loc.lng.toFixed(6) + '</b></p>');
                // When popup is closed, delete the marker
                popup.on('remove', function() {
                    // When tooltip is closed
                    self.leafletService.removeFromMap(marker);
                });
                // When user moves the marker, update the popup content
                marker.on('move', function(e) {
                    if (e != undefined && e.latlng != undefined && e.latlng.lat != undefined && e.latlng.lng != undefined) {
                        popup.setLatLng([e.latlng.lat, e.latlng.lng]);
                        popup.setContent('<p><span>Lat: </span><b style="user-select: all">' + e.latlng.lat.toFixed(6) + '</b><span style="margin-left: 10px">Lng: </span><b style="user-select: all">' + e.latlng.lng.toFixed(6) + '</b></p>');
                    }
                });
            }
        }));
    }


    /* ------------------------------------------------------------------------- */
    /* -                           Private functions                           - */
    /* ------------------------------------------------------------------------- */

    // Save this list of floorplans
    // The second parameter is the boolean that we will switch to indicate the request status
    private _saveListFloorplans(floorplans, requestStatusObj?) {
        var self = this;
        if (requestStatusObj == undefined)
            requestStatusObj = {};
        // For each floorplans
        floorplans.forEach(function(floorplanWithKml) {
            // Use the floorplan service to save our floorplan
            if (floorplanWithKml.floorplan == undefined || floorplanWithKml.newKml == undefined)
                self.alertService.showMessage(ALERT_TYPE.Error, "An error occurred, cannot edit the floorplan");
            else {
                // Request the server to update the floorplan
                requestStatusObj.showSpinner = true;
                // If the floorplan has an image, put the blob in the floorplan object
                if (floorplanWithKml.image != undefined)
                    floorplanWithKml.floorplan.image = floorplanWithKml.image;
                self.floorplanService.saveFloorplan(floorplanWithKml.floorplan, floorplanWithKml.newKml).then((newFloorplan: Floorplan) => {
                    // Success
                    requestStatusObj.showSpinner = false;
                    // Show success message
                    self.alertService.showMessage(ALERT_TYPE.Success, "The floorplan has been correctly updated");
                }, (err) => {
                    // Error
                    console.log(err);
                    requestStatusObj.showSpinner = false;
                    var message = "An error occurred, cannot update the floorplan";
                    // Show error message
                    if (err && err.error && err.error.errors && err.error.errors.length > 0 && err.error.errors[0].indexOf('not authorized') != -1) {
                        self.alertService.showMessage(ALERT_TYPE.Error, "Not authorized");
                        message = "You do not have sufficient rights to upload a floorplan.";
                    } else if (err && err.error && err.error.errors && err.error.errors.length > 0 && err.error.errors[0].indexOf('Image size exceeded') != -1) {
                        self.alertService.showMessage(ALERT_TYPE.Error, "Image size too big");
                        message = "The image you have used is too big to create a floorplan, please check the size does not exceed 5MB.";
                    } else
                        self.alertService.showMessage(ALERT_TYPE.Error, message);
                    // Show a dialog
                    self.dialog.open(OkDialogComponent, { panelClass: ['mw-none','w-90','w-sm-80','w-md-50','w-lg-40'], data: {
                        title: "Upload floorplan error",
                        message:  message
                    }});
                });
            }
        });
    }

}


/* ------------------------------------------------------------------------- */
/* -            Custom data source for the expandable table                - */
/* ------------------------------------------------------------------------- */
export class MyCustomDataSource<T = any> extends DataSource<any> {

    data;

    constructor(data) {
        super();
        this.data = data;
    }

    // Connect function called by the table to retrieve one stream containing the data to render
    connect(): Observable<Element[]> {
        const rows = [];
        if (this.data != undefined)
            this.data.forEach(element => rows.push(element, { detailRow: true, element }));
        return of(rows);
    }

    disconnect() { }
}