import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, Validators } from '@angular/forms';

import { ALERT_TYPE, AlertService } from '../../../_services/alert.service';
import { LeafletService } from '../../../_services/leaflet.service';

import { MapData } from '../../../_models/mapData';

@Component({    
        selector: 'edit-floorplan',
        template:  `<h1 mat-dialog-title>Edit floorplan</h1>

                    <div mat-dialog-content>
                      <div class="container py-3">

                        <form [formGroup]="myFormGroup">

                          <div class="w-100 d-flex flex-wrap">

                            <mat-form-field appearance="outline" class="mx-auto" style="width: 260px" color="accent">
                              <mat-label>Name</mat-label>
                              <input matInput placeholder="Name" formControlName="name">

                              <mat-error *ngIf="myFormGroup.get('name').errors?.maxlength">
                                Name can not be longer than 20 characters
                              </mat-error>

                            </mat-form-field>

                            <mat-form-field appearance="outline" class="mx-auto" style="width: 260px" color="accent">
                              <mat-label>Description</mat-label>
                              <input matInput placeholder="Description" formControlName="desc">
                            </mat-form-field>

                          </div>
                        </form>

                        <div id="map" #map class="mx-auto w-100 noselect" style="height: 350px;"></div>
                      </div>
                    </div>

                    <div mat-dialog-actions>

                      <button mat-raised-button class="ml-auto mr-2 lightColor" (click)="cancel()">Cancel</button>

                      <button mat-raised-button color="accent" class="ml-2 mr-auto" (click)="save()">Save</button>

                    </div>`
})
export class EditFloorplanDialogComponent {

    public myFormGroup = this.fb.group({
      name: ['', [Validators.maxLength(20)]],
      desc: ['']
    });

    constructor(public dialogRef: MatDialogRef<EditFloorplanDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
                private leafletService: LeafletService, private fb: FormBuilder, private alertService: AlertService) {
      // Check we have the data we need
      if (data == undefined || data.site == undefined || data.floorplan == undefined)
          this.dialogRef.close({error: true});
      else {
          // Set the form values if we can
          this.myFormGroup.patchValue(data.floorplan || {});
          // Draw the map for this floorplan
          this.leafletService.drawMap("map", new MapData({
              useFloorplan: data.floorplan,
              editableFloorplan: true,
              showScale: true,
              uniformScaling: true,
              showRuler: true
          }));
      }
    }

    // If user chooses the 'cancel' option
    cancel() {
      this.dialogRef.close({value: false});
    }

    // If user chooses the 'save' option
    save() {
      if (this.myFormGroup.valid == false)
        return;
      var self = this, oneFpTransformed: Boolean = false;
      // Get the layers (with their floorplans) from the map service
      this.leafletService.getEditedFloorplans().then((layers: any[]) => {
          // Check if at least one floorplan has been touched
          layers.forEach(function(fp) {
              if (fp.hasBeenTransformed == true)
                  oneFpTransformed = true;
          });
          // Nothing has been touched, just cancel
          if (oneFpTransformed == false && this.myFormGroup.dirty == false)
              this.dialogRef.close({value: false});
          // Just the floorplan on the map has been touched, directly return the new floorplans
          else if (oneFpTransformed == true && this.myFormGroup.dirty == false)
              this.dialogRef.close({value: true, result: layers});
          // Else, the form has been touched, need to update our floorplan before the incoming save
          else {
              var ourFp = layers.filter((x) => x.floorplan != undefined && x.floorplan.fpId == this.data.floorplan.fpId);
              if (ourFp != undefined && ourFp.length > 0)
                  ourFp[0].floorplan = {...ourFp[0].floorplan, ...this.myFormGroup.value};
              this.dialogRef.close({value: true, result: layers});
          }
      }, (err) => {
          this.alertService.showMessage(ALERT_TYPE.Error, "An error occurred, cannot get the floorplan data");
      });
    }
}