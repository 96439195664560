import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    constructor() {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
        	// Try to find an error message
        	if (err != undefined && err.error != undefined && typeof(err.error) == 'string')
        		return throwError(err.error);
        	if (err != undefined && err.error != undefined && err.error.message != undefined && typeof(err.error.message) == 'string')
        		return throwError(err.error.message);
        	if (err != undefined && err.statusText != undefined && typeof(err.statusText) == 'string')
        		return throwError(err.statusText);
        	// Else, just display a generic error
        	return throwError("Unknown error");
        }))
    }
}