import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';

import { ConfigService } from './config.service';

import { Site } from '../_models/site';
import { Account } from '../_models/account';

@Injectable({ providedIn: 'root' })
export class SiteService {

	private sitesSubject: BehaviorSubject<Site[]>;
    public sites: Observable<Site[]>;
    private _sites: Site[];

	private currentSiteSubject: BehaviorSubject<Site>;
    public currentSite: Observable<Site>;

    private accountsSubject: BehaviorSubject<Account[]>;
    public accounts: Observable<Account[]>;
    private _accounts: Account[];

    public accountAccess = [
        { value: '10', label: 'Read Only' },
        { value: '20', label: 'Read-Write' },
        { value: '30', label: 'Full Control' },
        { value: 'superuser', label: 'Superuser' }
    ];

    public siteType = [
        { value: 'trial', label: 'Free evaluation' },
        { value: 'trial_eu', label: 'Free evaluation (End user)' },
        { value: 'trial_si', label: 'Free evaluation (System integration)' },
        { value: 'navizon', label: 'Internal' },
        { value: 'regular', label: 'Paying - Monthly' },
        { value: 'regular_annual', label: 'Paying - Annual' },
        { value: 'educational', label: 'Educational' },
        { value: 'development', label: 'Development' },
        { value: 'standalone', label: 'Standalone' },
        { value: 'pre_production', label: 'Pre-production' }
    ];

    constructor(private http: HttpClient, private configService: ConfigService) {
    	this.currentSiteSubject = new BehaviorSubject<Site>(undefined);
        this.currentSite = this.currentSiteSubject.asObservable();
        this.sitesSubject = new BehaviorSubject<Site[]>(undefined);
        this.sites = this.sitesSubject.asObservable();
        this.accountsSubject = new BehaviorSubject<Account[]>(undefined);
        this.accounts = this.accountsSubject.asObservable();
    }


    /* -------------------------------------------------------- */
    /*                    Public functions                      */
    /* -------------------------------------------------------- */

    public loadSites(sites: Site[]) {
        // Save the list of sites
    	this._sites = sites;
        // Update observable
    	this.sitesSubject.next(sites);
    	// Get saved siteId from local storage, else use default 1001
    	var siteId = localStorage.getItem('currentSiteId') || '1001';
    	// Select the site
    	return this.selectSite(siteId);
    }

    // Select the site with the correct id, then update observable
    public selectSite(siteId: string) {
        var foundSite = this._sites.find(x => x.siteId == siteId);
        // Check if we found it, else try to use the 1001
        if (foundSite == undefined)
            foundSite = this._sites.find(x => x.siteId == '1001');
    	// Check if we found it, else use the first one as default
    	if (foundSite == undefined && this._sites.length > 0)
    		foundSite = this._sites[0];
    	// Update the local storage
    	if (foundSite != undefined)
    		localStorage.setItem('currentSiteId', foundSite.siteId);
    	// Notify observable
    	this.currentSiteSubject.next(foundSite);
        // Load accounts
        this._loadAccounts(foundSite);
        return foundSite;
    }

    // Save the site by requesting the server
    public saveSite(newSite: Site) {
        return new Promise((resolve, reject) => {
            // Check the site object
            if (newSite == undefined || newSite.siteId == undefined) {
                reject();
            } else {
                // Create the form data object
                var formData = new HttpParams();
                for (var key in newSite)
                    formData = formData.set(key, newSite[key]);
                // Make a PUT request to update the site
                this.http.put(`${this.configService.config.network.navizonApiUrl}/sites/${newSite.siteId}/`, formData.toString()).subscribe((res: Site) => {
                    // Success
                    // Update the site in our data
                    this._updateSite(res);
                    resolve(res);
                }, (err) => {
                    // Error
                    reject();
                });
            }
        });
    }

    // Save the admin section of the site by requesting the server
    public saveAdminSite(newSite: any, product = undefined) {
        return new Promise((resolve, reject) => {
            // Check the site object
            if (newSite == undefined || newSite.siteId == undefined) {
                reject();
            } else {
                // Create the form data object
                var formData = new HttpParams();
                for (var key in newSite)
                    formData = formData.set(key, newSite[key]);
                // Make a PUT request to update the site
                var url = `${this.configService.config.network.navizonAdminApiUrl}/sites/${newSite.siteId}/admin/`;
                if (product)
                    url += product + '/';
                this.http.put(url, formData.toString()).subscribe((res: Site) => {
                    // Success
                    // Update the site in our data
                    this._updateSite(res);
                    resolve(res);
                }, (err) => {
                    // Error
                    reject();
                });
            }
        });
    }

    // Change the user's password
    public changePassword(user: string, newPassword: string) {
        return new Promise((resolve, reject) => {
            // Check the user and password
            if (user == undefined || newPassword == undefined || newPassword.length <= 5) {
                reject();
            } else {
                // Create the form data object
                var formData = new HttpParams();
                formData = formData.set("password", newPassword);
                // Make a PUT request to update the site
                this.http.put(`${this.configService.config.network.navizonApiUrl}/users/${user}/`, formData.toString()).subscribe((res: any) => {
                    // Success
                    // Check status
                    if (res != undefined && res.status == "success")
                        resolve();
                    else
                        reject();
                }, (err) => {
                    // Error
                    reject();
                });
            }
        });
    }

    // Delete the account by requesting the server
    public deleteAccount(site: Site, username: string) {
        return new Promise((resolve, reject) => {
            // Check the site object
            if (site == undefined || site.siteId == undefined || username == undefined) {
                reject();
            } else {
                // Make a DELETE request to delete the account
                this.http.delete(`${this.configService.config.network.navizonApiUrl}/sites/${site.siteId}/accounts/${username}/`).subscribe((res: any) => {
                    // Success
                    // Update the account in our data
                    this._loadAccounts(site);
                    if (res != undefined && res.status == "success")
                        resolve();
                    else
                        reject();
                }, (err) => {
                    // Error
                    reject();
                });
            }
        });
    }

    // Edit/create an account by requesting the server
    public editOrCreateAccount(site: Site, username: string, access: number) {
        return new Promise((resolve, reject) => {
            // Check the site object
            if (site == undefined || site.siteId == undefined || username == undefined || access == undefined) {
                reject();
            } else {
                // Create the form data object
                var formData = new HttpParams();
                formData = formData.set("access", access.toString());
                // Make a PUT request to update/create the account
                this.http.put(`${this.configService.config.network.navizonApiUrl}/sites/${site.siteId}/accounts/${username}/`, formData.toString()).subscribe((res: Account) => {
                    // Success
                    // Update the account in our data
                    this._loadAccounts(site);
                    resolve(res);
                }, (err) => {
                    // Error
                    reject();
                });
            }
        });
    }

    // Freeze the site by requesting the server
    public freezeSite(site: Site) {
        return new Promise((resolve, reject) => {
            // Check the site object
            if (site == undefined || site.siteId == undefined) {
                reject();
            } else {
                // Prepare form data
                const formData = 'cmd=freeze';
                // Request server
                this.http.put(`${this.configService.config.network.navizonAdminApiUrl}/sites/${site.siteId}/admin/`, formData).subscribe((res) => {
                    resolve(res);
                }, (err) => {
                    console.log(err);
                    reject(err);
                });
            }
        });
    }
    // Unfreeze the site by requesting the server
    public unfreezeSite(site: Site) {
        return new Promise((resolve, reject) => {
            // Check the site object
            if (site == undefined || site.siteId == undefined) {
                reject();
            } else {
                // Prepare form data
                const formData = 'cmd=unfreeze';
                // Request server
                this.http.put(`${this.configService.config.network.navizonAdminApiUrl}/sites/${site.siteId}/admin/`, formData).subscribe((res) => {
                    resolve(res);
                }, (err) => {
                    console.log(err);
                    reject(err);
                });
            }
        });
    }

    // Send a request to the server to send a reset password email
    public sendResetPasswordEmail(email) {
        return new Promise((resolve, reject) => {
            // Check the site object
            if (email == undefined) {
                reject();
            } else {
                // Prepare form data
                const formData = 'email=' + email;
                // Request server
                this.http.put(`${this.configService.config.network.navizonAdminApiUrl}/resetPassword/`, formData, {
                    headers : new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' }),
                    responseType: 'text'
                })
                .subscribe((res) => {
                    resolve(res);
                }, (err) => {
                    try {
                        var response = JSON.parse(err);
                        reject(response);
                    } catch(e) {
                        reject(err);
                    }
                });
            }
        });
    }

    // Send a request to change the password for this account
    public resetPassword(email, token, newPassword) {
        return new Promise((resolve, reject) => {
            // Check the site object
            if (email == undefined || token == undefined || newPassword == undefined) {
                reject();
            } else {
                // Create the form data object
                var formData = new HttpParams();
                formData = formData.set("email", email);
                formData = formData.set("token", token);
                formData = formData.set("password", newPassword);
                // Request server
                this.http.put(`${this.configService.config.network.navizonAdminApiUrl}/newPassword/`, formData.toString(), {
                    headers : new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' }),
                    responseType: 'text'
                })
                .subscribe((res) => {
                    resolve(res);
                }, (err) => {
                    try {
                        var response = JSON.parse(err);
                        reject(response);
                    } catch(e) {
                        reject(err);
                    }
                });
            }
        });
    }

    /* -------------------------------------------------------- */
    /*                    Private functions                     */
    /* -------------------------------------------------------- */

    // Update our data with the new site, then notify the observables
    private _updateSite(newSite: Site) {
        if (newSite == undefined || newSite.siteId == undefined)
            return;
        var self = this;
        // Go over all the sites, then update the right one
        this._sites.forEach(function(site, index) {
            if (newSite.siteId == site.siteId)
                self._sites[index] = newSite;
        });
        // Update observables
        this.sitesSubject.next(this._sites);
        this.currentSiteSubject.next(newSite);
        // Load accounts
        this._loadAccounts(newSite);
    }

    // Load the accounts for this site, then notify the observable
    private _loadAccounts(site: Site) {
        if (site == undefined || site.siteId == undefined)
            return;
        // Make a GET request to load the accounts
        this.http.get(`${this.configService.config.network.navizonApiUrl}/sites/${site.siteId}/accounts/`).subscribe((accounts: any) => {
            // Success
            var resAccounts = [];
            accounts.forEach(function(acc) {
                if (acc != undefined && acc.username != undefined && acc.access != undefined)
                    resAccounts.push(new Account(acc.username, acc.access));
            });
            this.accountsSubject.next(resAccounts);
        }, (err) => {
            // Error
            this.accountsSubject.next([]);
        });
    }
}