import { Injectable } from '@angular/core';
import { HttpClient, HttpBackend, HttpHeaders } from '@angular/common/http';
import * as xml2js from 'xml2js';

import { Level } from '../_models/level';

import { ConfigService } from './config.service';

const MAC_REGEX = new RegExp(/^([0-9a-f]{2}([:-]|$)){6}$|([0-9a-f]{4}([.]|$)){3}$|([0-9a-f]{12}$)/gi);
const PSEUDO_MAC_REGEX = new RegExp(/^([0-9a-z]{2}([:-]|$)){6}$|([0-9a-z]{4}([.]|$)){3}$|([0-9a-z]{12}$)/gi);

@Injectable({ providedIn: 'root' })
export class UtilsService {

    // Our personne http client (to avoid interceptors)
    private http: HttpClient;

    constructor(private handler: HttpBackend, private configService: ConfigService) {
        this.http = new HttpClient(handler);
    }

    // Converts from degrees to radians.
	public radiansFromDegrees(degrees) {
		return degrees * Math.PI / 180;
	};
	// Converts from radians to degrees.
	public degreesFromRadians(radians) {
		return radians * 180 / Math.PI;
	};

    // Parse an address (eg. 48.8581822,2.3059838), and return a tuple with the coordinates
    // or undefined if parsing fails
    public getCoordinateFromAddress(address: string) : [number, number] {
    	// Regexp the address
		let reg = /(.+),(.+)/g;
		let groups = reg.exec(address);
		if (groups != undefined && groups.length > 2)
			return [parseFloat(groups[1]), parseFloat(groups[2])];
		// If cannot get the groups, return undefined
		return undefined;
    }

    // Return true if the object is located on the current level passed in parameter
    public isLocatedOnLevel(object, currentLevel: Level) {
        return (object != undefined && currentLevel != undefined &&
                ((object.loc != undefined && object.loc.levelId == currentLevel.levelId) ||
                 (object.position != undefined && object.position.levelId == currentLevel.levelId) || 
                 (object.levelId != undefined && object.levelId == currentLevel.levelId)));
    }













    /* ------------------------------------------------------------------------- */
    /* -                           Format functions                            - */
    /* ------------------------------------------------------------------------- */

    public formatTimeApprox(msec) {
        var days, hours, min, sec;
        if (msec < 0)
            msec = 0;
        days = Math.floor(msec / (24*60*60*1000));
        msec -= days * 24*60*60*1000;
        hours =  Math.floor(msec / (60*60*1000));
        msec -= hours *  60*60*1000;
        min = Math.floor(msec / (60*1000));
        msec -= min * 60*1000;
        if (days > 1)
            return days + ' days';
        else if (days > 0)
            return '1 day and ' + hours + ' hours'
        else if (hours > 0)
            return hours + ' hours';
        else if (min > 0)
            return min + ' minutes';
        else
            return 'less than a minute';
    }

    public formatTimeFull(msec) {
        var days, hours, min, sec, res = "";
        if (msec < 0)
            msec = 0;
        days = Math.floor(msec / (24*60*60*1000));
        msec -= days * 24*60*60*1000;
        hours =  Math.floor(msec / (60*60*1000));
        msec -= hours *  60*60*1000;
        min = Math.floor(msec / (60*1000));
        msec -= min * 60*1000;
        sec = Math.floor(msec / 1000);
        if (days > 1)
            res += days + " days ";
        else if (days == 1)
            res += days + " day ";
        if (hours > 0)
            res += hours + "h ";
        if (min > 0)
            res += min + "m ";
        if (sec >= 0 && days <= 0)
            res += sec + "s ";
        if (res.length > 0)
            res += "ago";
        return res;
    }

    public formatTimeSimplify(msec) {
        var hours, min, sec, res = "";
        if (msec < 0)
            msec = 0;
        if (msec > 86400000)
            msec = 86400000;
        hours =  Math.floor(msec / (60*60*1000));
        msec -= hours * 60*60*1000;
        min = Math.floor(msec / (60*1000));
        msec -= min * 60*1000;
        sec = Math.floor(msec / 1000);
        if (hours >= 0 && hours < 10)
            res += "0" + hours + "h";
        else
            res += hours + "h"
        if (min >= 0 && min < 10)
            res += "0" + min;
        else
            res += min;
        // if (sec > 0)
        //     res += sec + "s ";
        return res;
    }













    /* ------------------------------------------------------------------------- */
    /* -                            MAC functions                              - */
    /* ------------------------------------------------------------------------- */

    // Accepts MAC addresses in four formats 
    // Valid formats (not case sensitive)
    //      1: 0072CF21EB14
    //      2: 00-72-CF-21-EB-14
    //      3: 00:72:CF:21:EB:14
    //      4: 0072.CF21.EB14 
    private _validateMac(mac) {
        var re, m;
        if (!mac || mac.length === 0)
            return null;
        if (mac[0] === 'u' || mac[0] === 'U' || mac[0] === 'w' || mac[0] === 'W' || mac[0] === 'z' || mac[0] === 'Z') {
            // pseudo-mac
            PSEUDO_MAC_REGEX.lastIndex = 0;   // reset regular expression matcher
            m = PSEUDO_MAC_REGEX.exec(mac);
        } else {
            // regular mac
            MAC_REGEX.lastIndex = 0; // reset regular expression matcher
            m = MAC_REGEX.exec(mac);
        }
        // If invalid MAC
        if (m === null || m.index !== 0)
            return null;
        // Else valid MAC - Remove non alphanumeric characters (".", ":", "-") and convert to uppercase
        else
            return mac.replace(/\.|:|\-/g,'').toUpperCase();
    }

    public formatMac(mac, separator) {
        var vmac = this._validateMac(mac);
        var sep = separator || ':';
        if (vmac != null) {
            vmac =  vmac[0] + vmac[1] + sep + vmac[2] + vmac[3] + sep +
                    vmac[4] + vmac[5] + sep + vmac[6] + vmac[7] + sep +
                    vmac[8] + vmac[9] + sep + vmac[10] + vmac[11];
            return vmac;
        } 
        return "";
    }















    /* ------------------------------------------------------------------------- */
    /* -                            Image functions                            - */
    /* ------------------------------------------------------------------------- */
    // Return the dimensions for this image (base64)
    public getImageDimensionsFromBase64(imageBase64) {
    	return new Promise((resolve, reject) => {
    		// Create an image object
    		var i = new Image();
    		// When the image is loaded
    		i.onload = function() {
    			if (i != undefined && i.width != undefined && i.height != undefined)
    				resolve([i.width, i.height]);
				else
					reject();
			};
			i.src = imageBase64;
    	});
    }

    // Convert a base64 data image into a blob
    public b64toBlob(b64Data: string, contentType?: string, sliceSize?: number) {
        // If contentType is undefined, we have to get it from the base64 data
        if (contentType == undefined) {
            var block = b64Data.split(";");
            if (block == undefined || block.length <= 1)
                return undefined;
            var contentTypeArray = block[0].split(":");
            if (contentTypeArray == undefined || contentTypeArray.length <= 1)
                return undefined;
            contentType = contentTypeArray[1];
            var realDataArray = block[1].split(",");
            if (realDataArray != undefined && realDataArray.length <= 1)
                return undefined;
            b64Data = realDataArray[1];
        }
        if (b64Data == undefined || contentType == undefined)
            return undefined;
        sliceSize = sliceSize || 512;
        var byteCharacters = atob(b64Data);
        var byteArrays = [];
        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            var slice = byteCharacters.slice(offset, offset + sliceSize);
            var byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++)
                byteNumbers[i] = slice.charCodeAt(i);
            var byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        var blob = new Blob(byteArrays, {type: contentType});
        return blob;
    }















    /* ------------------------------------------------------------------------- */
    /* -                            KML functions                              - */
    /* ------------------------------------------------------------------------- */
    // Return a new empty KML object (to create a new floorplan)
    public getNewFloorplanKml() {
        return new Promise((resolve, reject) => {
            var kmlStr = '<?xml version="1.0" encoding="UTF-8" standalone="no"?><kml xmlns:kml="http://www.opengis.net/kml/2.2" xmlns="http://www.opengis.net/kml/2.2" xmlns:atom="http://www.w3.org/2005/Atom" xmlns:gx="http://www.google.com/kml/ext/2.2"><GroundOverlay><name>Untitled Image Overlay</name><Icon><href></href><viewBoundScale>0.75</viewBoundScale></Icon><LatLonBox><north>0</north><south>0</south><east>0</east><west>0</west><rotation>0</rotation></LatLonBox></GroundOverlay></kml>';
            this.stringToKml(kmlStr).then((res) => {
                resolve(res);
            }, (err) => {
                reject({});
            });
        });
    }

    // Request the URL to get the KML content and returns an object
    public getKmlContentFromUrl(kmlUrl: string) {
        return new Promise((resolve, reject) => {
            this.http.get(kmlUrl, { responseType: 'text' }).subscribe((kmlContent) => {
                this.stringToKml(kmlContent).then((res) => {
                    resolve(res);
                }, (err) => {
                    reject({});
                });
            }, (err) => {
                reject({});
            });
        });
    }

    // Get a KML string and returns it as an object
    public stringToKml(kmlStr) {
        return new Promise((resolve, reject) => {
            // Check the content
            if (kmlStr != undefined) {
                // Parse the XML string into a JS object
                xml2js.parseString(kmlStr, {trim: true, explicitArray: false}, function (err, result) {
                    if (err == undefined && result != undefined)
                        resolve(result);
                    else
                        reject({});
                });
            } else
                reject({});
        });
    }

    // Get a KML object and returns it as a string
    public kmlToString(kml) {
        var builder = new xml2js.Builder();
        return builder.buildObject(kml);
    }














    /* ------------------------------------------------------------------------- */
    /* -                           Image functions                             - */
    /* ------------------------------------------------------------------------- */

    // Transform an arraybuffer into a base64 object
    public arrayBufferToBase64(buffer) {
        var binary = '';
        var bytes = new Uint8Array(buffer);
        var len = bytes.byteLength;
        for (var i = 0; i < len; i++)
            binary += String.fromCharCode(bytes[i]);
        return window.btoa(binary);
    }

}