<h1 class="text-center mb-4">Floorplans</h1>

<!-- Spinner -->
<mat-spinner *ngIf="floorplans == undefined" class="mx-auto" color="accent"></mat-spinner>

<!-- Create new floorplan button -->
<div class="d-flex w-100 pb-4" *ngIf="floorplans != undefined">
	<!-- Button (desktop only) -->
	<button mat-raised-button *ngIf="showSpinner == false" color="accent" class="d-none d-md-block mx-auto" [matMenuTriggerFor]="createFpMenu">Create new floorplan</button>
	<!-- Button (mobile only) -->
	<button mat-mini-fab color="accent" *ngIf="showSpinner == false" class="fixedBottomRight d-block d-md-none" [matMenuTriggerFor]="createFpMenu">
		<mat-icon aria-label="Create new floorplan">add</mat-icon>
	</button>
	<!-- Menu -->
	<mat-menu #createFpMenu="matMenu">
		<button mat-menu-item (click)="createFpWithImage()">By uploading an image</button>
	</mat-menu>
	<!-- Spinner -->
	<mat-spinner *ngIf="showSpinner == true" class="mx-auto" color="accent" [diameter]="36"></mat-spinner>
</div>

<!-- Data table -->
<div class="container d-flex flex-column" *ngIf="floorplans != undefined">

	<mat-table [dataSource]="floorplans" class="border w-100 responsive-datatable">

		<!-- Columns -->
		<ng-container matColumnDef="{{column.attribute}}" *ngFor="let column of columnsToDisplay">
			<mat-header-cell *matHeaderCellDef class="justify-content-center">
				{{column.display}}
			</mat-header-cell>
			<mat-cell *matCellDef="let element">
				<span class="mobile-label pr-2">{{column.display}}:</span>
				<div class="mx-auto">{{element[column.attribute]}}</div>
			</mat-cell>
		</ng-container>

		<!-- Expanded Content Column -->
		<ng-container matColumnDef="expandedDetail">
			<mat-header-cell *matHeaderCellDef></mat-header-cell>
			<mat-cell *matCellDef="let detail" class="w-100"> 

				<!-- Map -->
				<div class="container py-3" *ngIf="detail != undefined && detail.element != undefined && detail.element.fpId != undefined" [ngClass]="{'visible': detail.element == expandedElement, 'invisible': detail.element != expandedElement}">
					<div id="map-{{detail.element.fpId}}" #map class="mx-auto w-100 w-lg-75 w-xl-60 noselect" style="height: 250px;"></div>
				</div>

			</mat-cell>
		</ng-container>

		<!-- Edit/Delete -->
		<ng-container matColumnDef="action">
			<mat-header-cell *matHeaderCellDef></mat-header-cell>
			<mat-cell *matCellDef="let element" class="mx-auto">
				<div class="mx-auto">

					<!-- Edit button -->
					<button *ngIf="element.showSpinner != true" class="mx-1" mat-mini-fab color="accent" matTooltip="Edit" (click)="$event.stopPropagation(); editFloorplan(element)">
						<mat-icon aria-label="Edit">edit</mat-icon>
					</button>
					<!-- Delete button -->
					<button *ngIf="element.showSpinner != true" class="mx-1" mat-mini-fab color="accent" matTooltip="Delete" (click)="$event.stopPropagation(); deleteFloorplan(element)">
						<mat-icon aria-label="Delete">delete</mat-icon>
					</button>

					<!-- Spinner -->
					<mat-spinner *ngIf="element.showSpinner == true" color="accent" [diameter]="36"></mat-spinner>

				</div>
			</mat-cell>
		</ng-container>

		<!-- Expanded icon -->
		<ng-container matColumnDef="expandedIcon">
			<mat-header-cell *matHeaderCellDef></mat-header-cell>
			<mat-cell *matCellDef="let element" class="mx-auto"> 
				<mat-icon *ngIf="element != expandedElement" class="mx-auto">expand_more</mat-icon>
				<mat-icon *ngIf="element == expandedElement" class="mx-auto">expand_less</mat-icon>
			</mat-cell>
		</ng-container>

		<mat-header-row *matHeaderRowDef="columnsAttributes"></mat-header-row>


		<mat-row *matRowDef="let row; columns: columnsAttributes;" matRipple class="element-row" [class.expanded]="expandedElement == row" (click)="toggleRow(row)"></mat-row>
		<mat-row *matRowDef="let row; columns: ['expandedDetail']; when: isExpansionDetailRow" [@detailExpand]="row.element == expandedElement ? 'expanded' : 'collapsed'" style="overflow: hidden" class="border-0"></mat-row>

	</mat-table>

</div>