<!-- Normal dashboard navbar -->
<mat-toolbar id="mainMatToolbar" color="primary" class="mat-elevation-z6" *ngIf="disableMenu != 'true'">
    <!-- Logo & title -->
    <a *ngIf="logoUrl == undefined" class="navbar-brand" [routerLink]="['/']">
        <img src="./assets/img/onit-logo.png" class="mr-2" id="accuwareLogo">
    </a>
    <a *ngIf="logoUrl != undefined" class="navbar-brand" [routerLink]="['/']">
        <img [src]="logoUrl" class="mr-2" id="accuwareLogo">
    </a>
    <!-- Desktop links -->
    <!-- When user is connected -->
    <div class="d-none d-lg-flex w-100" *ngIf="currentUser != undefined">
        <!-- General menu button
        <button mat-button class="text-white ml-2" [routerLink]="['/general']">General</button> -->
        <button mat-flat-button [color]="isSelectedCategory('general')" class="text-white ml-2" (click)="openSubMenu('general')">General</button>
        <!-- Admin menu button  [routerLink]="['/admin']"-->
        <button mat-button [color]="isSelectedCategory('admin')" class="text-white ml-2" (click)="openSubMenu('admin')" *ngIf="currentUser && currentUser.isSuperuser == true">Admin</button>
        <!-- Dragonfly menu button [routerLink]="['/dragonfly']"-->
        <button mat-flat-button [color]="isSelectedCategory('dragonfly')" class="text-white ml-2" (click)="openSubMenu('dragonfly')">Dragonfly</button>
        <!-- Site button -->
        <button mat-stroked-button *ngIf="currentSite != undefined" class="ml-auto text-white" (click)="openSiteSelector()">Site {{currentSite.siteId}}</button>
        <!-- Level button -->
        <button mat-stroked-button *ngIf="currentLevel != undefined" class="ml-2 text-white" [matMenuTriggerFor]="levelSelectorMenu">Level {{currentLevel.levelId}}</button>
        <!-- Logout button -->
        <button mat-flat-button color="warn" class="ml-2" (click)="logout()">Logout</button>
    </div>
    <!-- When user is not connected -->
    <div class="d-none d-lg-flex w-100" *ngIf="currentUser == undefined">
        <!-- Login button -->
        <button mat-button class="ml-auto" [routerLink]="['/login']">Login</button>
    </div>
    <!-- Mobile menu button -->
    <div class="d-flex d-lg-none flex-row-reverse w-100">
        <!-- Open mobile menu button -->
        <button class="" mat-icon-button (click)="openMainMenu()">
            <mat-icon>menu</mat-icon>
        </button>
        <!-- Level button -->
        <button *ngIf="currentLevel != undefined && currentUser != undefined" mat-stroked-button class="mr-2 text-white" [matMenuTriggerFor]="levelSelectorMenu">Level {{currentLevel.levelId}}</button>
    </div>
    <!-- Level selector menu -->
    <mat-menu #levelSelectorMenu="matMenu">
        <button mat-menu-item *ngFor="let level of levels" (click)="selectLevel(level)">
            L{{level.levelId}}
            <span *ngIf="level.name != undefined && level.name != ''">, {{level.name}}</span>
        </button>
    </mat-menu>
</mat-toolbar>


<!-- Main content -->
<mat-sidenav-container [ngClass]="{'mainMatSidenavContainer': disableMenu != 'true'}">

    <!-- Normal dashboard -->
    <mat-sidenav #sidenav [fixedInViewport]="true" style="min-width: 220px" [autoFocus]="false">
        <!-- When user is connected -->
        <div *ngIf="categories.length > 0 && currentUser != undefined" class="d-flex flex-column h-100">
            <button  mat-flat-button *ngFor="let category of categories" [matTooltip]="category.description" [color]="isSelectedMenu(category.routerLink)" class="w-100 rounded-0 text-left py-1" [routerLink]="category.routerLink"  (click)="sidenav.close()">
                {{category.title}}
            </button>
        </div>
        <div *ngIf="categories.length == 0 && currentUser != undefined" class="d-flex flex-column h-100">
            <!-- Home button -->
            <button mat-button class="w-100 rounded-0 text-left py-1" [routerLink]="['/']" (click)="sidenav.close()">
                Home
            </button>
            <!-- General button -->
            <button mat-flat-button [color]="isSelectedCategory('general',true)" class="w-100 rounded-0 text-left py-1"  (click)="openSubMenu('general')">
                General
            </button>
            <!-- Admin menu button -->
            <button mat-flat-button [color]="isSelectedCategory('admin',true)" class="w-100 rounded-0 text-left py-1"  (click)="openSubMenu('admin')" *ngIf="currentUser && currentUser.isSuperuser == true">
                Admin
            </button>
            <!-- Dragonfly button -->
            <button mat-flat-button [color]="isSelectedCategory('dragonfly',true)" class="w-100 rounded-0 text-left py-1"  (click)="openSubMenu('dragonfly')">
                Dragonfly
            </button>
            <div class="mt-auto">
                <!-- Site button -->
                <div class="d-flex">
                    <button id="mobileSiteBtn" mat-flat-button *ngIf="currentSite != undefined" class="rounded-0 text-left py-1" (click)="openSiteSelector(sidenav)">Site {{currentSite.siteId}}</button>
                </div>
                <!-- Logout button -->
                <button mat-flat-button color="warn" class="w-100 rounded-0 text-left py-1" (click)="logout(); sidenav.close()">Logout</button>
            </div>
        </div>
        <!-- When user is not connected -->
        <div *ngIf="currentUser == undefined">
            <!-- Login button -->
            <button mat-button class="w-100 rounded-0 text-left py-1" [routerLink]="['/login']" (click)="sidenav.close()">Login</button>
        </div>
    </mat-sidenav>

    <mat-sidenav-content id="mainDiv">
        <router-outlet></router-outlet>
    </mat-sidenav-content>

</mat-sidenav-container>