import { Component, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { SiteService } from '../../../../_services/site.service';

import { Site } from '../../../../_models/site';

@Component({
	selector: 'site-selector-dialog',
	templateUrl: './site-selector-dialog.html',
	styles: ['i#closeButton { float: right;top: 2px; }']
})
export class SiteSelectorDialogComponent implements OnDestroy {

	private sites: Site[];

	// Site selector variables
	siteSelectorControl = new FormControl();
	filteredOptions: Observable<Site[]>;

	// List of subs
    private subs: Subscription[] = [];

	constructor(public dialogRef: MatDialogRef<SiteSelectorDialogComponent>, private siteService: SiteService) {
		// Get the list of sites
		this.subs.push(this.siteService.sites.subscribe(sites => {
			this.sites = sites;
			// Create the filtered options for the site selector if needed
			if (this.filteredOptions == undefined && this.sites != undefined) {
				this.filteredOptions = this.siteSelectorControl.valueChanges.pipe(
					startWith(''),
					map(value => this._filter(value))
				);
			}
		}));
	}

	ngOnDestroy() {
        // Clear the subs
        this.subs.forEach(function(sub) { sub.unsubscribe(); });
    }

	onSiteSelected(event) {
		// Try to get the siteId
		if (event != undefined && event.option != undefined && event.option.value != undefined)
			this.siteService.selectSite(event.option.value);
		// Reset the input
		this.siteSelectorControl.reset('');
		// Close the dialog
		this.closeDialog();
	}

	private _filter(value: string): Site[] {
		const filterValue = value.toLowerCase();
		return this.sites.filter(site => {
			return (site.siteId && site.siteId.toLowerCase().indexOf(filterValue) === 0) ||
				   (site.company && site.company.toLowerCase().indexOf(filterValue) === 0) ||
				   (site.desc && site.desc.toLowerCase().indexOf(filterValue) === 0) ||
				   (site.name && site.name.toLowerCase().indexOf(filterValue) === 0);
		});
	}

	closeDialog(): void {
		this.dialogRef.close();
	}

}