import { Component, OnDestroy, ViewChild } from '@angular/core';
import { FormBuilder, Validators, FormGroupDirective } from '@angular/forms';
import { Subscription } from 'rxjs';
import { HttpClient, HttpBackend } from '@angular/common/http';

import { ALERT_TYPE, AlertService } from '../../_services/alert.service';
import { LicenseService } from '../../_services/license.service';
import { SiteService } from '../../_services/site.service';

import { Site } from '../../_models/site';

@Component({
	templateUrl: 'create-new-license.component.html',
	styleUrls: ['create-new-license.component.scss']
})
export class DragonflyCreateNewLicenseComponent implements OnDestroy {

	public currentDate = new Date();

	// Boolean if the password is hidden
	public hide = true;
	// Boolean when creating the license
	public isCreatingLicense = false;

	// The current site
	public currentSite: Site;
	// List of all the sites
	public allSites: Site[];

	// A new HttpClient to avoid the interceptor (for the multipart/form-data POST request)
    private http2: HttpClient;

	// List of subs
	private subs: Subscription[] = [];

	// Create new license form
	createNewLicenseForm = this.fb.group({
		username: ['', [Validators.required]],
		password: ['', [Validators.required, Validators.minLength(6)]],
		siteId: ['', [Validators.required]],
		company: ['', [Validators.required]],
		max_instances: ['', [Validators.required, Validators.min(0)]],
		license_end_timestamp: ['', [Validators.required]]
    });

    @ViewChild(FormGroupDirective) myForm;

    constructor(private licenseService: LicenseService, private siteService: SiteService, private fb: FormBuilder,
    			private handler: HttpBackend, private alertService: AlertService) {
    	this.http2 = new HttpClient(handler);
    	// Get the selected site
		this.subs.push(this.siteService.currentSite.subscribe((site: Site) => {
			this.currentSite = site;
		}));
		// Get the list of all the sites
		this.subs.push(this.siteService.sites.subscribe((sites: Site[]) => {
			this.allSites = sites;
		}));
    }

    ngOnDestroy() {
    	// Clear the subs
    	this.subs.forEach(function(sub) { sub.unsubscribe(); });
	}

	onSubmit() {
		var self = this;
		this.isCreatingLicense = true;
		// Get the values
		var licenseData = this.createNewLicenseForm.value;
		// Cast the date object to a timestamp
		if (licenseData != undefined && licenseData.license_end_timestamp != undefined && licenseData.license_end_timestamp.getTime != undefined)
			licenseData.license_end_timestamp = licenseData.license_end_timestamp.getTime();
		// Request server to create the license
		this.licenseService.createDragonflyLicense(licenseData, this.http2).then((res) => {
			if (self.myForm)
				self.myForm.resetForm();
			self.alertService.showMessage(ALERT_TYPE.Success, "The Dragonfly license has been successfully created");
			self.isCreatingLicense = false;
		}, (err) => {
			// If user not authorized
			if (err != undefined && err.status == 401)
				self.alertService.showMessage(ALERT_TYPE.Error, "Not authorized, please check credentials");
			else
				self.alertService.showMessage(ALERT_TYPE.Error, "An error occurred, cannot create the Dragonfly license");
			self.isCreatingLicense = false;
		});
	}

}