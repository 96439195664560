import { Component, OnDestroy, OnInit, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { SiteService } from '../../_services/site.service';
import { AuthenticationService } from '../../_services/authentication.service';
import { ALERT_TYPE, AlertService } from '../../_services/alert.service';
import { LeafletService } from '../../_services/leaflet.service';
import { UtilsService } from '../../_services/utils.service';
import { ResponsiveService } from '../../_services/responsive.service';

import { EditAccountDialogComponent } from '../../common/dialogs/accounts/edit-dialog.component';
import { YesNoDialogComponent } from '../../common/dialogs/yes-no-dialog.component';

import { Account } from '../../_models/account';
import { User } from '../../_models/user';
import { Site } from '../../_models/site';

import { Match } from '../../_helpers/match.validator';

@Component({templateUrl: 'account.component.html'})
export class AccountComponent implements OnInit, AfterViewInit, OnDestroy {

	// Boolean when user is changing password
	public isSavingAccount: Boolean = false;
	// Boolean when user is creating a new account
	public isCreatingAccount: Boolean = false;

	// The current site
	public currentSite: Site;

	// The current user
	public currentUser: User;

	// List of all the accounts for the selected site
	public accounts: MatTableDataSource<Account>;

	/* Datatable variables */
	public columnsAttributes = ['username', 'access', 'action'];
	// Use for the datatable sorting
	@ViewChild(MatSort) sort: MatSort;

	// List of subs
    private subs: Subscription[] = [];

	// Account form
	public accountForm = this.fb.group({
		newPassword: ['', [Validators.required, Validators.minLength(6)]],
		confirmPassword: ['', Validators.required]
    }, {
		validator: Match('newPassword', 'confirmPassword')
	});

    constructor(public siteService: SiteService, private fb: FormBuilder, private alertService: AlertService,
    			private router: Router, private leafletService: LeafletService, private utilsService: UtilsService,
    			private authenticationService: AuthenticationService, public dialog: MatDialog, private responsiveService: ResponsiveService) {}

    ngOnInit() {
    	// Get the selected site
		this.subs.push(this.siteService.currentSite.subscribe(site => {
			this.currentSite = site;
		}));
		// Get the connected user
		this.subs.push(this.authenticationService.currentUser.subscribe(user => {
			this.currentUser = user;
		}));
		// Get the accounts
		this.subs.push(this.siteService.accounts.subscribe(accounts => {
            if (accounts != undefined) {
				// Fill our datatable with the data
				this.accounts = new MatTableDataSource(accounts);
				// Try to initialize the sort
				this._initializeSort();
            }
		}));
    }

    ngAfterViewInit() {
    	// Try to initialize the sort
    	this._initializeSort();
    }

	ngOnDestroy() {
		// Clear the subs
        this.subs.forEach(function(sub) { sub.unsubscribe(); });
	}

	// When user saves the new password
	onSubmit() {
		const newPassword = this.accountForm.getRawValue().newPassword;
		// Check the configuration is correct
		if (this.accountForm.invalid || this.accountForm.dirty == false || newPassword.length <= 5) {
			// Error
			return;
		}
		if (this.currentUser == undefined || this.currentUser.username == undefined) {
			// Error
			this.alertService.showMessage(ALERT_TYPE.Error, "An error occurred, cannot change the password");
			return;
		}
		this.isSavingAccount = true;
		// Request the server to change the password
		this.siteService.changePassword(this.currentUser.username, newPassword).then(() => {
			// Success
			this.isSavingAccount = false;
			this.accountForm.reset();
			// Show success message
			this.alertService.showMessage(ALERT_TYPE.Success, "The password has been correctly changed");
		}, () => {
			// Error
			this.isSavingAccount = false;
			this.alertService.showMessage(ALERT_TYPE.Error, "An error occurred, cannot change the password");
		});
	}

	public deleteAccount(account) {
		var self = this;
		// Open dialog
		var dialog = this.dialog.open(YesNoDialogComponent, { panelClass: ['mw-none','w-90','w-sm-80','w-md-50','w-lg-40'], data: {
    		title: "Delete account",
    		message: "Are you sure you want to delete the account: " + account.username,
    		noBtnLabel: "Cancel",
    		yesBtnLabel: "Delete",
    		YesIsWarn: true
    	}});
		dialog.updatePosition({ top: this.responsiveService.getDialogTopPosition() + 'px' });
		// When user answers
		dialog.afterClosed().subscribe(result => {
			// If true, means user wants to delete the account
			if (result == true) {
				// Check the current site
		    	if (self.currentSite == undefined || self.currentSite.siteId == undefined) {
					self.alertService.showMessage(ALERT_TYPE.Error, "An error occurred, cannot delete the account");
		    		return;
		    	}
				// Show the spinner for this account
				account.showSpinner = true;
				// Delete account
				self.siteService.deleteAccount(self.currentSite, account.username).then(() => {
					// Success
					// Remove the spinner
					account.showSpinner = undefined;
					// Show success message
					self.alertService.showMessage(ALERT_TYPE.Success, "The account has been correctly deleted");
				}, () => {
					// Error
					// Remove the spinner
					account.showSpinner = undefined;
					// Show error message
					self.alertService.showMessage(ALERT_TYPE.Error, "An error occurred, cannot delete the account");
				})
			}
		});
	}

	private _initializeSort() {
    	if (this.accounts && this.sort)
	    	this.accounts.sort = this.sort;
    }

    public editAccount(account) {
    	var type = (account == undefined) ? 'create' : 'edit', self = this;
    	// Check the current site
    	if (this.currentSite == undefined || this.currentSite.siteId == undefined) {
    		var msg = (type == 'create') ? "An error occurred, cannot create the account" : "An error occurred, cannot edit the account";
			this.alertService.showMessage(ALERT_TYPE.Error, msg);
    		return;
    	}
    	// Open the dialog
    	var dialog = this.dialog.open(EditAccountDialogComponent, { panelClass: ['mw-none','w-90','w-sm-70','w-md-50','w-lg-40', 'w-xl-30'], disableClose: true, data: {
    		account: account,
    		type: type
    	}});
		dialog.updatePosition({ top: this.responsiveService.getDialogTopPosition() + 'px' });
		// When user answers
		dialog.afterClosed().subscribe((response) => {
			if (response == undefined || response.error != undefined) {
				// Error
				var msg = (type == 'create') ? "An error occurred, cannot create the account" : "An error occurred, cannot edit the account";
				self.alertService.showMessage(ALERT_TYPE.Error, msg);
			} else if (response.value != undefined) {
				// Show spinner
				if (account != undefined) account.showSpinner = true; else self.isCreatingAccount = true;
				// Create/edit the account
				self.siteService.editOrCreateAccount(self.currentSite, response.value.username, response.value.access).then(() => {
					// Success
					// Remove the spinner
					if (account != undefined) account.showSpinner = false; else self.isCreatingAccount = false;
					// Show success message
					var msg = (type == 'create') ? "The account has been correctly created" : "The account has been correctly updated";
					self.alertService.showMessage(ALERT_TYPE.Success, msg);
				}, () => {
					// Error
					// Remove the spinner
					if (account != undefined) account.showSpinner = false; else self.isCreatingAccount = false;
					// Show error message
					var msg = (type == 'create') ? "An error occurred, cannot create the account" : "An error occurred, cannot edit the account";
					self.alertService.showMessage(ALERT_TYPE.Error, msg);
				});
			}
		});
    }
}