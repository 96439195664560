import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, Validators } from '@angular/forms';

import { SiteService } from '../../../_services/site.service';

@Component({    
        selector: 'edit-account',
        template:  `<h1 mat-dialog-title *ngIf="data.type == 'edit'">Edit account</h1>
                    <h1 mat-dialog-title *ngIf="data.type == 'create'">Create account</h1>

                    <div mat-dialog-content>
                      <div class="container py-3">

                        <form [formGroup]="myFormGroup">

                          <div class="w-100 d-flex flex-wrap">

                            <mat-form-field appearance="outline" class="mx-auto" style="width: 260px" color="accent">
                              <mat-label>Username</mat-label>
                              <input matInput placeholder="Username" formControlName="username">

                              <mat-error *ngIf="myFormGroup.get('username').errors?.required">
                                Name is required
                              </mat-error>

                              <mat-error *ngIf="myFormGroup.get('username').errors?.maxlength">
                                Name can not be longer than 200 characters
                              </mat-error>

                            </mat-form-field>

                          </div>

                          <div class="w-100 d-flex flex-wrap">

                            <mat-form-field appearance="outline" class="mx-auto" style="width: 120px" color="accent">
                              <mat-select placeholder="Access" formControlName="access" [compareWith]="compareFn">
                                <mat-option *ngFor="let access of accountAccess" [value]="access.value">{{access.label}}</mat-option>
                              </mat-select>
                              <mat-error *ngIf="myFormGroup.get('access').errors?.required">
                                Access is required
                              </mat-error>
                            </mat-form-field>

                          </div>

                        </form>

                      </div>
                    </div>

                    <div mat-dialog-actions>

                      <button mat-raised-button class="ml-auto mr-2 lightColor" (click)="cancel()">Cancel</button>

                      <button mat-raised-button color="accent" class="ml-2 mr-auto" (click)="save()" [disabled]="myFormGroup.invalid || myFormGroup.pristine">Save</button>

                    </div>`
})
export class EditAccountDialogComponent {

    public myFormGroup = this.fb.group({
      username: ['', [Validators.required, Validators.maxLength(200)]],
      access: [10, [Validators.required]]
    });

    // We want all the account access except the superuser one
    public accountAccess = this.siteService.accountAccess.filter((a) => a.value != 'superuser');

    constructor(public dialogRef: MatDialogRef<EditAccountDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private fb: FormBuilder,
                public siteService: SiteService) {
      // Check we have the data we need
      if (data == undefined || data.type == undefined)
        this.dialogRef.close({error: true});
      else if (data.type == 'edit' && (data.account == undefined || data.account.username == undefined || data.account.access == undefined))
        this.dialogRef.close({error: true});
      else if (data.type == 'edit') {
        // Set the form values if we can
        this.myFormGroup.patchValue(data.account);
        // Disabled the username input
        this.myFormGroup.controls['username'].disable();
      }
    }

    // If user chooses the 'cancel' option
    cancel() {
      this.dialogRef.close({value: undefined});
    }

    // If user chooses the 'save' option
    save() {
      var newAccount = this.myFormGroup.getRawValue();
      if (newAccount.access != undefined)
        newAccount.access = parseInt(newAccount.access);
      this.dialogRef.close({value: newAccount});
    }

    compareFn: ((f1: any, f2: any) => boolean) | null = this.compareByValue;
    compareByValue(f1: any, f2: any) {
      return f1 && f2 && f1.toString() === f2.toString();
    }
}