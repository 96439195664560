


export class GroupItem<T, K>{
    constructor(public Key:K,public Items: T[]){}
}


export const sortByString=<T>(list: T[], getKey: (item: T) => string|undefined,des?:boolean):T[]=>
{
    // sort by name
  return  list?.sort(function(a, b) {
    var nameA = getKey(a)?.toUpperCase()??""; // ignore upper and lowercase
    var nameB = getKey(b)?.toUpperCase()??""; // ignore upper and lowercase
    if (nameA < nameB) {
      return des?1: -1;
    }
    if (nameA > nameB) {
      return des?-1: 1;
    }
  
    // names must be equal
    return 0;
  })??[];
}


export const groupBy=<T, K>(list: T[], getKey: (item: T) => K):GroupItem<T, K>[] =>{
    const map = new Map<K, T[]>();
    list.forEach((item) => {
        const key = getKey(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return Array.from(map,(i)=>new GroupItem<T, K>(i[0],i[1]));
};



export const partitionBy=<T, K>(list: T[], length: number, select: (item: T) => K):K[][] =>{
    const res = [];
    list.forEach((item,idx) => {
        if(idx % length == 0){
            res.push([]);
        }
        const c =res[res.length - 1];
        c.push(select(item));
    });
    return res;
};