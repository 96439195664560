<h1 class="text-center mb-4">Account</h1>

<!-- Spinner -->
<mat-spinner *ngIf="currentUser == undefined" class="mx-auto" color="accent"></mat-spinner>

<!-- Accout form -->
<form id="accountFormId" (ngSubmit)="onSubmit()" [formGroup]="accountForm" *ngIf="currentUser != undefined && isSavingAccount == false" class="text-center d-flex flex-wrap align-items-center justify-content-center">

	<!-- New password -->
	<mat-form-field appearance="outline" class="mx-2" style="width: 260px" color="accent">
		<mat-label>New password</mat-label>
		<input matInput placeholder="New password" formControlName="newPassword" type="password">
		<mat-error *ngIf="accountForm.controls['newPassword'].errors?.required">
          New password is required
        </mat-error>
        <mat-error *ngIf="accountForm.controls['newPassword'].errors?.minlength">
          New password must be at least 6 characters long
        </mat-error>
	</mat-form-field>

	<!-- Confirm new password -->
	<mat-form-field appearance="outline" class="mx-2" style="width: 260px" color="accent">
		<mat-label>Confirm password</mat-label>
		<input matInput placeholder="Confirm password" formControlName="confirmPassword" type="password">
		<mat-error *ngIf="accountForm.controls['confirmPassword'].errors?.required">
          Confirm password is required
        </mat-error>
        <mat-error *ngIf="accountForm.controls['confirmPassword'].errors?.match">
          Password must match
        </mat-error>
	</mat-form-field>

	<!-- Edit button -->
	<div class="mx-2 mb-3">
		<button mat-raised-button form="accountFormId" color="accent" matTooltip="Change your password" [disabled]="accountForm.invalid || accountForm.pristine">Change password</button>
	</div>

</form>

<!-- Spinner -->
<mat-spinner *ngIf="currentUser != undefined && isSavingAccount == true" class="mx-auto mb-5" [diameter]="36" color="accent"></mat-spinner>

<!-- Accounts -->
<div *ngIf="currentUser != undefined">

	<hr class="w-100"/>

	<h1 class="text-center my-4">Registered accounts</h1>

	<!-- Spinner -->
	<mat-spinner *ngIf="accounts == undefined" class="mx-auto" color="accent"></mat-spinner>

	<!-- Add account -->
	<button mat-raised-button class="mx-auto d-block mt-3 mb-4" *ngIf="accounts != undefined && isCreatingAccount != true" color="accent" (click)="editAccount()">Add account</button>
	<!-- Spinner -->
	<mat-spinner *ngIf="isCreatingAccount == true" class="mx-auto mb-4" color="accent" [diameter]="36"></mat-spinner>

	<!-- List of accounts -->
	<div class="container d-flex flex-column">
		<mat-table [dataSource]="accounts" class="border w-100 responsive-datatable" matSort matSortActive="username" matSortDirection="asc" [ngClass]="{'d-none': accounts == undefined}">

			<!-- Username -->
			<ng-container matColumnDef="username">
				<mat-header-cell *matHeaderCellDef mat-sort-header class="justify-content-center">
					Username
				</mat-header-cell>
				<mat-cell *matCellDef="let element" style="flex-flow: wrap">
					<span class="mobile-label pr-2">Username:</span>
					<div class="mx-auto">{{element.username}}</div>
				</mat-cell>
			</ng-container>

			<!-- Access -->
			<ng-container matColumnDef="access">
				<mat-header-cell *matHeaderCellDef mat-sort-header class="justify-content-center">
					Access
				</mat-header-cell>
				<mat-cell *matCellDef="let element" style="flex-flow: wrap" class="justify-content-center">
					<span class="mobile-label pr-2">Access:</span>
					<ng-container *ngFor="let access of siteService.accountAccess" class="w-100 text-center">
						<div *ngIf="element.access == access.value">{{access.label}}</div>
					</ng-container>
				</mat-cell>
			</ng-container>

			<!-- Edit/Delete -->
			<ng-container matColumnDef="action">
				<mat-header-cell *matHeaderCellDef></mat-header-cell>
				<mat-cell *matCellDef="let element" class="mx-auto">
					<div class="mx-auto">

						<!-- Edit button -->
						<button *ngIf="element.showSpinner != true" class="mx-1" mat-mini-fab color="accent" matTooltip="Edit" (click)="editAccount(element)">
							<mat-icon aria-label="Edit">edit</mat-icon>
						</button>

						<!-- Delete button -->
						<button *ngIf="element.showSpinner != true" class="mx-1" mat-mini-fab color="accent" matTooltip="Delete" (click)="deleteAccount(element)">
							<mat-icon aria-label="Delete">delete</mat-icon>
						</button>

						<!-- Spinner -->
						<mat-spinner *ngIf="element.showSpinner == true" color="accent" [diameter]="36"></mat-spinner>

					</div>
				</mat-cell>
			</ng-container>

			<mat-header-row *matHeaderRowDef="columnsAttributes"></mat-header-row>
			<mat-row *matRowDef="let row; columns: columnsAttributes;"></mat-row>

		</mat-table>
	</div>

</div>