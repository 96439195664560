import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { SiteService } from './site.service';
import { ConfigService } from './config.service';

import { Site } from '../_models/site';
import { DragonflyDevice } from '../_models/station';

@Injectable({ providedIn: 'root' })
export class StationService {

    // The current site
    private currentSite: Site;

    constructor(private http: HttpClient, private siteService: SiteService, private configService: ConfigService) {
        // Get the selected site
        this.siteService.currentSite.subscribe(site => {
            this.currentSite = site;
        });
    }


    /* -------------------------------------------------------- */
    /*               Dragonfly station functions                */
    /* -------------------------------------------------------- */

    // Get the list of dragonfly stations for this site
    public getDragonflyDevices(siteId: string, params = {}) {
        return new Promise((resolve, reject) => {
            // Check site ID
            if (siteId == undefined)
                reject("Missing site ID");
            else {
                // Make a GET request to get the list of stations
                this.http.get(`${this.configService.config.network.cvnavApiUrl}/sites/${siteId}/dragonfly/devices/`, {params: params}).subscribe((stations: DragonflyDevice[]) => {
                    // Success
                    resolve(stations.map(function(e) { return DragonflyDevice.fromData(e); }));
                }, (err) => {
                    // Error
                    reject(err);
                });
            }
        });
    }

    // Return a boolean with the station's active status
    // Active if seen in the last 15 minutes
    public isDragonflyDeviceActive(station: DragonflyDevice) {
        if (station != undefined && station.position && station.position.fixed_at &&
            isFinite(station.position.fixed_at) && station.position.fixed_at < (15 * 60))
            return true;
        return false;
    }

    // Delete the Dragonfly device by requesting the server
    public deleteDragonflyDevice(station: DragonflyDevice, site?: Site, http?: HttpClient) {
        return new Promise((resolve, reject) => {
            site = (site != undefined) ? site : this.currentSite;
            http = (http != undefined) ? http : this.http;
            // Check the station object
            if (station == undefined || station.mac == undefined || site == undefined || site.siteId == undefined) {
                reject();
            } else {
                // Make a DELETE request to delete the station
                http.delete(`${this.configService.config.network.cvnavApiUrl}/sites/${site.siteId}/dragonfly/devices/${station.mac}/`).subscribe((res: any) => {
                    // Success
                    // Make sure it was a success
                    if (res != undefined && res.status == "success")
                        resolve();
                    else
                        reject();
                }, (err) => {
                    // Error
                    reject();
                });
            }
        });
    }

    // Save the Dragonfly device by requesting the server
    public saveDragonflyDevice(newStation: DragonflyDevice, site?: Site, http?: HttpClient) {
        return new Promise((resolve, reject) => {
            site = (site != undefined) ? site : this.currentSite;
            http = (http != undefined) ? http : this.http;
            // Check the station object
            if (newStation == undefined || newStation.mac == undefined || newStation.udo == undefined ||
                site == undefined || site.siteId == undefined) {
                reject();
            } else {
                // Create the data object
                var data = { udo: { name: newStation.udo.name, desc: newStation.udo.desc }};
                // Make a PUT request to update the station
                http.put(`${this.configService.config.network.cvnavApiUrl}/sites/${site.siteId}/dragonfly/devices/${newStation.mac}/`, data).subscribe((res: DragonflyDevice) => {
                    // Success
                    resolve(res);
                }, (err) => {
                    // Error
                    reject();
                });
            }
        });
    }

}