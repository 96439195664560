<!-- Close button -->
<i id="closeButton" class="material-icons position-relative cursor-pointer" (click)="closeDialog()">close</i>

<!-- Title -->
<h3 class="noselect pb-2 text-center">Choose level</h3>

<!-- Level selector -->
<mat-form-field class="w-100" *ngIf="currentLevel != undefined && levels != undefined && levels.length > 0">
    <mat-label>Level ID</mat-label>
    <mat-select [value]="currentLevel.levelId" (selectionChange)="onLevelSelected($event)">
        <mat-option *ngFor="let level of levels" [value]="level.levelId">
            L{{level.levelId}}<span *ngIf="level.name != ''">, {{level.name}}</span>
        </mat-option>
    </mat-select>
</mat-form-field>