import { Component, OnDestroy, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { first, finalize } from 'rxjs/operators';

import { ConfigService } from '../../_services/config.service';
import { ALERT_TYPE, AlertService } from '../../_services/alert.service';

import { SitesTableComponent } from './sitesTable/sitesTable.component';
import { EditSiteComponent } from './editSite/editSite.component';

import { Site } from '../../_models/site';

const REFRESH_TIME = 1000;

@Component({
  templateUrl: 'sites.component.html',
  styleUrls: ['sites.component.scss']
})
export class SitesComponent implements OnInit, AfterViewInit, OnDestroy {

	// List of all active sites
	public activeSites: Site[];
  public shownActiveSites: Site[];
	// List of all frozen sites
	public frozenSites: Site[];
  public shownFrozenSites: Site[];
  // List of all the sites together
  public allSites: Site[];
  public shownAllSites: Site[];

  // When editing a site
  public editingSite: Site;

  // Boolean to show only expired Dragonfly site
  public showExpiredOnly: Boolean = false;

	// Admin view variables
	public viewType = 0;
  // Booleans to keep track what we are fetching
  public isFetchingActiveSites = true;
  public isFetchingFrozenSites = true;

	// List of subs
	private subs: Subscription[] = [];
  // Interval sub
  private intervalSub;

  @ViewChild('allTable', { static: true }) allTable: SitesTableComponent;
  @ViewChild('activeTable', { static: true }) activeTable: SitesTableComponent;
  @ViewChild('frozenTable', { static: true }) frozenTable: SitesTableComponent;

  constructor(private http: HttpClient, private configService: ConfigService, private alertService: AlertService) {
  	// Check local storage
  	this._checkLocalStorage();
  }

  ngOnInit() {
    // Refresh the sites
    this.refreshSites();
    // Refresh the sites by interval of 1 second
    /*this.intervalSub = setInterval(() => {
      if (this.isFetchingActiveSites == true || this.isFetchingFrozenSites == true)
        return;
      // Refresh the sites
      this.refreshSites();
    }, REFRESH_TIME);*/
  }

  ngAfterViewInit() {

  }

	ngOnDestroy() {
		// Clear the subs
    this.subs.forEach(function(sub) { sub.unsubscribe(); });
    clearInterval(this.intervalSub);
	}




  /* ------------------------------------------------------------------------- */
  /* -                           Public functions                            - */
  /* ------------------------------------------------------------------------- */
  // When we change the tab
  public tabChanged(event) {
    if (event && event.index != undefined)
      localStorage.setItem('Admin_view', event.index);
  }

  // When a site status has been changed
  public siteStatusChanged(site) {
    if (!(site && site.siteId))
      return;
    // If the site has been frozen
    if (site.frozen == true) {
      // Make the switch
      this.activeSites = this.activeSites.filter((s) => s.siteId != site.siteId);
      this.frozenSites = this.frozenSites.concat([site]);
    }
    // Else, it has been activated
    else {
      // Make the switch
      this.frozenSites = this.frozenSites.filter((s) => s.siteId != site.siteId);
      this.activeSites = this.activeSites.concat([site]);
    }
  }

  // When we start editing a site
  public siteSelected(site) {
    this.editingSite = site;
  }

  // When we are done editing a site
  public doneEditing(site) {
    // Stop editing this site
    this.editingSite = undefined;
    // If a site has been edited and changed values
    if (site) {
      // Set the device as refreshing
      this.setSpinner(site.siteId, true);
      // We need to refresh the sites to show the change(s)
      this.refreshSites(site.siteId);
    }
  }

  // When user change the checkbox to show only expired Dragonfly sites
  public filterSites() {
    this.shownActiveSites = this.activeSites.filter((s) => {
      return (this.showExpiredOnly != true || (this.getDragonflyDaysLeft(s) && this.getDragonflyDaysLeft(s) <= 0) ||
             (this.getDaysLeft(s) && this.getDaysLeft(s) <= 0));
    });
    this.shownFrozenSites = this.frozenSites.filter((s) => {
      return (this.showExpiredOnly != true || (this.getDragonflyDaysLeft(s) && this.getDragonflyDaysLeft(s) <= 0) ||
             (this.getDaysLeft(s) && this.getDaysLeft(s) <= 0));
    });
    this.shownAllSites = this.allSites.filter((s) => {
      return (this.showExpiredOnly != true || (this.getDragonflyDaysLeft(s) && this.getDragonflyDaysLeft(s) <= 0) ||
             (this.getDaysLeft(s) && this.getDaysLeft(s) <= 0));
    });
  }




  /* ------------------------------------------------------------------------- */
  /* -                           Private functions                           - */
  /* ------------------------------------------------------------------------- */
  // Check local storage for admin variables
  private _checkLocalStorage() {
    // Check admin view tab
  	var savedViewType = parseInt(localStorage.getItem('Admin_view'));
  	if (savedViewType && !isNaN(savedViewType) && savedViewType >= 0 && savedViewType <= 2)
      this.viewType = savedViewType;
  }

  // Refresh the sites
  // Optional parameter: Site ID of the site that had changes and that requires a refresh
  private refreshSites(siteId = undefined) {
    // Set the booleans
    this.isFetchingActiveSites = true;
    this.isFetchingFrozenSites = true;
    // Get the list of active sites
    this.http.get(this.configService.config.network.navizonAdminApiUrl + "/sites/admin/")
    .pipe(finalize(() => {
      // Finally
      this.isFetchingActiveSites = false;
      // Set the all sites variable (which is active + frozen sites) if we can
      if (this.isFetchingFrozenSites == false) {
        this.allSites = this.activeSites.concat(this.frozenSites);
        // If we have a site ID, remove its spinner
        if (siteId)
          this.setSpinner(siteId, false);
        // Filer then show the sites
        this.filterSites();
      }
    }))
    .subscribe((activeSites: any[]) => {
        // Save the active sites
        this.activeSites = activeSites.map((s) => {
          s.frozen = false;
          s = this._parseDates(s);
          return s;
        });
    }, (err) => {
        console.log(err);
        this.activeSites = [];
        this.alertService.showMessage(ALERT_TYPE.Error, "Could not get the active sites");
    });
    // Get the list of frozen sites
    this.http.get(this.configService.config.network.navizonAdminApiUrl + "/sites/admin/?type=frozen")
    .pipe(finalize(() => {
      this.isFetchingFrozenSites = false;
      // Set the all sites variable (which is active + frozen sites) if we can
      if (this.isFetchingActiveSites == false) {
        this.allSites = this.activeSites.concat(this.frozenSites);
        // If we have a site ID, remove its spinner
        if (siteId)
          this.setSpinner(siteId, false);
        // Filer then show the sites
        this.filterSites();
      }
    }))
    .subscribe((frozenSites: any[]) => {
        // Save the frozen sites
        this.frozenSites = frozenSites.map((s) => {
          s.frozen = true;
          s = this._parseDates(s);
          return s;
        });
    }, (err) => {
        console.log(err);
        this.frozenSites = [];
        this.alertService.showMessage(ALERT_TYPE.Error, "Could not get the frozen sites");
    });
  }

  private setSpinner(siteId, value) {
    if (this.allTable && this.allTable.showSpinners)
      this.allTable.showSpinners[siteId] = (value == true) ? value : undefined;
    if (this.activeTable && this.activeTable.showSpinners)
      this.activeTable.showSpinners[siteId] = (value == true) ? value : undefined;
    if (this.frozenTable && this.frozenTable.showSpinners)
      this.frozenTable.showSpinners[siteId] = (value == true) ? value : undefined;
  }

  // Return the number of days left for Dragonfly for this site
  public getDragonflyDaysLeft(s) {
    if (s != undefined && s.admin != undefined && s.admin.dragonfly &&
        s.admin.dragonfly.expiration != undefined && s.admin.dragonfly.expiration.length > 0)
      return Math.round((((new Date(s.admin.dragonfly.expiration)).getTime()) - ((new Date()).getTime())) / 86400000);
    return;
  }

  // Return the number of days left for this site
  public getDaysLeft(s) {
    if (s != undefined && s.admin != undefined && s.admin.expiration != undefined && s.admin.expiration.length > 0)
      return Math.round((((new Date(s.admin.expiration)).getTime()) - ((new Date()).getTime())) / 86400000);
    return;
  }

  // Parse the dates for this site
  private _parseDates(site) {
    // Parse dates
    if (site.admin && site.admin.expiration) {
      var expiration = new Date(site.admin.expiration);
      if (expiration && expiration.getTime && expiration instanceof Date && isFinite(expiration.getTime()))
        site.admin.expiration = expiration.toUTCString();
    }
    if (site.admin && site.admin.dragonfly && site.admin.dragonfly) {
      var adminExpiration = new Date(site.admin.dragonfly.expiration);
      if (adminExpiration && adminExpiration.getTime && adminExpiration instanceof Date && isFinite(adminExpiration.getTime()))
        site.admin.dragonfly.expiration = adminExpiration.toUTCString();
    }
    return site;
  }

}