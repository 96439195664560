export class Site {

	public access: string;
	public address: string;
	public cloudtrax_network: string;
	public cloudtrax_password: string;
	public company: string;
	public desc: string;
	public name: string;
	public siteId: string;
	public admin: any;

    constructor(siteId: string, access: string, address: string = "", cloudtrax_network: string = "", cloudtrax_password: string = "",
    			company: string = "", desc: string = "", name: string = "", admin: any = undefined) {
		this.access = access;
		this.address = address;
		this.cloudtrax_network = cloudtrax_network;
		this.company = company;
		this.desc = desc;
		this.name = name;
		this.siteId = siteId;
		this.admin = admin;
    }

    // Second constructor
    static fromData(data: any) {
    	if (data == undefined)
    		return undefined;
        let { siteId, access, address = "", cloudtrax_network = "", cloudtrax_password = "", company = "", desc = "", name = "", admin = undefined } = data;
        if (siteId == undefined)
        	return undefined;
        return new this(siteId, access, address, cloudtrax_network, cloudtrax_password, company, desc, name, admin);
    }
}