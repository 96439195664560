<!-- <mat-toolbar color="primary">
	<mat-toolbar-row>
		
        <button class="d-block d-lg-none" mat-icon-button (click)="componentDrawer.toggle()">
            <mat-icon class="position-relative" style="top: -1px">menu</mat-icon>
        </button>
		<button mat-button class="noselect" style="font-size: 16px" [routerLink]="['/admin']">Admin</button>
	</mat-toolbar-row>
</mat-toolbar> -->

<mat-drawer-container [hasBackdrop]="drawerCollapsed">

	<!--  
	<mat-drawer *ngIf="disableMenu != 'true'" [mode]="(drawerCollapsed) ? 'over' : 'side'" #componentDrawer [opened]="!drawerCollapsed" class="w-15" style="width: 200px;" [ngClass]="{'fixedToTop': hasScrollMoreThanTopBar}">
		
		<button mat-button *ngFor="let category of categories" class="w-100 rounded-0 text-left py-1" [routerLink]="category.routerLink" (click)="closeDrawer()">
			{{category.title}}
		</button>
	</mat-drawer>-->

	<!-- Main content -->
	<mat-drawer-content class="componentDrawer py-4">
		<router-outlet></router-outlet>
	</mat-drawer-content>

</mat-drawer-container>