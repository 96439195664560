<h1 class="text-center mb-4">Dragonfly Map</h1>

<!-- Map -->
<div id="mapContainer" class="px-3 px-md-4 px-lg-5" [ngClass]="{'visible': currentSite != undefined, 'invisible': currentSite == undefined}">
	<div id="map" #map class="noselect w-100 h-100">
		<!-- Top right div inside the map -->
        <div class="leaflet-top leaflet-right mt-2 mr-2" style="pointer-events: auto" (click)="$event.stopPropagation()">
			<map-settings [mapSettings]="mapSettings"></map-settings>
		</div>
	</div>
</div>

<!-- Devices -->
<h1 class="text-center my-4">Devices</h1>
<element-table
	[elements]="stations"
	[currentSite]="currentSite"
	[elementName]="'Dragonfly device'"
	[options]="stationsTableOptions"
	[displayedColumns]="stationsDisplayedColumns"
  [attributesToDisplay]="stationsAttributesToDisplay"
  [attributesToEdit]="stationsAttributesToEdit"
  [formGroup]="stationsFormGroup"
  [editElementFct]="stationService.saveDragonflyDevice"
  [deleteElementFct]="stationService.deleteDragonflyDevice"
  sortField="position.fixed_at"
  sortDirection="desc"
></element-table>