<div *ngIf="isLoading == true" class="p-3">
	<mat-spinner class="mx-auto" color="accent" [diameter]="36"></mat-spinner>
</div>

<div [ngClass]="{'invisible': isLoading == true}" class="d-flex flex-column p-3">

	<mat-form-field class="w-100 w-sm-50 w-md-40 w-lg-30 mx-auto" color="accent">
		<input matInput (keyup)="applyFilter($event.target.value, dataSource)" placeholder="Filter">
	</mat-form-field>

	<div class="border bg-white" style="overflow-x: auto">

		<table #sort="matSort" mat-table class="w-100 centered-table" [ngStyle]="{'min-width.px': displayedColumns.length * columnMinWidth}" [dataSource]="dataSource" matSort matSortActive="siteId" matSortDirection="asc" (matSortChange)="sortData($event)">

			<!-- Site ID -->
			<ng-container matColumnDef="siteId">
				<th mat-header-cell *matHeaderCellDef mat-sort-header class="centered-header">Site ID</th>
				<td mat-cell class="text-center" *matCellDef="let element">
					{{element.siteId}}
				</td>
			</ng-container>

			<!-- Name -->
			<ng-container matColumnDef="name">
				<th mat-header-cell *matHeaderCellDef mat-sort-header class="centered-header">Name</th>
				<td mat-cell class="text-center" *matCellDef="let element">
					{{element.name}}
				</td>
			</ng-container>

			<!-- Type -->
			<ng-container matColumnDef="type">
				<th mat-header-cell *matHeaderCellDef mat-sort-header class="centered-header">Type</th>
				<td mat-cell class="text-center" *matCellDef="let element">
					{{getTypeLabel(element)}}
				</td>
			</ng-container>

			<!-- Status -->
			<ng-container matColumnDef="status">
				<th mat-header-cell *matHeaderCellDef mat-sort-header class="centered-header">Status</th>
				<td mat-cell class="text-center" *matCellDef="let element" [ngClass]="(element && element.frozen == true) ? 'red' : 'green'">
					{{getStatusLabel(element)}}
				</td>
			</ng-container>

			<!-- Days left -->
			<ng-container matColumnDef="days_left">
				<th mat-header-cell *matHeaderCellDef mat-sort-header class="centered-header">Days left</th>
				<td mat-cell class="text-center" *matCellDef="let element">
					<span [ngClass]="(getDaysLeft(element) && getDaysLeft(element) <= 0) ? 'red' : ''">
						{{getDaysLeft(element)}}
					</span>
				</td>
			</ng-container>

			<!-- Dragonfly status -->
			<ng-container matColumnDef="dStatus">
				<th mat-header-cell *matHeaderCellDef mat-sort-header class="centered-header">Dragonfly</th>
				<td mat-cell class="text-center" *matCellDef="let element" [ngClass]="(element && element.admin && element.admin.dragonfly && element.admin.dragonfly.enabled == true) ? 'green' : 'red'">
					{{getDragonflyStatusLabel(element)}}
				</td>
			</ng-container>

			<!-- Dragonfly days left -->
			<ng-container matColumnDef="dDays_left">
				<th mat-header-cell *matHeaderCellDef mat-sort-header class="centered-header">Days left</th>
				<td mat-cell class="text-center" *matCellDef="let element">
					<span [ngClass]="(getDragonflyDaysLeft(element) && getDragonflyDaysLeft(element) <= 0) ? 'red' : ''">
						{{getDragonflyDaysLeft(element)}}
					</span>
				</td>
			</ng-container>

			<!-- Action -->
			<ng-container matColumnDef="action">
				<th mat-header-cell *matHeaderCellDef mat-sort-header class="centered-header">Action</th>
				<td mat-cell class="text-center" *matCellDef="let element">

					<!-- Edit button -->
					<span *ngIf="showSpinners[element.siteId] == undefined">
						<button class="mx-1" mat-mini-fab color="primary" matTooltip="Edit" (click)="editSite(element)">
							<mat-icon aria-label="Edit">edit</mat-icon>
						</button>
					</span>

					<!-- Freeze / Activate button -->
					<span *ngIf="showSpinners[element.siteId] == undefined">
						<!-- Freeze button -->
						<button *ngIf="element.frozen != true" class="mx-1 green-bg" mat-mini-fab matTooltip="Freeze" (click)="setSiteStatus(element, true)">
							<mat-icon aria-label="Freeze">toggle_on</mat-icon>
						</button>
						<!-- Activate button -->
						<button *ngIf="element.frozen == true" class="mx-1" mat-mini-fab color="warn" matTooltip="Activate" (click)="setSiteStatus(element, false)">
							<mat-icon aria-label="Activate">toggle_off</mat-icon>
						</button>
					</span>

					<!-- Spinner -->
					<span *ngIf="showSpinners[element.siteId] != undefined">
						<mat-spinner class="mx-auto" color="accent" [diameter]="36"></mat-spinner>
					</span>

				</td>
			</ng-container>

			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr mat-row *matRowDef="let element; columns: displayedColumns;" [ngClass]="{'frozen': element.frozen}"></tr>

		</table>

		<mat-paginator #paginator [pageSizeOptions]="[10, 25, 100]" [ngStyle]="{'min-width.px': displayedColumns.length * columnMinWidth}" color="accent"></mat-paginator>

	</div>
	
</div>