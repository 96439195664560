import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({    
        selector: 'ok',
        template:  `<h1 mat-dialog-title>{{data.title}}</h1>
                    <div mat-dialog-content>
                      <p [innerHTML]="data.message"></p>
                    </div>
                    <div mat-dialog-actions>

                      <button mat-raised-button class="mx-auto" [mat-dialog-close]="true" color="accent">
                          {{data.btnLabel || 'Ok'}}
                      </button>

                    </div>`
})
export class OkDialogComponent {

  constructor(public dialogRef: MatDialogRef<OkDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {

  }
  
}