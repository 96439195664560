export class FpImage {

	public name: string = "";
	public type: string = "";
	public base64Data: any = "";
	public sanitizedData: any = "";
	public blob: any;

    constructor(name: string = "", type: string = "", base64Data: any = "", sanitizedData: any = "", blob: any = "") {
		this.name = name;
		this.type = type;
		this.base64Data = base64Data;
		this.sanitizedData = sanitizedData;
		this.blob = blob;
    }

    // Second constructor
    static fromData(data: any) {
    	if (data == undefined)
    		return undefined;
        let { name = "", type = "", base64Data = "", sanitizedData = "", blob = "" } = data;
        return new this(name, type, base64Data, sanitizedData, blob);
    }
}