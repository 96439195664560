import { Component, ViewChild, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import { AuthenticationService } from './_services/authentication.service';
import { AdminService } from './_services/admin.service';
import { SiteService } from './_services/site.service';
import { LevelService } from './_services/level.service';
import { ResponsiveService } from './_services/responsive.service';
import { IntegrationService } from './_services/integration.service';

import { SiteSelectorDialogComponent } from './common/dialogs/sites/siteSelector/site-selector-dialog.component';
import { LevelSelectorDialogComponent } from './common/dialogs/levels/levelSelector/level-selector-dialog.component';

import { User } from './_models/user';
import { Site } from './_models/site';
import { Level } from './_models/level';
import { CATEGORY, CategoryService } from './_services/category.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

	public disableMenu;
	public logoUrl;

	public currentUser: User;
	public currentSite: Site;
	public currentLevel: Level;
	public levels: Level[];
	public categories = [];

	@ViewChild('sidenav') sidenav;

	constructor(private router: Router, private authenticationService: AuthenticationService, private responsiveService: ResponsiveService,
				private siteService: SiteService, public dialog: MatDialog, private levelService: LevelService, private integrationService: IntegrationService,
				private renderer: Renderer2, private adminService: AdminService, private categoryService: CategoryService) {
		// Get the connected user
		this.authenticationService.currentUser.subscribe(user => {
			this.currentUser = user;
		});
		// Get the selected site
		this.siteService.currentSite.subscribe(site => {
			this.currentSite = site;
		});
		// Get the selected level
		this.levelService.currentLevel.subscribe(level => {
			this.currentLevel = level;
		});
		// Get all the levels
		this.levelService.levels.subscribe(levels => {
			// Order levels by level ID
			if (levels != undefined)
				this.levels = levels.sort(function(a, b){ return a.levelId - b.levelId });  ;
		});
		// Watch the custom logo
		this.integrationService.logo_obs.subscribe((logoUrl) => {
			this.logoUrl = logoUrl;
		});
		// Watch the disable menu
		this.integrationService.disableMenu_obs.subscribe((disableMenu) => {
			this.disableMenu = disableMenu;
		});
	}
	isSelectedCategory(category:CATEGORY,isSubMenu:boolean = false):string {
		return (this.categoryService.getSelectedPath(this.router.url) == category)? 'accent': (isSubMenu ?'basic':'primary');
	}
	isSelectedMenu(category:string):string {
		return (this.router.url == category)? 'accent':'basic';
	}

	public isLinkActive(urls: Array<string>): boolean {
		var self = this, res = false;
		if (urls == undefined || urls.length <= 0 || this.router.url == undefined)
			return res;
		urls.forEach(function(url) {
			if (self.router.url.startsWith(url) == true)
				res = true;
		});
		return res;
	}

	// Open the dialog to select a new site
	openSiteSelector(sidenav) {
		var dialog = this.dialog.open(SiteSelectorDialogComponent, { panelClass: ['mw-none','w-90','w-sm-80','w-md-60','w-lg-50'] });
		dialog.updatePosition({ top: this.responsiveService.getDialogTopPosition() + 'px' });
		// Close the sidenav if we can
		if (sidenav != undefined && sidenav.close != undefined)
			sidenav.close();
	}
	currentSidenav:string;
	// Open the dialog to select a new site
	openSubMenu(name:CATEGORY) {
		if(this.currentSidenav != name)
		{
			this.categories = this.categoryService.loadCategory(name, this.currentUser);
			this.sidenav.toggle(true);
		}	
		else
			this.sidenav.toggle();
		this.currentSidenav = name;
	}
	openMainMenu() {
		this.currentSidenav = undefined;
		this.categories = [];
		this.sidenav.toggle();
	}

	// Open the dialog to select a new level
	openLevelSelector() {
		var dialog = this.dialog.open(LevelSelectorDialogComponent, { panelClass: ['mw-none','w-80','w-sm-50','w-md-30','w-lg-25'] });
		dialog.updatePosition({ top: this.responsiveService.getDialogTopPosition() + 'px' });
	}

	// Select the level
	selectLevel(level) {
		if (level != undefined && level.levelId != undefined)
			this.levelService.selectLevel(level.levelId);
	}

	// When user clicks the logout button
	logout() {
		this.authenticationService.logout();
		this.router.navigate(['/login']);
	}
}
