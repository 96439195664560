import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ReplaySubject, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class IntegrationService {

	// Credentials
	private _credentials: any;
	public credentials_obs = new Subject<any>();

	// Site ID
	public siteId: string;

	// Return url
	public returnUrl: string;

	// Logo url
	private _logo: String;
  public logo_obs = new ReplaySubject<String>(1);

  // Disable menu
	private _disableMenu: String;
  public disableMenu_obs = new ReplaySubject<String>(1);

	// ------------------------
	// Constructor
	// ------------------------
	constructor(private route: ActivatedRoute) {
		this.route.queryParams.subscribe(params => {
			if (params == undefined) {
				this.credentials_obs.next(undefined);
				return;
			}
			// Check for logo
			if (params.logo != undefined) {
				this._logo = params.logo;
				this.logo_obs.next(this._logo);
			}
			// Check for disable menu
			if (params.disableMenu != undefined) {
				this._disableMenu = params.disableMenu;
				this.disableMenu_obs.next(this._disableMenu);
			}
			// Check for username and password
			if (params.username != undefined) {
				this._credentials = {username: params.username, password: params.password || ''};
				this.credentials_obs.next(this._credentials);
			} else {
				this._credentials = undefined;
				this.credentials_obs.next(this._credentials);
			}
			// Check for site ID
			if (params.siteId != undefined)
				this.siteId = params.siteId;
			// Check for returnUrl
			if (params.returnUrl != undefined)
				this.returnUrl = params.returnUrl;
		});
	}

	// ------------------------
	// Public functions
	// ------------------------

}