<div class="row mx-0">
	<div class="col-12 col-sm-6 col-lg-4 pt-2 pb-3" *ngFor="let category of categories">
		<mat-card class="cursor-pointer h-100 p-0" [routerLink]="category.routerLink">
			<div matRipple class="p-3">
				<mat-card-header class="ml-0">
					<mat-card-title class="noselect">{{category.title}}</mat-card-title>
				</mat-card-header>
				<mat-card-content>
					<p class="noselect">{{category.description}}</p>
				</mat-card-content>
			</div>
		</mat-card>
	</div>
</div>