import { Injectable } from '@angular/core';
import L from 'leaflet';

import { Position } from '../_models/position';

@Injectable({ providedIn: 'root' })
export class MarkerService {

	// Create a regular marker object
	public createMarker() {
		return L.icon({iconUrl: 'assets/leaflet/marker-icon.png', iconSize:[26, 42], iconAnchor:[13, 40]});
	}

	// Create a node marker object
	public createNode(active = false) {
		let url = (active == true) ? 'assets/leaflet/node_active.png' : 'assets/leaflet/node_inactive.png';
		return L.icon({iconUrl: url, iconSize:[33, 33], iconAnchor:[16, 16]});
	}

	// Create a station marker object
	public createStation(active = true, onLevel = true, isKnown = true) {
		let url = 'assets/leaflet/station_known_active.png';
		if (active == true && onLevel != true)
			url = 'assets/leaflet/station_known_active_33p.png';
		if (active != true && onLevel == true)
			url = 'assets/leaflet/station_known_inactive.png';
		if (active != true && onLevel != true)
			url = 'assets/leaflet/station_known_inactive_33p.png';
		if (isKnown == false)
			url = 'assets/leaflet/station_unknown.png';
		return L.icon({iconUrl: url, iconSize:[36, 36], iconAnchor:[18, 18]});
	}

	// Create a Dragonfly marker object
	public createDragonflyStation(active = true, position: Position) {
		let url = (active == true) ? 'assets/leaflet/station_known_inactive.png' : 'assets/leaflet/station_known_inactive_33p.png';
		// Check timestamp first
		if (position != undefined && position.fixed_at != undefined) {
			let seenSince = new Date().getTime() - position.fixed_at;
			// If device has been seen in the last 15 seconds
			if ((seenSince / 1000) < 15) {
				// Check status
				if (position.status == 3)
					url = (active == true) ? 'assets/leaflet/station_known_navigating.png' : 'assets/leaflet/station_known_navigating_33p.png';
				if (position.status == 4)
					url = (active == true) ? 'assets/leaflet/station_known_lost.png' : 'assets/leaflet/station_known_lost_33p.png';
			}
		}
		return L.icon({iconUrl: url, iconSize:[36, 36], iconAnchor:[18, 18]});
	}

	// Create a Dragonfly visual marker
	public createDragonflyVMarker(orientation: number = 0) {
		return L.icon({iconUrl: 'assets/leaflet/dragonfly_vmarker.png', iconSize:[36, 36], iconAnchor:[18, 18]});
	}
}