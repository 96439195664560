import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';

import { SiteService } from './site.service';
import { ALERT_TYPE, AlertService } from './alert.service';
import { ConfigService } from './config.service';

import { Site } from '../_models/site';

@Injectable({ providedIn: 'root' })
export class LicenseService {

	private dragonflyLicenseInfoSubject: BehaviorSubject<any>;
    public dragonflyLicenseInfo: Observable<any>;
    private _dragonflyLicenseInfo: any;

    private dragonflyRegisteredDevicesSubject: BehaviorSubject<any>;
    public dragonflyRegisteredDevices: Observable<any>;
    private _dragonflyRegisteredDevices: any;

    // The current site
    private currentSite: Site;

    constructor(private http: HttpClient, private siteService: SiteService, private alertService: AlertService,
                private configService: ConfigService) {
        this.dragonflyLicenseInfoSubject = new BehaviorSubject<any>(undefined);
        this.dragonflyLicenseInfo = this.dragonflyLicenseInfoSubject.asObservable();
        this.dragonflyRegisteredDevicesSubject = new BehaviorSubject<any>(undefined);
        this.dragonflyRegisteredDevices = this.dragonflyRegisteredDevicesSubject.asObservable();
        // Get the selected site
        this.siteService.currentSite.subscribe(site => {
            this.currentSite = site;
            // Check we have the current site
            if (this.currentSite != undefined && this.currentSite.siteId != undefined) {
                // Get the license info for the selected site
                this._getDragonflyLicenseInfo(this.currentSite).then((dragonflyLicenseInfo: any) => {
                    // Success
                    // Save the license info
                    this._dragonflyLicenseInfo = dragonflyLicenseInfo;
                    // Update observable
                    this.dragonflyLicenseInfoSubject.next(dragonflyLicenseInfo);
                }, () => {
                    // Error
                    this.alertService.showMessage(ALERT_TYPE.Error, "An error occurred, cannot get the license info");
                });
                // Get the registered devices for the selected site
                this._getDragonflyRegisteredDevices(this.currentSite).then((dragonflyRegisteredDevices: any) => {
                    // Success
                    // Save the registered devices
                    this._dragonflyRegisteredDevices = dragonflyRegisteredDevices;
                    // Update observable
                    this.dragonflyRegisteredDevicesSubject.next(dragonflyRegisteredDevices);
                }, () => {
                    // Error
                    this.alertService.showMessage(ALERT_TYPE.Error, "An error occurred, cannot get the registered devices for this license");
                });
            }
        });
    }

    /* -------------------------------------------------------- */
    /*                    Public functions                      */
    /* -------------------------------------------------------- */

    // Delete a registered Dragonfly device
    public deleteDragonflyRegisteredDevice(device, site?: Site, http?: HttpClient) {
    	return new Promise((resolve, reject) => {
            site = (site != undefined) ? site : this.currentSite;
            http = (http != undefined) ? http : this.http;
            // Check the device object
            if (device == undefined || device.mac == undefined || site == undefined || site.siteId == undefined) {
                reject();
            } else {
                // Make a DELETE request to delete the device
                http.delete(`${this.configService.config.network.cvnavApiUrl}/sites/${site.siteId}/dragonfly/authentication/${device.mac}`).subscribe((res: any) => {
                    // Success
                    // Make sure it was a success
                    if (res != undefined && res.status == "success")
                        resolve();
                    else
                        reject();
                }, (err) => {
                    // Error
                    reject();
                });
            }
        });
    }

    // Create a Dragonfly license
    public createDragonflyLicense(licenseData: any, http?: HttpClient) {
        return new Promise((resolve, reject) => {
            http = (http != undefined) ? http : this.http;
            // Check the license data object
            if (licenseData == undefined || licenseData.username == undefined || licenseData.password == undefined ||
                licenseData.siteId == undefined || licenseData.company == undefined || licenseData.max_instances == undefined ||
                licenseData.license_end_timestamp == undefined) {
                reject();
            } else {
                var authData = "Basic " + window.btoa(licenseData.username + ':' + licenseData.password);
                // Make a POST request to create the license
                http.post(`${this.configService.config.network.cvnavApiUrl}/sites/${licenseData.siteId}/dragonfly/license`, {
                        company: licenseData.company,
                        max_instances: licenseData.max_instances,
                        license_end_timestamp: licenseData.license_end_timestamp
                    }, { headers: {"Authorization": authData}}).subscribe((res: any) => {
                    // Success
                    // Make sure it was a success
                    if (res != undefined && res.status == "success")
                        resolve();
                    else
                        reject();
                }, (err) => {
                    // Error
                    reject(err);
                });
            }
        });
    }

    /* -------------------------------------------------------- */
    /*                    Private functions                     */
    /* -------------------------------------------------------- */

    // Make a GET request to get the license info for this site
    private _getDragonflyLicenseInfo(site: Site) {
        return new Promise((resolve, reject) => {
            // Check the site object
            if (site == undefined || site.siteId == undefined) {
                reject();
            } else {
                // Make a GET request to get the license info
                this.http.get(`${this.configService.config.network.cvnavApiUrl}/sites/${site.siteId}/dragonfly/license`).subscribe((dragonflyLicenseInfo: any) => {
                    // Success
                    resolve(dragonflyLicenseInfo);
                }, (err: string) => {
                    // Error
                    // Request returned 404, so license is just not existing for this site
                    if (err && err.toLowerCase().indexOf('not found') != -1)
                        resolve({'error': 'not found'});
                    else
                        reject(err);
                });
            }
        });
    }

    // Make a GET request to get the registered devices for this license
    private _getDragonflyRegisteredDevices(site: Site) {
    	return new Promise((resolve, reject) => {
            // Check the site object
            if (site == undefined || site.siteId == undefined) {
                reject();
            } else {
                // Make a GET request to get the registered devices
                this.http.get(`${this.configService.config.network.cvnavApiUrl}/sites/${site.siteId}/dragonfly/authentication`).subscribe((dragonflyRegisteredDevices: any) => {
                    // Success
					// For each device, copy the 'device' field into a 'mac' field
            		// (it makes things easier for the generic table)
                    if (dragonflyRegisteredDevices != undefined && dragonflyRegisteredDevices.length > 0) {
                        dragonflyRegisteredDevices = dragonflyRegisteredDevices.map((d) => {
                            d.mac = d.device;
                            return d;
                        });
                    }
                    // Return result
                    resolve(dragonflyRegisteredDevices);
                }, (err) => {
                    // Error
                    reject([]);
                });
            }
        });
    }

}