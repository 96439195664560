<h1 *ngIf="editingSite == undefined || editingSite.siteId == undefined" class="text-center mb-4">Sites</h1>
<h1 *ngIf="editingSite && editingSite.siteId != undefined" class="text-center mb-4">Site {{editingSite.siteId}}</h1>

<div class="text-center" *ngIf="editingSite == undefined">
	<mat-checkbox [(ngModel)]="showExpiredOnly" (change)="filterSites()" [disabled]="isFetchingActiveSites == true || isFetchingFrozenSites == true">
		Show expired Dragonfly only
	</mat-checkbox>
</div>

<!-- Tabs -->
<mat-tab-group [hidden]="editingSite != undefined" mat-align-tabs="center" [(selectedIndex)]="viewType" (selectedTabChange)="tabChanged($event)">

	<!-- All -->
	<mat-tab label="All">
		<element-sites-table #allTable [sites]="shownAllSites" (siteStatusChanged)="siteStatusChanged($event)" (siteSelected)="siteSelected($event)"></element-sites-table>
	</mat-tab>

	<!-- Active -->
	<mat-tab label="Active" class="overflow-hidden">
		<element-sites-table #activeTable [sites]="shownActiveSites" [removeCols]="['status']" (siteStatusChanged)="siteStatusChanged($event)" (siteSelected)="siteSelected($event)"></element-sites-table>
	</mat-tab>

	<!-- Frozen -->
	<mat-tab label="Frozen">
		<element-sites-table #frozenTable [sites]="shownFrozenSites" [removeCols]="['status']" (siteStatusChanged)="siteStatusChanged($event)" (siteSelected)="siteSelected($event)"></element-sites-table>
	</mat-tab>

</mat-tab-group>

<!-- When editing a site -->
<div *ngIf="editingSite != undefined">

	<!-- Edit site page -->
	<element-edit-site [currentSite]="editingSite" (doneEditing)="doneEditing($event)"></element-edit-site>

</div>