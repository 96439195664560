import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({    
        selector: 'confirm',
        template:  `<h1 mat-dialog-title>{{data.title}}</h1>
                    <div mat-dialog-content>
                      <p [innerHTML]="data.message"></p>
                    </div>
                    <div mat-dialog-actions>

                      <button mat-raised-button
                        [ngClass]="{'mat-primary': data.NoIsPrimary, 'mat-accent': data.NoIsAccent, 'mat-warn': data.NoIsWarn, 'lightColor': !(data.NoIsPrimary || data.NoIsAccent || data.NoIsWarn)}"
                        class="ml-auto mr-2" [mat-dialog-close]="false">
                          {{data.noBtnLabel || 'No'}}
                      </button>

                      <button mat-raised-button
                        [ngClass]="{'mat-primary': data.YesIsPrimary, 'mat-accent': data.YesIsAccent, 'mat-warn': data.YesIsWarn, 'lightColor': !(data.YesIsPrimary || data.YesIsAccent || data.YesIsWarn)}"
                        class="ml-2 mr-auto" [mat-dialog-close]="true">
                          {{data.yesBtnLabel || 'Yes'}}
                      </button>

                    </div>`
})
export class YesNoDialogComponent {

  constructor(public dialogRef: MatDialogRef<YesNoDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {

  }
  
}