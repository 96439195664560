<!-- Form -->
<form id="siteFormId" (ngSubmit)="onSubmit()" [formGroup]="siteForm" *ngIf="currentSite != undefined" class="text-center d-flex flex-wrap align-items-center justify-content-center">

	<!-- Site ID -->
	<mat-form-field appearance="outline" class="mx-2" style="width: 100px" color="accent">
		<mat-label>Site ID</mat-label>
		<input matInput placeholder="Site ID" formControlName="siteId">
	</mat-form-field>

	<!-- Type -->
	<mat-form-field class="mx-2" style="width: 120px" color="accent" [formGroup]="adminSiteForm">
		<mat-select placeholder="Type" formControlName="type">
			<mat-option *ngFor="let type of siteService.siteType" [value]="type.value">{{type.label}}</mat-option>
		</mat-select>
	</mat-form-field>

	<hr class="w-100 invisible my-0"/>

	<!-- Name -->
	<mat-form-field appearance="outline" class="mx-2" style="width: 260px" color="accent">
		<mat-label>Name</mat-label>
		<input matInput placeholder="Name" formControlName="name">
	</mat-form-field>

	<!-- Description -->
	<mat-form-field appearance="outline" class="mx-2" style="width: 260px" color="accent">
		<mat-label>Description</mat-label>
		<input matInput placeholder="Description" formControlName="desc">
	</mat-form-field>

	<hr class="w-100 invisible my-0"/>

	<!-- Contact -->
	<mat-form-field appearance="outline" class="mx-2" style="width: 260px" color="accent" [formGroup]="adminSiteForm">
		<mat-label>Contact</mat-label>
		<input matInput placeholder="Contact" formControlName="contact">
	</mat-form-field>

	<!-- Expiration date -->
	<mat-form-field appearance="outline" class="mx-2" style="width: 260px" color="accent" [formGroup]="adminSiteForm">
		<mat-label>Expiration date</mat-label>
		<input matInput [matDatepicker]="picker" formControlName="expiration">
		<mat-hint *ngIf="!adminSiteForm.get('expiration').value || adminSiteForm.get('expiration').value.length <= 0">(will never expire)</mat-hint>
		<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
		<mat-datepicker #picker></mat-datepicker>
	</mat-form-field>

	<hr class="w-100"/>

	<!-- Dragonfly -->
	<div [formGroup]="dragonflySiteForm" class="ml-2 mr-4">
		<mat-checkbox [checked]="dragonflySiteForm.get('enabled').value" (change)="productChanged(dragonflySiteForm, $event)">Dragonfly</mat-checkbox>
	</div>

	<!-- Type -->
	<mat-form-field class="ml-2 mr-4" style="width: 120px" color="accent" [formGroup]="dragonflySiteForm">
		<mat-select placeholder="Type" formControlName="type">
			<mat-option *ngFor="let type of siteService.siteType" [value]="type.value">{{type.label}}</mat-option>
		</mat-select>
	</mat-form-field>

	<!-- Expiration date -->
	<mat-form-field appearance="outline" class="mx-2" style="width: 260px" color="accent" [formGroup]="dragonflySiteForm">
		<mat-label>Expiration date</mat-label>
		<input matInput [matDatepicker]="picker2" formControlName="expiration">
		<mat-hint *ngIf="!dragonflySiteForm.get('expiration').value || dragonflySiteForm.get('expiration').value.length <= 0">(will never expire)</mat-hint>
		<mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
		<mat-datepicker #picker2></mat-datepicker>
	</mat-form-field>

	<hr class="w-100"/>

	<!-- Address -->
	<mat-form-field appearance="outline" class="mx-2" color="accent">
		<mat-label>Address</mat-label>
		<input matInput placeholder="Address" formControlName="address" (focusout)="addressFocusout($event)">
	</mat-form-field>

	<!-- Edit button -->
	<div class="mx-2 mb-3" *ngIf="canEditAddress == false">
		<button mat-stroked-button color="accent" matTooltip="Edit the address by changing the coordinates or by moving the map" (click)="setEditAddress(true)">Edit</button>
	</div>

</form>

<!-- Map -->
<div class="container" [ngClass]="{'visible': currentSite != undefined, 'invisible': currentSite == undefined}">
	<div id="map" #map class="mx-auto noselect" style="height: 220px; max-width: 350px;"></div>
</div>

<hr class="w-100" *ngIf="currentSite != undefined" />

<!-- Buttons -->
<div class="container d-flex" *ngIf="currentSite != undefined">

	<div class="mx-auto" *ngIf="isSavingSite == false">
		<button mat-raised-button class="mr-2 lightColor" (click)="doneEditing.emit()">Cancel</button>
		<button mat-raised-button form="siteFormId" color="accent" class="ml-2">Save</button>
	</div>

	<div class="mx-auto" *ngIf="isSavingSite == true">
		<mat-spinner color="accent" [diameter]="36"></mat-spinner>
	</div>

</div>