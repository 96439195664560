export class User {

    public username: string;
    public password: string;
    public authdata: string;
    public isSuperuser: Boolean;

    constructor(username: string, password: string, authdata: string) {
        this.username = username;
        this.password = password;
        this.authdata = authdata;
        this.isSuperuser = false;
    }

    // Function to set as superuser
    public setSuperuser(value: Boolean) {
    	this.isSuperuser = value;
    }
}