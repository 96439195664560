import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { map, first } from 'rxjs/operators';

import { User } from '../_models/user';
import { Site } from '../_models/site';

import { SiteService } from './site.service';
import { IntegrationService } from './integration.service';
import { ConfigService } from './config.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {

    public isConnected = false;
    public isSuperuser = false;

    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;

    constructor(private router: Router, private http: HttpClient, private siteService: SiteService, private integrationService: IntegrationService,
                private configService: ConfigService) {
        this.currentUserSubject = new BehaviorSubject<User>(undefined);
        this.currentUser = this.currentUserSubject.asObservable();

        // Watch for the credentials
        this.integrationService.credentials_obs.pipe(first()).subscribe((credentials) => {
            if (credentials != undefined) {
                this.logout();
                // Get credentials from URL
                this.login(credentials.username, credentials.password).then((data) => {
                    // Success
                    // Check if we need to change the site ID
                    if (integrationService.siteId != undefined)
                        this.siteService.selectSite(integrationService.siteId);
                    // Redirect if we have an URL
                    if (integrationService.returnUrl != undefined)
                        this.router.navigate([integrationService.returnUrl]);
                }, (err) => {
                    console.log(err);
                    // Error
                    this.logout();
                    this.router.navigate(['/login']);
                });
            } else {
                // Get credentials saved in local storage
                var user = JSON.parse(localStorage.getItem('currentUser'));
                // If we got some credentials, try to login
                if (user != undefined && user.username != undefined && user.password != undefined && user.authdata != undefined) {
                    this.login(user.username, user.password, user.authdata).then(
                        data => {
                            // Success
                            // Redirect if we have an URL
                            if (integrationService.returnUrl != undefined)
                                this.router.navigate([integrationService.returnUrl]);
                        },
                        error => {
                            console.log(error);
                            // Error
                            this.logout();
                            this.router.navigate(['/login']);
                        }
                    );
                } else {
                    // Remove user from local storage to log user out
                    localStorage.removeItem('currentUser');
                    this.currentUserSubject.next(undefined);
                }
            }
        });
    }

    // Request the server to login (will use authdata parameter if present)
    login(username: string, password: string, optionalAuthdata?) {
        return new Promise((resolve, reject) => {
            // Either we calculate the authdata, or we use the optional one if possible
            var authdata = (optionalAuthdata) ? optionalAuthdata : window.btoa(username + ':' + password);
            var user: User = new User(username, password, authdata);
            // Request the server to login
            this.http.get<Site[]>(this.configService.config.network.navizonApiUrl + "/sites/", { headers: {"Authorization": "Basic " + user.authdata}})
            .pipe(first()).subscribe((sites: Site[]) => {
                // Login successful
                if (sites && sites.length > 0) {
                    // We don't want to store the superuser status in local storage
                    delete user.isSuperuser;
                    // Store user details and basic auth credentials in local storage 
                    // to keep user logged in between page refreshes
                    localStorage.setItem('currentUser', JSON.stringify(user));
                    // Update our site service
                    var currentSite = this.siteService.loadSites(sites.map(function(e) { return Site.fromData(e); }));
                    // Set booleans
                    this.isConnected = true;
                    // Check if superuser
                    this.isSuperuser = (currentSite && currentSite.access == "superuser") ? true : false;
                    user.setSuperuser(this.isSuperuser);
                    // Notify our subscribers
                    this.currentUserSubject.next(user);
                    resolve(user);
                } else
                    reject('No site found for this user');
            }, (err) => {
                reject(err);
            });
        });
    }

    logout() {
        // Remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        this.currentUserSubject.next(undefined);
    }
}