
<div id="lineHeatSelector" class="w-100 text-center mb-3" >
    <h1>Settings</h1>
</div>
<mat-card>
    <mat-card-header>
      <mat-card-title>History display measure units</mat-card-title>
    </mat-card-header>
    <div mat-card-image></div>
    <mat-card-content>
     <!-- Line/Heatmap selector -->
		<div id="lineHeatSelector" class="" >
			<mat-form-field  id="durationUnitSelector" class="mx-3" color="accent">
				<mat-select [(value)]="selectedDurationUnit" placeholder="Duration" (selectionChange)="durationUnitChanged($event)" >
				  <mat-option value="HourMinuteSecond">hour:minute:second</mat-option>
				  <mat-option value="DayHourMinuteSecond">day:hour:minute:second</mat-option>
				  <mat-option value="Minutes">Minutes</mat-option>
				  <mat-option value="Percent">Percent</mat-option>
				</mat-select>
				
			</mat-form-field>

			<mat-form-field id="distanceUnitSelector" class="mx-3" color="accent">
				<mat-select [(value)]="selectedDistanceUnit" placeholder="Distance" (selectionChange)="distanceUnitChanged($event)" >
				  <mat-option value="Meter">Meter</mat-option>
				  <mat-option value="kilometer">Kilometer</mat-option>
				  <mat-option value="Foot">Foot</mat-option>
				  <mat-option value="Mile">Mile</mat-option>
				</mat-select>
				
			</mat-form-field>

			<mat-form-field id="speedUnitSelector" class="mx-3" color="accent">
				<mat-select [(value)]="selectedSpeedUnit" placeholder="Speed " (selectionChange)="speedUnitChanged($event)" >
				  <mat-option value="Kmh">Km/h</mat-option>
				  <mat-option value="MPH">MPH</mat-option>
				  <mat-option value="ms">m/s</mat-option>
				</mat-select>
				
			</mat-form-field>
				

			<!-- Timezone selector -->
			<mat-form-field id="timezoneSelector" class="mb-2 ml-auto ml-sm-2 mr-auto mr-sm-0" color="accent">
				<input type="text" placeholder="Timezone" aria-label="Timezone" matInput [formControl]="currentTimezone" [matAutocomplete]="auto">
				<mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="onTimezoneSelected($event)">
					<mat-option *ngFor="let timezone of filteredTimezonesName | async" [value]="timezone">
						{{timezone}}
					</mat-option>
				</mat-autocomplete>
			</mat-form-field>
			
		</div>
    </mat-card-content>
    <!-- <mat-card-actions>
      <button mat-button>LIKE</button>
      <button mat-button>SHARE</button>
    </mat-card-actions> -->
  </mat-card>
  <mat-card>
    <mat-card-header>
      <mat-card-title>History calculation thresholds</mat-card-title>
    </mat-card-header>
    <div mat-card-image></div>
    <mat-card-content>
        		
		<div id="lineHeatSelector" class="" >
            <mat-form-field  id="stopThreshold" class="mx-3" color="accent" matTooltip="Stop threshold ( Minute : Second )">				
				<input type="text" matInput DurationPicker [value]="settingsService.getStopThresholdInput()" placeholder="Stop" (durationChanged)="settingsService.setStopThreshold($event.value)" />
				
				  <mat-hint align="end">mm:ss </mat-hint>
			</mat-form-field>

			<mat-form-field  id="offlineThresholdInput" class="mx-3" color="accent" matTooltip="Offline threshold ( Minute : Second )">				
				<input type="text"  matInput DurationPicker [value]="settingsService.getOfflineThresholdInput()" placeholder="Offline" (durationChanged)="settingsService.setOfflineThreshold($event.value)" />
<!-- 				
				<button matSuffix mat-icon-button color="accent" matTooltip="Apply" aria-label="Apply turned off threshold" (click)="applyOfflineThresholdChanged($event)">
					<mat-icon>save_alt</mat-icon>
				  </button> -->
				  
				  <mat-hint align="end">mm:ss </mat-hint>
			</mat-form-field>
	
			<!-- <mat-form-field  id="distanceThresholdInput" class="mx-3" color="accent" matTooltip="Distance threshold with selected distance measure unit">				
				<input type="number" matInput [formControl]="distanceThresholdInput" placeholder="Distance" (change)="applyThresholdChanged($event)"  />
				<mat-hint align="start">threshold</mat-hint>
				<mat-hint align="end">{{ selectedDistanceUnit }}</mat-hint>			
			</mat-form-field>	 -->
		</div>
    </mat-card-content>
  </mat-card>
		
