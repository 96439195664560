import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';

import { ConfigService } from './config.service';

@Injectable({ providedIn: 'root' })
export class MapService {

  constructor(private http: HttpClient, private configService: ConfigService) {}


  /* ------------------------------------------------------------------------- */
  /* -                            Public functions                           - */
  /* ------------------------------------------------------------------------- */
  // Get the list of maps
  public getMaps() {
    return new Promise((resolve, reject) => {
      // Make a GET request to get the list of map points
      this.http.get(`${this.configService.config.network.localUrl}/manage/maps/list`).subscribe((maps) => {
          // Success
          resolve(maps);
      }, (err) => {
          // Error
          reject(err);
      });
    });
  }

  // Get the map points for the current map
  public getMapPoints() {
    return new Promise((resolve, reject) => {
      // Make a GET request to get the list of map points
      this.http.get(`${this.configService.config.network.localUrl}/manage/maps/mappoints`).subscribe((mapPoints) => {
          // Success
          resolve(mapPoints);
      }, (err) => {
          // Error
          reject(err);
      });
    });
  }

  // Update the map
  public updateMap(map) {
    return new Promise((resolve, reject) => {
      if (map == undefined || map.map_name == undefined)
        return reject('Missing map ID (map_name)');
      let { map_name, description } = map;
      let data = { map_name, description };
      // Make a PUT request to update the map data
      this.http.put(`${this.configService.config.network.localUrl}/maps/${map.map_name}`, data).subscribe((res) => {
          // Check for success
          resolve(res);
      }, (err) => {
          // Error
          reject(err);
      });
    });
  }

  // Delete the map
  public deleteMap(map) {
    return new Promise((resolve, reject) => {
      if (map == undefined || map.map_name == undefined)
        return reject('Missing map ID (map_name)');
      // Make a DELETE request to delete the map
      this.http.delete(`${this.configService.config.network.localUrl}/maps/${map.map_name}`).subscribe((res) => {
          // Check for success
          resolve(res);
      }, (err) => {
          // Error
          reject(err);
      });
    });
  }

  // Load a map
  public loadMap(mapName) {
    return new Promise((resolve, reject) => {
      if (mapName == undefined)
        return reject('Wrong map ID (map_name)');
      // Make a GET request to load the map
      this.http.get(`${this.configService.config.network.localUrl}/maps/${mapName}`).subscribe((res) => {
          // Check for success
          resolve(res);
      }, (err) => {
          // Error
          reject(err);
      });
    });
  }

  // Reset the current map
  public resetMap() {
    return new Promise((resolve, reject) => {
      // Make a PUT request to load the map
      this.http.put(`${this.configService.config.network.localUrl}/manage/maps/reset`, {}).subscribe((res) => {
          // Check for success
          resolve(res);
      }, (err) => {
          // Error
          reject(err);
      });
    });
  }

  // Save the current points into the map passed as parameter
  // (can be used to create a new map)
  public saveMap(map) {
    return new Promise((resolve, reject) => {
      if (map == undefined || map.map_name == undefined)
        return reject('Missing map ID (map_name)');
      let { map_name, description } = map;
      let data = { map_name, description };
      // Make a POST request to save the map
      this.http.post(`${this.configService.config.network.localUrl}/maps`, data).subscribe((res) => {
          // Check for success
          resolve(res);
      }, (err) => {
          // Error
          reject(err);
      });
    });
  }

  /* ------------------------------------------------------------------------- */
  /* -                           Private functions                           - */
  /* ------------------------------------------------------------------------- */


}