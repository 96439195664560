<h1 class="text-center mb-4">Dragonfly License</h1>

<!-- Spinner -->
<div *ngIf="licenseInfo == undefined">
	<mat-spinner class="mx-auto" color="accent" [diameter]="36"></mat-spinner>
</div>

<!-- License info -->
<div class="d-block d-md-flex" *ngIf="licenseInfo != undefined">

	<!-- License not found -->
	<div class="d-flex flex-column mx-auto mt-5" *ngIf="licenseInfo.error != undefined">
		<h2 class="mx-auto">License not found</h2>
		<h3 class="mx-auto text-center">If you need one, please contact our team at: <a href = "mailto: techsupport.dragonflycv@onit.it">techsupport.dragonflycv@onit.it</a></h3>
	</div>

	<!-- License found -->
	<div class="mx-auto col-12 col-md-4 p-2" *ngIf="licenseInfo.company != undefined">
		<mat-card>
			<mat-card-content class="text-center">
				<h2 class="mb-3">Company</h2>
				{{licenseInfo.company}}
			</mat-card-content>
		</mat-card>
	</div>
	<div class="mx-auto col-12 col-md-4 p-2" *ngIf="licenseInfo.license_end_timestamp != undefined">
		<mat-card>
			<mat-card-content class="text-center">
				<h2 class="mb-3">Expiration date</h2>
				{{licenseInfo.license_end_timestamp}}
			</mat-card-content>
		</mat-card>
	</div>
	<div class="mx-auto col-12 col-md-4 p-2" *ngIf="licenseInfo.max_instances != undefined">
		<mat-card>
			<mat-card-content class="text-center">
				<h2 class="mb-3">Maximum number of devices</h2>
				{{licenseInfo.max_instances}}
			</mat-card-content>
		</mat-card>
	</div>

</div>

<h1 class="text-center mt-5 mb-3" *ngIf="licenseInfo != undefined && licenseInfo.error == undefined">Registered devices</h1>

<!-- Registered devices -->
<div *ngIf="licenseInfo != undefined && licenseInfo.error == undefined">
	<element-table
		[elements]="registeredDevices"
		[currentSite]="currentSite"
		[elementName]="'Dragonfly registered device'"
		[options]="stationsTableOptions"
		[displayedColumns]="stationsDisplayedColumns"
	    [attributesToDisplay]="stationsAttributesToDisplay"
	    [deleteElementFct]="licenseService.deleteDragonflyRegisteredDevice"
	    (deleteElementEvent)="onDeviceDeleted($event)"
	></element-table>
</div>