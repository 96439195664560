import { Component, OnDestroy, OnInit, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { HttpClient, HttpBackend } from '@angular/common/http';
import { Subscription, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { FormControl, FormGroup } from '@angular/forms';
import { Options, LabelType } from 'ng5-slider';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';

import L from 'leaflet';
import 'leaflet-hotline';
import 'leaflet.heat';
import '../../../../node_modules/leaflet-timedimension/dist/leaflet.timedimension.src.js';

import * as Plotly from 'plotly.js';

import { ALERT_TYPE, AlertService } from '../../_services/alert.service';
import { SiteService } from '../../_services/site.service';
import { ResponsiveService } from '../../_services/responsive.service';
import { LeafletService, TimeLayerAPI } from '../../_services/leaflet.service';
import { UtilsService } from '../../_services/utils.service';
import { FloorplanService } from '../../_services/floorplan.service';
import { LevelService } from '../../_services/level.service';
import { HistoryService, TECHNOLOGY } from '../../_services/history.service';
import { MarkerService } from '../../_services/marker.service';

import { Site } from '../../_models/site';
import { Floorplan } from '../../_models/floorplan';
import { Level } from '../../_models/level';
import { MapData } from '../../_models/mapData';
import { ActivityHistory, ActivityHistoryType, DeviceColors, HistoryBase, HistoryDevice, IHistoryDevice, IPolyline } from '../../_models/station';

import { YesNoDialogComponent } from '../../common/dialogs/yes-no-dialog.component';

import * as moment from 'moment';
import 'moment-timezone';
import { TableComponentCommand, TableComponentCommandEvent } from 'src/app/common/table/table.component';
import { Udo } from 'src/app/_models/udo';
import { ChartType, SettingsService } from 'src/app/_services/settings.service.js';
import { HistoryPosition, Position } from 'src/app/_models/position.js';
import { GeoFenceService } from 'src/app/_services/geoFence.service.js';
import { GeoFence } from 'src/app/_models/geoFence.js';

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
    parse: {
        dateInput: 'LL'
    },
    display: {
        dateInput: 'LL',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY'
    }
};

export interface BarChartData { duration:number,allDuration:number,distance:number,maxSpeed:number,averageSpeed:number,label:string,color?:string }
export interface PieChartData { label:string,duration:number,allDuration:number,distance:number, color:string }
export interface SelectionAreaData {color:string,label:string,from:Date,to:Date,allDuration:number,duration?:number}
export interface SelectedAreaContext { id:string, vertexes :string[][] , coordinates :number[][]}

@Component({
	templateUrl: 'history.component.html',
	styleUrls: ['history.component.scss'],
    providers: [
        // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
        // application's root module. We provide it at the component level here, due to limitations of
        // our example generation script.
        {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]},
        {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
        // Use UTC mode
        {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true}},
    ]
})
export class DragonflyHistoryComponent implements OnInit, AfterViewInit, OnDestroy {
    public selectedDevice: HistoryDevice|null = null;
    public selectedActivities: ActivityHistory[] = [];
    public selectionAreaData: SelectionAreaData[] = [];
	// The current site
	public currentSite: Site;
    // The current level
    private currentLevel: Level;
    // The current floorplan
    private currentFloorplan: Floorplan;
    // The current selected date
    public startSelectedDate = new FormControl();
    public startDate:Date|null = null;
    public endSelectedDate = new FormControl();
    public endDate:Date|null = null;
    public selectedArea:SelectedAreaContext|null = null;

    public filteredOptions: Observable<string[]>;

    isMultiDateSelect:Boolean= false;
    isSearched:Boolean= false;
    // Time slider variables
    // The current time range
    public currentTimeRangeMin = 0;
    public currentTimeRangeMax = 86340;
    public graphs:any[] = [];
    //     {
    //     data: [
    //       { x: [1, 2, 3], y: [2, 3, 4], type: 'bar' },
    //     ],
    //     layout: {title: 'Some Data to Hover Over'}
    //   }];
    
    public zeroTime: Date = new Date();
    public defaultFromValue: Date = new Date();
    public defaultToValue: Date = new Date();

    // Boolean to enabled/disable the heatmap
    public heatmapActivated: Boolean = false;
    public chartActivated: Boolean = false;
    public isPlaying: Boolean = false;
    
    public activitySelectedIndex: number = 0;
    public deviceSelectedIndex: number = 0;
    
    public heightActivated: Boolean = false;
    public timelineActivated: Boolean = false;
    public followTimelineActivated: Boolean = true;
    public filterBasedOnLevel: Boolean = false;

    // Boolean if we can delete the currently selected date
    public canDeleteSelectedDate: Boolean = false;
    // Boolean if we are deleting data
    public isDeletingData: Boolean = false;

    // The history status
    public historyStatus: Boolean = false;
    public loadingStatus : number = 0;
    public timeLayerAPI:TimeLayerAPI = null;

    // The list of enabled dates
    private enabledDates: string[] = [];
    private enabledDateRegexp = new RegExp('([0-9]{1,2})/([0-9]{1,2})/([0-9]{4})');
    // The list of devices location history
    public historyDevices: HistoryDevice[] = [];// Object containing all the displayed devices
    // List of polylines
    private _polylines = [];
    // List of heatmaps
    private _heatmaps = [];
    // List of markers
    private _markers = [];
    // Device table options
    public stationsTableOptions = {
        showable: true,
    }
    public activitiesTableOptions = {
        showable: true,
    }
    public selectionAreaTableOptions = {
        showable: true,
    }
    public stationsDisplayedColumns;
    public stationsCommands:TableComponentCommand[];
    public stationsAttributesToDisplay;

    public activitiesDisplayedColumns;   
    public activitiesAttributesToDisplay;


    public selectionAreaDisplayedColumns;   
    public selectionAreaAttributesToDisplay;




    private activityColors:Record<number,string> = {};

	// List of subs
	private subs: Subscription[] = [];

    // A new HttpClient to avoid the interceptor (for the presigned S3 url request)
    private http2: HttpClient;

	// The map container
	@ViewChild('map', { static: true }) mapContainer: ElementRef;
	private mapId = 'map';

    constructor(public settingsService: SettingsService,private siteService: SiteService, private leafletService: LeafletService, private levelService: LevelService,
    			private historyService: HistoryService, private alertService: AlertService, private http: HttpClient,
                private handler: HttpBackend, private floorplanService: FloorplanService, private utilsService: UtilsService,
                private adapter: DateAdapter<any>, private responsiveService: ResponsiveService, private dialog: MatDialog,
                public geoFenceService:GeoFenceService, private markerService: MarkerService) {
        var self = this;
        this.zeroTime.setHours(0,0,0,0);
        this.defaultFromValue.setHours(0,0,0,0);
        this.defaultToValue.setHours(23,59,59,999);

        this.http2 = new HttpClient(handler);

        this.activityColors[ActivityHistoryType.Driving] = 'limegreen';
        this.activityColors[ActivityHistoryType.Stopped] = 'orangered';
        this.activityColors[ActivityHistoryType.Offline] = 'moccasin';

        this.selectionAreaDisplayedColumns =['label','from','to','duration'];
        this.selectionAreaAttributesToDisplay = [            
            {display: 'Label', columnDef: 'label', getFct: (n) => {
                if (n) return n.label;
                return;
            }},           
            {display: 'From', columnDef: 'from', getFct: (n) => {
                if (n) return self.settingsService.formatDisplayDate(self.isMultiDateSelect,n.from);
                return;
            }},
            {display: 'To', columnDef: 'to', getFct: (n) => {
                if (n) return self.settingsService.formatDisplayDate(self.isMultiDateSelect,n.to); 
                return;
            }},
            {display: 'Duration', columnDef: 'duration', getFct: (n) => {
                if (n) return self.settingsService.formatDuration(n.duration,n.allDuration);// this.round((n.offlineDuration / n.allDuration) * 100);
                return;
            }}
        ];

        this.activitiesDisplayedColumns = ['label','start','stop','duration','distance','maxSpeed','averageSpeed','levelIdsText'];
        this.activitiesAttributesToDisplay = [            
            {display: 'Label', columnDef: 'label', getFct: (n) => {
                if (n) return n.label;
                return;
            },tooltipFct:  (n:ActivityHistory) => {
                let res ='Go to location';
                res += ` name : '${n.label}'`;//;
                res += ` level Id : '${n.levelIdsText}'`;
                return res;
            },  clickable: (n:IPolyline) => {

                // Get the positions that defines the polyline
                this.fitPolylineBounds(n);
            }},           
            {display: 'Start', columnDef: 'start', getFct: (n) => {
                if (n) return self.settingsService.formatDisplayDate(self.isMultiDateSelect,n.start);
                return;
            }},
            {display: 'Stop', columnDef: 'stop', getFct: (n) => {
                if (n) return self.settingsService.formatDisplayDate(self.isMultiDateSelect,n.stop); 
                return;
            }},
            {display: 'Duration', columnDef: 'duration', getFct: (n) => {
                if (n) return self.settingsService.formatDuration(n.duration,self.selectedDevice.allDuration);// this.round((n.offlineDuration / n.allDuration) * 100);
                return;
            }},
            {display: 'Distance', columnDef: 'distance', getFct: (n) => {
                if (n) return self.settingsService.formatDistance(n.distance);
                return;
            }},
            {display: 'Max Speed', columnDef: 'maxSpeed', getFct: (n) => {
                if (n) return self.settingsService.formatSpeed(n.maxSpeed);
                return;
            }},
            {display: 'Average Speed', columnDef: 'averageSpeed', getFct: (n) => {
                if (n) return self.settingsService.formatSpeed(n.averageSpeed);
                return;
            }},
            {display: 'level Id', columnDef: 'levelIdsText', getFct: (n) => {
                if (n) return n.levelIdsText;
                return;
            },  clickable: (n:IPolyline) => {

                // Get the positions that defines the polyline
                this.setLevel(n);
            }}
        ];

        this.setDevicesCommands();
        this.stationsDisplayedColumns = ['label', 'drivingDuration','stoppedDuration','offlineDuration','distance','maxSpeed','averageSpeed'];
        this.stationsAttributesToDisplay = [
            {display: 'Title', columnDef: 'label', getFct: (n:HistoryDevice) => {               
                return n.label;
            }, tooltipFct:  (n:HistoryDevice) => {
                let res ='Go to location';
                if (n?.udo?.name && n.udo.name != 'unknown')
                    res += ` name : '${n.udo.name}'`;//;
                res += ` mac : '${n.mac}' level Id : '${n.levelIdsText}'`;
                if (n?.udo?.desc && n.udo.desc != 'unknown')
                    res += ` description : '${n.udo.desc}'`;
                return res;
            }, clickable: (n:IPolyline) => {
                // Get the positions that defines the polyline
                this.fitPolylineBounds(n);
            }},
            {display: 'Driving', columnDef: 'drivingDuration', getFct: (n) => {
                if (n) return self.settingsService.formatDuration(n.drivingDuration,n.allDuration);
                return;
            }},
            {display: 'Stopped', columnDef: 'stoppedDuration', getFct: (n) => {
                if (n) return self.settingsService.formatDuration(n.stoppedDuration,n.allDuration); 
                return;
            }},
            {display: 'Turned off', columnDef: 'offlineDuration', getFct: (n) => {
                if (n) return self.settingsService.formatDuration(n.offlineDuration,n.allDuration);// this.round((n.offlineDuration / n.allDuration) * 100);
                return;
            }},
            {display: 'Distance', columnDef: 'distance', getFct: (n) => {
                if (n) return self.settingsService.formatDistance(n.distance);
                return;
            }},
            {display: 'Max Speed', columnDef: 'maxSpeed', getFct: (n) => {
                if (n) return self.settingsService.formatSpeed(n.maxSpeed);
                return;
            }},
            {display: 'Average Speed', columnDef: 'averageSpeed', getFct: (n) => {
                if (n) return self.settingsService.formatSpeed(n.averageSpeed);
                return;
            }}
        ];
    }


    private setLevel( n: IPolyline) {
        const self = this;    
        if(self.timelineActivated)
            return;
            
         // When user clicks on the name, move the map to the element
         if (self.leafletService == undefined || n == undefined || n.positions == undefined ||
            n.positions.length <= 0)
            return;
        // Change to the right level if needed
        if (n.levelIds != undefined && self.currentLevel != undefined &&
            self.currentLevel.levelId != undefined && !n.levelIds.includes(self.currentLevel.levelId))
            self.levelService.selectLevel(n.levelIds[0]); 
        
    }

    private fitPolylineBounds( n: IPolyline) {
        const self = this;

        self.setLevel(n);   

        setTimeout(()=>{

            var tempArr = (self.heatmapActivated == false) ?
                n.getPolylines()[0]
                :
                n.getHeatmaps();
            // Create the temporary polyline
            var temp = (self.heatmapActivated == false) ?
                L.hotline(tempArr)
                :
                L.heatLayer(tempArr);
            // Move the map to the correct bounds after 1 second
            self.leafletService.moveMapToBounds(temp.getBounds());
            
        },1000);        
    }

    private setDevicesCommands() {
        this.stationsCommands = [
            { key: 'detail', title: "Detail", icon: 'list_alt' },
            { key: 'export', title: "CSV Export", icon: 'cloud_download' },
        ];
    }

    wait() {
        this.loadingStatus++;
        
    }

    continue() {
        this.loadingStatus--;
        if(this.loadingStatus < 0)
            this.loadingStatus = 0;
    }
    clearWaiting() {
        this.loadingStatus = 0;
    }

    activitiesCommand(event:TableComponentCommandEvent) {
    
    }
    stationsCommand(event:TableComponentCommandEvent) {
        switch(event.command?.key)
        {
            case 'export':
                this.exportDevice(event.element);
            break;
            case 'detail':
                this.selectedDevice = event.element;
                this.selectedActivities =  this.selectedDevice.activities.filter(i=> i.type == ActivityHistoryType.Driving || i.type == ActivityHistoryType.Stopped)??[];
                this.activitySelectedIndex = 0;
                this.drawOnMap();
            break;
        }
    }

    exportDevice(element:HistoryDevice) {
        const self = this;
        const columns = ["Device","Mac","Start","Stop","Duration","Distance","MaxSpeed","LevelId"];
        this.exportDataToCsv<string,Record<string,any>>(`${(element.udo.name??"")}_${element.mac}`,columns,
            i=> i,
            element.activities.map(i=>{
                return {...i,
                    Device:element.udo.name??"" ,
                    Mac:element.mac??"" ,
                    Start: self.settingsService.formatDate(i.start),
                    Stop: self.settingsService.formatDate(i.stop),
                    Duration : self.settingsService.formatDuration(i.duration,element.allDuration) ,
                    Distance : self.settingsService.formatDistance(i.distance),
                    MaxSpeed : self.settingsService.formatSpeed(i.maxSpeed),
                    AverageSpeed : self.settingsService.formatDistance(i.averageSpeed),
                    LevelId : i.levelIdsText
                };
            }),
            (c,r)=> r[c]);
    }
    exportSelectionArea() {
        const self = this;
        const columns = ["Label","From","To","Duration"];
        this.exportDataToCsv<string,Record<string,any>>(`Selected_Area_Devices`,columns,
            i=> i,
           self.selectionAreaData.map(i=>{
                return {
                    Label:i.label ,
                    From: self.settingsService.formatDate(i.from),
                    To: self.settingsService.formatDate(i.to),
                    Duration : self.settingsService.formatDuration(i.duration,i.allDuration) ,
                };
            }), (c,r)=> r[c]);
    }
    
    returnToDevices() {
        this.selectedDevice =null;
        this.selectedActivities =[];
        this.drawOnMap();
    }
    exportToCsv() {
        const self = this;
        this.exportDataToCsv<any,HistoryDevice>(`From_${moment(this.startDate).format('MMMM Do YYYY')}_To_${moment(this.endDate).format('MMMM Do YYYY')}`,this.stationsAttributesToDisplay.filter(i=> self.stationsDisplayedColumns.includes(i.columnDef)),
                i=> i.display,
                this.historyDevices,
                (c,r)=> c.getFct(r));
    }

    goToInfo() {
        window.open("https://dragonflycv.com/support/knowledge-base/information-about-the-dragonfly-dashboard/#Dragonfly_History", "_blank");
    }

    exportDataToCsv<TColumn,TRow>(filename:string,keys:TColumn[],getTitle:(column:TColumn) => string, values: TRow[],getValue:(column:TColumn,row:TRow) => any) {
        filename =`${filename}.csv`; 
        const separator = ',';
        const csvContent =
          keys.map(i=>getTitle(i)).join(separator) +
          '\n' +
          values.map(row => {
            return keys.map(k => {
              let cell = getValue(k,row);
              cell = cell instanceof Date
                ? cell.toLocaleString()
                : cell.toString().replace(/"/g, '""');
              if (cell.search(/("|,|\n)/g) >= 0) {
                cell = `"${cell}"`;
              }
              return cell;
            }).join(separator);
          }).join('\n');
  
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        if (navigator['msSaveBlob']) { // IE 10+
            navigator['msSaveBlob'](blob, filename);
        } else {
          const link = document.createElement('a');
          if (link.download !== undefined) {
            // Browsers that support HTML5 download attribute
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', filename);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        }
      }

    ngOnInit() {
        var self = this;
        // Get the current timezone (guessing the user's)
        //(moment as any).tz.guess(true) || 
        // Set moment to the current timezone
        (moment as any).tz.setDefault(self.settingsService.getSelectedTimezone());
    	// Get the selected site
		this.subs.push(this.siteService.currentSite.subscribe((site: Site) => {
			self.currentSite = site;
            // Clear variables
            self.historyStatus = undefined;
            self.isSearched = false;
            self.resetValues(true,true);
            if (self.currentSite != undefined && self.currentSite.siteId != undefined) {
                // Get the history status
                self.historyService.getHistoryStatus(self.currentSite.siteId, TECHNOLOGY.Dragonfly).then((res: any) => {
                    // Get the enabled dates
                    self._getDates();
                }, (err) => {
                    // Error getting history status
                    self.alertService.showMessage(ALERT_TYPE.Error, "An error occurred, cannot get the history status");
                    self.historyStatus = false;
                });
            }
		}));
        // Get the current level
        this.subs.push(this.levelService.currentLevel.subscribe((currentLevel: Level) => {
            const wasChanged = self?.currentLevel?.levelId !=  currentLevel?.levelId;
            self.currentLevel = currentLevel;
            if(self?.currentLevel && self.filterBasedOnLevel && wasChanged && self.isSearched)
            {
                self.search();
            }
        }));
        // Get the current floorplan
        this.subs.push(this.floorplanService.currentFloorplan.subscribe((currentFloorplan: Floorplan) => {
            self.currentFloorplan = currentFloorplan;
            // Try to draw the map
            self.drawMap();
        }));
        
        this.filterBasedOnLevel = this.settingsService.getFilterBasedOnLevel();   
        //     self.defaultToValue =  self.settingsService.getTimeRangeMax(self.defaultToValue);
        //     self.timeToChanged(self.defaultToValue);
        //     self.defaultFromValue = self.settingsService.getTimeRangeMin(self.defaultFromValue);
        //     self.timeFromChanged(self.defaultFromValue);
       
        //     self.startSelectedDate.setValue(self.settingsService.getStartDate(self.startSelectedDate.value));
        // setTimeout(()=>{
        //     self.endSelectedDate.setValue(self.settingsService.getEndDate(self.endSelectedDate.value));
        // },1000);    
        this.geoFenceService.refreshGeoFences(); 
    }


    ngAfterViewInit() {
        // Try drawing the map
        this.drawMap();
    }

    ngOnDestroy() {
    	var self = this;
    	// Clear the subs
    	this.subs.forEach(function(sub) { sub.unsubscribe(); });
	}



    /* ------------------------------------------------------------------------- */
    /* -                           Private functions                           - */
    /* ------------------------------------------------------------------------- */

    // Get the enabled dates for the current site
    private _getDates() {
        const self = this;
        self.clearWaiting();
        this.wait();
        this.historyService.getEnabledDates(this.currentSite.siteId, TECHNOLOGY.Dragonfly).then((enabledDates: string[]) => {
            // Save the dates
            this.enabledDates = enabledDates;
            console.log(this.enabledDates);
            // Check the number of enabled dates and show (or not) the history page
            this.historyStatus =  (this.enabledDates && this.enabledDates.length && this.enabledDates.length >= 1) ? true : false;
            // Try drawing the map if needed
            if (this.historyStatus == true)
                this.drawMap();
        }, (err) => {
            // Error getting enabled dates
            this.alertService.showMessage(ALERT_TYPE.Error, "An error occurred, cannot get the enabled dates");
        }).finally(()=> {
            self.clearWaiting();
        });
    }

    private _prepareDevices(devices:any[],tempDevices:Record<string,HistoryDevice>) {
        const self = this;
        if (devices != undefined && devices.length > 0) {
            // Regroup locations per device
            let index = Object.keys(tempDevices).length % DeviceColors.length;
            devices.forEach(function(d) {
                const p = d?.position as Position;
                if(!self.filterBasedOnLevel || self.currentLevel?.levelId == p?.levelId)
                {
                    if (tempDevices[d.mac] == undefined){
                        tempDevices[d.mac] = HistoryDevice.fromData(d,self.settingsService.getSelectedTimezone(),DeviceColors[index]);
                        index = (++index) % DeviceColors.length;                    
                    }
                    else{
                        tempDevices[d.mac].udo = Udo.fromData(d.udo);
                        tempDevices[d.mac].addPosition(d.position,self.settingsService.getSelectedTimezone());
                    }
                }
                   
            });

            let idx = 0;
            // Update our variable
            for (var mac in tempDevices) {
                this.historyDevices.push(tempDevices[mac]);
                tempDevices[mac].index = idx++;
                this.canDeleteSelectedDate = true;
            }
        } 
    }
    private _calculateDevices(tempDevices:Record<string,HistoryDevice>) {
        const self = this; 

        this.canDeleteSelectedDate = false;
        this.isSearched = true;
        // Update our variable
        this.historyDevices = [];
        this.deviceSelectedIndex =0;
        const promises:Promise<boolean>[]=[];
        for (var mac in tempDevices) {
            
            promises.push(this.calculateActivities(tempDevices[mac]));            
            this.historyDevices.push(tempDevices[mac]);
            this.canDeleteSelectedDate = true;
        }
        Promise.all(promises).then(()=>{
            self.clearWaiting();
            this.drawOnMap();
            self.continue(); 
        });
    }


    /* ------------------------------------------------------------------------- */
    /* -                          Stations functions                           - */
    /* ------------------------------------------------------------------------- */

    changeDeviceColor(event) {
        this.drawOnMap();
    }
    showAllDevice(event) {
        // event[0] = Checkbox event (to know if it is checked or unchecked)
        // event[1] = HistoryDevice object
        // Check we have everything we need, then add it into our list of displayed devices
        // const isSelected = (event != undefined && event.length > 1 && event[0] != undefined &&
        //     event[0].checked == true && event[1] != undefined && event[1].positions != undefined && event[1].positions.length > 0);
        
        // event[1].isSelected = isSelected;
        // Update the polylines/heatmaps on the map
        this.drawOnMap();
    }

    showDevice(event) {
        // event[0] = Checkbox event (to know if it is checked or unchecked)
        // event[1] = HistoryDevice object
        // Check we have everything we need, then add it into our list of displayed devices
        // const isSelected = (event != undefined && event.length > 1 && event[0] != undefined &&
        //     event[0].checked == true && event[1] != undefined && event[1].positions != undefined && event[1].positions.length > 0);
        
        if(event && event[1] && event[1].isSelected)
        {
            this.fitPolylineBounds(event[1]);
        }
        // event[1].isSelected = isSelected;
        // Update the polylines/heatmaps on the map
        this.drawOnMap();
    }

    /* ------------------------------------------------------------------------- */
    /* -                             Map functions                             - */
    /* ------------------------------------------------------------------------- */

    // Try to draw the map
    drawMap() {
        var self = this;
    	if (this.currentSite != undefined && this.mapContainer != undefined) {
            // Remove the map
            this.leafletService.removeMap();
            // (Re)draw it
            this.leafletService.drawMap(this.mapId, new MapData({
                polylines: this._polylines,
                showGeoFences: true,
                geoFences:  self.geoFences,
                showDrawToolbar: true,
                hideEditInToolbar:true,
                onGeoFenceCreated: function(element) {
                  //setTimeout(()=>{
                    self.createGeoFence(element);
                    self.filterDevicesBasedOnGeoFences();
                  //},200);  
                },
            }));
    	}
    }
    selectionIncluded:boolean= true;
    readonly geoFences: Observable<GeoFence[]> = this.geoFenceService.autocomplete;
    readonly selectedGeoFence = new FormControl();
    onGeoFenceInput(event: Event): void {
      this.geoFenceService.setAction((event?.target as HTMLInputElement)?.value);
    }
    onGeoFenceSelected(event: any): void {
    	var self = this;
        const selectedItem = this.geoFenceService.loadedGeoFences.find(i=> i.id == event.option.id);
        if(selectedItem != null)
        {
            self.selectionIncluded = selectedItem.behavior == "include";
            var coordinates = [[]];
            selectedItem.vertexes.forEach(function(latLng) {
                coordinates[0].push([parseFloat(latLng[0]), parseFloat(latLng[1])]);
            });
            self.selectedArea = {
                id : "Selection",
                vertexes: selectedItem.vertexes,
                coordinates:selectedItem.vertexes
            };
            this.leafletService.updateGeoFences([self.selectedArea]); 
            self.filterDevicesBasedOnGeoFences();
        }
    }

    public returnFromSelection(event) {
    	var self = this;
        this.selectedArea = null;
        this.selectedGeoFence.setValue("");
        this.leafletService.updateGeoFences([]); 
        this.drawOnMap();
    }
    public selectionIncludeChanged(event) {
        this.drawOnMap();
    }
    public filterDevicesBasedOnGeoFences(){
    	var self = this;
        if(self.selectedArea == null)
        {
            self.selectionAreaData = [];           
        }
        else{
            const data :SelectionAreaData[] = [];
            let current:SelectionAreaData = null;

            this.historyDevices.forEach(device=>{
                device.positions.forEach(position=>{
                    const isIn = self.leafletService.isPointInsidePolygon(position.content,self.selectedArea.coordinates);
                    if((isIn && self.selectionIncluded) || (!isIn && !self.selectionIncluded)){    
                        if(current)
                        {
                            current.to = position.dateTime;
                        }
                        else{
                            current = { label: device.label ,color : device.color,allDuration:device.allDuration,from:position.dateTime,to:position.dateTime,duration : 0};
                            data.push(current);
                        }
                    }
                    else{
                        current = null;
                    }
                })
            });
            data.forEach(i=>i.duration =  ((i.to as any) - (i.from as any)));
            self.selectionAreaData = data;
        }
    }
    // When user wants to create a geo-fence
    public createGeoFence(element) {
    	var self = this;
    	// Check object
        if (element == undefined || element.layer == undefined || element.layer._latlngs == undefined ||
        	element.layer._latlngs.length <= 0 || element.layer._latlngs[0].length <= 0) {
            this.alertService.showMessage(ALERT_TYPE.Error, "An error occurred, cannot create the geo-fence");
            return;
        }
        var vertexes = [[]];
        var coordinates = [[]];
        console.log(element.layer._latlngs)
        element.layer._latlngs[0].forEach(function(latLng) {
        	vertexes[0].push([latLng.lat.toFixed(6), latLng.lng.toFixed(6)]);
        	coordinates[0].push([latLng.lat, latLng.lng]);
        });
        self.selectedArea = {
            id : "Selection",
    		vertexes: vertexes,
    		coordinates:coordinates
    	}
        this.selectedGeoFence.setValue("");
        this.leafletService.updateGeoFences([self.selectedArea]);    
    }
    // recalculateActivities() 
    // {
    //     var self = this;
    //     this.historyDevices.forEach(device=>{
    //         self.calculateActivities(device);
    //     });
    // }   
    calculateActivities(device:HistoryDevice):Promise<boolean> {
        var self = this;
        return device.calculateDistance(self.settingsService.getStopThreshold() ,self.settingsService.getOfflineThreshold(),self.startDate,self.endDate,self.settingsService.distanceThreshold);
    }
    drawLinesOnMap() {
        var self = this;
        if(this.heightActivated || !this.timelineActivated)
        {
            if(this.selectedDevice)
            {
                this.drawPolyLinesOnMap(this.selectedActivities.filter(i=> i.isSelected));
            }
            else
            {
                this.drawPolyLinesOnMap(this.historyDevices.filter(i=> i.isSelected));
            }

        }
        else
        {
            this.clearPolylinesHeatmaps();
            const r:any[] =[];
            if(this.selectedDevice)
            {
                this.selectedActivities.filter(i=> i.isSelected &&  (i.type == ActivityHistoryType.Driving ||  i.type == ActivityHistoryType.Stopped)).forEach((activity,activityIdx)=>{
                    self.createCoordinates(activity.positions, r, activity.label, activity.color, activity.isRect,activityIdx,(v)=> { 
                        activity.isProcessing = v
                        self.activitySelectedIndex = activityIdx;
                    });
                });
            }
            else
            {
                this.historyDevices.filter(i=> i.isSelected).forEach((device,deviceIdx)=>{
                    device.activities.filter(i=> i.type == ActivityHistoryType.Driving ||  i.type == ActivityHistoryType.Stopped).forEach(activity=>{
                        self.createCoordinates(activity.positions, r, device.label,device.color, activity.isRect,deviceIdx,(v)=> {
                                device.isProcessing = v;                            
                                self.deviceSelectedIndex = deviceIdx;
                            });
                    });
                    
                });
            }        
            self.timeLayerAPI = this.leafletService.addTimeLayer((label, alt, dateTime)=>self.getMarkerTooltip(label, alt, dateTime),r,()=> self.followTimelineActivated,self.settingsService.getSelectedTimezone(),()=>{
                self.changePlayingState(false);
            },()=>{
                self.changePlayingState(true);
            });
        }
    }
    changePlayingState(value:Boolean,stopProcessing:Boolean = false){
        (this.selectedDevice ? this.selectedActivities as HistoryBase[] : this.historyDevices).forEach(i=>{
            i.isDisabled = value;
            if(!value && stopProcessing)
                i.isProcessing = false;
        });
        if(value)
        {
            this.stationsCommands = [];
        }
        else
        {      
            if(this.timeLayerAPI)
                this.timeLayerAPI.stop();      
            this.setDevicesCommands();
        }
        this.isPlaying = value;
    }
    private createCoordinates(positions: HistoryPosition[], r: any[],label: string, color: string,isStopped: Boolean,index:number,changeProcessing:(value:Boolean)=> void) {

        if(positions.length> 0)
            r.push({
                isStopped,
                index: index,
                changeProcessing,
                label,
                time: positions[0].dateTime,
                color: color,
                coordinates: [
                    [positions[0].content.lng,positions[0].content.lat,positions[0].content.alt],
                    [ positions[0].content.lng,positions[0].content.lat,positions[0].content.alt]
                ]
            });

        for (let i = 1; i < positions.length; i++) {
            r.push({
                isStopped,
                index: index,
                changeProcessing,
                label,
                time: positions[i].dateTime,
                color: color,
                coordinates: [
                    [positions[i - 1].content.lng,positions[i - 1].content.lat,positions[i - 1].content.alt],
                    [ positions[i].content.lng,positions[i].content.lat,positions[i].content.alt]
                ]
            });
        }
    }



    // Draw all the devices lines from 'displayedDevices' variable
    drawPolyLinesOnMap(polyLines:IPolyline[]) {
        var self = this;
        // Clear all the existing polylines and heatmaps
        this.clearPolylinesHeatmaps();

        // If missing data in the date, stop here
        if (!this.hasValidSelectedDateRange())
            return;
            
        // Draw only the devices we want to display
        polyLines.forEach(device=> {            
            
            // Get the positions that defines the polyline
            var polylinesArr = device.getPolylines();
            // If there is no polylines to display, stop this iteration
            if (polylinesArr == undefined || polylinesArr.length <= 0)
                return;
            var hotlinesArr = [];
            // For each line, generate a hotline
            polylinesArr[0].forEach((polylineData) => {
                if (!(polylineData && polylineData.length > 1))
                    return;
                // Create the nulticolor polyline
                // See doc:  https://github.com/iosphere/Leaflet.hotline/#documentation
                let polyline:any;
                if(this.heightActivated)
                {
                     polyline = L.hotline(polylineData, {
                        outlineWidth: 0,
                        weight: 2,
                        palette: this.heightActivated?{ 0.0: 'green', 0.5: 'yellow', 1.0: 'red' } : { 0.0: device.color, 1.0: device.color },
                        min: device.minAlt,
                        max: device.maxAlt
                    });
                }
               else{
                   polyline =  L.polyline(polylineData, { color: device.color });
               }
                polyline.bindTooltip(device.label);

                polyline.markers = [];
                // For each point, create a marker with a popup linked to it
                polylinesArr[1].forEach((point) => {
                    const latlng = { lat: point.content.lat, lng:  point.content.lng };
                    
                    var marker = L.marker(latlng);
                    marker.bindPopup(self.getMarkerTooltip(device.label,point.content.alt,point.dateTime)
                    , { offset: L.point([0, 38]) });
                    polyline.markers.push(marker);
                });

                polyline.on('click', (e) => {
                    // Determinate closest point
                    var closest = self.leafletService.locateOnLine(e.target.markers, e.latlng);
                    // Open popup if we can
                    if (closest && closest.layer && closest.layer._popup && closest.layer._popup.openOn && closest.layer._popup.setLatLng) {
                        closest.layer._popup.setLatLng({
                            lat: closest.layer._latlng.lat - 0.00001,
                            lng: closest.layer._latlng.lng
                        }).openOn(self.leafletService.getMap());
                    }
                });
                // Add the hotline to our arrays
                hotlinesArr.push(polyline);
                this._polylines.push(polyline);
            });
            // if (hotlinesArr.length <= 0)
            //     return;
            // Add the red dot on the end of the last line
            polyLines.filter(i=> i.positions.length > 0).forEach(device=>{
                const point = device.positions[device.positions.length -1];
                const redDotPosition =point.toArray();
                const latLng = L.latLng(redDotPosition[0],redDotPosition[1]);
             
                const redDotMarker = self.leafletService.createMarker(latLng, device.positions.length > 1 ? device.label : self.getMarkerTooltip(device.label,point.content.alt,point.dateTime),device.color,device.isRect);
                this._markers.push(redDotMarker);
                this.leafletService.addOnMap(redDotMarker);
               
            });

            // When done generating all the hotlines, add them to the map and center it
            var featureGroup = L.featureGroup(hotlinesArr);
            const addRes =  this.leafletService.addOnMap(featureGroup); 
        });
    }
    private getMarkerTooltip(label: string, alt: number, dateTime: Date): string {
        
        return `${label},  Altitude: ${alt.toFixed(4)} , Time: ${this.settingsService.formatDisplayDate(this.isMultiDateSelect, dateTime)}`;
    }

    isValidDate(dateTime:FormControl){
        return !(dateTime == undefined || 
                dateTime.value == undefined || 
                dateTime.value.valueOf == undefined);
    }
    hasValidSelectedDateRange(){
        return this.isValidDate(this.startSelectedDate) &&  this.isValidDate(this.endSelectedDate);
    }
    getDateTimestamp(dateTime:FormControl){
        // Calculate the UTC offset for the currently selected timezone
        var timezoneUtcOffset = (moment as any).tz.zone(this.settingsService.getSelectedTimezone()).utcOffset(dateTime.value.valueOf());
        // Get the timestamp of the selected date, and add the UTC offset
        var currDateTimestamp = dateTime.value.valueOf() + (timezoneUtcOffset * 60 * 1000);
        return currDateTimestamp;
    }
    // Draw all the devices heatmaps from 'displayedDevices' variable
    drawHeatmapsOnMap() {
        var self = this;
        // If missing data in the date, stop here
        if (self.selectedDevice)
        {
            self.drawpSelectedHeatmapsOnMap(self.selectedActivities.filter(i=> i.isSelected));
        }
        else
        {
            self.drawpSelectedHeatmapsOnMap(self.historyDevices.filter(i=> i.isSelected));
        }
    }
   
    drawpSelectedHeatmapsOnMap(polyLines:IPolyline[]) {
        var self = this;
        // Clear all the existing polylines and heatmaps
        this.clearPolylinesHeatmaps();
        // If missing data in the date, stop here
        if (!this.hasValidSelectedDateRange())
            return;
        // Draw only the devices we want to display
        for (var device of polyLines) {
            // Get the positions that defines the heatmap
            var heatmapsArr = device.getHeatmaps();
            // If there is no polylines to display, stop this iteration
            if (heatmapsArr == undefined || heatmapsArr.length <= 0)
                continue;
            // Create the heatmap
            // See doc:  https://github.com/Leaflet/Leaflet.heat
            var heatmap = L.heatLayer(heatmapsArr);
            this.leafletService.addOnMap(heatmap, true);
            this._heatmaps.push(heatmap);
        }
    } 
    selectedChartChanged(event){    
        this.settingsService.selectedChartChanged(event);   
        this.drawOnMap(); 
    }
    _lastChartType:string|null=null;
    // Choose to draw the lines or the heatmaps depending on the user choices
    drawOnMap() {
        const self = this;
        
        if(this.selectedArea)
        {
            this.filterDevicesBasedOnGeoFences();
        }
        else if(this.chartActivated)
        {
            this.graphs = [];

            const activities = ()=> this.selectedActivities.filter(i=> i.isSelected);

            const dDevices = ()=> this.historyDevices.filter(i=> i.isSelected);
            
                      
            if((this.selectedDevice == null && dDevices().length == 0) ||
                (this.selectedDevice != null && activities().length  == 0))
                return;
            
            const barData =this.selectedDevice == null ? ()=> dDevices().map(i=>{
                return { 
                    duration: i.allActiveDuration,
                    distance: i.distance,
                    color: i.color,
                    label: i.label,
                    averageSpeed: i.averageSpeed,
                    maxSpeed: i.maxSpeed,
                    allDuration:i.allDuration
                };
            }) : ()=> activities().map(i=>{
                return { 
                    duration: i.duration,
                    distance:i.distance,
                    color:i.color,
                    label:i.label,
                    averageSpeed: i.averageSpeed,
                    maxSpeed: i.maxSpeed,
                    allDuration:self.selectedDevice.allActiveDuration
                };
            });

            const pieData =this.selectedDevice == null ?
            ()=> dDevices().map(i=>{
                return { label: i.label,duration: i.allActiveDuration,allDuration:i.allDuration,distance:i.distance, color:i.color};
            }): ()=> activities().map(i=>{
                return { label: i.label,duration:i.duration,allDuration:self.selectedDevice.allActiveDuration,distance:i.distance, color:i.color};
            });

            switch(self.settingsService.selectedChart){                
                case ChartType.DurationPieChart:
                    this.addDurationPieChart(pieData());     
                    break;
                case ChartType.DistancePieChart:    
                    this.addDistancePieChart(pieData());    
                    break;
                case ChartType.DistanceBarChart:
                    this.addDistanceBarChart(barData());
                    break;
                case ChartType.DurationBarChart:
                    this.addDurationBarChart(barData());
                    break; 
                case ChartType.MaximumSpeedBarChart:
                    this.addMaximumSpeedBarChart(barData());
                    break; 
                case ChartType.AverageSpeedBarChart:
                    this.addAverageSpeedBarChart(barData());
                    break; 
                case ChartType.DurationDistanceInRange:                        
                    if(this.selectedDevice == null)
                        self.returnToLastChart(ChartType.DurationDistanceInRange);
                    else{
                        this.addDeviceDurationDistanceInTimeChart(this.selectedDevice.maxActiveDuration,
                            this.selectedDevice.allActiveDuration,
                            activities());
                    }
                    break; 
                case ChartType.EachDeviceDurationPercent:
                    if(this.selectedDevice == null)
                        this.addEachDeviceDurationPercentChart(self, dDevices());
                    else 
                        self.returnToLastChart(ChartType.DurationRange);
                    break;
                case ChartType.DurationRange:
                    if(this.selectedDevice == null)
                        this.addDurationRangeChart(self, dDevices()); 
                    else 
                        self.returnToLastChart(ChartType.DurationRange);
                    break;
                default:
                    break;
            }
        }
        else
        {
            if (this.heatmapActivated == true)
                this.drawHeatmapsOnMap();
            else
                this.drawLinesOnMap();
        }
    }

    private returnToLastChart(chartType:ChartType) {
        const self= this;
        const sChart = self._lastChartType ?? chartType;
        self._lastChartType = self.settingsService.selectedChart;
        self.settingsService.selectedChart = sChart;
        self.drawOnMap();
    }

    private addDurationRangeChart(self: this, dDevices: HistoryDevice[]) {
        const traces2: any[] = [];

        const showlegend: Record<number, boolean> = {};

        this.historyDevices.filter(i=> i.isSelected).forEach(device => {
            device.activities.map(i => {
                const txt = self.settingsService.formatDuration(i.duration, dDevices[0].allDuration);
                var from = self.settingsService.formatDisplayDate(self.isMultiDateSelect,i.start);
                var to = self.settingsService.formatDisplayDate(self.isMultiDateSelect,i.stop);


                return {
                    x: [i.duration],
                    y: [device.label],
                    showlegend: showlegend[i.type] ? false : (showlegend[i.type] = true),
                    orientation: 'h',
                    type: 'bar', 
                    hovertemplate:
                    `<b>${txt}</b><br><br>` +
                    `From '${from}'<br>` +
                    `To    '${to}'<br>` +
                    (i.averageSpeed>0 ? `Average speed '${self.settingsService.formatSpeed(i.averageSpeed)}'<br>Max speed      '${self.settingsService.formatSpeed(i.maxSpeed)}'<br>` :"") +
                    "<extra></extra>",
                    label: 'ffff',
                    text: txt,
                    name: ActivityHistoryType[i.type],
                    legendgroup: ActivityHistoryType[i.type],
                    marker: {
                        color: self.activityColors[i.type],
                        width: 1
                    }
                };
            }).forEach(i => traces2.push(i));
        });

        const [config,layout] = self.getChartConfig();
        const xaxis = this.getTimeRangeAxis(dDevices[0].allDuration);
        this.graphs.push({
            data: traces2,
            layout: {...layout,
                xaxis,
                yaxis: {
                    tickangle: 45
                },
                barmode: 'stack',
                displayModeBar: false
            },config
        });
    }
    private getTimeRangeAxis(allDuration:number){
        const self = this;
        const tickvals:any[] = [];
        const ticktext:any[] = []; 
        const part = allDuration/12;
        
        let start = self.startDate;

        for(let i= 0; i<=12; i++){
            tickvals.push(i * part); 
            ticktext.push(this.settingsService.formatDisplayDate(self.isMultiDateSelect,moment.utc(start).add(i * part,'milliseconds').toDate()));
        }  
        return {
            tickvals,
            ticktext,
            tickangle: 15
        };
    }
    private getDurationRangeAxis(maxDuration:number,allDuration:number){
        const self = this;
        const tickvals:any[] = [];
        const ticktext:any[] = []; 
        const part = maxDuration/8;
        for(let i= 0; i<=8; i++){
            tickvals.push(i * part); 
            ticktext.push(self.settingsService.formatDuration(i * part,allDuration));
        }  
        return {
            tickvals,
            ticktext,
            tickangle: 15
        };
    }

    private addDurationPieChart( dDevices: PieChartData[]) {
        const self = this;
        const [config,layout] = self.getChartConfig();

        this.graphs.push({
        data : [{           
            values: dDevices.map(i=> i.duration),
            labels: dDevices.map(i=> `${i.label} = ${self.settingsService.formatDuration(i.duration,i.allDuration)}`),
            domain: {column: 1},
            marker: {
                colors: dDevices.map(i=> i.color)
            },
            textposition: 'inside',
            hoverinfo: 'label+percent',
            type: 'pie'
            }],
        layout :layout,
        config
        });
     }
    private addDistancePieChart( dDevices: PieChartData[]) {
        const self = this;
        const [config,layout] = self.getChartConfig();  
        this.graphs.push({
            data : [{           
                values: dDevices.map(i=> i.distance),
                labels: dDevices.map(i=> `${i.label} = ${self.settingsService.formatDistance(i.distance)}`),
                domain: {column: 1},
                marker: {
                  colors: dDevices.map(i=> i.color)
                },
                textposition: 'inside',
                hoverinfo: 'label+percent',
                type: 'pie'
              }],
            layout :layout,
            config
          });
    }

    divide(numerator:number, denominator:number) {
        if(denominator == 0)
            return 0;
        return numerator / denominator;
    }
    
    private addDurationBarChart(dDevices: BarChartData[]) {
        
        const self= this;
        const [config,layout] = self.getChartConfig();

        const durationDevices =dDevices.sort((a, b) => b.duration - a.duration); 
        this.graphs.push({
            data :[{
            x: durationDevices.map(i=> i.label),
            y: durationDevices.map(i=> (i.duration / (60 * 1000))),
            text: durationDevices.map(i=> self.settingsService.formatDuration(i.duration,i.allDuration)),
            hoverinfo: 'text',
            name:durationDevices.map(i=> i.label),
            type: 'bar',
            marker: {
                color: durationDevices.map(i=> i.color)
            }            
            }],
            layout :{...layout , 
                yaxis: {
                    title: 'Minutes',
                }
            },
            config
        });

    }
    
    private addDistanceBarChart(dDevices: BarChartData[]) {
        
        const self= this;
        const [config,layout] = self.getChartConfig();

        const distanceDevices =dDevices.sort((a, b) => b.distance - a.distance); 
        this.graphs.push({
            data :[{
            x: distanceDevices.map(i=> i.label),
            y: distanceDevices.map(i=> self.settingsService.convertDistanceToMeter(i.distance)),
            text: distanceDevices.map(i=> self.settingsService.formatDistance(i.distance)),
            hoverinfo: 'text',
            name:distanceDevices.map(i=> i.label),
            type: 'bar',
            marker: {
                color: distanceDevices.map(i=> i.color)
            }            
            }],
            layout :{...layout , 
                yaxis: {
                    title: self.settingsService.getDistanceUnit(),
                }
            },
            config
        });

    }
    
    private addAverageSpeedBarChart(dDevices: BarChartData[]) {    
        const self= this;
        const [config,layout] = self.getChartConfig();

        const avgSpeedDevices =dDevices.sort((a, b) => b.averageSpeed - a.averageSpeed); 
        this.graphs.push({
            data :[{
            x: avgSpeedDevices.map(i=> i.label),
            y: avgSpeedDevices.map(i=> self.settingsService.convertSpeed(i.averageSpeed)),
            text: avgSpeedDevices.map(i=> self.settingsService.formatSpeed(i.averageSpeed)),
            hoverinfo: 'text',
            name:avgSpeedDevices.map(i=> i.label),
            type: 'bar',
            marker: {
                color: avgSpeedDevices.map(i=> i.color)
            }}],
            layout :{...layout , 
                yaxis: {
                    title: self.settingsService.getSpeedUnit(),
                }
            },
            config
        });

    }
    
    private addMaximumSpeedBarChart(dDevices: BarChartData[]) {
        const self= this;
        const [config,layout] = self.getChartConfig();

        const maxSpeedDevices =dDevices.sort((a, b) => b.maxSpeed - a.maxSpeed); 
        this.graphs.push({
            data :[{
            x: maxSpeedDevices.map(i=> i.label),
            y: maxSpeedDevices.map(i=> self.settingsService.convertSpeed(i.maxSpeed)),
            text: maxSpeedDevices.map(i=> self.settingsService.formatSpeed(i.maxSpeed)),
            hoverinfo: 'text',
            name:maxSpeedDevices.map(i=> i.label),
            type: 'bar',
            marker: {
                color: maxSpeedDevices.map(i=> i.color)
            }            
            }],
            layout :{...layout , 
                yaxis: {
                    title: self.settingsService.getSpeedUnit(),
                }
            },
            config
        });
    }
    
    
    private addEachDeviceDurationPercentChart(self: this, dDevices: HistoryDevice[]) {
        const traces = [ActivityHistoryType.Driving, ActivityHistoryType.Stopped, ActivityHistoryType.Offline].map(i => {
            return {
                x: [],
                y: [],
                text: [],      
                textposition:"auto",
                insidetextanchor:"middle",          
                name: ActivityHistoryType[i],
                orientation: 'h',
                hoverinfo: 'text',
                type: 'bar',
                marker: {
                    color: self.activityColors[i],
                    width: 1
                }
            };
        });

        const driving = traces[0];
        const stopped = traces[1];
        const offline = traces[2];

        dDevices.forEach(device => {
            const drivingV = self.settingsService.round((device.drivingDuration / device.allDuration));
            driving.x.push(drivingV);
            driving.y.push(device.label);
            driving.text.push(`${drivingV * 100}%`);

            const stoppedV = self.settingsService.round((device.stoppedDuration / device.allDuration));
            stopped.x.push(stoppedV);
            stopped.y.push(device.label);
            stopped.text.push(`${stoppedV * 100}%`);

            const offlineV = self.settingsService.round((device.offlineDuration / device.allDuration));
            offline.x.push(offlineV);
            offline.y.push(device.label);
            offline.text.push(`${offlineV * 100}%`);
        });


        const [config,layout] = self.getChartConfig();
        this.graphs.push({
            data: traces,
            config,
            layout: {...layout,
                xaxis: {
                    tickformat: ',.0%',
                    tickangle: 45
                },
                yaxis: {
                    tickangle: 45
                },
                barmode: 'stack',
                legend: { "orientation": "h" },
                //showlegend: false,
                displayModeBar: false
            }
        });
    }
    private addDeviceDurationDistanceInTimeChart( maxDuration:number, allDuration:number, dDevices: ActivityHistory[]) {
        const self= this;
        var trace1 = {
            x: [],
            y: [],
            text: [],
            mode: 'markers',
            hoverinfo: 'text',
            marker: {
              color: [],
              opacity: [],
              size: []
            }
          };

          dDevices.forEach(i=>{
            trace1.x.push(i.start);
            trace1.y.push(i.duration);
            trace1.text.push(` Distance:'${self.settingsService.formatDistance(i.distance)}' , Duration:'${self.settingsService.formatDuration(i.duration,allDuration)}'`);
            trace1.marker.size.push(i.distance);
            trace1.marker.color.push(i.color);
          });

          
        const [config,layout] = self.getChartConfig();
        this.graphs.push({
            data:[trace1],
            layout:{...layout,
                showlegend: false,
                yaxis:this.getDurationRangeAxis(maxDuration,allDuration)
                //xaxis
            },
          config});
    }

    public chartFullSize:boolean= false;
    private getChartConfig(){
        const self= this;
        var fullScreenIcon = {
            'width': '8',
            'height': '8',
            'viewBox':'0 0 17 16',
            'path': 'M0 0v4l1.5-1.5L3 4l1-1l-1.5-1.5L4 0H0zm5 4L4 5l1.5 1.5L4 8h4V4L6.5 5.5L5 4z'
          };
        var collapseIcon = {
            'width': '8',
            'height': '8',
            'path': 'M1 0L0 1l1.5 1.5L0 4h4V0L2.5 1.5L1 0zm3 4v4l1.5-1.5L7 8l1-1l-1.5-1.5L8 4H4z'
          };
        return [ {
            modeBarButtonsToAdd: [
            {
                name: self.chartFullSize ? 'collapse' :'full screen',
                icon: self.chartFullSize ? collapseIcon :fullScreenIcon ,
                click: function(gd,ee) {
                    self.chartFullSize= !self.chartFullSize;
                    self.drawOnMap();
                }}]
            },{
                height : self.chartFullSize ? window.innerHeight - 80: undefined,
                width : self.chartFullSize ? window.innerWidth: undefined
            }]
    }

    // Clear map from polylines and heatmaps
    clearPolylinesHeatmaps() {
        var self = this;
        // Clear all the existing polylines
        this._polylines.forEach(function(p) {
            self.leafletService.removeFromMap(p);
        });
        // Reset our array
        this._polylines = [];
        // Clear all the existing heatmaps
        this._heatmaps.forEach(function(h) {
            self.leafletService.removeFromMap(h);
        });
        // Reset our array
        this._heatmaps = [];
        // Clear all the existing markers
        this._markers.forEach(function(m) {
            self.leafletService.removeFromMap(m);
        });
        // Reset our array
        this._markers = [];
        this.leafletService.removeTimeLayer();
    }



    /* ------------------------------------------------------------------------- */
    /* -                          Datepicker functions                         - */
    /* ------------------------------------------------------------------------- */
    
    dateFilter = (d: any): boolean => {
        var dateEnabled = false, self = this;
        if (d == undefined || d.date == undefined || d.month == undefined || d.year == undefined)
            return false;
        // For each enabled dates
        this.enabledDates.forEach(function(enabledDate) {
            // Use regexp to get the day, month and year
            var enabledDateArr = enabledDate.match(self.enabledDateRegexp);
            // Check result
            if (enabledDateArr == undefined || enabledDateArr.length <= 3)
                return false;
            // If the current date (d) is the same as one of the enabled date, enable it
            if (d.date() == parseInt(enabledDateArr[2]) && (d.month() + 1) == parseInt(enabledDateArr[1]) &&
                d.year() == parseInt(enabledDateArr[3]))
                dateEnabled = true;
        });
        return dateEnabled;
    }
    getSelectedDays():moment.Moment[] {
        const days:moment.Moment[] = [];
        const start =moment(this.startDate).utc();
        for (const m = start; m.isBefore(this.endDate); start.add(1, 'days')) {
            if(this.dateFilter(m))
            {
                days.push(moment(m));
            }
        }
        days.push(moment(this.endDate));
        return days;
    }
    convertDateTime(start:moment.Moment,timeRange:number):Date {
        const r= moment.tz([start.year() ,start.month() , start.date() ],this.settingsService.getSelectedTimezone()).add(timeRange,'seconds');
        return r.toDate();
    }
    search() {
        const self = this;
        if(this.timeLayerAPI)
            this.timeLayerAPI.stop();
        if (!this.hasValidSelectedDateRange()) {
            self.resetValues(false,false);
            this.canDeleteSelectedDate = false;
            return;
        }


        self.startDate = self.convertDateTime( this.startSelectedDate.value,self.currentTimeRangeMin);       
        self.endDate = self.convertDateTime( this.endSelectedDate.value,self.currentTimeRangeMax);
        
        // Clear variables
        self.resetValues(false,false);
        // Clear polylines and heatmaps
        this.clearPolylinesHeatmaps();
        // Check new date
        //Difference in number of days  
        this.historyDevices = [];
        const selectedDays = self.getSelectedDays();
        self.isMultiDateSelect = ((self.endDate as any) - (self.startDate as any))  > (24 * 60 * 60 * 1000) ;
        self.clearWaiting();
        const tempDevices:Record<string,HistoryDevice> ={};
        self.getNextDate(selectedDays,0,tempDevices); 
    }

    dateChanged(event) {
        if(!event?.target?.value)
            return;

            
        this.settingsService.setStartDate(this.startSelectedDate.value);
        this.settingsService.setEndDate(this.endSelectedDate.value);
    }

    getNextDate(days:moment.Moment[],index:number, tempDevices:Record<string,HistoryDevice>){  
        const self = this;      
        if(!days[index])
            return;
        this.wait();
        self.getDataForDate(moment(days[index])).then((devicesData:any[]) => { 
            self._prepareDevices(devicesData,tempDevices);
        }, (err) => {             
            self.alertService.showMessage(ALERT_TYPE.Error, err);
        }).finally(()=>{             
            if(index < days.length -1)
                self.getNextDate(days,index + 1, tempDevices); 
            else
                self._calculateDevices(tempDevices);  
        });
    }

    resetSelectedDevice(){  
        if(this.selectedDevice)
        this.selectedActivities = this.selectedDevice.activities;
    }
    onUpdateUnits(){        
        this.historyDevices = Array.from(this.historyDevices);
        if(this.chartActivated)
            this.drawOnMap();
    }

    getDataForDate(date) {
        var self = this;
        return new Promise((resolve, reject) => {
            if (date == undefined || date.date == undefined || date.month == undefined || date.year == undefined)
                return reject("An error occurred, invalid date");
            // Get the presigned URL from the server
            // (getMonth() returns the month, but starting from 0)
            this.historyService.getPresignedS3Url(this.currentSite.siteId, date.date(), date.month() + 1, date.year(), TECHNOLOGY.Dragonfly).then((res: any) => {
                // If the res object contains an url (it's a presigned URL)
                // it means we need to get the json file from S3
                if (res != undefined && res.url != undefined) {
                    // Get the JSON content by using the url
                    self.http2.get(res.url).subscribe((devices: any) => {
                        return resolve(devices);
                    }, (err) => {
                        return reject("An error occurred, cannot get the location history from the presigned URL");
                    });
                }
                // Else, it means we directly got device location history
                // (only for the current day, since the JSON file is not uploaded to S3 yet)
                else
                    return resolve(res);
            }, (err) => {
                return reject("An error occurred, cannot get the presigned URL from the server");
            });
        });
    }



    /* ------------------------------------------------------------------------- */
    /* -                          Time slider functions                        - */
    /* ------------------------------------------------------------------------- */

    
    timeFromChanged(event) {
       this.currentTimeRangeMin = (event - (this.zeroTime as any))/1000;
       this.settingsService.setTimeRangeMin(event);
    }


    timeToChanged(event) {
        this.currentTimeRangeMax = (event - (this.zeroTime as any))/1000;
       this.settingsService.setTimeRangeMax(event);
    }


    ChangeChartActivated(chartActivated) {
        this.chartActivated = chartActivated;
        this.drawOnMap();
    }


    /* ------------------------------------------------------------------------- */
    /* -                    Line/Heatmap selector functions                    - */
    /* ------------------------------------------------------------------------- */

    // When user changes the line/heatmap selector
    lineSelectorChanged(event) {
        if(this.heatmapActivated)
            return;
        if(this.timelineActivated)
            this.changePlayingState(false,true);
        this.heatmapActivated = true;
        this.heightActivated = false;        
        this.timelineActivated = false;
        this.drawOnMap();
    }
    heightSelectorChanged(event) {
        if(this.heightActivated)
            return; 
        if(this.timelineActivated)
            this.changePlayingState(false,true);
            this.heatmapActivated = false;
            this.heightActivated = true;        
            this.timelineActivated = false;
        this.drawOnMap();
    }
    // When user changes the line/heatmap selector
    timelineActivatedChanged(event) {        
        if(this.timelineActivated)
            return;
        this.heatmapActivated = false;
        this.heightActivated = false;        
        this.timelineActivated = true;  
        this.changePlayingState(false,true);

        this.drawOnMap();
    }
    // When user changes the line/heatmap selector
    noneActivatedChanged(event) {  
        if(this.timelineActivated)
            this.changePlayingState(false,true);
        this.timelineActivated = false;
        this.heightActivated = false;
        this.heatmapActivated = false;
        this.drawOnMap();
    }


    // When user changes the line/heatmap selector
    /* ------------------------------------------------------------------------- */
    /* -                           Delete functions                            - */
    /* ------------------------------------------------------------------------- */
    // When a user wants to delete the selected date
    public deleteSelectedDate() {
        const self = this;
        // If missing data in the date, stop here
        if (!this.hasValidSelectedDateRange()) {
            this.canDeleteSelectedDate = false;
            this.alertService.showMessage(ALERT_TYPE.Error, "An error occurred, invalid date");
            return;
        }
        // Ask the user if he really wants to
        var dialog = this.dialog.open(YesNoDialogComponent, { panelClass: ['mw-none','w-90','w-sm-80','w-md-50','w-lg-40'], data: {
            title: "Delete data",
            message:  "Are you sure you want to delete the data from " + moment(this.startDate).format('MMMM Do YYYY') + 
            "to " + moment(this.endDate).format('MMMM Do YYYY') + 
            "  ?<br/><br/><b>This action is permanent and irreversible.</b>",
            noBtnLabel: "No",
            yesBtnLabel: "Yes",
            YesIsAccent: true
        }});
        dialog.updatePosition({ top: this.responsiveService.getDialogTopPosition() + 'px' });
        // When user answers
        dialog.afterClosed().subscribe(result => {
            // If true, means user wants
            if (result == true) {
                // Send a request to delete the data for this day
                self.isDeletingData = true;
                self.clearWaiting();
                self.deleteNextDate(self.getSelectedDays(),0);
            }
        });
    }
    deleteNextDate(days:moment.Moment[],index:number){
        const self = this;
        const date = days[index];
        self.wait();
        self.historyService.deleteDayData(self.currentSite.siteId, date.date(), date.month() + 1, date.year(), TECHNOLOGY.Dragonfly).then((res: any) => {
            // Success
        }, (err) => {
            self.alertService.showMessage(ALERT_TYPE.Error, "An error occurred, cannot delete data for this day");
        }).finally(() => {
          
            if(index < days.length -1)
            {
                self.deleteNextDate(days,index + 1); 
            }
            else{
                
                self.clearWaiting();
                self.resetValues(true,true);
                // Get the enabled dates
                self._getDates();
            } 
            self.continue();
        });
    }
    // When a user wants to delete ALL the data
    public resetValues(resetDate:boolean,resetEnabledDate:boolean) {
        const self = this;
        self.isDeletingData = false;

        // Clear variables
        if(resetDate)
        {
             self.startSelectedDate.reset();
             self.endSelectedDate.reset();
        }
        if(resetEnabledDate)
        {
            self.enabledDates = [];
        }
        
        self.clearPolylinesHeatmaps();
        self.selectedActivities = [];
        self.selectedDevice = null;
        self.historyDevices = [];
        self.canDeleteSelectedDate = false;
    }
    public changeFilterBasedOnLevel() {
        this.filterBasedOnLevel = !this.filterBasedOnLevel; 
        this.settingsService.setFilterBasedOnLevel(this.filterBasedOnLevel);       
    }

    // When a user wants to delete ALL the data
    public deleteAllData() {
        const self = this;
        // Ask the user if he really wants to
        var dialog = self.dialog.open(YesNoDialogComponent, { panelClass: ['mw-none','w-90','w-sm-80','w-md-50','w-lg-40'], data: {
            title: "Delete ALL data",
            message:  "Are you sure you want to delete ALL the data for this whole site?<br/><br/><b>This action is permanent and irreversible.</b>",
            noBtnLabel: "No",
            yesBtnLabel: "Yes",
            YesIsAccent: true
        }});
        dialog.updatePosition({ top: self.responsiveService.getDialogTopPosition() + 'px' });
        // When user answers
        dialog.afterClosed().subscribe(result => {
            // If true, means user wants to enable web sockets
            if (result == true) {
                // Send a request to delete the data for this site
                self.isDeletingData = true;
                self.historyService.deleteData(self.currentSite.siteId, TECHNOLOGY.Dragonfly).then((res: any) => {
                    // Success
                    // Clear variables
                    self.historyStatus = undefined;
                    self.resetValues(true,true);
                    // Get the enabled dates
                    self._getDates();
                }, (err) => {
                    self.alertService.showMessage(ALERT_TYPE.Error, "An error occurred, cannot delete data for this site");
                }).finally(() => {
                    self.isDeletingData = false;
                });
            }
        });
    }
}