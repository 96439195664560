// List of all the setting types for the map settings
export enum SETTING_TYPE {
    Checkbox = "checkbox",
    SlideToggle = "slideToggle",
    Divider = "divider"
};

export class MapSetting {

    public id: string;
	public type: SETTING_TYPE;
    public value: any;
	public label?: string;
	public label2?: string;

	constructor(id: string, type: SETTING_TYPE, value: any, label?: string, label2?: string) {
        this.id = id;
		this.type = type;
        this.value = value;
		this.label = label;
		this.label2 = label2;
    }

    // Second constructor
    static fromData(data: any) {
    	if (data == undefined)
    		return undefined;
        let { id, type, value, label, label2 } = data;
        if (id == undefined || type == undefined)
            return undefined;
        return new this(id, type, value, label, label2);
    }
}

export class MapSettings {

	public title: string;
	public settings: MapSetting[];
    public onChange?: any;

    constructor(title: string, settings: MapSetting[], onChange?: any) {
		this.title = title;
		this.settings = settings;
        this.onChange = onChange;
    }

    // Function to get the mapSetting value corresponding to this ID
    public getMapSetting(id: string) {
        var res = undefined;
        if (id != undefined && this.settings != undefined && this.settings.length > 0) {
            this.settings.forEach(function(s) {
                if (s.id == id)
                    res = s.value;
            });
        }
        return res;
    }

    // Function to set the mapSetting value corresponding to this ID
    public setMapSetting(id: string, value: any) {
        if (id != undefined && value != undefined && this.settings != undefined && this.settings.length > 0) {
            this.settings.forEach(function(s) {
                if (s.id == id)
                    s.value = value;
            });
        }
    }

    // Update the properties by looking at the MapSettings object passed in parameter
    public updateMapSettings(newSettings: MapSetting[]) {
        // Check parameters
        if (newSettings == undefined || newSettings.length <= 0)
            return;
        var self = this;
        // For each setting, update the value
        newSettings.forEach(function(s) {
            if (s != undefined && s.id != undefined)
                self.setMapSetting(s.id, s.value);
        });
    }

    // Second constructor
    static fromData(data: any) {
    	if (data == undefined)
    		return undefined;
        let { title, settings, onChange } = data;
        if (settings != undefined && settings.length > 0)
        	settings = settings.map(function(s){return MapSetting.fromData(s)});
        return new this(title, settings, onChange);
    }
}