import { Component, OnDestroy, OnInit, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { SiteService } from '../../_services/site.service';
import { ALERT_TYPE, AlertService } from '../../_services/alert.service';
import { LeafletService } from '../../_services/leaflet.service';
import { UtilsService } from '../../_services/utils.service';

import { Site } from '../../_models/site';
import { MapData } from '../../_models/mapData';

@Component({templateUrl: 'site.component.html'})
export class SiteComponent implements OnInit, AfterViewInit, OnDestroy {

	// The current site
	public currentSite: Site;
	// Boolean to know if user wants to edit the address (to avoid mistakes)
	public canEditAddress: Boolean = false;
	// Boolean when we are requesting the server
	public isSavingSite: Boolean = false;

	// The map container
	@ViewChild('map', { static: true }) mapContainer: ElementRef;
	private mapId = 'map';

	// List of subs
    private subs: Subscription[] = [];

	// Site form
	siteForm = this.fb.group({
		siteId: [{value: '', disabled: true}],
		company: [{value: '', disabled: true}],
        name: [''],
        desc: [''],
        access: [{value: '', disabled: true}],
        address: [{value: '', disabled: true}]
    });

    constructor(private siteService: SiteService, private fb: FormBuilder, private alertService: AlertService,
    			private router: Router, private leafletService: LeafletService, private utilsService: UtilsService) {}

    ngOnInit() {
    	// Get the selected site
		this.subs.push(this.siteService.currentSite.subscribe((site: Site) => {
			// If site is different, disable the address
			if (site && this.currentSite && site.siteId != this.currentSite.siteId)
				this.setEditAddress(false);
			this.currentSite = site;
			// Set the form values
			this.siteForm.patchValue(this.currentSite || {});
			// Try to draw the map
			this.drawMap();
		}));
    }

    // When user changes the address manually with keyboard
    addressFocusout(event) {
    	if (event && event.target && event.target.value)
    		this.leafletService.moveMapToCoordinates(this.utilsService.getCoordinateFromAddress(event.target.value));
    }

    ngAfterViewInit() {
    	// Try to draw the map
    	this.drawMap();
    }

    private drawMap() {
    	if (this.currentSite != undefined && this.mapContainer != undefined) {
    		var self = this;
    		this.leafletService.drawMap(this.mapId, new MapData({
    			crosshair: true,
    			isDisabled: true,
    			showFloorplan: false,
    			centerOnFloorplan: false,
    			fullscreenControl: false,
    			onMove: function(e) {
    				// Update the address input
    				if (e && e.target && e.target.getCenter) {
    					self.siteForm.controls['address'].patchValue(e.target.getCenter().lat.toFixed(7) + ',' + e.target.getCenter().lng.toFixed(7));
    					self.siteForm.markAsDirty();
    				}
    			}
    		}));
    	}
    }

	ngOnDestroy() {
		// Clear the subs
        this.subs.forEach(function(sub) { sub.unsubscribe(); });
		// Remove the map and clear the html element passed as parameter
		this.leafletService.removeMap();
	}

	// When user saves the site
	onSubmit() {
		// Try to instantiate a Site object
		var newSite = Site.fromData(this.siteForm.getRawValue());
		// Check the configuration is correct
		if (this.siteForm.invalid || newSite == undefined) {
			// Error
			this.alertService.showMessage(ALERT_TYPE.Error, "The site configuration is incorrect");
			return;
		}
		// If nothing has been changed, go back without doing anything
		if (this.siteForm.dirty == false) {
			// Navigate back
			this.router.navigate(['/general']);
			return;
		}
		this.isSavingSite = true;
		// Request the server to update the site
		this.siteService.saveSite(newSite).then(() => {
			// Success
			this.isSavingSite = false;
			// Show success message
			this.alertService.showMessage(ALERT_TYPE.Success, "The site has been correctly updated");
			// Navigate back
			this.router.navigate(['/general']);
		}, () => {
			// Error
			this.isSavingSite = false;
			this.alertService.showMessage(ALERT_TYPE.Error, "An error occurred, cannot update the site");
		});
	}

	// When user clicks the 'Edit' button to edit the address
	setEditAddress(val) {
		if (val == true) {
			this.canEditAddress = true;
			// Update the form control
			this.siteForm.controls['address'].enable();
			// Enable the map
			this.leafletService.enableMap();
		} else {
			this.canEditAddress = false;
			// Update the form control
			this.siteForm.controls['address'].disable();
			// Enable the map
			this.leafletService.disableMap();
		}
	}

}