import { Component, OnDestroy, LOCALE_ID, Inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { formatDate } from '@angular/common';

import { LicenseService } from '../../_services/license.service';
import { SiteService } from '../../_services/site.service';

import { Site } from '../../_models/site';

@Component({
	templateUrl: 'license.component.html',
	styleUrls: ['license.component.scss']
})
export class DragonflyLicenseComponent implements OnDestroy {

	// License info
	public licenseInfo = undefined;
	// Registered devices
	public registeredDevices = undefined;

	// The current site
	public currentSite: Site;

	// List of subs
	private subs: Subscription[] = [];

	// Stations table options
    public stationsTableOptions = {
        editable: false,
        deletable: true
    }
    public stationsDisplayedColumns;
    public stationsAttributesToDisplay;

    constructor(@Inject(LOCALE_ID) private locale: string, private licenseService: LicenseService, private siteService: SiteService) {
    	// Get the selected site
		this.subs.push(this.siteService.currentSite.subscribe((site: Site) => {
			this.currentSite = site;
            // Reset variables
            this.licenseInfo = undefined;
            this.registeredDevices = undefined;
            // Get the license info
            this.subs.push(this.licenseService.dragonflyLicenseInfo.subscribe((licenseInfo) => {
                // Cast the timestamp to Date
                if (licenseInfo != undefined && licenseInfo.license_end_timestamp != undefined)
                    licenseInfo.license_end_timestamp = formatDate(new Date(licenseInfo.license_end_timestamp), 'longDate', this.locale);
                this.licenseInfo = licenseInfo;
                // If there is a license, request the list of registered devices
                if (this.licenseInfo != undefined && this.licenseInfo.error == undefined) {
                    // Get the registered devices
                    this.subs.push(this.licenseService.dragonflyRegisteredDevices.subscribe((registeredDevices) => {
                        this.registeredDevices = registeredDevices;
                    }));
                }
            }));
		}));
    	// Instantiate the table variables
        this.stationsDisplayedColumns = ['device', 'lastAuthenticationTimestamp', 'ip'];
        this.stationsAttributesToDisplay = [
            {display: 'Device ID', columnDef: 'device', getFct: (n) => {
                if (n.device == undefined)
                    return 'n/a';
                return n.device;
            }},
            {display: 'Last authentication date', columnDef: 'lastAuthenticationTimestamp', getFct: (n) => {
                if (n.lastAuthenticationTimestamp == undefined)
                    return 'n/a';
                return formatDate(new Date(n.lastAuthenticationTimestamp), 'medium', this.locale);
            }},
            {display: 'IP', columnDef: 'ip', getFct: (n) => {
                if (n.ip == undefined)
                    return;
                return n.ip;
            }, clickable: (n) => {
                if (this.currentSite && this.currentSite.access == 'superuser' && n.ip)
                    window.open('https://whatismyipaddress.com/ip/' + n.ip);
            }}
        ];
    }

    ngOnDestroy() {
    	// Clear the subs
    	this.subs.forEach(function(sub) { sub.unsubscribe(); });
	}

    onDeviceDeleted(device) {
        // Remove the device from our list
        this.registeredDevices = this.registeredDevices.filter((d) => {
            return d.mac != device.mac;
        });
    }

}