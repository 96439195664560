import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

// Number of pixel before leaving the mobile mode
const PIXEL_WIDTH_MOBILE = 576;
// Number of pixel before the drawers collapse
const PIXEL_WIDTH_DRAWER = 992;
// Number of pixel before the dialogs moves up
const PIXEL_WIDTH_DIALOG_UP = 1000;

@Injectable({ providedIn: 'root' })
export class ResponsiveService {

	private windowWidthSubject: BehaviorSubject<number>;
    public windowWidth: Observable<number>;

	private isMobileSubject: BehaviorSubject<boolean>;
    public isMobile: Observable<boolean>;

    private drawerCollapseSubject: BehaviorSubject<boolean>;
    public drawerCollapse: Observable<boolean>;

    constructor() {
        // Instantiate the subjects/observables
    	this.windowWidthSubject = new BehaviorSubject<number>(1080);
        this.windowWidth = this.windowWidthSubject.asObservable();
        this.isMobileSubject = new BehaviorSubject<boolean>(false);
        this.isMobile = this.isMobileSubject.asObservable();
        this.drawerCollapseSubject = new BehaviorSubject<boolean>(false);
        this.drawerCollapse = this.drawerCollapseSubject.asObservable();
    	// Update the window width for the first time
    	if (window != undefined)
    		this.updateWindowWidth(window.innerWidth);
    	// Add event listener
		window.addEventListener('resize', (event) => {
			this.updateWindowWidth(window.innerWidth);
		});
    }

    private updateWindowWidth(innerWidth) {
    	// Check the width is a correct value
    	if (innerWidth != undefined && isNaN(innerWidth) == false) {
    		this.windowWidthSubject.next(innerWidth);
    		// Check if we are in mobile mode
    		var mobileModeEnabled = (innerWidth < PIXEL_WIDTH_MOBILE) ? true : false;
    		this.isMobileSubject.next(mobileModeEnabled);
            // Check if the drawers should be collapsed
            var drawerCollapsed = (innerWidth < PIXEL_WIDTH_DRAWER) ? true : false;
            this.drawerCollapseSubject.next(drawerCollapsed);
    	}
    }

    // Return the top position (from the top) in pixels for the dialogs position
    public getDialogTopPosition() {
        if (window != undefined && window.innerWidth != undefined && isNaN(window.innerWidth) == false)
            return (window.innerWidth < PIXEL_WIDTH_DIALOG_UP) ? 30 : 80;
    }
}