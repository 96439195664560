<!-- <mat-toolbar class="d-block d-lg-none" color="primary">
	<mat-toolbar-row> -->
		<!-- Open mobile menu button -->
        <!-- <button class="" mat-icon-button (click)="componentDrawer.toggle()">
            <mat-icon class="position-relative" style="top: -1px">menu</mat-icon>
        </button> -->
        <!-- Title -->
		<!-- <button mat-button class="noselect" style="font-size: 16px" [routerLink]="['/dragonfly']">Dragonfly</button>
	</mat-toolbar-row>
</mat-toolbar> -->

<mat-drawer-container [hasBackdrop]="drawerCollapsed">

	<!-- Drawer -->
	<!-- <mat-drawer *ngIf="disableMenu != 'true'" [mode]="(drawerCollapsed) ? 'over' : 'side'" #componentDrawer [opened]="!drawerCollapsed" class="w-15" style="width: 200px;" [ngClass]="{'fixedToTop': hasScrollMoreThanTopBar}">
		
		<mat-toolbar class="d-none d-lg-block" color="primary">
			<mat-toolbar-row>
				<button mat-button class="noselect" style="font-size: 16px" [routerLink]="['/dragonfly']">Dragonfly</button>
			</mat-toolbar-row>
		</mat-toolbar>
		<button mat-button *ngFor="let category of categories" class="w-100 rounded-0 text-left py-1" [routerLink]="category.routerLink" (click)="closeDrawer()">
			{{category.title}}
		</button>
	</mat-drawer> -->

	<!-- Main content -->
	<mat-drawer-content class="componentDrawer py-4">
		<router-outlet></router-outlet>
	</mat-drawer-content>

</mat-drawer-container>