<h1 class="text-center my-4">Reset password</h1>

<form (ngSubmit)="sendResetPasswordEmail()" [formGroup]="resetForm">

    <!-- Username -->
    <div class="d-flex justify-content-center mb-2" *ngIf="done != true">
        <mat-form-field style="min-width: 250px !important" color="accent">
            <input matInput placeholder="Email" name="username" formControlName="email">
            <mat-error *ngIf="resetForm.controls['email'].errors?.email && !resetForm.controls['email'].errors?.required">
                Please enter a valid email address
            </mat-error>
            <mat-error *ngIf="resetForm.controls['email'].errors?.required">
                Email is required
            </mat-error>
        </mat-form-field>
    </div>

    <!-- Reset button -->
    <div class="d-flex justify-content-center py-4" *ngIf="done != true">
        <button mat-raised-button *ngIf="loading == false" color="accent" [disabled]="resetForm.invalid">Reset password</button>
        <mat-spinner *ngIf="loading == true" color="accent" [diameter]="36"></mat-spinner>
    </div>

    <!-- Success message -->
    <div class="d-flex justify-content-center py-4" *ngIf="done == true">
        An email to reset your password has been sent.
    </div>
    <div class="d-flex justify-content-center py-4" *ngIf="done == true">
        <button mat-raised-button color="accent" routerLink="/login">Login</button>
    </div>

</form>